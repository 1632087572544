import { Modal, Text } from "@RooUI";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { showToast } from "../../../../Common/Toasts/action/ToastActions";

import { clearUnsavedChangesState, setContinueFromUnsavedChanges } from "./actions";

export function UnsavedChangesModal({
  when,
  onSaveAllChanges,
  btnTitle = "Yes, Save All Changes",
  content = " You have unsaved changes that will be lost if you leave this page. Would you like to save all changes before leaving this page?",
}: {
  when: boolean;
  onSaveAllChanges: () => void;
  btnTitle?: string;
  content?: string;
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { checkForUnsavedChanges } = useSelector((state: any) => state.unsavedChanges);

  const [isShowModal, setIsShowModal] = useState(false);
  const [currentPath, setCurrentPath] = useState("");

  const unblockRef = useRef<any>();

  useEffect(() => {
    if (when) {
      unblockRef.current = history.block((location): string | false | void => {
        setCurrentPath(location.pathname);
        setIsShowModal(true);
        return false;
      });
    }

    return () => {
      unblockRef.current && unblockRef.current();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [when]);

  useEffect(() => {
    if (checkForUnsavedChanges) {
      setIsShowModal(when);
      dispatch(setContinueFromUnsavedChanges(!when));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [when, checkForUnsavedChanges]);

  // timeout, then go to page
  // hospital profile wasn't unsetting without timeout
  const goToPage = useCallback(() => {
    setTimeout(() => {
      history.push(currentPath);
      dispatch(clearUnsavedChangesState());
    }, 2000);
  }, [currentPath, history, dispatch]);

  const handleChanges = useCallback(
    async (isSaveAllChanges: boolean) => {
      setIsShowModal(false);
      if (onSaveAllChanges) {
        if (unblockRef) {
          unblockRef.current();
        }

        // save changes
        if (isSaveAllChanges) {
          try {
            onSaveAllChanges();
          } catch {
            dispatch(
              showToast({
                variant: "error",
                message: "Uh Oh!",
                description: "There are errors in the form.",
              })
            );
            return;
          }
          dispatch(
            showToast({
              variant: "success",
              message: "Roo-hoo!",
              description: `Changes were successfully saved.`,
            })
          );
        } else {
          dispatch(clearUnsavedChangesState());
        }

        if (currentPath) {
          goToPage();
        }
      }
    },
    [currentPath, onSaveAllChanges, dispatch, goToPage]
  );

  return isShowModal ? (
    <Modal
      showModal={isShowModal}
      onCloseModal={() => setIsShowModal(false)}
      $size="m"
      header="Unsaved Changes"
      body={<Text>{content}</Text>}
      $primaryButtonProps={{
        title: btnTitle,
        "data-testid": "postShiftsButton",
        onClick: () => handleChanges(true),
      }}
      $secondaryButtonProps={{
        title: "Discard Changes",
        "data-testid": "discardChangeButton",
        onClick: () => handleChanges(false),
      }}
      $buttonVariant="confirmation"
    />
  ) : null;
}
