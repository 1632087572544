import { rooFetch } from "./common/rooFetch";

export interface ShiftDetails {
  additionalComments: string;
  additionalDoctor: number | null;
  appointmentTypes: string;
  avgWellnessApptLen: number;
  cancellationReasonId: number | null;
  travelStipendAmount: number | null;
  confirmedVetId: number | null;
  confirmedVetName: string | null;
  contactPerson: string;
  endTime: string;
  expectedAppointmentTypeId: number;
  expectedLunchTime: string;
  expectedNoOfAppointments: string;
  flexEndTimeRange: string;
  flexStartTimeRange: string;
  hospitalEmail: string;
  hospitalId: number;
  hospitalImage: string | null;
  hospitalName: string;
  hospitalPhoneNumber: string;
  hospitalTimezone: string;
  hospitalType: number;
  imagePath: string | null;
  instantBookable: number;
  isAutoConfirmed: boolean | null;
  isDEALicenseRequired: number;
  isDeductBoostAmount: boolean | null;
  isFlexibleSurgery: number;
  isRequestWithoutSurgery: boolean | null;
  isSurgeryExpected: number;
  isVetBid: boolean | null;
  isWalkInsOrDropOffs: number;
  isWithin24Hours: number;
  metroAreaId: number | null;
  procedureTypes: any[];
  promoBoostAmount: number | null;
  requestedHospitalShiftAmount: number | null;
  requestedOn: string | null;
  requestedShiftEndTime: string | null;
  requestedShiftStartTime: string | null;
  requestedVetIds: number[] | null;
  requestedVetShiftAmount: number | null;
  shiftAmount: number;
  shiftConfirmedOn: string | null;
  shiftCreatedBy: number;
  shiftCreatedOn: number;
  shiftDate: string;
  shiftEndDatetime: number;
  shiftGroupId: number;
  shiftId: number;
  shiftRepeat: string;
  shiftRequestDetails: string;
  shiftRequestEndTimestamp: string | null;
  shiftRequestedOn: string | null;
  shiftStartDatetime: number;
  shiftStatus: string | null;
  shiftType: string;
  startTime: string;
  stateId: number;
  stripeTransferId: string | null;
  surgeryTier: number;
  totalPostConfirmEditAdditionalAmount: number;
  totalShiftBoostAmount: number | null;
  updatedOn: string | null;
  userId: number;
  vetEmail: string | null;
  vetMobilePhone: string | null;
  vetShiftAmount: number;
  vetShiftAmountUpdated: string | null;
  vetUserId: number | null;
  zipcode: string;
  confirmedTechId?: number | null;
  deletedOn: string | null;
  deletedBy: number | null;
}

export const fetchVetShiftDetails = async ({
  shiftGroupId,
  vetId,
  isGetRatingDetails,
  latitude,
  longitude,
}: {
  shiftGroupId: string | number;
  vetId?: string | number;
  isGetRatingDetails?: number;
  latitude?: string | number;
  longitude?: string | number;
}) => {
  const response = await rooFetch(
    `${window.RooConfig.API_URL}api/hospital/shift/getShiftDetailsById?shiftGroupId=${shiftGroupId}&vetId=${vetId}&isGetRatingDetails=${isGetRatingDetails}&latitude=${latitude}&longitude=${longitude}`
  );

  const { data } = await response.json();
  return data.data?.[0] || {};
};

export const fetchHospitalAvailableVetShifts = async (
  hospitalId: string | number,
  vetId: string | number,
  latitude: string | number,
  longitude: string | number
) => {
  const response = await rooFetch(
    `${window.RooConfig.API_URL}api/vet/shift/getShiftsByHospitalId?hospitalId=${hospitalId}&vetId=${vetId}&isExcludeBlockedShifts=true&latitude=${latitude}&longitude=${longitude}`
  );

  const { data } = await response.json();
  return data?.data || [];
};

export const requestVetShift = async (params: any): Promise<any> => {
  const response = await rooFetch(`${window.RooConfig.API_URL}api/vet/shift/requestShift`, {
    method: "POST",
    body: JSON.stringify(params),
  });

  const resData = await response.json();
  return resData?.data || {};
};
