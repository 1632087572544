import moment from "moment";
import React from "react";

import { useShiftDetailsStore } from "@RooBeta/store";

import { ConfirmedShiftCTA } from "./ConfirmedShiftCTA";
import { RequestedShiftCTA } from "./RequestedShiftCTA";
import { UnfilledShiftCTA } from "./UnfilledShiftCTA";
import { OverlappingShiftCTA } from "./OverlappingShiftCTA";

export const ShiftCTA = () => {
  const { shiftDetails } = useShiftDetailsStore();
  const { shiftStatus, shiftDate } = shiftDetails || {};
  const now = moment();
  const isShiftInPast = shiftDate ? moment(shiftDate).isBefore(now, "day") : false;
  const isOverlappingShift = shiftDetails?.isOverlapping;

  switch (shiftStatus) {
    case "REQUESTED":
      return <RequestedShiftCTA />;
    case "UNFILLED":
      return <>{isOverlappingShift ? <OverlappingShiftCTA /> : <UnfilledShiftCTA />}</>;
    case "CONFIRMED":
      return <ConfirmedShiftCTA isShiftInPast={isShiftInPast} />;
    default:
      return null;
  }
};
