import React, { createContext, useContext, useState, useCallback, useMemo } from "react";

type ChatNotificationsContextType = {
  isOpen: boolean;
  openNotifications: () => void;
  closeNotifications: () => void;
};

const ChatNotificationsContext = createContext<ChatNotificationsContextType | undefined>(undefined);

export const ChatNotificationsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const openNotifications = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeNotifications = useCallback(() => {
    setIsOpen(false);
  }, []);

  const value = useMemo(
    () => ({
      isOpen,
      openNotifications,
      closeNotifications,
    }),
    [isOpen, openNotifications, closeNotifications]
  );

  return (
    <ChatNotificationsContext.Provider value={value}>{children}</ChatNotificationsContext.Provider>
  );
};

export const useChatNotifications = () => {
  const context = useContext(ChatNotificationsContext);
  if (context === undefined) {
    throw new Error("useChatNotifications must be used within a ChatNotificationsProvider");
  }
  return context;
};
