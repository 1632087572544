import React, { useRef, useState } from "react";
import { VList } from "virtua";

import { NAVIGATION_HEIGHT, SEARCH_HEIGHT } from "@RooBeta/constants";
import { useViewportStore } from "@RooBeta/store";
import { UngroupedShiftList } from "@RooBeta/types";
import { ShiftCardPointOfEntry } from "@Roo/constants/posthogEventContextTypes";

import { spacingXXL } from "@RooUI";
import { ShiftCard } from "./ShiftCard";
import { StyledScrollArea } from "./styles";
import { GlobalNotificationCard } from "../globalNotificationCard";
import { ShiftProposalHospitalList } from "./ShiftProposalHospitalList";
import { useRequiredAuthorizedUser, useSearchRequestBody } from "@RooBeta/hooks";
import { ToggleHiddenShiftsRow } from "./ToggleHiddenShiftsRow";

type FlatShiftListProps = {
  shiftList: UngroupedShiftList;
  hiddenShiftList?: UngroupedShiftList;
  fixedHeight?: boolean;
  page?: string;
};

export const FlatShiftList = ({ fixedHeight = true, page, ...props }: FlatShiftListProps) => {
  const { windowSize } = useViewportStore();
  const containerHeight = windowSize.height - (NAVIGATION_HEIGHT + SEARCH_HEIGHT);
  const { provider } = useRequiredAuthorizedUser();
  const proposalListRef = useRef<HTMLDivElement>(null);
  const [showHiddenShifts, setShowHiddenShifts] = useState(false);
  const searchRequestBody = useSearchRequestBody();
  const isSchedulePage = page === "schedule";

  // Only include hidden shifts if showHiddenShifts is true
  const visibleShifts = props.shiftList.shifts;
  const hiddenShifts =
    showHiddenShifts && props.hiddenShiftList ? props.hiddenShiftList.shifts : [];
  const allShifts = [...visibleShifts, ...hiddenShifts];
  const hasHiddenShifts = !!props.hiddenShiftList?.shifts.length;

  // ShiftProposalHospitalList component
  const ProposalListRow = () => (
    <div ref={proposalListRef} style={{ padding: spacingXXL }}>
      <ShiftProposalHospitalList />
    </div>
  );

  const showProposalList = !isSchedulePage;
  const totalShifts = allShifts.length;
  const rowCount = totalShifts + (hasHiddenShifts ? 1 : 0) + (showProposalList ? 1 : 0);
  const lastRowIndex = totalShifts + (hasHiddenShifts ? 1 : 0);

  const renderRow = (virtualIndex: number) => {
    // Last row - ShiftProposalHospitalList
    if (showProposalList && virtualIndex === lastRowIndex) {
      return <ProposalListRow />;
    }

    // Toggle row - after all visible shifts but before ShiftProposalHospitalList
    if (hasHiddenShifts && virtualIndex === visibleShifts.length) {
      return (
        <ToggleHiddenShiftsRow
          showHiddenShifts={showHiddenShifts}
          hiddenShiftsCount={props.hiddenShiftList?.count}
          onToggle={() => setShowHiddenShifts(!showHiddenShifts)}
        />
      );
    }

    // account for toggle row
    const shiftIndex = virtualIndex < visibleShifts.length ? virtualIndex : virtualIndex - 1;

    if (shiftIndex >= allShifts.length) {
      return <></>;
    }

    const shift = allShifts[shiftIndex];
    const isFirstShift = shiftIndex === 0;

    return (
      <div>
        {isFirstShift && !isSchedulePage && <GlobalNotificationCard />}
        <ShiftCard
          shift={shift}
          count={props.shiftList.count}
          variant="list"
          pointOfEntry={
            isSchedulePage
              ? ShiftCardPointOfEntry.MyShiftsPane
              : ShiftCardPointOfEntry.SearchShiftsPane
          }
          data-testid={`flatShiftListCard${shift.shiftId}`}
          searchParameters={isSchedulePage ? undefined : searchRequestBody}
          provider={provider}
        />
      </div>
    );
  };

  return (
    <StyledScrollArea
      $height={containerHeight}
      $fixedHeight={fixedHeight}
      data-testid="flatShiftListScrollArea"
    >
      {() => (
        <div style={{ width: "100%", height: containerHeight }}>
          <VList count={rowCount} overscan={5}>
            {renderRow}
          </VList>
        </div>
      )}
    </StyledScrollArea>
  );
};
