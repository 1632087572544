import * as Sentry from "@sentry/react";
import { call, put, takeEvery } from "redux-saga/effects";

import { rooFetch } from "../../api/common/rooFetch";
import * as C from "../../constants/Unsubscribe";

// @ts-expect-error TS7006
function* unsubscribeVetWeeklyEmailAsync(action) {
  try {
    const { vetId, userId } = action.payload;
    // @ts-expect-error TS7057
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/vet/unsubscribeWeeklyEmail",
      {
        method: "POST",
        body: JSON.stringify({ vetId, userId }),
      }
    );

    // @ts-expect-error TS7057
    const data = yield response.json();

    if (data.status_code === 200) {
      yield put({ type: C.UNSUBSCRIBE_VET_WEEKLY_EMAIL_SUCCESS });
    } else {
      Sentry.captureException(`Error while unsub vet weekly emails: ${data.status_code}`);
      yield put({ type: C.UNSUBSCRIBE_VET_WEEKLY_EMAIL_FAIL });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while unsubscribing vet weekly email: " },
    });
    yield put({ type: C.UNSUBSCRIBE_VET_WEEKLY_EMAIL_FAIL });
  }
}

// @ts-expect-error TS7006
function* unsubscribeNewEventsEmailAsync(action) {
  try {
    // @ts-expect-error TS7057
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/user/unsubscribeNewEventEmail",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    // @ts-expect-error TS7057
    const data = yield response.json();

    if (data.status_code === 200) {
      yield put({ type: C.UNSUBSCRIBE_NEW_EVENTS_EMAIL_SUCCESS });
    } else {
      yield put({ type: C.UNSUBSCRIBE_NEW_EVENTS_EMAIL_FAIL });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while unsubscribing new events email: " },
    });
    yield put({ type: C.UNSUBSCRIBE_NEW_EVENTS_EMAIL_FAIL });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootUnsubscribeSaga() {
  yield takeEvery(C.UNSUBSCRIBE_VET_WEEKLY_EMAIL, unsubscribeVetWeeklyEmailAsync);
  yield takeEvery(C.UNSUBSCRIBE_NEW_EVENTS_EMAIL, unsubscribeNewEventsEmailAsync);
}
