import { useQuery } from "@tanstack/react-query";

import { get } from "@RooBeta/api";
import { CacheKey } from "@RooBeta/types";
import { getShiftProposals } from "./endpoints";

interface ShiftProposal {
  shiftProposalId: number;
  hospitalId: number;
  commsSentOn: string;
}

type ShiftProposalStatus = "active" | "deleted";

export const useGetShiftProposals = (
  status: ShiftProposalStatus,
  options?: { onSuccess: () => void; onError: () => void }
) => {
  return useQuery({
    queryKey: [CacheKey.ShiftProposals, status],
    queryFn: async () => {
      const result = await get<{ data: ShiftProposal[] }>(getShiftProposals(status));
      return result?.data;
    },
    onSuccess: options?.onSuccess,
    onError: options?.onError,
  });
};
