import dayjs from "dayjs";
import { find } from "lodash";

import { getProcedureTypeLabels } from "../Common/shiftDataHelper";
import { ShiftStatus, ToastType } from "../ProfilePanels/ProfilePanelTypes";
import { fetchContractStatus } from "../api/contracts";
import { fetchVetShiftDetails, requestVetShift } from "../api/shift";
import { fetchTechShiftDetails, requestTechShift } from "../api/techShift";
import { License } from "../api/vet";
import { oApptTypesOptions } from "../constants/dropDownConstants";
import { EXCEPTION_MESSAGE } from "../constants/errorMessage";
import { SURGERY_TIER } from "../constants/radiobuttonConstants";
import { californiaStateId } from "../constants/states";
import * as userStatusTypes from "../constants/userStatusTypes";
import { checkContractorHasLicenseRequiredForState, getHourlyRate } from "../helpers/jsUtility";
import { formatPrice } from "../helpers/textUtility";

import { toastMessages } from "./constants";

export const checkTechLicenseRequirement = ({
  hasCALicense,
  hasTechLicense,
  isLicenseRequired,
  hospitalStateId,
  licenses,
}: {
  hasCALicense: number;
  hasTechLicense: number;
  isLicenseRequired: number;
  hospitalStateId: number;
  licenses: License[];
}) => {
  return {
    isTechLicenseRequired: isLicenseRequired && !hasTechLicense,
    isCATechLicenseRequired: hospitalStateId === californiaStateId && !hasCALicense,
    hasTechLicenseForState: checkContractorHasLicenseRequiredForState(licenses, hospitalStateId),
  };
};

export const formatShiftDate = (shiftDate: string) => dayjs(shiftDate).format("ddd MMM DD, YYYY");

export const getPayout = (isVet: boolean, shiftDetails: any, shiftStatus?: ShiftStatus) => {
  const {
    vetShiftAmount,
    vetShiftAmountUpdated,
    techShiftAmount,
    techShiftAmountUpdated,
    shiftRequestDetails,
  } = shiftDetails;
  let pay = isVet ? vetShiftAmount : techShiftAmount;
  let updatedPay = null;
  if (shiftStatus === ShiftStatus.Completed) {
    updatedPay = isVet ? vetShiftAmountUpdated : techShiftAmountUpdated;
  } else {
    updatedPay = isVet
      ? shiftRequestDetails?.requestedVetShiftAmount || vetShiftAmountUpdated
      : techShiftAmountUpdated;
  }

  // If the price was decreased by a Roo admin or a hospital bid/custom price, treat the
  // updated price as the original price, to avoid showing a negative price difference.
  if (updatedPay && updatedPay < pay) {
    pay = updatedPay;
    updatedPay = null;
  }

  return {
    updatedTotalPay: updatedPay && updatedPay !== pay ? formatPrice(updatedPay) : null,
    totalPay: pay,
  };
};

export const getShiftBasicDetails = (
  isVet: boolean,
  shiftDetails: any,
  shiftStatus?: ShiftStatus,
  isMobile?: boolean
) => {
  const { shiftDate, startTime, endTime, shiftRequestDetails, flexStartTimeRange } = shiftDetails;

  const payout = getPayout(isVet, shiftDetails, shiftStatus);

  return [
    { title: `Date${isMobile ? "" : ":"}`, value: formatShiftDate(shiftDate) },
    {
      title: `Time${isMobile ? "" : ":"}`,
      value: `${startTime} - ${endTime}`,
      updatedFlexText:
        !!flexStartTimeRange && !shiftRequestDetails.requestedShiftStartTime ? "Flex" : undefined,
      updatedValue: !!shiftRequestDetails.requestedShiftStartTime
        ? `${shiftRequestDetails.requestedShiftStartTime} - ${shiftRequestDetails.requestedShiftEndTime}`
        : undefined,
    },
    {
      title: `Total Pay${isMobile ? "" : ":"}`,
      value: formatPrice(payout.totalPay),
      updatedValue: payout.updatedTotalPay,
      "data-testid": "totalPay",
    },
  ];
};

export const getShiftHeaderDetails = (
  isVet: boolean,
  shiftDetails: any,
  hospitalName: string,
  shiftStatus?: ShiftStatus,
  isMobile?: boolean
) => {
  const shiftTimeDetails: any = getShiftBasicDetails(isVet, shiftDetails, shiftStatus, isMobile);
  if (hospitalName) {
    return [
      { title: `Hospital Name${isMobile ? "" : ":"}`, value: hospitalName },
      ...shiftTimeDetails,
    ];
  }
  return [...shiftTimeDetails];
};

export const getSurgeryExpected = (isVet: boolean, shiftDetails: any) => {
  const { isSurgeryExpected } = shiftDetails;

  if (isVet) {
    const { surgeryTier, isFlexibleSurgery, shiftRequestDetails } = shiftDetails;
    return {
      header: `Surgery Expected`,
      data: isSurgeryExpected ? find(SURGERY_TIER, { value: surgeryTier })?.shortLabel : "No",
      updated: !!shiftRequestDetails?.isRequestWithoutSurgery
        ? "Requested without surgery"
        : undefined,
      flexText: isFlexibleSurgery && !shiftRequestDetails?.isRequestWithoutSurgery ? "Flex" : null,
    };
  }

  const { procedureTypeIds } = shiftDetails;
  const techSurgeryText = procedureTypeIds ? getProcedureTypeLabels(procedureTypeIds) : "";
  return {
    header: `Surgery Expected`,
    data: isSurgeryExpected ? techSurgeryText : "No",
  };
};

export const getFullShiftDetails = ({
  isVet,
  shiftDetails,
  isTexasFlow,
}: {
  isVet: boolean;
  shiftDetails: any;
  isTexasFlow?: boolean;
}) => {
  const { startTime, endTime, distance, additionalComments } = shiftDetails;

  let details = [];
  if (parseFloat(distance) >= 0) {
    details.push({
      header: `Distance`,
      data: `${Math.round(parseFloat(distance))} miles from home`,
    });
  }

  if (isVet) {
    const {
      expectedAppointmentTypeId,
      expectedNoOfAppointments,
      isWalkIns,
      isDropOffs,
      additionalDoctor,
      isDEALicenseRequired,
    } = shiftDetails;

    details.push(getSurgeryExpected(isVet, shiftDetails));

    if (expectedAppointmentTypeId) {
      details.push({
        header: "Appointment type",
        data: oApptTypesOptions[expectedAppointmentTypeId].label,
      });
    }

    details.push({
      header: "Expected number of appointments",
      data: expectedNoOfAppointments || 0,
    });

    details = [
      ...details,
      {
        header: "Additional doctor(s) practicing during shift",
        data: additionalDoctor ? "Yes" : "No",
      },
      {
        header: "Same day appointments possible",
        data: isWalkIns === 1 ? "Yes" : "No",
      },
      {
        header: "Scheduled drop-offs possible",
        data: isDropOffs === 1 ? "Yes" : "No",
      },
    ];

    if (isDEALicenseRequired) {
      details.push({
        header: "Licenses",
        data: "DEA required",
      });
    }
  } else {
    const { tier, techShiftAmount } = shiftDetails;
    details = [
      ...details,
      getSurgeryExpected(isVet, shiftDetails),
      {
        header: `Tech Tier`,
        data: String(tier) === "4" && isTexasFlow ? "Specialty" : tier,
      },
      {
        header: `Hourly Rate`,
        data: formatPrice(getHourlyRate(startTime, endTime, techShiftAmount) as number),
      },
    ];
  }
  if (additionalComments?.length) {
    details.push({
      header: `More Info`,
      data: additionalComments,
    });
  }
  return details;
};

export const getVetShiftDetails = async ({
  shiftGroupId,
  contractorId,
  isGetRatingDetails,
  latitude,
  longitude,
}: {
  shiftGroupId: string | number;
  contractorId: string | number;
  isGetRatingDetails?: number;
  latitude?: string | number;
  longitude?: string | number;
}) => {
  const shiftDetails = await fetchVetShiftDetails({
    shiftGroupId,
    vetId: contractorId,
    isGetRatingDetails,
    latitude,
    longitude,
  });
  if (shiftDetails) {
    if (isGetRatingDetails && shiftDetails.ratingDetails) {
      shiftDetails.ratingDetails = JSON.parse(shiftDetails.ratingDetails);
    }
    const shiftRequestDetails = JSON.parse(shiftDetails.shiftRequestDetails);
    if (shiftRequestDetails?.length) {
      shiftDetails.shiftRequestDetails = find(shiftRequestDetails, { vetId: contractorId });
    }
  }
  return shiftDetails;
};

export const getTechShiftDetails = async ({
  shiftGroupId,
  contractorId,
  isGetRatingDetails,
  latitude,
  longitude,
}: {
  shiftGroupId: string | number;
  contractorId: string | number;
  isGetRatingDetails?: number;
  latitude?: string | number;
  longitude?: string | number;
}) => {
  const shiftDetails = await fetchTechShiftDetails({
    shiftGroupId,
    techId: contractorId,
    isGetRatingDetails,
    latitude,
    longitude,
  });
  if (shiftDetails) {
    if (isGetRatingDetails) {
      shiftDetails.ratingDetails = JSON.parse(shiftDetails.ratingDetails);
    }
    const shiftRequestDetails = JSON.parse(shiftDetails.shiftRequestDetails);
    if (shiftRequestDetails?.length) {
      shiftDetails.shiftRequestDetails = find(shiftRequestDetails, { techId: contractorId });
    }
  }
  return shiftDetails;
};

export const getRequestBtnText = (
  isRequestExpired?: boolean,
  isInstantBookable?: number,
  requestedVetShiftAmount?: number,
  biddingStartTime?: string,
  MarsNDA?: {
    isMarsAgreementEnabled?: boolean;
    isMarsNDARequired?: boolean;
    isMarsNDASigned?: boolean;
  }
) => {
  const userTypeValue = parseInt(localStorage.getItem("isActive") || "0");
  const isPendingOrBlocked =
    userTypeValue === userStatusTypes.PENDING || userTypeValue === userStatusTypes.BLOCKED;
  const { isMarsAgreementEnabled, isMarsNDARequired, isMarsNDASigned } = MarsNDA ?? {};
  if (isMarsAgreementEnabled && isMarsNDARequired && !isMarsNDASigned && !isPendingOrBlocked) {
    return "Review Agreement";
  }
  const canBeBookedInstantly =
    isInstantBookable && isInstantBookable <= 2 && !requestedVetShiftAmount && !biddingStartTime;
  let requestBtnText;
  if (canBeBookedInstantly) requestBtnText = "Instantly Book Now";
  else requestBtnText = `${isRequestExpired ? "Re-" : ""}Request Shift`;
  return requestBtnText;
};

export const getDetailsForRequestModal = (shiftDetails: any, isVet?: boolean) => {
  const { shiftDate, startTime, endTime, hospitalTimezone, hospitalName, shiftGroupId } =
    shiftDetails;
  let shiftRequestDetails: any = {
    shiftDate,
    startTime,
    endTime,
    hospitalTimezone,
    hospitalName,
    shiftGroupId,
  };
  if (isVet) {
    const {
      hospitalType,
      hospitalId,
      stateId,
      shiftId,
      shiftStartDatetime,
      shiftEndDatetime,
      expectedLunchTime,
      expectedAppointmentTypeId,
      avgWellnessApptLen,
      expectedNoOfAppointments,
      surgeryTier,
      isSurgeryExpected,
      isFlexibleSurgery,
      isWalkInsOrDropOffs,
      procedureTypes,
      vetShiftAmount,
      vetShiftAmountUpdated,
      shiftRequestedOn,
      flexStartTimeRange,
      flexEndTimeRange,
      isWithin24Hours,
      shiftCreatedOn,
      instantBookable,
      requestedHospitalShiftAmount,
      requestedVetShiftAmount,
      totalPromoBoostAmount,
      totalShiftBoostAmount,
    } = shiftDetails;
    shiftRequestDetails = {
      ...shiftRequestDetails,
      hospitalId,
      hospitalType,
      hospitalStateId: stateId,
      shiftId,
      shiftStartDatetime,
      shiftEndDatetime,
      expectedLunchTime,
      expectedAppointmentTypeId,
      avgWellnessApptLen,
      expectedNoOfAppointments,
      surgeryTier,
      isSurgeryExpected,
      isFlexibleSurgery,
      isWalkInsOrDropOffs,
      procedureTypes,
      vetShiftAmount,
      vetShiftAmountUpdated,
      shiftRequestedOn,
      flexStartTimeRange,
      flexEndTimeRange,
      isWithin24Hours,
      shiftCreatedOn,
      instantBookable,
      requestedHospitalShiftAmount,
      requestedVetShiftAmount,
      totalPromoBoostAmount,
      totalShiftBoostAmount,
      instantBookType: instantBookable,
      isVetShift: true,
    };
  }
  return shiftRequestDetails;
};

export const isRequestExpired = (shiftRequestEndTimestamp: number) => {
  const currentTimeStamp = (new Date().getTime() / 1000 + "").split(".")[0];
  return shiftRequestEndTimestamp && shiftRequestEndTimestamp <= parseInt(currentTimeStamp);
};

export const requestShift = async (
  shiftDetails: any,
  shiftRequestDetails?: any,
  isVet?: boolean
) => {
  const {
    shiftId,
    shiftGroupId,
    shiftDate,
    hospitalId,
    startTime,
    endTime,
    hospitalName,
    tier,
    techShiftAmount,
    shiftRequestEndTimestamp,
  } = shiftDetails;

  const currentTimeStamp = (new Date().getTime() / 1000 + "").split(".")[0];
  const isRequestExpired =
    shiftRequestEndTimestamp && shiftRequestEndTimestamp <= parseInt(currentTimeStamp);

  let requestShiftData: any = {
    shiftId,
    hospitalId,
    shiftGroupId,
    startTime,
    endTime,
    hospitalName,
    shiftDate,
    email: localStorage.getItem("email"),
    currentTimeStamp: parseInt(currentTimeStamp),
    isReRequest: isRequestExpired ? 1 : 0,
  };

  if (isVet) {
    const {
      expectedAppointmentTypeId,
      expectedNoOfAppointments,
      expectedLunchTime,
      isSurgeryExpected,
      vetShiftAmount,
      procedureTypes,
      surgeryTier,
      flexStartTimeRange,
    } = shiftDetails;

    requestShiftData = {
      ...requestShiftData,
      vetId: localStorage.getItem("vetId"),
      expectedAppointmentTypeId,
      expectedNoOfAppointments,
      expectedLunchTime,
      surgeryTier,
      isSurgeryExpected,
      vetShiftAmount,
      procedureTypes,
      flexStartTimeRange,
      shiftAmount: shiftDetails.shiftAmount,
    };

    if (shiftRequestDetails) {
      const {
        requestedShiftStartTime,
        requestedShiftEndTime,
        isRequestWithoutSurgery,
        requestedVetShiftAmount,
        requestedHospitalShiftAmount,
        isVetBid,
        isDeductBoostAmount,
        promoId,
      } = shiftRequestDetails;

      const timeIsDifferent =
        (!!requestedShiftStartTime && requestedShiftStartTime !== startTime) ||
        (!!requestedShiftEndTime && requestedShiftEndTime !== endTime);

      requestShiftData.isVetBid = !!isVetBid;
      requestShiftData.isDeductBoostAmount = isDeductBoostAmount;

      if (timeIsDifferent || isRequestWithoutSurgery) {
        requestShiftData.requestedHospitalShiftAmount = requestedHospitalShiftAmount;
        if (isRequestWithoutSurgery) {
          requestShiftData.isRequestWithoutSurgery = isRequestWithoutSurgery;
        }
        if (timeIsDifferent) {
          requestShiftData.requestedShiftStartTime = requestedShiftStartTime;
          requestShiftData.requestedShiftEndTime = requestedShiftEndTime;
        }
      }

      if (requestedVetShiftAmount) {
        requestShiftData.requestedVetShiftAmount = requestedVetShiftAmount;
      }
      requestShiftData.promoId = promoId;
    }
    return await requestVetShift(requestShiftData);
  } else {
    requestShiftData = {
      ...requestShiftData,
      tier,
      techShiftAmount,
      techId: localStorage.getItem("techId"),
      promoId: shiftDetails.promoId,
    };
    return requestTechShift(requestShiftData);
  }
};

export const getToastDetails = (toastType: ToastType, message?: string) => {
  let variant: any = "success";
  const toastMsg = "Roo hoo!";
  let description;

  switch (toastType) {
    case ToastType.ShiftRequested:
    case ToastType.HospitalRated:
    case ToastType.ShiftConfirmed:
      description = toastMessages[toastType];
      break;
    case ToastType.ShiftRequestedError:
    case ToastType.HospitalRatedError:
      variant = "error";
      description = message ? message : EXCEPTION_MESSAGE;
      break;
  }
  return {
    variant,
    message: toastMsg,
    description,
  };
};

interface MarsNDAStatusReturnType {
  isMarsNDARequired: boolean;
  isMarsNDASigned: boolean;
  contractId: number | null;
  signature?: string | null;
  address?: string | null;
  phoneNumber?: string | null;
  date?: string | null;
}

export const getMarsNDAStatus = async (
  userId: number,
  enterpriseId: number,
  isReturnValues?: boolean
): Promise<MarsNDAStatusReturnType> => {
  const contractName = "MARS_NDA";

  const response = await fetchContractStatus(userId, enterpriseId, contractName);

  const isMarsNDARequired = !!response?.contractId;
  const isMarsNDASigned =
    userId === response?.userId &&
    response?.signature !== null &&
    response?.ipAddress !== null &&
    response?.address !== null &&
    response?.phoneNumber !== null &&
    response?.createdOn !== null;

  const result: MarsNDAStatusReturnType = {
    isMarsNDARequired,
    isMarsNDASigned,
    contractId: response?.contractId ?? null,
  };

  if (isMarsNDASigned && isReturnValues) {
    result.signature = response?.signature;
    result.address = response?.address;
    result.phoneNumber = response?.phoneNumber;
    result.date = response?.createdOn;
  }

  return result;
};
