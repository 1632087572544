import {
  purpleBase,
  purpleLight50,
  spacingL,
  spacingM,
  BodyText,
  grayLight400,
  Heading,
  Container,
  spacingXXL,
  Button,
  purpleLight200,
} from "@RooUI";
import React, { useState } from "react";
import styled from "styled-components";
import { VStack } from "@RooBeta/components";
import { CardDetails, CardDetailsWrapper, ShiftMetadata, Card } from "./styles";
import { useGetWorkedHospitals } from "@Roo/hooks/api/useGetWorkedHospitals";
import { ShiftProposalHospitalCard } from "./ShiftProposalHospitalCard";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";
import { isRooFeatureEnabled } from "@Roo/Common/Wrappers/isRooFeatureEnabled";
import { useRooTranslation } from "@Roo/Common/Wrappers/useRooTranslation";
import { FEATURE_FLAGS } from "@Roo/constants/postHogFeatureFlags";

import { useShiftProposalModals } from "./useShiftProposalModals";
export const ShiftProposalHospitalList = () => {
  const { vetId } = useRequiredAuthorizedUser();
  const isVet = typeof vetId === "number";
  const { openShiftProposalModal, isModalLoading } = useShiftProposalModals();
  const { data: workedHospitals } = useGetWorkedHospitals(1, {
    enabled: isVet,
  });
  const { t } = useRooTranslation();

  const [selectedHospitalIds, setSelectedHospitalIds] = useState<number[]>([]);

  const isShiftProposalEnabled = isRooFeatureEnabled(FEATURE_FLAGS.SHIFT_PROPOSAL);

  const handleHospitalSelect = (hospitalId: number) => {
    if (selectedHospitalIds.includes(hospitalId)) {
      setSelectedHospitalIds((prev) => prev.filter((id) => id !== hospitalId));
    } else {
      setSelectedHospitalIds((prev) => [...prev, hospitalId]);
    }
  };

  if (
    typeof vetId !== "number" ||
    !workedHospitals ||
    !workedHospitals.length ||
    !isShiftProposalEnabled
  )
    return null;

  return (
    <Container
      backgroundColor={purpleLight50}
      borderColor={purpleLight50}
      style={{
        width: "100%",
        padding: spacingXXL,
      }}
      data-testid="shiftProposalHospitalList"
    >
      <VStack $gap="l">
        <VStack $gap="xs" $alignItems="center">
          <Heading level={5} color={purpleBase}>
            {t("shiftProposal.list.heading")}
          </Heading>
          <Body>{t("shiftProposal.list.description")}</Body>
        </VStack>
        <VStack>
          <Card $variant="action_list" color={purpleLight200}>
            <Button
              title={t("shiftProposal.list.sendAvailabilityButton")}
              disabled={!selectedHospitalIds.length}
              loading={isModalLoading}
              onClick={() => {
                openShiftProposalModal({
                  hospitalIds: selectedHospitalIds,
                  source: "shiftList",
                });
              }}
            />
          </Card>
          {workedHospitals.map((hospital) => (
            <ShiftProposalHospitalCard
              key={hospital.hospitalId}
              hospital={hospital}
              onSelect={() => handleHospitalSelect(hospital.hospitalId)}
              isSelected={selectedHospitalIds.includes(hospital.hospitalId)}
            />
          ))}
        </VStack>
      </VStack>
    </Container>
  );
};

export const ButtonContainer = styled.div`
  margin-left: auto;
  padding-left: ${spacingL};

  @media (max-width: 768px) {
    margin-left: 0;
    padding-left: 0;
    margin-top: ${spacingM};
    width: 100%;

    button {
      width: 100%;
    }
  }
`;

export const CustomCardDetails = styled(CardDetails)`
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Body = styled.div`
  ${BodyText}
  text-align: center;
  color: ${grayLight400};
`;

export const CustomCardDetailsWrapper = styled(CardDetailsWrapper)`
  flex: 1;
`;

export const CustomShiftMetadata = styled(ShiftMetadata)`
  white-space: wrap;
  word-break: break-word;
`;
