import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";
import { post } from "@Roo/api/common/rooFetch";
import { CACHE } from "@Roo/api/utility/constants";
import { registerParticipant } from "@Roo/constants/apiRoutes";

export interface RegisterParticipantRequest {
  fullName: string;
  email: string;
  userId: number;
  userTypeId: number;
}

export interface RegisterParticipantResponse {
  id: number;
  userId: number;
  name: string;
  email: string;
  userTypeId: number;
  isFailed?: boolean;
}

export const useRegisterParticipant = (options?: {
  onSuccess?: (data: RegisterParticipantResponse) => void;
  onError?: (error: Error) => void;
}) => {
  const queryClient = useQueryClient();

  return useMutation<RegisterParticipantResponse, Error, RegisterParticipantRequest>(
    async (request) => {
      try {
        const response = await post<RegisterParticipantRequest, RegisterParticipantResponse>(
          registerParticipant(),
          request
        );

        if (response.isFailed) {
          throw new Error("Failed to register participant");
        }

        return response;
      } catch (error) {
        Sentry.captureException(error, {
          extra: {
            context: "useRegisterParticipant error",
            request,
          },
        });
        throw error;
      }
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([CACHE.CONVERSATIONS]);
        options?.onSuccess?.(data);
      },
      onError: (error) => {
        options?.onError?.(error);
      },
    }
  );
};
