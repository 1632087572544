import React, { useCallback, useState, useEffect, useRef } from "react";
import { MediumText, Container, spacingS, grayLight100 } from "@RooUI";
import { HStack, DatePicker, DatePickerValue, RooIcon } from "@RooBeta/components";
import moment from "moment";
import styled from "styled-components";
import {
  useAvailableDaysQuery,
  useAvailableDayMutation,
  useRequiredAuthorizedUser,
} from "@RooBeta/hooks";
import { ReactDatePickerCustomHeaderProps } from "react-datepicker";

interface VetAvailabilityProps {
  onDatesChange?: (dates: Date[]) => void;
}

type ArrowDirection = "left" | "right";

interface ArrowProps {
  direction: ArrowDirection;
  disabled: boolean;
  onClick: () => void;
  "data-testid": string;
}

export const VetAvailability = ({ onDatesChange }: VetAvailabilityProps) => {
  const { vetId } = useRequiredAuthorizedUser();
  const { data: availableDays } = useAvailableDaysQuery();
  const { mutate: setAvailableDay } = useAvailableDayMutation();

  const [prevDates, setPrevDates] = useState<Date[]>([]);
  const [value, setValue] = useState<DatePickerValue>({ format: "dates", dates: [] });
  const [isPrevMonthDisabled, setIsPrevMonthDisabled] = useState<boolean>(false);
  const [isNextMonthDisabled, setIsNextMonthDisabled] = useState<boolean>(false);

  const decreaseMonthRef = useRef<(() => void) | null>(null);
  const increaseMonthRef = useRef<(() => void) | null>(null);

  useEffect(() => {
    const now = moment();
    const filteredAvailableDays = availableDays?.filter((date) => moment(date.date).isAfter(now));
    if (filteredAvailableDays?.length) {
      setValue({
        format: "dates",
        dates: filteredAvailableDays.map((date) => moment(date.date).toDate()),
      });
      setPrevDates(filteredAvailableDays.map((date) => moment(date.date).toDate()));
      onDatesChange?.(filteredAvailableDays.map((date) => moment(date.date).toDate()));
    }
  }, [availableDays, onDatesChange]);

  const minDate = moment().toDate();
  const maxDate = moment().add(3, "month").endOf("month").toDate();

  const handleMonthChange = (direction: ArrowDirection) => {
    const navFunction = direction === "left" ? decreaseMonthRef.current : increaseMonthRef.current;
    if (navFunction) {
      navFunction();
    }
  };

  const onDatesChanged = useCallback(
    async (dates: DatePickerValue) => {
      setValue(dates);

      if (dates.format === "dates" && dates.dates) {
        const oldDates = new Set(prevDates.map((d) => moment(d).format("YYYY-MM-DD")));
        const newDates = new Set(dates.dates.map((d) => moment(d).format("YYYY-MM-DD")));

        const updates: Array<{ date: string; isAvailable: boolean }> = [];

        for (const date of Array.from(newDates)) {
          if (!oldDates.has(date)) {
            updates.push({ date, isAvailable: true });
          }
        }

        for (const date of Array.from(oldDates)) {
          if (!newDates.has(date)) {
            updates.push({ date, isAvailable: false });
          }
        }

        for (const update of updates) {
          setAvailableDay(
            { date: update.date, isAvailable: update.isAvailable, vetId: vetId! },
            {
              onError: () => {
                setValue((prev) => ({
                  format: "dates",
                  dates: update.isAvailable
                    ? prev.format === "dates"
                      ? prev.dates?.filter((d) => moment(d).format("YYYY-MM-DD") !== update.date)
                      : []
                    : [
                        ...(prev.format === "dates" ? prev.dates || [] : []),
                        moment(update.date).toDate(),
                      ],
                }));
              },
            }
          );
        }

        setPrevDates(dates.dates);
        onDatesChange?.(dates.dates);
      }
    },
    [setAvailableDay, prevDates, vetId, onDatesChange]
  );

  if (!vetId) {
    return null;
  }

  // Arrow component to reduce repetition
  const Arrow = ({ direction, disabled, onClick, "data-testid": testId }: ArrowProps) => (
    <ArrowContainer
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
      className={`${direction}-arrow`}
      data-testid={testId}
    >
      <RooIcon
        size={32}
        icon={direction === "left" ? "chevron_left" : "chevron_right"}
        color={disabled ? grayLight100 : undefined}
      />
    </ArrowContainer>
  );

  return (
    <Container style={{ padding: spacingS }}>
      <HStack $alignItems="center" $justifyContent="center">
        <DatePickerWrapper>
          <Arrow
            direction="left"
            disabled={isPrevMonthDisabled}
            onClick={() => handleMonthChange("left")}
            data-testid="datePickerPrevMonth"
          />

          <DatePicker
            monthsShown={2}
            inline
            selectsMultiple
            value={value}
            onChange={onDatesChanged}
            minDate={minDate}
            maxDate={maxDate}
            renderCustomHeader={({
              monthDate,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
              customHeaderCount,
            }: ReactDatePickerCustomHeaderProps) => {
              // Store the navigation functions and disabled states in refs
              // We only need to store them once for each month
              if (customHeaderCount === 0) {
                decreaseMonthRef.current = decreaseMonth;
                setIsPrevMonthDisabled(prevMonthButtonDisabled);
              } else if (customHeaderCount === 1) {
                increaseMonthRef.current = increaseMonth;
                setIsNextMonthDisabled(nextMonthButtonDisabled);
              }

              return (
                <HeaderContainer>
                  <MonthContainer>
                    <MediumText data-testid="datePickerCurrentMonth">
                      {moment(monthDate).format("MMM YYYY")}
                    </MediumText>
                  </MonthContainer>
                </HeaderContainer>
              );
            }}
          />

          <Arrow
            direction="right"
            disabled={isNextMonthDisabled}
            onClick={() => handleMonthChange("right")}
            data-testid="datePickerNextMonth"
          />
        </DatePickerWrapper>
      </HStack>
    </Container>
  );
};

const DatePickerWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 0 8px;
`;

const MonthContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

interface ArrowContainerProps {
  disabled?: boolean;
}

const ArrowContainer = styled.div<ArrowContainerProps>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  z-index: 2;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  border-radius: 50%;

  &.left-arrow {
    left: -40px;
    top: 50%;
    transform: translateY(-50%);
  }

  &.right-arrow {
    right: -40px;
    top: 50%;
    transform: translateY(-50%);
  }
`;
