import React from "react";
import { useRooFeatureFlagEnabled } from "@Roo/Common/Wrappers/useRooFeatureFlagEnabled";
import HeaderLoggedIn from "../../Common/HeaderLoggedIn";
import { FEATURE_FLAGS } from "../../constants/postHogFeatureFlags";

export const HeaderLoggedInComponent = () => {
  const isHospitalReferralEnabled = useRooFeatureFlagEnabled(
    FEATURE_FLAGS.HOSPITALS_REFER_HOSPITALS
  );

  // @ts-expect-error TS2322
  return <HeaderLoggedIn referEnabled={isHospitalReferralEnabled} />;
};
