import { Detector } from "detector-js";

type DetectorResult = {
  os: {
    name?: string | undefined;
    version?: string | undefined;
  };
  browser: {
    name?: string | undefined;
    version?: string | undefined;
  };
  platform: string;
};

export type PlatformProperties = {
  platform: string;
  deviceType: string;
  browserName: string;
  browserVersion: string;
  operatingSystemName: string;
  operatingSystemVersion: string;
};

export const detectPlatform = (): PlatformProperties => {
  const detector = new Detector();

  const detection: DetectorResult = {
    os: detector.os,
    browser: detector.browser,
    platform: detector.platform,
  };

  return {
    platform: "web",
    deviceType: detection.platform,
    // @ts-expect-error TS2322
    browserName: detection.browser.name,
    // @ts-expect-error TS2322
    browserVersion: detection.browser.version,
    // @ts-expect-error TS2322
    operatingSystemName: detection.os.name,
    // @ts-expect-error TS2322
    operatingSystemVersion: detection.os.version,
  };
};
