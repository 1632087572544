import UserPromo from "@Roo/Promo/types/UserPromo";
import { useShiftDetailsStore } from "@RooBeta/store/useShiftDetailsStore";
import { FlexLayout, Modal, Select } from "@RooUI";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const ModalContent = styled(FlexLayout)`
  padding: 16px;
  gap: 16px;
  flex-direction: column;
`;

interface PromoPickerModalProps {
  isOpen: boolean;
  onClose: () => void;
  startedPromos?: UserPromo[];
}

export const PromoPickerModal = ({
  isOpen,
  onClose,
  startedPromos = [],
}: PromoPickerModalProps) => {
  const { promoId, setPromoId } = useShiftDetailsStore();
  const [selectedPromoId, setSelectedPromoId] = useState<number | undefined>(promoId);

  useEffect(() => {
    if (promoId) {
      setSelectedPromoId(promoId);
    }
  }, [promoId]);

  const handleSelectPromo = () => {
    if (selectedPromoId) {
      setPromoId(selectedPromoId);
      onClose();
    }
  };

  const promoOptions = startedPromos.map((promo: UserPromo) => ({
    value: promo.promoId,
    label: promo.promoName,
  }));

  return (
    <Modal
      showModal={isOpen}
      onCloseModal={onClose}
      $size="m"
      header="Select Promo"
      body={
        <ModalContent>
          <Select
            label="Promo"
            options={promoOptions}
            value={selectedPromoId}
            onChange={({ value }) => setSelectedPromoId(value)}
            placeholder="Select a promo"
            data-testid="promoSelect"
          />
        </ModalContent>
      }
      $primaryButtonProps={{
        title: "Apply",
        onClick: handleSelectPromo,
        disabled: !selectedPromoId,
      }}
      $secondaryButtonProps={{
        title: "Cancel",
        onClick: onClose,
      }}
      $buttonVariant="confirmation"
    />
  );
};
