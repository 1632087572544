import * as C from "../../../constants/constants";
import {
  REGISTER_ENTERPRISE_HOSPITAL_FAIL,
  REGISTER_ENTERPRISE_HOSPITAL_SUCCESS,
} from "../../../constants/enterpriseConstants";

const initialState = {
  hospitalData: {
    email: "",
    cognitoId: "",
    referralCodeId: "",
    userTypeId: null,
    userId: null,
    hospitalId: null,
    hospitalName: "",
    selfIdentifiedEnterpriseId: "",
    selfIdentifiedEnterpriseName: "",
    fullName: "",
    roleId: "",
    hospitalAddress: "",
    hospitalImageURL: "",
    city: "",
    stateId: "",
    zipcode: "",
    hospitalPhoneNumber: "",
    hospitalWebsite: "",
    mobilePhoneNumber: "",
    hearAboutRoo: "",
    heardFromWordOfMouth: "",

    isAahaAccredited: "",
    typeOfHospital: "",
    averageNoOfDVMWorkPerDay: "",
    medicalDirector: "",
    averageTransactionVolumePerDay: "",
    averageTransactionValue: "",
    averageSurgeryVolumePerDay: "",
    averageNoOfTechPerDvmPerDay: "",
    typicalLunchBreakSetup: "",

    hasApptGeneral: "",
    hasApptWellness: "",
    hasApptWalkin: "",
    hasApptNonwell: "",
    hasApptEmergency: "",
    hasApptHighVol: "",
    hasApptLargeAnimal: "",
    hasApptShelter: "",
    hasApptSurgery: "",
    hasApptSpecialty: "",
    hasApptDental: "",
    hasApptAcupuncture: "",
    hasApptChiropractic: "",
    hasApptLaser: "",
    hasApptUrgentCare: "",

    apptFlow: "",
    clientInteract: "",
    staffInteract: "",

    characterTypeId: null,
    amenitiesNotes: "",
    parkingNotes: "",
    additionalSkills: "",
    hospitalReliefWork: "",
    hospitalMaintainRecords: "",
    hospitalDressCodes: "",
    practiceManagementSoftware: "",
    wantsFullTime: "",
    hospitalReferralCode: "",
    userName: "",
  },
  password: "",
  accountCreated: false,
  createAccountServer: {
    statusCode: null,
    message: "",
  },
  states: [],
  isShowPhotoLoader: false,
  hospitalImages: [],
  hospitalShiftImageObj: null,
  userDetails: null,
  decodedValues: null,
  isDecodeSuccessfull: null,

  isBasicInfoFetched: false,
  isBasicInfoCompleted: false,
  isHospitalDataPageFetched: false,
  isHospitalDataPageCompleted: false,
  isHospitalGetToKnowFetched: false,
  isHospitalGetToKnowSaved: false,
  isHospitalPhotoPageFetched: false,
  isProfileCreated: false,
  isDataReducerSave: false,
  isKnowYourPageReducerSave: false,
  isReducerPhotoSave: false,
  hospital501c3Letter: [],
  isLastPageSubmitted: false,
  isDisableAddShift: false,
};

/* eslint-disable default-param-last */
// @ts-expect-error TS7006
const RegistrationHospitalReducer = (state = initialState, action) => {
  /* eslint-enable default-param-last */
  let hospitalShiftImageObj = null;
  switch (action.type) {
    case C.CREATE_HOSPITAL_ACCOUNT_SUCCESS:
    case REGISTER_ENTERPRISE_HOSPITAL_SUCCESS:
      localStorage.setItem("hospitalId", action.payload.hospitalId);
      localStorage.setItem("userId", action.payload.userId);

      return {
        ...state,
        hospitalData: {
          ...state.hospitalData,
          email: action.payload.email,
          referralCodeId: action.payload.referralCodeId,
          userTypeId: action.payload.userTypeId,
          cognitoId: action.payload.userSub,
          userId: action.payload.userId,
          hospitalId: action.payload.hospitalId,
        },
        password: action.payload.password,
        accountCreated: true,
      };

    case C.CREATE_HOSPITAL_ACCOUNT_FAIL:
    case REGISTER_ENTERPRISE_HOSPITAL_FAIL:
      return {
        ...state,
        createAccountServer: {
          ...state.createAccountServer,
          statusCode: action.payload.status,
          message: action.payload.message,
        },
      };

    // case C.HOSPITAL_DETAILS_CHANGE_SUCCESS:
    //   return {
    //     ...state,
    //     ...action.payload
    //   };

    case C.FETCH_HOSPITAL_BASIC_INFO:
      return {
        ...state,
        isBasicInfoCompleted: false,
        isBasicInfoFetched: false,
      };

    case C.FETCH_HOSPITAL_BASIC_INFO_SUCCESS:
      localStorage.setItem("hospitalStateId", action.payload.stateId);
      return {
        ...state,
        hospitalData: {
          ...state.hospitalData,
          hospitalName: action.payload.hospitalName,
          selfIdentifiedEnterpriseId: action.payload.selfIdentifiedEnterpriseId,
          selfIdentifiedEnterpriseName: action.payload.selfIdentifiedEnterpriseName,
          enterpriseId: action.payload.enterpriseId,
          fullName: action.payload.fullName,
          roleId: action.payload.roleId,
          hospitalAddress: action.payload.hospitalAddress,
          city: action.payload.city,
          stateId: action.payload.stateId,
          zipcode: action.payload.zipcode,
          hospitalPhoneNumber: action.payload.hospitalPhoneNumber,
          hospitalWebsite: action.payload.hospitalWebsite,
          mobilePhoneNumber: action.payload.mobilePhoneNumber,
          hearAboutRoo: action.payload.hearAboutRoo,
          heardFromWordOfMouth: action.payload.heardFromWordOfMouth,
        },
        isBasicInfoFetched: true,
      };

    case C.SAVE_HOSPITAL_BASIC_INFO_SUCCESS:
      localStorage.setItem("hospitalStateId", action.payload.stateId);
      localStorage.setItem("fullName", action.payload.hospitalName);
      return {
        ...state,
        hospitalName: action.payload.hospitalName,
        isBasicInfoCompleted: true,
      };

    case C.FETCH_HOSPITAL_DATA_PAGE:
      return {
        ...state,
        isHospitalDataPageCompleted: false,
        isHospitalDataPageFetched: false,
      };

    case C.FETCH_HOSPITAL_DATA_REDUCER:
    case C.FETCH_HOSPITAL_DATA_PAGE_SUCCESS:
      let fetchedHospitaldata = "";
      if (!state.isDataReducerSave) {
        // @ts-expect-error TS2322
        fetchedHospitaldata = {
          ...state,
          hospitalData: {
            ...state.hospitalData,
            isAahaAccredited: action.payload.isAahaAccredited,
            typeOfHospital: action.payload.typeOfHospital,
            averageNoOfDVMWorkPerDay: action.payload.averageNoOfDVMWorkPerDay,
            medicalDirector: action.payload.medicalDirector,
            averageTransactionVolumePerDay: action.payload.averageTransactionVolumePerDay,
            averageTransactionValue: action.payload.averageTransactionValue,
            averageSurgeryVolumePerDay: action.payload.averageSurgeryVolumePerDay,
            averageNoOfTechPerDvmPerDay: action.payload.averageNoOfTechPerDvmPerDay,
            typicalLunchBreakSetup: action.payload.typicalLunchBreakSetup,
            hospitalReliefWork: action.payload.hospitalReliefWork,
            hospitalMaintainRecords: action.payload.hospitalMaintainRecords,
            hospitalDressCodes: action.payload.hospitalDressCodes,
            practiceManagementSoftware: action.payload.practiceManagementSoftware,
            wantsFullTime: action.payload.wantsFullTime,
            stateId: action.payload.stateId,
          },
          isHospitalDataPageFetched: true,
          hospital501c3Letter: action.payload.letterPath
            ? [{ letterPath: action.payload.letterPath, letterName: action.payload.letterName }]
            : [],
        };
      } else {
        // @ts-expect-error TS2322
        fetchedHospitaldata = {
          ...state,
          isHospitalDataPageFetched: true,
        };
      }
      return fetchedHospitaldata;

    case C.SAVE_HOSPITAL_DATA_REDUCER:
    case C.SAVE_HOSPITAL_DATA_PAGE_SUCCESS:
      return {
        ...state,
        hospitalData: {
          ...state.hospitalData,
          ...action.payload,
        },
        isHospitalDataPageCompleted: !action.payload.isDataReducerSave,
        isDataReducerSave: action.payload.isDataReducerSave,
        isHospitalDataPageFetched: false,
      };

    case C.FETCH_HOSPITAL_GET_TO_KNOW:
      return {
        ...state,
        isHospitalGetToKnowSaved: false,
        isHospitalGetToKnowFetched: false,
      };
    case C.FETCH_GET_TO_KNOW_REDUCER:
    case C.FETCH_HOSPITAL_GET_TO_KNOW_SUCCESS:
      let fetchedGetToKnowValue = "";
      if (!state.isKnowYourPageReducerSave) {
        // @ts-expect-error TS2322
        fetchedGetToKnowValue = {
          ...state,
          hospitalData: {
            ...state.hospitalData,
            hasApptGeneral: action.payload.hasApptGeneral,
            hasApptWellness: action.payload.hasApptWellness,
            hasApptWalkin: action.payload.hasApptWalkin,
            hasApptNonwell: action.payload.hasApptNonwell,
            hasApptEmergency: action.payload.hasApptEmergency,
            hasApptHighVol: action.payload.hasApptHighVol,
            hasApptLargeAnimal: action.payload.hasApptLargeAnimal,
            hasApptShelter: action.payload.hasApptShelter,
            hasApptSurgery: action.payload.hasApptSurgery,
            hasApptSpecialty: action.payload.hasApptSpecialty,
            hasApptDental: action.payload.hasApptDental,
            hasApptAcupuncture: action.payload.hasApptAcupuncture,
            hasApptChiropractic: action.payload.hasApptChiropractic,
            hasApptLaser: action.payload.hasApptLaser,
            hasApptUrgentCare: action.payload.hasApptUrgentCare,

            apptFlow: action.payload.apptFlow,
            clientInteract: action.payload.clientInteract,
            staffInteract: action.payload.staffInteract,

            characterTypeId: action.payload.characterTypeId,
            amenitiesNotes: action.payload.amenitiesNotes,
            parkingNotes: action.payload.parkingNotes,
            additionalSkills: action.payload.additionalSkills,
          },
          isHospitalGetToKnowFetched: true,
        };
      } else {
        // @ts-expect-error TS2322
        fetchedGetToKnowValue = {
          ...state,
          isHospitalGetToKnowFetched: true,
        };
      }
      return fetchedGetToKnowValue;

    case C.SAVE_GET_TO_KNOW_REDUCER:
    case C.SAVE_HOSPITAL_GET_TO_KNOW_SUCCESS:
      return {
        ...state,
        hospitalData: {
          ...state.hospitalData,
          ...action.payload,
        },
        isHospitalGetToKnowSaved: !action.payload.isKnowYourPageReducerSave,
        isKnowYourPageReducerSave: action.payload.isKnowYourPageReducerSave,
        isHospitalGetToKnowFetched: false,
      };

    case C.UPLOAD_HOSPITAL_IMAGES_REQUEST:
      return {
        ...state,
        isShowPhotoLoader: true,
      };

    case C.UPLOAD_HOSPITAL_IMAGES_SUCCESS:
      // check if image is shift image
      hospitalShiftImageObj = null;
      if (action.payload.hospitalImages) {
        for (let index = 0; index < action.payload.hospitalImages.length; index++) {
          if (action.payload.hospitalImages[index].isShiftPhoto) {
            hospitalShiftImageObj = action.payload.hospitalImages[index];
            action.payload.hospitalImages.splice(index, 1);
            break;
          }
        }
      }
      if (hospitalShiftImageObj) {
        localStorage.setItem("profileImage", hospitalShiftImageObj.imagePath);
      }
      return {
        ...state,
        isShowPhotoLoader: false,
        hospitalImages: [...action.payload.hospitalImages],
        hospitalShiftImageObj: hospitalShiftImageObj,
      };

    case C.UPLOAD_HOSPITAL_IMAGES_FAIL:
      return {
        ...state,
        isShowPhotoLoader: false,
      };

    case C.DELETE_HOSPITAL_IMAGE_REQUEST:
      return {
        ...state,
        isShowPhotoLoader: true,
      };

    case C.DELETE_HOSPITAL_IMAGE_SUCCESS:
      // check if image is shift image
      hospitalShiftImageObj = null;
      if (action.payload.hospitalImages) {
        for (let index = 0; index < action.payload.hospitalImages.length; index++) {
          if (action.payload.hospitalImages[index].isShiftPhoto) {
            hospitalShiftImageObj = action.payload.hospitalImages[index];
            action.payload.hospitalImages.splice(index, 1);
            break;
          }
        }
      }
      if (hospitalShiftImageObj) {
        localStorage.setItem("profileImage", hospitalShiftImageObj.imagePath);
      }
      return {
        ...state,
        isShowPhotoLoader: false,
        hospitalImages: [...action.payload.hospitalImages],
        hospitalShiftImageObj: hospitalShiftImageObj,
      };

    case C.DELETE_HOSPITAL_IMAGE_FAIL:
      return {
        ...state,
        isShowPhotoLoader: false,
      };

    case C.FETCH_HOSPITAL_PHOTO_PAGE:
      return {
        ...state,
        isHospitalPhotoPageFetched: false,
      };
    case C.FETCH_PHOTO_REDUCER:
    case C.FETCH_HOSPITAL_PHOTO_PAGE_SUCCESS:
      let fetchedPhotoPage = "";
      if (!state.isReducerPhotoSave) {
        // check if image is shift image
        hospitalShiftImageObj = null;
        if (action.payload.hospitalImages) {
          for (let index = 0; index < action.payload.hospitalImages.length; index++) {
            if (action.payload.hospitalImages[index].isShiftPhoto) {
              hospitalShiftImageObj = action.payload.hospitalImages[index];
              action.payload.hospitalImages.splice(index, 1);
              break;
            }
          }
        }
        if (hospitalShiftImageObj) {
          localStorage.setItem("profileImage", hospitalShiftImageObj.imagePath);
        }
        // @ts-expect-error TS2322
        fetchedPhotoPage = {
          ...state,
          hospitalData: {
            ...state.hospitalData,
            enterpriseId: action.payload.hospitalData?.enterpriseId,
            hospitalImageURL: action.payload.hospitalData.hospitalImageURL,
            userName: action.payload.hospitalData.userName,
          },
          hospitalImages: [...action.payload.hospitalImages],
          hospitalShiftImageObj: hospitalShiftImageObj,
          isHospitalPhotoPageFetched: true,
        };
      } else {
        // @ts-expect-error TS2322
        fetchedPhotoPage = {
          ...state,
          isHospitalPhotoPageFetched: true,
        };
      }
      return fetchedPhotoPage;

    case C.SAVE_PHOTO_REDUCER:
    case C.CREATE_HOSPITAL_PROFILE_SUCCESS: // photo upload page submit
      return {
        ...state,
        hospitalData: {
          ...state.hospitalData,
          hospitalImageURL: action.payload.hospitalImageURL,
          hospitalReferralCode: action.payload.referralCode, // This is the referralCode generated on creation of hosp profile which will be used for referral.
        },
        isProfileCreated: !action.payload.isReducerPhotoSave, //isReducerPhotoSave = true means back button is clicked
        isLastPageSubmitted: action.payload.isLastPage ? true : false,
        isReducerPhotoSave: action.payload.isReducerPhotoSave,
        isHospitalPhotoPageFetched: false,
      };

    case C.CREATE_HOSPITAL_PROFILE_FAIL:
      return {
        ...state,
        isProfileCreated: false,
        isLastPageSubmitted: false,
      };

    case C.FETCH_STATES_SUCCESS:
      return {
        ...state,
        states: [...action.payload.states],
      };

    case C.INITIALIZE_REGISTER_HOSPITAL_STATE:
      return {
        ...initialState,
      };

    case C.GET_DECODED_VALUES_SUCCESS:
      return {
        ...state,
        decodedValues: action.payload.data,
        isDecodeSuccessfull: true,
      };
    case C.GET_DECODED_VALUES_FAIL:
      return {
        ...state,
        decodedValues: [],
        isDecodeSuccessfull: false,
      };

    // --- 501c3 Letter
    case C.DELETE_HOSPITAL_501C3_LETTER_FAIL:
    case C.UPLOAD_HOSPITAL_501C3_LETTER_FAIL:
      return {
        ...state,
        hospital501c3Letter: [...state.hospital501c3Letter],
      };

    case C.UPLOAD_HOSPITAL_501C3_LETTER_SUCCESS:
    case C.DELETE_HOSPITAL_501C3_LETTER_SUCCESS:
      return {
        ...state,
        hospital501c3Letter: action.payload.hospital501c3Letter,
      };
    case C.CLEAR_LOCAL_STORAGE:
      if (!localStorage.getItem("jwtToken")) {
        localStorage.clear();
      } else {
        localStorage.setItem("isRegistrationComplete", "1");
        if (state.hospitalImages.length !== 0) {
          // @ts-expect-error TS2339
          localStorage.setItem("profileImage", state.hospitalImages[0].imagePath.toString());
        }
        localStorage.setItem("fullName", state.hospitalData.fullName);
        localStorage.setItem("hospitalPhoneNumber", state.hospitalData.hospitalPhoneNumber);
      }
      return {
        ...state,
      };
    case C.UPDATE_IS_DISABLE_ADD_SHIFT:
      return {
        ...state,
        isDisableAddShift: action.payload,
      };
    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default RegistrationHospitalReducer;
