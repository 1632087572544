import { useCallback } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useChatNotifications } from "@Roo/Common/ChatNotifications/ChatNotificationsContext";
import { CACHE } from "@Roo/api/utility/constants";

type OpenChatNotificationsOptions = {
  onSuccess?: () => void;
};

export const useOpenChatNotifications = (options: OpenChatNotificationsOptions = {}) => {
  const queryClient = useQueryClient();
  const { openNotifications } = useChatNotifications();
  const { onSuccess } = options;

  const openChatNotifications = useCallback(() => {
    // Invalidate chat messages query to trigger a refetch
    queryClient.invalidateQueries({ queryKey: [CACHE.CONVERSATIONS] });

    // Open notifications popover
    openNotifications();
    onSuccess?.();
  }, [queryClient, openNotifications, onSuccess]);

  return { openChatNotifications };
};
