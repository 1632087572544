import styled, { css } from "styled-components";

import {
  h1Headlines,
  h2Headlines,
  h3Headlines,
  h4Headlines,
  h5Headlines,
  h6Headlines,
} from "../../styles/constants";

import { Color } from "../../styles/constants/colors";

// Use a type assertion to help TypeScript understand the conversion
const colorExtension = (props: { color?: unknown }) => {
  if (!props.color) return "";
  return css`
    color: ${props.color as Color};
  `;
};

export const H1 = styled.h1`
  ${h1Headlines}
  ${colorExtension}
`;

export const H2 = styled.h2`
  ${h2Headlines}
  ${colorExtension}
`;

export const H3 = styled.h3`
  ${h3Headlines}
  ${colorExtension}
`;

export const H4 = styled.h4`
  ${h4Headlines}
  ${colorExtension}
`;

/**
 * @deprecated Use large body text instead.
 */
export const H5 = styled.h5`
  ${h5Headlines}
  ${colorExtension}
`;

/**
 * @deprecated Use large body text instead.
 */
export const H6 = styled.h6`
  ${h6Headlines}
  ${colorExtension}
`;
