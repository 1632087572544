import { MediumText, SmallText, spacingXXL } from "@RooUI";
import React, { useRef, useState } from "react";
import { Milestone } from "@roo-dev/roo-node-types";
import { VList } from "virtua";

import { HStack } from "@RooBeta/components";
import { NAVIGATION_HEIGHT, SEARCH_HEIGHT, SHIFT_CARD_HEIGHT } from "@RooBeta/constants";
import { useViewportStore } from "@RooBeta/store";
import { useScrollStore } from "@RooBeta/store/scrollStore";
import { GroupedShiftList as List, Provider, ViewportSize } from "@RooBeta/types";
import { ShiftCardPointOfEntry } from "@Roo/constants/posthogEventContextTypes";
import { truncate } from "@RooBeta/utils";
import { ShiftCard } from "./ShiftCard";
import { ReliefWorkInterstitial } from "./ReliefWorkInterstitial";
import { StickyHeader, StyledScrollArea } from "./styles";
import { useGetMilestoneStatus, MilestoneStatusResponse } from "../../../hooks/api";
import { useRequiredAuthorizedUser, useSearchRequestBody } from "@RooBeta/hooks";
import { FEATURE_FLAGS } from "@Roo/constants/postHogFeatureFlags";
import { useRooFeatureFlagEnabled } from "@Roo/Common/Wrappers/useRooFeatureFlagEnabled";
import { ShiftProposalHospitalList } from "./ShiftProposalHospitalList";
import { ToggleHiddenShiftsRow } from "./ToggleHiddenShiftsRow";
import { GlobalNotificationCard } from "../globalNotificationCard";

type GroupedShiftListProps = {
  shiftList: List;
  hiddenShiftList?: List;
  fixedHeight?: boolean;
  page?: string;
};

type ShiftListRowProps = {
  group: List["groups"][number];
  index: number;
  count: number;
  milestoneStatus?: MilestoneStatusResponse;
  isFeatureEnabled: boolean;
  provider: Provider;
  page?: string;
  groups: List["groups"];
  visibleGroupsCount: number;
};

export const GroupedShiftList = ({ fixedHeight = true, page, ...props }: GroupedShiftListProps) => {
  const isFeatureEnabled =
    useRooFeatureFlagEnabled(FEATURE_FLAGS.CURRENT_SITUATION_PROMPT) ?? false;
  const { provider } = useRequiredAuthorizedUser();
  const proposalListRef = useRef<HTMLDivElement>(null);
  const [prevScrollTop, setPrevScrollTop] = useState(0);
  const { scrollDirection, setScrollDirection } = useScrollStore();

  const fromDashboard = page === "dashboard";
  const { windowSize, viewportSize } = useViewportStore();
  const isSearchContainerVisible = viewportSize > ViewportSize.s || scrollDirection === "up";
  const containerHeight =
    windowSize.height -
    (NAVIGATION_HEIGHT + (!fromDashboard || isSearchContainerVisible ? SEARCH_HEIGHT : 0));
  const { data: milestoneStatus } = useGetMilestoneStatus({
    milestone: Milestone.UserMilestone.CURRENT_SITUATION_QUESTION,
  });

  const [showHiddenShifts, setShowHiddenShifts] = useState(false);
  const isSchedulePage = page === "schedule";

  // Only include hidden shifts if showHiddenShifts is true
  const visibleGroups = props.shiftList.groups;
  const hiddenGroups =
    showHiddenShifts && props.hiddenShiftList ? props.hiddenShiftList.groups : [];
  const allGroups = [...visibleGroups, ...hiddenGroups];
  const hasHiddenShifts = !!props?.hiddenShiftList?.groups.length;

  const renderShiftRow = (virtualIndex: number) => {
    const group = allGroups[virtualIndex];
    const rowProps: ShiftListRowProps = {
      group,
      index: virtualIndex,
      count: props.shiftList.count + (props.hiddenShiftList?.count ?? 0),
      milestoneStatus,
      isFeatureEnabled,
      provider: provider,
      page,
      groups: allGroups,
      visibleGroupsCount: visibleGroups.length,
    };
    return <ShiftListRow {...rowProps} />;
  };

  const showProposalList = !isSchedulePage;
  const totalShiftGroups = allGroups.length;
  const rowCount = totalShiftGroups + (hasHiddenShifts ? 1 : 0) + (showProposalList ? 1 : 0);
  const lastRowIndex = totalShiftGroups + (hasHiddenShifts ? 1 : 0);

  const ProposalListRow = () => (
    <div ref={proposalListRef} style={{ padding: spacingXXL }}>
      <ShiftProposalHospitalList />
    </div>
  );

  return (
    <StyledScrollArea
      $height={containerHeight}
      $fixedHeight={fixedHeight}
      $isSearchContainerVisible={isSearchContainerVisible}
      $fromDashboard={fromDashboard}
      data-testid="groupedShiftListScrollArea"
    >
      {() => (
        <div style={{ width: "100%", height: containerHeight }}>
          <VList
            count={rowCount}
            overscan={5}
            onScroll={(scrollTop) => {
              //only change scroll direction only if amount scrolled is more than 1 shift card
              if (Math.abs(scrollTop - prevScrollTop) > SHIFT_CARD_HEIGHT) {
                setScrollDirection(scrollTop > prevScrollTop ? "down" : "up");
                setPrevScrollTop(scrollTop);
              }
            }}
          >
            {(virtualIndex) => {
              // Last row - ShiftProposalHospitalList
              if (showProposalList && virtualIndex === lastRowIndex) {
                return <ProposalListRow />;
              }

              // Toggle row - after all visible shifts but before ShiftProposalHospitalList
              if (hasHiddenShifts && virtualIndex === visibleGroups.length) {
                return (
                  <ToggleHiddenShiftsRow
                    showHiddenShifts={showHiddenShifts}
                    hiddenShiftsCount={props.hiddenShiftList?.count}
                    onToggle={() => setShowHiddenShifts(!showHiddenShifts)}
                  />
                );
              }

              // handle the toggle row index
              return renderShiftRow(
                virtualIndex < visibleGroups.length ? virtualIndex : virtualIndex - 1
              );
            }}
          </VList>
        </div>
      )}
    </StyledScrollArea>
  );
};

function ShiftListRow({
  group,
  index,
  count,
  milestoneStatus,
  isFeatureEnabled,
  provider,
  page,
  groups,
  visibleGroupsCount,
}: ShiftListRowProps) {
  const isSchedulePage = page === "schedule";
  const searchRequestBody = useSearchRequestBody();

  const shouldShowBanner = (shiftIndex: number) => {
    if (!isFeatureEnabled || milestoneStatus?.completed || provider.providerType !== "VET")
      return false;

    if (isSchedulePage) return false;

    // Only consider visible shifts for the interstitial
    const visibleGroups = groups.slice(0, visibleGroupsCount);
    const totalShifts = visibleGroups.reduce((sum, group) => sum + group.shifts.length, 0);
    const previousShifts = visibleGroups
      .slice(0, index)
      .reduce((sum, group) => sum + group.shifts.length, 0);
    const currentShiftNumber = previousShifts + shiftIndex + 1;

    return currentShiftNumber === 4 || (totalShifts < 4 && currentShiftNumber === totalShifts);
  };

  return (
    <div>
      {index === 0 && page !== "schedule" && <GlobalNotificationCard />}
      <StickyHeader>
        <HStack $justifyContent="space-between">
          <MediumText>{truncate(group.label)}</MediumText>
          {group.subLabel && <SmallText>{group.subLabel}</SmallText>}
        </HStack>
      </StickyHeader>
      {group.shifts.map((shift, shiftIndex) => {
        const isBannerPosition = shouldShowBanner(shiftIndex);
        const showBanner = isBannerPosition && milestoneStatus?.completed === false;
        return [
          <ShiftCard
            key={`shift-${shift.shiftId}`}
            shift={shift}
            count={count}
            variant="list"
            data-testid={`groupedShiftListCard${shift.shiftId}`}
            pointOfEntry={
              isSchedulePage
                ? ShiftCardPointOfEntry.MyShiftsPane
                : ShiftCardPointOfEntry.SearchShiftsPane
            }
            searchParameters={isSchedulePage ? undefined : searchRequestBody}
            provider={provider}
          />,
          showBanner && <ReliefWorkInterstitial key={`banner-${shift.shiftId}`} />,
        ];
      })}
    </div>
  );
}
