import { VetProfileForm, fetchVetProfile, updateVetProfile } from "../../../../api/vet";
import { VET_USER_TYPE } from "../../../../constants/UserTypeConstants";
import {
  ANIMAL_TYPES,
  HOSPITAL_TYPES,
  IMPROVED_FILTER_APPOINTMENT_TYPES,
  IMPROVED_PREFERRED_PROCEDURES,
  idToFieldMap,
} from "../../../../constants/checkBoxConstants";

/*
 *  Fetch profile data and map API values to work with form values
 * */
export const getVetProfileForm = async (vetId: string): Promise<VetProfileForm> => {
  const data = await fetchVetProfile(VET_USER_TYPE, vetId);

  if (!data.vet?.[0]) {
    return {
      vetSchool: "",
      graduationYear: 0,
      yearsOfPractice: 0,
      freeDaysInMonthToWork: 0,
      vetAvailability: 0,
      wantsFullTime: null,
      isPrefGeneral: false,
      isPrefEmergency: false,
      isPrefHighVol: false,
      isPrefLargeAnimal: false,
      isPrefShelter: false,
      isPrefSurgery: false,
      isPrefSpecialty: false,
      isPrefChiropractic: false,
      isPrefLaserTherapy: false,
      isPrefAcupunture: false,
      isPrefUrgentCare: false,
      isPrefOther: false,
      isPrefNoPreference: false,
      isPrefVaccine: false,
      prefOtherHospTypeOptions: IMPROVED_FILTER_APPOINTMENT_TYPES.map((type) => ({
        id: type.id,
        label: type.label,
        checked: false,
      })),
      apptFlow: undefined,
      clientInteract: undefined,
      staffInteract: undefined,
      vetAnimalType: ANIMAL_TYPES.map((type) => ({
        ...type,
        checked: false,
      })),
      vetProcedure: IMPROVED_PREFERRED_PROCEDURES.map((type) => ({
        id: type.id,
        label: type.label,
        checked: false,
      })),
      hospitalFocusPreferences: HOSPITAL_TYPES.map((type) => ({
        id: type.id,
        label: type.label,
        checked: false,
      })),
      licenses: [],
      deletedLicenses: [],
      specializations: [],
      characterTypeId: undefined,
      profileImage: [],
      resumePath: "",
      resumeName: "",
      hasDEALicense: 0,
      hasFearFreeCertification: 0,
      additionalSkills: "",
      accreditations: [
        {
          id: 1,
          label: "I have a DEA License",
          checked: false,
          "data-testid": "deaLicenseCheckbox",
        },
        {
          id: 2,
          label: "I am Fear Free Certified",
          checked: false,
          "data-testid": "fearFreeCheckbox",
        },
      ],
    };
  }

  const hospitalPreferences = {
    isPrefGeneral: data.vet[0].isPrefGeneral,
    isPrefEmergency: data.vet[0].isPrefEmergency,
    isPrefHighVol: data.vet[0].isPrefHighVol,
    isPrefLargeAnimal: data.vet[0].isPrefLargeAnimal,
    isPrefShelter: data.vet[0].isPrefShelter,
    isPrefSurgery: data.vet[0].isPrefSurgery,
    isPrefSpecialty: data.vet[0].isPrefSpecialty,
    isPrefChiropractic: data.vet[0].isPrefChiropractic,
    isPrefLaserTherapy: data.vet[0].isPrefLaserTherapy,
    isPrefAcupunture: data.vet[0].isPrefAcupunture,
    isPrefUrgentCare: data.vet[0].isPrefUrgentCare,
    isPrefOther: data.vet[0].isPrefOther,
    isPrefNoPreference: data.vet[0].isPrefNoPreference ?? false,
    isPrefVaccine: data.vet[0].isPrefVaccine ?? false,
  };

  return {
    ...hospitalPreferences,
    vetSchool: data.vet[0].vetSchool,
    graduationYear: data.vet[0].graduationYear,
    yearsOfPractice: data.vet[0].yearsOfPractice,
    freeDaysInMonthToWork: data.vet[0].freeDaysInMonthToWork,
    vetAvailability: data.vet[0].vetAvailability,
    wantsFullTime: data.vet[0].wantsFullTime,
    prefOtherHospTypeOptions: IMPROVED_FILTER_APPOINTMENT_TYPES.map((hospitalType) => ({
      id: hospitalType.id,
      label: hospitalType.label,
      checked: hospitalPreferences[idToFieldMap[hospitalType.id]],
    })),
    apptFlow: data.vet[0].apptFlow,
    clientInteract: data.vet[0].clientInteract,
    staffInteract: data.vet[0].staffInteract,
    vetAnimalType: ANIMAL_TYPES.map((animalType) => ({
      ...animalType,
      checked: !!data.vetAnimalType.find((vat) => vat.animalTypeId === animalType.id),
    })),
    vetProcedure: IMPROVED_PREFERRED_PROCEDURES.map((procedureType) => ({
      id: procedureType.id,
      label: procedureType.label,
      checked: !!data.vetProcedure.find((vpt) => vpt.procedureTypeId === procedureType.id),
    })),
    hospitalFocusPreferences: HOSPITAL_TYPES.map((hospitalType) => ({
      id: hospitalType.id,
      label: hospitalType.label,
      checked: !!data.vetHospitalFocusPreference.find(
        (vht) => vht.hospitalFocusId.toString() === hospitalType.id
      ),
    })),
    licenses: data.vetLicences,
    deletedLicenses: [],
    specializations:
      data.vetSpecialization && data.vetSpecialization.length ? data.vetSpecialization : [],
    characterTypeId: data.user[0]?.characterTypeId,
    profileImage: data.profileImage,
    resumePath: data.vet[0].resumePath,
    resumeName: data.vet[0].resumeName,
    hasDEALicense: data.vet[0].hasDEALicense,
    hasFearFreeCertification: data.vet[0].hasFearFreeCertification,
    additionalSkills: data.user[0]?.additionalSkills,
    accreditations: [
      {
        id: 1,
        label: "I have a DEA License",
        checked: !!data.vet[0].hasDEALicense,
        "data-testid": "deaLicenseCheckbox",
      },
      {
        id: 2,
        label: "I am Fear Free Certified",
        checked: !!data.vet[0].hasFearFreeCertification,
        "data-testid": "fearFreeCheckbox",
      },
    ],
  };
};
/*
 *  Map form values to work with api values and submit form
 * */
export const saveVetProfile = async (
  vetProfileForm: VetProfileForm,
  originalVetProfileForm: VetProfileForm,
  vetId: string,
  userId: string
): Promise<void> => {
  const { accreditations, hospitalFocusPreferences, ...restVetProfileForm } = vetProfileForm;

  const request = {
    ...restVetProfileForm,
    hasDEALicense:
      vetProfileForm.accreditations && vetProfileForm.accreditations[0].checked ? 1 : 0,
    hasFearFreeCertification:
      vetProfileForm.accreditations && vetProfileForm.accreditations[1].checked ? 1 : 0,
    prefOtherHospType: "",
    vetId: vetId.toString(),
    userId: userId.toString(),
    animalTypeIds:
      vetProfileForm.vetAnimalType?.filter((vat) => vat.checked).map((vat) => vat.id) || [],
    procedureTypeIds:
      vetProfileForm.vetProcedure?.filter((vp) => vp.checked).map((vp) => vp.id) || [],
    hospitalTypePreferenceIds:
      hospitalFocusPreferences?.filter((hp) => hp.checked).map((hp) => parseInt(hp.id)) || [],
    isLicenseModified: {
      isAdded: !!vetProfileForm.licenses?.find((license) => !license.licenceId),
      isUpdated: !!vetProfileForm.licenses?.find(
        (license) =>
          !!originalVetProfileForm.licenses?.find((originalLicense) => {
            return (
              license.licenceId === originalLicense.licenceId &&
              (license.expirationMonth !== originalLicense.expirationMonth ||
                license.expirationYear !== originalLicense.expirationYear ||
                license.licenceNumber !== originalLicense.licenceNumber ||
                license.id !== originalLicense.id)
            );
          })
      ),
    },
    ...vetProfileForm.prefOtherHospTypeOptions?.reduce(
      (collect, { id, checked }) => ({ ...collect, [idToFieldMap[id]]: !!checked }),
      {} as { [key: string]: boolean }
    ),
  };

  delete request.vetAnimalType;
  delete request.vetProcedure;
  delete request.prefOtherHospTypeOptions;
  delete request.profileImage;
  delete request.resumeName;
  delete request.resumePath;
  await updateVetProfile(request);
};

const SKIP_VALIDATION: (keyof VetProfileForm)[] = [
  "resumeName",
  "resumePath",
  "isPrefGeneral",
  "isPrefEmergency",
  "isPrefHighVol",
  "isPrefLargeAnimal",
  "isPrefShelter",
  "isPrefSurgery",
  "isPrefSpecialty",
  "isPrefChiropractic",
  "isPrefLaserTherapy",
  "isPrefAcupunture",
  "isPrefUrgentCare",
  "isPrefOther",
  "hasDEALicense",
  "hasFearFreeCertification",
  "additionalSkills",
  "vetProcedure",
  "vetAnimalType",
  "vetAvailability",
  "prefOtherHospTypeOptions",
  "wantsFullTime",
  "characterTypeId",
  "freeDaysInMonthToWork",
  "accreditations",
  "hospitalFocusPreferences",
];

export const validate = (form: VetProfileForm) => {
  const errors = Object.keys(form).reduce(
    (errors: { [key: string]: string }, key: keyof VetProfileForm) => {
      if (SKIP_VALIDATION.includes(key)) {
        return errors;
      }
      if (
        typeof form[key] === "object" &&
        !["deletedLicenses", "licenses", "specializations", "profileImage"].includes(key) &&
        !(Array.isArray(form[key]) && form[key].find((i: { checked: boolean }) => i.checked))
      ) {
        errors[key] = "This field is required";
      }
      return errors;
    },
    {}
  );
  return Object.keys(errors).length ? errors : undefined;
};
