import _ from "lodash";

import { BaseOptionType, SelectProps } from ".";

export const getFilterOptionByAdditionalFieldsFunc = <NarrowOptionType extends BaseOptionType>(
  additionalSearchFilterFields: SelectProps<NarrowOptionType>["additionalSearchFilterFields"]
) => {
  return (inputValue: string, option?: NarrowOptionType) => {
    // Always use the label for searching
    const fieldsToFilterBy = _.concat<keyof NarrowOptionType>(
      additionalSearchFilterFields ?? [],
      "label"
    );
    return (
      !!option &&
      // @ts-expect-error TS7030
      fieldsToFilterBy.some((field) => {
        const targetString =
          typeof option[field] === "number" ? option[field].toString() : option[field];
        if (_.isString(targetString)) {
          return _.toLower(targetString)?.includes(_.toLower(inputValue));
        }
      })
    );
  };
};
