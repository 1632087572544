import { Button, Input, Link } from "@RooUI";
import * as Sentry from "@sentry/react";
import { Auth } from "aws-amplify";
import React, { useState } from "react";

import * as ErrorMessage from "../../../constants/errorMessage";
import { Event, EventTracker } from "../../../tracking/service/EventTracker/EventTrackerService";
import HeaderLogIn from "../../HeaderLogIn";
import { canSendResetPasswordEmail } from "../service/ForgotPasswordService";

export function ForgotPasswordComponent() {
  const [isShowComponent, setIsShowComponent] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [isEmailError, setIsEmailError] = useState<boolean>(false);
  const [isShowLoader, setIsShowLoader] = useState<boolean>(false);

  const setStateValues = (isSuccess: boolean, errorCode = "") => {
    let errorMessage = "";

    if (!isSuccess && errorCode !== "UserNotFoundException") {
      if (errorCode === "LimitExceededException") {
        errorMessage = ErrorMessage.FORGOT_PASSWORD_LIMIT_EXCEEDED;
      } else {
        errorMessage = ErrorMessage.EXCEPTION_MESSAGE;
      }
    }

    setIsError(!isSuccess);
    setErrorMessage(errorMessage);
    // show success screen even if user does not exist
    setIsShowComponent(errorCode === "UserNotFoundException" ? true : isSuccess);
    setIsShowLoader(false);
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    EventTracker.send({
      eventName: Event.Name.FORGOT_PASSWORD,
      eventType: Event.Type.CLICK,
      // @ts-expect-error TS2322
      entityType: null,
      // @ts-expect-error TS2322
      entityId: null,
      context: { email },
    });
    e.preventDefault();
    if (!email) {
      setIsEmailError(true);
      setIsShowLoader(false);
    } else {
      setIsShowLoader(true);
      canSendResetPasswordEmail(email.toLowerCase())
        // eslint-disable-next-line promise/prefer-await-to-then
        .then((canSendResetPasswordEmail) => {
          if (canSendResetPasswordEmail) {
            Auth.forgotPassword(email.toLowerCase())
              // eslint-disable-next-line promise/prefer-await-to-then
              .then(() => setStateValues(true))
              .catch((error) => {
                setStateValues(false, error.code);
                Sentry.captureException(error, {
                  extra: { context: "Error while forgot password: " },
                });
              });
          } else {
            // display success screen even if user does not exists
            setIsShowComponent(true);
          }
        })
        .catch((error) => {
          Sentry.captureException(error, { extra: { context: "Error while forgot password: " } });
          setStateValues(false, error.code);
        });
    }
  };

  const renderForgotPasword = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsShowComponent(false);
    // reset the form when returning to the page
    setEmail("");
    setErrorMessage("");
    setIsError(false);
    setIsEmailError(false);
    setIsShowLoader(false);
  };
  return (
    <div className="page-wrapper">
      <div className="login inner-container d-flex align-items-center align-items-sm-start ">
        <HeaderLogIn />
        <div className="container">
          <div className="d-flex justify-content-center">
            <div
              className={isShowComponent === false ? "col-md-8 col-lg-6 px-0" : "d-none"}
              data-testid="forgotPasswordForm"
            >
              <div className="well forgot-password-box">
                <h5 className="form-title text-center font-w-500">Forgot Password</h5>
                <span className="password-label">
                  Please enter your registered email ID. You should receive an email containing a
                  link to create a new password.
                </span>
                <div className="registration-form-group no-mb">
                  <label>Email</label>
                  <div className="required">
                    <Input
                      type="text"
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      placeholder="Enter your email"
                      // @ts-expect-error TS2322
                      error={isEmailError && !email.length && "Required"}
                      data-testid="emailInput"
                    />
                  </div>
                </div>
                <div className="text-center pt-5 pb-3 d-flex justify-content-center">
                  <div className={isShowLoader ? "btn-loader" : ""}>
                    <Button
                      type="submit"
                      onClick={submitHandler}
                      $buttonType="primary"
                      title="SUBMIT"
                      data-testid="submitButton"
                    />
                  </div>
                </div>
                <div className={isError ? "text-danger text-center pt-5 pb-3" : "d-none"}>
                  <span>{errorMessage}</span>
                </div>
              </div>
            </div>
          </div>
          <div className={isShowComponent ? "container" : "d-none"} data-testid="successScreen">
            <div className="d-flex justify-content-center">
              <div className="col-md-8 col-lg-6 px-0">
                <div className="well forgot-password-box">
                  <h5 className="form-title text-center">Forgot Password</h5>
                  <div className="verify-box">
                    <p>
                      We just sent you a verification email. Click the link inside to reset your
                      password.
                    </p>
                    <p>Haven’t received an email?</p>
                    <div className="text-center diff-email">
                      <Link onClick={renderForgotPasword}>Input a different email address</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
