import _ from "lodash";

import { sortSearchedExternships } from "../../../Common/externshipDataHelper";
import { sortSearchedInternships } from "../../../Common/internshipDataHelper";
import * as C from "../../../constants/student";

const initialState = {
  internships: [],
  externships: [],
  isSearchInternshipSuccess: true,
  isSearchExternshipSuccess: true,
  isGridView: false,
  isMapView: true,
  searchParameters: {},
  totalCount: 0,
  isSearchClicked: false,
  searchCenterLatitude: "",
  searchCenterLongitude: "",
  studentProfileDetails: [],
  isStudentProfileFetched: false,
};

/* eslint-disable default-param-last */
// @ts-expect-error TS7006
const studentDashboardReducer = (state = initialState, action) => {
  /* eslint-enable default-param-last */
  switch (action.type) {
    case C.SEARCH_EXTERNSHIPS_REQUEST: {
      return {
        ...state,
        isSearchShiftSuccess: false,
      };
    }

    case C.SEARCH_EXTERNSHIPS_SUCCESS:
      if (action.payload.searchExternshipResult === undefined) {
        return {
          ...state,
          externships: [],
          isSearchShiftSuccess: true,
          totalCount: 0,
          isSearchClicked: action.payload.isSearchClicked,
          searchCenterLatitude: "",
          searchCenterLongitude: "",
        };
      } else {
        const filteredResult = sortSearchedExternships(action.payload.searchExternshipResult);
        return {
          ...state,
          externships: filteredResult.sortedExternships,
          externshipsByStatus: filteredResult.externshipsByStatus,
          isSearchShiftSuccess: true,
          totalCount: action.payload.totalCount,
          isSearchClicked: action.payload.isSearchClicked,
          searchCenterLatitude: action.payload.latitude,
          searchCenterLongitude: action.payload.longitude,
        };
      }

    case C.INITIALIZE_EXTERNSHIP_SEARCH_RESULTS:
    case C.SEARCH_EXTERNSHIPS_FAIL:
      return {
        ...state,
        isSearchShiftSuccess: false,
        externships: [],
        totalCount: 0,
      };
    case C.SEARCH_INTERNSHIPS_REQUEST: {
      return {
        ...state,
        isSearchShiftSuccess: false,
      };
    }
    case C.SEARCH_INTERNSHIPS_SUCCESS:
      if (action.payload.searchInternshipResult === undefined) {
        return {
          ...state,
          internships: [],
          isSearchShiftSuccess: true,
          totalCount: 0,
          isSearchClicked: action.payload.isSearchClicked,
          searchCenterLatitude: "",
          searchCenterLongitude: "",
        };
      } else {
        const filteredResult = sortSearchedInternships(action.payload.searchInternshipResult);
        return {
          ...state,
          internships: filteredResult.sortedInternships,
          isSearchShiftSuccess: true,
          totalCount: action.payload.totalCount,
          isSearchClicked: action.payload.isSearchClicked,
          searchCenterLatitude: action.payload.latitude,
          searchCenterLongitude: action.payload.longitude,
        };
      }

    case C.INITIALIZE_INTERNSHIP_SEARCH_RESULTS:
    case C.SEARCH_INTERNSHIPS_FAIL:
      return {
        ...state,
        isSearchShiftSuccess: false,
        internships: [],
        totalCount: 0,
      };

    case C.SAVE_SEARCH_INTERNSHIP_PARAMETERS: {
      return {
        ...state,
        searchParameters: action.payload,
      };
    }

    case C.FETCH_STUDENT_PROFILE_REQUEST: {
      return {
        ...state,
        isStudentProfileFetched: false,
      };
    }

    case C.FETCH_STUDENT_PROFILE_SUCCESS: {
      return {
        ...state,
        studentProfileDetails: action.payload.studentProfileDetails,
        isStudentProfileFetched: true,
      };
    }

    case C.FETCH_STUDENT_PROFILE_FAIL: {
      return {
        ...state,
        studentProfileDetails: [],
        isStudentProfileFetched: false,
      };
    }

    case C.SET_STUDENT_DASHBOARD_GRID_VIEW:
      return {
        ...state,
        isGridView: true,
        isMapView: false,
      };

    case C.SET_STUDENT_DASHBOARD_MAP_VIEW:
      return {
        ...state,
        isGridView: false,
        isMapView: true,
      };

    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default studentDashboardReducer;
