import { all, call } from "redux-saga/effects";

import rootContractorBonusSaga from "../Admin/ContractorBonus/sagas/ContractorBonusSagas";
import rootAdminEnterpriseSaga from "../Admin/Enterprise/sagas/EnterpriseSagas";
import rooEventSaga from "../Admin/Events/sagas/EventSagas";
import rootAdminExternshipSaga from "../Admin/Externships/sagas/ExternshipsSagas";
import rootFeedbackSaga from "../Admin/FeedbackForm/sagas/FeedbackSagas";
import rooAdminInternshipsSaga from "../Admin/Internships/sagas/InternshipSagas";
import rootManualSMSSaga from "../Admin/ManualSMS/sagas/ManualSMSSagas";
import rootAdminReferralSaga from "../Admin/Referral/sagas/ReferralSaga";
import rootAdminShiftSaga from "../Admin/Shifts/sagas/ShiftSagas";
import rootUserSaga from "../Admin/Users/sagas/UserSagas";
import rootW9Saga from "../Admin/W9/sagas/W9Sagas";
import rooBulkUploadSaga from "../BulkUpload/saga/batchUploadSaga";
import rootCalendarNote from "../Common/AddCalendarNote/sagas/addNewCalendarNoteSagas";
import rootChatSaga from "../Common/Chat/sagas/chatSagas";
import editRatingSaga from "../Common/EditRating/sagas/EditRatingSagas";
import rootManageHospitalUserDetailsSaga from "../Common/ManageHospitalUserDetails/sagas/ManageHospitalUserDetailsSagas";
import rootReferNewUserSaga from "../Common/ReferralInvite/sagas/ReferNewUserSagas";
import rootEnterpriseSaga from "../Enterprise/saga/enterpriseSaga";
import rootManageHospitalUsersSaga from "../Hospital/ManageHospitalUsers/sagas/ManageHospitalUsersSagas";
import rootShiftRatingByHospitalSaga from "../Hospital/ShiftRatingByHospital/sagas/shiftRatingByHospitalSagas";
import rootHospitalBankBillingSaga from "../Hospital/billing/saga/hospitalBillingSaga";
import rootHospitalProfileSaga from "../Hospital/profile/sagas/hospitalProfileSaga";
import rooUniversityHospitalSaga from "../Hospital/rooUniversityHospital/sagas/rooUniversityHospitalSagas";
import rootHospitalSaga from "../Hospital/saga/hospitalSaga";
import rootHospitalSettingsSaga from "../Hospital/settings/sagas/hospitalSettingsSaga";
import rootLoginSaga from "../Login/Saga/LoginSagas";
import rootForgotPasswordSaga from "../Login/forgotPassword/sagas/ForgotPasswordSaga";
import profilePanelSaga from "../ProfilePanels/sagas/profilePanelSaga";
import rootRegisterEnterpriseSaga from "../Registration/RegistrationEnterprise/Sagas/RegisterEnterpriseSaga";
import rootRegisterHospitalSaga from "../Registration/RegistrationHospital/Sagas/RegisterHospitalSaga";
import rootRegisterRooAdminSaga from "../Registration/RegistrationRooInternalUser/Sagas/RegisterRooAdminSaga";
import rootRegisterStudentSaga from "../Registration/RegistrationStudent/Sagas/RegisterStudentSaga";
import rootSignUpSaga from "../Registration/Saga/RegistrationSaga";
import rootRegisterTechSaga from "../RegistrationNew/Sagas/RegisterTechSaga";
import rootRegisterVetSaga from "../RegistrationNew/Sagas/RegisterVetSaga";
import rootUnsubscribeSaga from "../Unsubscribe/sagas/UnsubscribeSagas";
import rootCalendarSaga from "../calendar/sagas/CalendarSagas";
import rootLoaderSaga from "../loader/sagas/loaderSaga";
import rootStudentDashboardSaga from "../student/dashboard/saga/dashboardSaga";
import rootStudentSaga from "../student/saga/studentSaga";
import rootHospitalProfileForStudentSaga from "../student/studentHospitalProfile/saga/studentHospitalProfileSaga";
import rootTechFirstTimerSaga from "../tech/firstTimer/sagas/firstTimerSaga";
import rootTechOnboardingSaga from "../tech/onboarding/sagas/techOnboardingSaga";
import rootTechProfileSaga from "../tech/profile/sagas/techProfileSagas";
import rootTechSettingSaga from "../tech/settings/sagas/techSettingSagas";
import rootTechShiftHistorySaga from "../tech/techShiftHistory/sagas/techShiftHistorySaga";
import rootTechCalendarSaga from "../techCalendar/sagas/TechCalendarSagas";
import rootVetFirstTimerSaga from "../vet/firstTimer/sagas/firstTimerSaga";
import rootVetOnboardingSaga from "../vet/onboarding/sagas/vetOnboardingSagas";
import rootVetProfileSaga from "../vet/profile/sagas/vetProfileSagas";
import rootVetSettingSaga from "../vet/settings/sagas/vetSettingSagas";
import rootVetShiftHistorySaga from "../vet/vetShiftHistory/sagas/vetShiftHistorySaga";
import rootVetCalendarSaga from "../vetCalendar/sagas/VetCalendarSagas";
import rootTechDashboardSaga from "../vetTechDashboard/sagas/techDashboardSaga";
import rootVetDashboardSaga from "../vetTechDashboard/sagas/vetDashboardSaga";

// eslint-disable-next-line no-restricted-exports
export default function* rootSaga() {
  yield all([
    call(rootLoaderSaga), // to get webAppVersion
    call(rootRegisterRooAdminSaga),
    call(rootRegisterHospitalSaga),
    call(rootRegisterEnterpriseSaga),
    call(rootRegisterVetSaga),
    call(rootRegisterStudentSaga),
    call(rootRegisterTechSaga),
    call(rootCalendarSaga),
    call(rootLoginSaga),
    call(rootSignUpSaga),
    call(rootHospitalSaga),
    call(rootEnterpriseSaga),
    call(rootAdminEnterpriseSaga),
    call(rootVetDashboardSaga),
    call(rootTechDashboardSaga),
    call(rootHospitalProfileSaga),
    call(rootVetCalendarSaga),
    call(rootTechCalendarSaga),
    call(rootVetProfileSaga),
    call(rootTechProfileSaga),
    call(rootVetShiftHistorySaga),
    call(rootTechShiftHistorySaga),
    call(rootShiftRatingByHospitalSaga),
    call(rootUserSaga),
    call(rootHospitalSettingsSaga),
    call(rootVetSettingSaga),
    call(rootTechSettingSaga),
    call(rootStudentSaga),
    call(rootStudentDashboardSaga),
    call(rootHospitalProfileForStudentSaga),
    call(rootAdminShiftSaga),
    call(rooAdminInternshipsSaga),
    call(rooUniversityHospitalSaga),
    call(rootHospitalBankBillingSaga),
    call(rootForgotPasswordSaga),
    call(rootVetFirstTimerSaga),
    call(rootTechFirstTimerSaga),
    call(rootManualSMSSaga),
    call(rootW9Saga),
    call(rootManageHospitalUsersSaga),
    call(rootManageHospitalUserDetailsSaga),
    call(rootReferNewUserSaga),
    call(rootAdminReferralSaga),
    call(rootCalendarNote),
    call(editRatingSaga),
    call(rootFeedbackSaga),
    call(rootUnsubscribeSaga),
    call(rootContractorBonusSaga),
    call(rooEventSaga),
    call(rootVetOnboardingSaga),
    call(rootAdminExternshipSaga),
    call(rootChatSaga),
    call(rootTechOnboardingSaga),
    call(rooBulkUploadSaga),
    call(profilePanelSaga),
  ]);
}
