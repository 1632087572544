import { ShiftCancellationReason } from "@RooBeta/types";

export const SHIFT_CANCELLATION_REASONS = [
  {
    value: ShiftCancellationReason.HiredFulltimeFromRoo,
    label: "I was hired permanently at a Roo hospital",
  },
  {
    value: ShiftCancellationReason.HiredFulltime,
    label: "I was hired permanently (not a Roo hospital)",
  },
  {
    value: ShiftCancellationReason.Accident,
    label: "Accidentally requested shift",
  },
  {
    value: ShiftCancellationReason.ScheduleChanges,
    label: "Schedule changes",
  },
  {
    value: ShiftCancellationReason.PersonalEmergency,
    label: "Personal emergency",
  },
  {
    value: ShiftCancellationReason.Covid19,
    label: "COVID-19 related",
  },
  {
    value: ShiftCancellationReason.Other,
    label: "Other",
  },
];
