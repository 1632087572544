import { CustomToastProps } from "@RooUI";

import * as C from "../constants";

const initialState: { data: CustomToastProps } = {
  // @ts-expect-error TS2322
  data: null,
};
const ToastReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: { payload: CustomToastProps; type: string }
) => {
  switch (action.type) {
    case C.SET_TOAST_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case C.CLEAR_TOAST_DATA:
      return {
        ...state,
        data: null,
      };
    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default ToastReducer;
