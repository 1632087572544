import { find, forEach, map } from "lodash";

import { aStudentSubspecialityInterestOptions } from "../constants/checkBoxConstants";

// @ts-expect-error TS7006
export function getSpecialityInterestLabels(ids) {
  if (ids) {
    const aSpecialityIds = ids.split(",");
    // @ts-expect-error TS7006
    const aSpecialityLabels = aSpecialityIds.map((id) => {
      const speciality = find(aStudentSubspecialityInterestOptions, { id: parseInt(id) });
      return speciality ? speciality.label : "";
    });
    return aSpecialityLabels.join(", ");
  }
}

// @ts-expect-error TS7006
export function getSpecialityInterestShortLabels(ids) {
  if (ids) {
    const aSpecialityIds = ids.split(",");
    // @ts-expect-error TS7006
    const aSpecialityLabels = aSpecialityIds.map((id) => {
      const speciality = find(aStudentSubspecialityInterestOptions, { id: parseInt(id) });
      return speciality ? (speciality.shortLabel ? speciality.shortLabel : speciality.label) : "";
    });
    return aSpecialityLabels.join(", ");
  }
}

// @ts-expect-error TS7006
export function filterInternships(internships) {
  // @ts-expect-error TS7006
  return internships.map((o) => {
    o.appliedStudentsDetails = [];
    o.hiredStudentsDetails = [];
    o.contactedStudentsDetails = [];
    if (o.internshipStatusDetails) {
      const internshipStatusDetails = JSON.parse(`[${o.internshipStatusDetails}]`);
      if (internshipStatusDetails && internshipStatusDetails.length) {
        // @ts-expect-error TS7006
        internshipStatusDetails.forEach((itm) => {
          if (itm.confirmedOn && itm.confirmedBy) {
            o.hiredStudentsDetails.push(itm);
          } else if (itm.contactedOn && itm.contactedBy) {
            o.contactedStudentsDetails.push(itm);
          } else {
            o.appliedStudentsDetails.push(itm);
          }
        });
      }
    } else {
      o.hiredStudentsDetails = [];
      o.appliedStudentsDetails = [];
      o.contactedStudentsDetails = [];
    }
    return o;
  });
}

// @ts-expect-error TS7006
export function sortInternshipsForHospital(internships) {
  // @ts-expect-error TS7034
  const filledInternships = [];
  // @ts-expect-error TS7034
  const noStudentsRequestedInternships = [];
  // @ts-expect-error TS7034
  const previousInternships = [];
  // @ts-expect-error TS7034
  const otherInternships = [];

  map(internships, (o) => {
    // @ts-expect-error TS7034
    const requestedStudentsDetails = [];
    // @ts-expect-error TS7034
    const confirmedStudentsDetails = [];
    // @ts-expect-error TS7034
    const contactedStudentDetails = [];
    if (o.internshipRequestDetails) {
      const internshipRequestDetails = JSON.parse(`[${o.internshipRequestDetails}]`);
      if (internshipRequestDetails && internshipRequestDetails.length) {
        // @ts-expect-error TS7006
        internshipRequestDetails.forEach((itm) => {
          if (itm.confirmedOn) {
            itm.isConfirmed = true;
            confirmedStudentsDetails.push(itm);
          } else if (itm.contactedOn) {
            itm.isContacted = true;
            contactedStudentDetails.push(itm);
          } else {
            requestedStudentsDetails.push(itm);
          }
        });

        if (o.isExpired) {
          // @ts-expect-error TS7005
          o.internshipRequestDetails = confirmedStudentsDetails;
          previousInternships.push(o);
        } else if (o.totalNoOfPositions === o.totalNoOfFilledPositions) {
          // @ts-expect-error TS7005
          o.internshipRequestDetails = confirmedStudentsDetails;
          filledInternships.push(o);
        } else {
          o.internshipRequestDetails = [
            // @ts-expect-error TS7005
            ...confirmedStudentsDetails,
            // @ts-expect-error TS7005
            ...contactedStudentDetails,
            // @ts-expect-error TS7005
            ...requestedStudentsDetails,
          ];
          otherInternships.push(o);
        }
      } else {
        o.internshipRequestDetails = [];
        o.hasNoRequestedInterns = true;
        noStudentsRequestedInternships.push(o);
      }
    } else {
      o.internshipRequestDetails = [];
      o.hasNoRequestedInterns = true;
      noStudentsRequestedInternships.push(o);
    }
  });

  return {
    sortedInternships: [
      // @ts-expect-error TS7005
      ...otherInternships,
      // @ts-expect-error TS7005
      ...noStudentsRequestedInternships,
      // @ts-expect-error TS7005
      ...filledInternships,
    ],
    // @ts-expect-error TS7005
    previousInternships: previousInternships,
  };
}

// @ts-expect-error TS7006
export function sortSearchedInternships(internships) {
  // @ts-expect-error TS7034
  const confirmedInternships = [];
  // @ts-expect-error TS7034
  const contactedInternships = [];
  // @ts-expect-error TS7034
  const appliedInternships = [];
  // @ts-expect-error TS7034
  const otherInternships = [];
  forEach(internships, (o) => {
    if (o.confirmedOn) {
      o.isConfirmed = true;
      confirmedInternships.push(o);
    } else if (o.contactedOn) {
      o.isContacted = true;
      contactedInternships.push(o);
    } else if (o.requestId) {
      o.isApplied = true;
      appliedInternships.push(o);
    } else {
      otherInternships.push(o);
    }
  });

  // @ts-expect-error TS7005
  otherInternships.sort((a, b) => {
    return a.distance < b.distance ? -1 : a.distance > b.distance ? 1 : 0;
  });

  return {
    sortedInternships: [
      // @ts-expect-error TS7005
      ...confirmedInternships,
      // @ts-expect-error TS7005
      ...contactedInternships,
      // @ts-expect-error TS7005
      ...appliedInternships,
      // @ts-expect-error TS7005
      ...otherInternships,
    ],
  };
}
