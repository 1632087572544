import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FormProvider } from "../../vet/profile/components/vetEditProfileTabbed/vetEditProfileContext";
import {
  getVetProfileForm,
  saveVetProfile,
  validate,
} from "../../vet/profile/components/vetEditProfileTabbed/service";
import { VetProfileForm } from "../../api/vet";
import { UpdateHiringModal } from "./UpdateHiringModal";
import moment from "moment";

interface UpdateHiringModalContainerProps {
  showModal?: boolean;
  onClose?: () => void;
}

export const UpdateHiringModalContainer: React.FC<UpdateHiringModalContainerProps> = ({
  showModal: externalShowModal,
  onClose: externalOnClose,
}) => {
  const [defaultForm, setDefaultForm] = useState<VetProfileForm>();
  const [internalShowModal, setInternalShowModal] = useState(false);
  const [targetDate, setTargetDate] = useState<string | null>(null);

  // @ts-expect-error TS2339
  const { vetId, userId } = useSelector((state) => state.login);

  // Load persisted target date from localStorage when vetId becomes available
  useEffect(() => {
    if (vetId) {
      const savedDate = localStorage.getItem(`hiringModalTargetDate_${vetId}`);
      if (savedDate) {
        setTargetDate(savedDate);
      }
    }
  }, [vetId]);

  // Keep target date persisted in localStorage for page refreshes
  useEffect(() => {
    if (!vetId) {
      return;
    }

    if (targetDate) {
      localStorage.setItem(`hiringModalTargetDate_${vetId}`, targetDate);
    } else {
      localStorage.removeItem(`hiringModalTargetDate_${vetId}`);
    }
  }, [targetDate, vetId]);

  useEffect(() => {
    const fetchProfile = async () => {
      if (vetId) {
        const form = await getVetProfileForm(vetId);
        setDefaultForm(form);
      }
    };
    fetchProfile();
  }, [vetId]);

  // Set initial target date based on wantsFullTime value (1 week or 3 months)
  useEffect(() => {
    // Skip if modal is externally controlled
    if (externalShowModal !== undefined) return;

    // Skip if there's already a target date
    if (targetDate) return;

    // Only set new target date if we have the form and no existing date
    if (defaultForm) {
      const newDate =
        defaultForm.wantsFullTime === null || defaultForm.wantsFullTime === undefined
          ? moment().add(1, "week").format("YYYY-MM-DD")
          : moment().add(3, "months").format("YYYY-MM-DD");

      setTargetDate(newDate);
    }
  }, [defaultForm, externalShowModal, targetDate]);

  // Check if current date matches target date to show the modal
  // Runs daily to ensure we don't miss the target date even if the page wasn't open that day
  useEffect(() => {
    // Skip if modal is externally controlled or no target date
    if (externalShowModal !== undefined || !targetDate) return;

    const checkDate = async () => {
      const now = moment().format("YYYY-MM-DD");
      if (now === targetDate) {
        // Fetch latest profile data before showing modal
        if (vetId) {
          const latestForm = await getVetProfileForm(vetId);
          setDefaultForm(latestForm);
          setInternalShowModal(true);
        }
      }
    };

    checkDate();
    const interval = setInterval(checkDate, 24 * 60 * 60 * 1000); // Check once per day at the same time

    return () => {
      clearInterval(interval);
    };
  }, [targetDate, externalShowModal, vetId]);

  const save = async (form: VetProfileForm): Promise<void> => {
    if (!defaultForm) return;
    await saveVetProfile(form, defaultForm, vetId, userId);
    const newForm = await getVetProfileForm(vetId);
    setDefaultForm(newForm);

    setTargetDate(null);

    if (externalOnClose) {
      externalOnClose();
    } else {
      setInternalShowModal(false);
    }
  };

  const handleRemindLater = () => {
    const newDate =
      defaultForm?.wantsFullTime === null || defaultForm?.wantsFullTime === undefined
        ? moment().add(1, "week").format("YYYY-MM-DD")
        : moment().add(3, "months").format("YYYY-MM-DD");

    setTargetDate(newDate);
    setInternalShowModal(false);
  };

  if (!defaultForm) return null;

  return (
    <FormProvider defaultForm={defaultForm} save={save} validate={validate} unsavedCheck={false}>
      <UpdateHiringModal
        showModal={externalShowModal ?? internalShowModal}
        onClose={externalOnClose ?? handleRemindLater}
        isVet={true}
      />
    </FormProvider>
  );
};
