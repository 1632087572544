import * as ManualSMSActionTypes from "../../../constants/manualSMS";

const initialState = {
  usersList: [],
  isMessageSent: null,
};

/* eslint-disable default-param-last */
// @ts-expect-error TS7006
const manualSMSReducer = (state = initialState, action) => {
  /* eslint-enable default-param-last */
  switch (action.type) {
    case ManualSMSActionTypes.SMS_GET_USER_LIST_SUCCESS:
      return {
        ...state,
        usersList: action.payload,
        isMessageSent: null,
      };
    case ManualSMSActionTypes.ADMIN_SEND_SMS_SUCCESS:
      return {
        ...state,
        isMessageSent: true,
      };
    case ManualSMSActionTypes.ADMIN_SEND_SMS_FAIL: {
      return {
        ...state,
        isMessageSent: false,
      };
    }
    case ManualSMSActionTypes.INITIALIZE_STATE: {
      return {
        ...state,
        isMessageSent: null,
      };
    }
    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default manualSMSReducer;
