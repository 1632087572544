import * as Sentry from "@sentry/react";
import { call, put, takeEvery } from "redux-saga/effects";

import { rooFetch } from "../../../api/common/rooFetch";
import {
  UPDATE_USER_PERSONAL_INFO_SUCCESS,
  UPDATE_USER_PROFILE_INFO_FROM_W9,
} from "../../../constants/Login";
import * as errorMessage from "../../../constants/errorMessage";
import { SET_LOADER, UNSET_LOADER } from "../../../constants/loader";
import * as techActions from "../../../constants/techConstants";

// @ts-expect-error TS7006
function* getTechSettingInformation(action) {
  try {
    // @ts-expect-error TS7057
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL +
        "api/tech/getTechInfoById?techId=" +
        action.techId +
        "&userType=" +
        action.userTypeId
    );

    // @ts-expect-error TS7057
    const data = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      yield put({
        type: techActions.GET_TECH_SETTING_INFORMATION_SUCCESS,
        payload: { data: data },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: techActions.GET_TECH_SETTING_INFORMATION_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while fetching tech setting information: " },
    });
    yield put({
      type: techActions.GET_TECH_SETTING_INFORMATION_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// @ts-expect-error TS7006
function* updateTechBasicInfo(action) {
  try {
    // @ts-expect-error TS7057
    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/tech/techSettings", {
      method: "PUT",
      body: JSON.stringify(action.payload.techInformation),
    });

    // @ts-expect-error TS7057
    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: UPDATE_USER_PERSONAL_INFO_SUCCESS,
        payload: action.payload.techInformation,
      });
      yield put({
        type: techActions.UPDATE_TECH_BASIC_INFO_SUCCESS,
        payload: { data: action.payload.techInformation },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: techActions.UDPATE_TECH_BASIC_INFO_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating tech basic information: " },
    });
    yield put({
      type: techActions.UDPATE_TECH_BASIC_INFO_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// @ts-expect-error TS7006
export function* updateW9Async(action) {
  try {
    const {
      isUpdateProfile,
      signNowAddress,
      signNowAptUnitSuite,
      signNowCity,
      signNowStateId,
      signNowZipcode,
    } = action.payload;
    // @ts-expect-error TS7057
    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/tech/taxpayerDetails", {
      method: "POST",
      body: JSON.stringify(action.payload),
    });

    // @ts-expect-error TS7057
    const data = yield response.json();

    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: techActions.UPDATE_TECH_W9_SUCCESS,
        payload: { status: data.status_code, message: data.data[0] },
      });
      if (isUpdateProfile) {
        yield put({
          type: UPDATE_USER_PROFILE_INFO_FROM_W9,
          payload: {
            address: signNowAddress,
            aptUnitSuite: signNowAptUnitSuite,
            city: signNowCity,
            stateId: signNowStateId,
            zipcode: signNowZipcode,
          },
        });
      }
    } else {
      yield put({
        type: techActions.UPDATE_TECH_W9_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating tech w9 information: " },
    });
    yield put({
      type: techActions.UPDATE_TECH_W9_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// @ts-expect-error TS7006
function* getW9InfoAsync(action) {
  try {
    // @ts-expect-error TS7057
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL +
        "api/tech/getW9Info?techId=" +
        action.techId +
        "&userId=" +
        action.userId
    );

    // @ts-expect-error TS7057
    const data = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      yield put({ type: techActions.GET_TECH_W9_INFO_SUCCESS, payload: data.data });
    } else if (data.status_code === 400) {
      yield put({
        type: techActions.GET_TECH_W9_INFO_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while fetching tech w9 information: " },
    });
    yield put({
      type: techActions.GET_TECH_W9_INFO_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// @ts-expect-error TS7006
function* getBlockedHospitalListAsync(action) {
  try {
    // @ts-expect-error TS7057
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/tech/getBlockedHospitalList/${action.payload.techId}`
    );

    // @ts-expect-error TS7057
    const data = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      yield put({
        type: techActions.GET_BLOCKED_HOSPITAL_LIST_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: techActions.GET_BLOCKED_HOSPITAL_LIST_FAIL,
        payload: { status_code: data.status_code, message: data.data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while fetching blocked hospital list: " },
    });
    yield put({
      type: techActions.GET_BLOCKED_HOSPITAL_LIST_FAIL,
      payload: { status_code: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// @ts-expect-error TS7006
function* removeBlockedHospitalAsync(action) {
  try {
    yield put({ type: SET_LOADER });
    let data;
    if (!action.payload.isNewlyAdded) {
      const body = { ...action.payload };
      delete body.isNewlyAdded;
      // @ts-expect-error TS7057
      const response = yield call(
        rooFetch,
        window.RooConfig.API_URL + "api/tech/removeBlockedHospital",
        {
          method: "POST",
          body: JSON.stringify(body),
        }
      );

      // @ts-expect-error TS7057
      data = yield response.json();
    }
    yield put({ type: UNSET_LOADER });

    if (action.payload.isNewlyAdded || data.status_code === 200) {
      yield put({
        type: techActions.REMOVE_BLOCKED_HOSPITAL_SUCCESS,
        payload: action.payload,
      });
    } else {
      yield put({
        type: techActions.REMOVE_BLOCKED_HOSPITAL_FAIL,
        payload: { status_code: data.status_code, message: data.data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while removing blocked hospital: " },
    });
    yield put({
      type: techActions.REMOVE_BLOCKED_HOSPITAL_FAIL,
      payload: { status_code: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// @ts-expect-error TS7006
function* blockHospitalAsync(action) {
  try {
    yield put({ type: SET_LOADER });
    let data;
    if (!action.payload.isNewlyAdded) {
      const body = { ...action.payload };
      delete body.isNewlyAdded;
      // @ts-expect-error TS7057
      const response = yield call(rooFetch, window.RooConfig.API_URL + "api/tech/blockHospital", {
        method: "POST",
        body: JSON.stringify(body),
      });
      // @ts-expect-error TS7057
      data = yield response.json();
    }

    yield put({ type: UNSET_LOADER });

    if (action.payload.isNewlyAdded || data.status_code === 200) {
      yield put({
        type: techActions.BLOCK_HOSPITAL_SUCCESS,
        payload: { data: action.payload },
      });
    } else {
      yield put({
        type: techActions.BLOCK_HOSPITAL_FAIL,
        payload: { status: data.status_code, message: errorMessage.EXCEPTION_MESSAGE },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while blocking hospital: " } });
    yield put({ type: UNSET_LOADER });

    yield put({
      type: techActions.BLOCK_HOSPITAL_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootTechSettingSaga() {
  yield takeEvery(techActions.GET_TECH_SETTING_INFORMATION, getTechSettingInformation);
  yield takeEvery(techActions.GET_TECH_W9_INFO, getW9InfoAsync);
  yield takeEvery(techActions.UPDATE_TECH_BASIC_INFO, updateTechBasicInfo);
  yield takeEvery(techActions.UPDATE_TECH_W9, updateW9Async);
  yield takeEvery(techActions.GET_BLOCKED_HOSPITAL_LIST, getBlockedHospitalListAsync);
  yield takeEvery(techActions.REMOVE_BLOCKED_HOSPITAL, removeBlockedHospitalAsync);
  yield takeEvery(techActions.BLOCK_HOSPITAL, blockHospitalAsync);
}
