import { forEach } from "lodash";

// @ts-expect-error TS7006
export function filterExternships(externships) {
  // @ts-expect-error TS7006
  return externships.map((o) => {
    o.appliedStudentsDetails = [];
    o.hiredStudentsDetails = [];
    o.contactedStudentsDetails = [];
    if (o.externshipStatusDetails) {
      const externshipStatusDetails = JSON.parse(`[${o.externshipStatusDetails}]`);
      if (externshipStatusDetails && externshipStatusDetails.length) {
        // @ts-expect-error TS7006
        externshipStatusDetails.forEach((itm) => {
          if (itm.confirmedOn && itm.confirmedBy) {
            o.hiredStudentsDetails.push(itm);
          } else if (itm.contactedOn && itm.contactedBy) {
            o.contactedStudentsDetails.push(itm);
          } else {
            o.appliedStudentsDetails.push(itm);
          }
        });
      }
    } else {
      o.hiredStudentsDetails = [];
      o.appliedStudentsDetails = [];
      o.contactedStudentsDetails = [];
    }
    return o;
  });
}

// @ts-expect-error TS7006
export function sortExternshipsForHospital(externships) {
  return (
    externships
      // @ts-expect-error TS2362, TS2363, TS7006
      .sort((ext1, ext2) => new Date(ext1.createdOn) - new Date(ext2.createdOn))
      // @ts-expect-error TS7006
      .map((externship) => ({
        ...externship,
        externshipRequestDetails: externship.externshipRequestDetails
          ? JSON.parse(`[${externship.externshipRequestDetails}]`)
          : [],
      }))
  );
}

// @ts-expect-error TS7006
export function sortSearchedExternships(externships) {
  // @ts-expect-error TS7034
  const confirmedExternships = [];
  // @ts-expect-error TS7034
  const contactedExternships = [];
  // @ts-expect-error TS7034
  const appliedExternships = [];
  // @ts-expect-error TS7034
  const deniedExternships = [];
  // @ts-expect-error TS7034
  const openExternships = [];
  // @ts-expect-error TS7034
  const completedExternships = [];
  forEach(externships, (o) => {
    if (o.confirmedOn && o.isStudentData === 1 && o.completed === 0) {
      o.isConfirmed = true;
      confirmedExternships.push(o);
    } else if (o.confirmedOn && o.isStudentData === 1 && o.completed === 1) {
      o.isCompleted = true;
      completedExternships.push(o);
    } else if (o.contactedOn && o.isStudentData === 1) {
      o.isContacted = true;
      contactedExternships.push(o);
    } else if (o.deniedOn && o.isStudentData === 1) {
      o.isDenied = true;
      deniedExternships.push(o);
    } else if (o.requestId && o.isStudentData === 1) {
      o.isApplied = true;
      appliedExternships.push(o);
    } else {
      o.isOpen = true;
      openExternships.push(o);
    }
  });

  // @ts-expect-error TS7005
  openExternships.sort((a, b) => {
    return a.distance < b.distance ? -1 : a.distance > b.distance ? 1 : 0;
  });

  return {
    sortedExternships: [
      // @ts-expect-error TS7005
      ...openExternships,
      // @ts-expect-error TS7005
      ...confirmedExternships,
      // @ts-expect-error TS7005
      ...contactedExternships,
      // @ts-expect-error TS7005
      ...appliedExternships,
      // @ts-expect-error TS7005
      ...completedExternships,
      // @ts-expect-error TS7005
      ...deniedExternships,
    ],
    externshipsByStatus: {
      // @ts-expect-error TS7005
      confirmed: confirmedExternships,
      // @ts-expect-error TS7005
      contacted: contactedExternships,
      // @ts-expect-error TS7005
      applied: appliedExternships,
      // @ts-expect-error TS7005
      denied: deniedExternships,
      // @ts-expect-error TS7005
      open: openExternships,
      // @ts-expect-error TS7005
      completed: completedExternships,
    },
  };
}
