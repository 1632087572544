import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, NavLink, withRouter } from "react-router-dom";

import {
  setEnterpriseHospitalSession,
  unsetEnterpriseHospitalSession,
} from "../Enterprise/actions/enterpriseActions";
import { updateRooUniCalloutStatus } from "../Hospital/actions/hospitalActions";
import { initializeHospitalSettingsAction } from "../Hospital/settings/actions/hospitalSettingsAction";
import { clearAuthAndRedirect } from "../LogOut/LogOut";
import { clearRedirect, updateAddShiftClick } from "../Login/Action/LoginActions";
import * as calendarActionCreators from "../calendar/actions/CalendarActions";
import * as userRoles from "../constants/UserRoleConstants";
import * as userTypes from "../constants/UserTypeConstants";
import { FEATURE_FLAGS } from "../constants/postHogFeatureFlags";
import * as userStatusTypes from "../constants/userStatusTypes";
import { AuthUtility } from "../helpers/AuthUtility";
import { isMobileDevice } from "../helpers/jsUtility";
import { setLoaderAction } from "../loader/actions/loaderAction";
import rooLogo from "../static/images/roo_logo_updated.svg";
import { initializeTechSettingsAction } from "../tech/settings/actions/settingActions";
import TrackingPixel from "../tracking/components/TrackingPixel";
import { Event, EventTracker } from "../tracking/service/EventTracker/EventTrackerService";
import { initializeVetSettingsAction } from "../vet/settings/actions/settingActions";
import * as techDashboardActions from "../vetTechDashboard/actions/techDashboardActions";
import * as vetDashboardActions from "../vetTechDashboard/actions/vetDashboardActions";

import { ChatNotifications } from "./ChatNotifications/ChatNotifications";
import { PendingEnterpriseHospitalBanner } from "./PendingEnterpriseHospitalBanner";
import { PromoNavLink } from "./PromoNavLink";
import RooButton from "./RooButton";
import { PostHogWrapper } from "./Wrappers/PostHogWrapper";
import { isRooFeatureEnabled } from "./Wrappers/isRooFeatureEnabled";

class HeaderLoggedIn extends Component {
  // @ts-expect-error TS7006
  constructor(props) {
    super(props);

    this.isInternshipsAvailable = localStorage.getItem("isInternshipsAvailable");
    this.state = {
      isMenuOpen: false,
      authContext: null,
    };
  }

  async componentDidMount() {
    this.setState({ authContext: await AuthUtility.getAuthContext() });
  }

  // @ts-expect-error TS7006
  componentDidUpdate(prevProps) {
    // const { techPaymentInfo, vetPaymentInfo } = this.props;
    // if ((techPaymentInfo || vetPaymentInfo) && this.state.hasDataLoaded === false) {
    //   this.setState({ hasDataLoaded: true });
    // }
    const { hospitalId, addVetShiftClicked, addTechShiftClicked, updateAddShiftClick, history } =
      this.props;
    if (
      hospitalId &&
      prevProps.hospitalId != hospitalId &&
      (addVetShiftClicked || addTechShiftClicked)
    ) {
      const path = addVetShiftClicked ? "/hospital/addshift" : "/hospital/addtechshift";
      history.push(path);
      updateAddShiftClick({ isSet: false, isVet: addVetShiftClicked ? true : false });
    }
  }

  // @ts-expect-error TS7006
  checkisRooAdmin(userTypeId) {
    let isRooAdmin = false;
    const vetId = localStorage.getItem("vetId");
    const hospitalId = localStorage.getItem("hospitalId");
    const techId = localStorage.getItem("techId");
    const studentId = localStorage.getItem("studentId");
    if (
      userTypeId &&
      (userTypeId === userTypes.ADMIN_USER_TYPE || userTypeId === userTypes.ROO_SUPER_USER_TYPE) &&
      !vetId &&
      !techId &&
      !studentId &&
      !hospitalId
    ) {
      isRooAdmin = true;
    }
    return isRooAdmin;
  }

  // @ts-expect-error TS6133, TS7006
  setRoutePathsForLinks(userTypeId, isProfileComplete, isStudentFourthYear) {
    const { location } = this.props;
    const isHospital = userTypeId === userTypes.HOSPITAL_USER_TYPE;
    const isVet = userTypeId === userTypes.VET_USER_TYPE;
    const isTech = userTypeId === userTypes.TECH_USER_TYPE;
    const isRooUniStudent = userTypeId === userTypes.STUDENT_USER_TYPE;
    const isRooAdmin = this.isRooAdmin;
    let routes = {};

    if (userTypeId) {
      if (isHospital) {
        routes = {
          userRootRoute: isProfileComplete ? "/hospital" : location.pathname,
          enterprise: "/enterprise",
          manageMultiSiteUsers: "/enterprise/users",
          billing: "/enterprise/billing",
          vetAnalytics: "/enterprise/vetAnalytics",
          internalReliefRoute: "/enterprise/internalRelief/batchUpload",
          updateProfile: "/hospital/editprofile",
          history: "/hospital/history",
          settings: "/hospital/settings",
          manageAccountSettings: "/hospital/manageSettings",
          rooPolicies: "/hospital/policies",
          manageUsers: "/hospital/users",
          ratings: "/hospital/ratings",
          overtimeconfirmation: "/hospital/overtimeconfirmation",
          internships: "/hospital/internships",
          externships: "/hospital/externships",
          calendar: "/hospital/calendar",
          manageShifts: "/hospital/manageShifts",
          notificationSettings: "/hospital/notificationSettings",
          referHospital: "/hospital/referHospital",
        };
      } else if (isVet) {
        routes = {
          userRootRoute: isProfileComplete ? "/vet" : location.pathname,
          updateProfile: "/vet/editprofile",
          history: "/vet/history",
          settings: "/vet/settings",
          rooPolicies: "/vet/policies",
          ratings: "/vet/ratings",
          roo4you: "/roo4you",
          referNewUser: "/vet/referuser",
          promos: "/vet/promos",
          taxInfo: "/vet/taxinfo",
          resources: "/roo-concierge",
        };
      } else if (isTech) {
        routes = {
          userRootRoute: isProfileComplete ? "/tech" : location.pathname,
          updateProfile: "/tech/editprofile",
          ratings: "/tech/editprofile/#ratings",
          history: "/tech/history",
          settings: "/tech/settings",
          rooPolicies: "/tech/policies",
          roo4you: "/roo4you",
          referNewUser: "/tech/referuser",
          promos: "/tech/promos",
          taxInfo: "/tech/taxinfo",
          resources: "/roo-concierge",
        };
      } else if (isRooUniStudent) {
        routes = {
          // userRootRoute:
          //   isProfileComplete && isStudentFourthYear
          //     ? "/student/externships"
          //     : "/student/internships",
          internships: "/student/internships",
          externships: "/student/externships",
          myexternships: "/student/myexternships",
          roo4you: "/roo4you",
          updateProfile: "/student/editprofile",
          settings: "/student/settings",
          referNewUser: "/student/referuser",
        };
      } else if (isRooAdmin) {
        routes = {
          userRootRoute: "/admin",
          userRoute: "/admin/users",
          shift: "/admin/shifts",
          map: "/admin/map",
          hospitals: "/admin/hospitals",
          enterprises: "/admin/enterprises",
          contactOwners: "/admin/contactOwners",
          rooAdmin: "/admin/rooAdmin",
          rooSuperUser: "/admin/rooSuperUser",
          sendSMS: "/admin/comms",
          W9: "/admin/W9",
          vetTech: "/admin/tech",
          rooUniStudent: "/admin/student",
          techShifts: "/admin/techShifts",
          techCallouts: "/admin/techCallouts",
          internships: "/admin/internships",
          externships: "/admin/externships",
          validateShiftPricing: "/admin/validateShiftPricing",
          referrals: "/admin/referral",
          referralCampaign: "/admin/referralCampaign",
          travelStipends: "/admin/travelStipends",
          promos: "/admin/promos",
          fullTimePlacement: "/admin/fullTimePlacement",
          addEvent: "/admin/addEvent",
          eventLogs: "/admin/eventLogs",
          conferenceEvents: "/admin/conferenceEvents",
          zapierSms: "/admin/zapierSms",
        };
      }
    }

    return routes;
  }

  // @ts-expect-error TS7006
  handleLogout = (e, isContractor, contractorEntity, contractorId, hospitalId) => {
    this.trackClickNavItem(
      isContractor ? contractorEntity : Event.Entity.HOSPITAL,
      isContractor ? contractorId : hospitalId,
      "Logout",
      "navbar_dropdown"
    );

    e.preventDefault();
    this.clearSessionProps(e);
    clearAuthAndRedirect();
  };

  // @ts-expect-error TS7006
  handleBackToAdmin = async (e) => {
    e.preventDefault();

    AuthUtility.clearPreviousSession();

    // If the Cognito auth context is valid, copy the necessary values to localStorage
    // before transitioning back to Roo Admin
    const { authContext } = this.state;
    if (authContext) {
      AuthUtility.setIsAuthorisedUser(true);
      // @ts-expect-error TS2339
      localStorage.setItem("userTypeId", authContext.userTypeId.toString());
      // @ts-expect-error TS2339
      localStorage.setItem("email", authContext.email);
    }

    this.clearSessionProps(e);
    window.location.replace(`/admin`);
  };

  // @ts-expect-error TS6133, TS7006
  clearSessionProps = (e) => {
    this.props.initializeSearchResultState();
    this.props.initializeTechSearchResultState();
    this.props.intializeVetSettings();
    this.props.intializeTechSettings();
    this.props.initializeState(); // init calendar shifts data
    this.props.initializeHospitalSettings();
    this.props.clearRedirect();
    this.props.history.replace({
      search: "",
    });
  };

  toggleMenuDrawer = () => {
    const { isMenuOpen } = this.state;
    this.setState({ isMenuOpen: !isMenuOpen });
  };

  getSettingsDropdown = (
    // @ts-expect-error TS7006
    route,
    // @ts-expect-error TS7006
    isHospital,
    // @ts-expect-error TS7006
    isContractor,
    // @ts-expect-error TS7006
    isTech,
    // @ts-expect-error TS7006
    isRooUniStudent,
    // @ts-expect-error TS7006
    isRooSuperUser,
    // @ts-expect-error TS7006
    authContext,
    // @ts-expect-error TS7006
    isVet,
    // @ts-expect-error TS7006
    vetId,
    // @ts-expect-error TS7006
    techId,
    // @ts-expect-error TS6133, TS7006
    isNewSearchExperienceEnabled
  ) => {
    if (!AuthUtility.isAuthorisedUser()) {
      return null;
    }

    const { isHospitalAdmin, isGroupAdmin, isEnterpriseAdmin, isMultiHospitalAccess, hospitalId } =
      this.props;
    // @ts-expect-error TS2345
    const isPendingUser = parseInt(localStorage.getItem("isActive")) === userStatusTypes.PENDING;
    const isEnterprise = isMultiHospitalAccess || isGroupAdmin || isEnterpriseAdmin ? true : false;
    const contractorId = isVet ? vetId : techId;
    const contractorEntity = isVet ? Event.Entity.VET : Event.Entity.TECH;
    return (
      <>
        {String(this.isRegistrationComplete) === "1" || isPendingUser ? (
          <span>
            {this.isRooAdmin ? null : (
              <section className="dropdown-container">
                <section className="list-item" data-testid="profileLink">
                  <section className="tooltip-wrapper">
                    <div className="tooltip-placeholder">TEXT</div>
                    <div className="dropdown-notification-dot-placeholder" />
                  </section>
                  <Link
                    to={route.updateProfile}
                    className="dropdown-item"
                    onClick={() => {
                      this.trackClickNavItem(
                        isContractor ? contractorEntity : Event.Entity.HOSPITAL,
                        isContractor ? contractorId : hospitalId,
                        "Profile",
                        "navbar_dropdown"
                      );
                    }}
                  >
                    Profile
                  </Link>
                </section>
                {isTech ? (
                  <section className="list-item" data-testid="ratingsLink">
                    <Link
                      to={{
                        pathname: route.ratings,
                        state: {
                          fromRatings: true,
                        },
                      }}
                      className="dropdown-item"
                      onClick={() => {
                        this.trackClickNavItem(
                          contractorEntity,
                          contractorId,
                          "Ratings",
                          "navbar_dropdown"
                        );
                      }}
                    >
                      <section className="tooltip-wrapper">
                        <div className="tooltip-placeholder">TEXT</div>
                        <div className="dropdown-notification-dot-placeholder" />
                      </section>
                      Ratings
                    </Link>
                  </section>
                ) : null}

                <section
                  className="list-item"
                  data-testid="settingsLink"
                  onClick={() => {
                    this.trackClickNavItem(
                      isContractor ? contractorEntity : Event.Entity.HOSPITAL,
                      isContractor ? contractorId : hospitalId,
                      "Settings",
                      "navbar_dropdown"
                    );
                  }}
                >
                  <Link to={"/manageSettings"} className="dropdown-item">
                    {isContractor ? "Settings + Bank" : "Settings"}
                  </Link>
                </section>

                {!isRooUniStudent ? (
                  <section
                    className="list-item"
                    data-testid="policiesLink"
                    onClick={() => {
                      this.trackClickNavItem(
                        isContractor ? contractorEntity : Event.Entity.HOSPITAL,
                        isContractor ? contractorId : hospitalId,
                        "Our Policies",
                        "navbar_dropdown"
                      );
                    }}
                  >
                    <Link to={route.rooPolicies} className="dropdown-item">
                      Our Policies
                    </Link>
                  </section>
                ) : null}
                {isHospital && this.props.referEnabled ? (
                  <section
                    className="list-item"
                    data-testid="referHospitalLink"
                    onClick={() => {
                      this.trackClickNavItem(
                        Event.Entity.HOSPITAL,
                        hospitalId,
                        "Refer & Earn",
                        "navbar_dropdown"
                      );
                    }}
                  >
                    <Link to={route.referHospital} className="dropdown-item">
                      Refer & Earn
                    </Link>
                  </section>
                ) : null}

                {isContractor ? (
                  <section className="list-item" data-testid="taxInfoLink">
                    <Link
                      to={route.taxInfo}
                      className="dropdown-item"
                      onClick={() => {
                        this.trackClickNavItem(
                          contractorEntity,
                          contractorId,
                          "Tax Info",
                          "navbar_dropdown"
                        );
                      }}
                    >
                      Tax Info
                    </Link>
                  </section>
                ) : null}
                {isHospital ? (
                  <Link
                    to={route.ratings}
                    className="dropdown-item"
                    data-testid="ratingsLink"
                    onClick={() => {
                      this.trackClickNavItem(
                        Event.Entity.HOSPITAL,
                        hospitalId,
                        "Ratings",
                        "navbar_dropdown"
                      );
                    }}
                  >
                    Ratings
                  </Link>
                ) : null}
                {isEnterprise &&
                isHospital &&
                ((isHospitalAdmin && isMultiHospitalAccess) ||
                  isGroupAdmin ||
                  this.isEnterpriseAdmin) ? (
                  <section className="list-item" data-testid="manageUsers">
                    <section className="tooltip-wrapper">
                      <div className="tooltip-placeholder">TEXT</div>
                      <div className="dropdown-notification-dot-placeholder" />
                    </section>
                    <Link
                      to={
                        isGroupAdmin || this.isEnterpriseAdmin
                          ? route.manageMultiSiteUsers
                          : route.manageUsers
                      }
                      className="dropdown-item"
                      onClick={() => {
                        this.trackClickNavItem(
                          Event.Entity.HOSPITAL,
                          hospitalId,
                          "Manage Account Users",
                          "navbar_dropdown"
                        );
                      }}
                    >
                      Manage Account Users
                    </Link>
                  </section>
                ) : null}
                {isContractor ? (
                  <section className="list-item" data-testid="rooConciergeLink">
                    <a
                      className="dropdown-item"
                      href={route.resources}
                      onClick={() => {
                        this.trackClickNavItem(
                          contractorEntity,
                          contractorId,
                          "Resources",
                          "navbar_dropdown"
                        );
                      }}
                    >
                      Resources
                    </a>
                  </section>
                ) : null}
                {isContractor ? (
                  <section className="list-item" data-testid="roo4ULink">
                    <a
                      className="dropdown-item"
                      href={route.roo4you}
                      onClick={() => {
                        this.trackClickNavItem(
                          contractorEntity,
                          contractorId,
                          "Roo4You",
                          "navbar_dropdown"
                        );
                      }}
                    >
                      Roo4You
                    </a>
                  </section>
                ) : null}
              </section>
            )}
          </span>
        ) : null}
        <a
          onClick={(e) =>
            this.handleLogout(e, isContractor, contractorEntity, contractorId, hospitalId)
          }
          className="dropdown-item"
          href="#"
          data-testid="logoutBtn"
        >
          Logout
        </a>

        {isRooSuperUser ? (
          <span>
            <a href="/superuser" className="dropdown-item" data-testid="superUserBtn">
              Super User
            </a>
          </span>
        ) : null}

        {/*
          Current user type is not a super user, but the Cognito auth context is.
          User is currently logged in as someone else. Provide option to navigate back to Roo Admin.
        */}
        {!isRooSuperUser && authContext?.userTypeId === userTypes.ROO_SUPER_USER_TYPE ? (
          <span>
            <a
              onClick={this.handleBackToAdmin}
              className="dropdown-item"
              href="#"
              data-testid="backToAdminBtn"
            >
              Back to Admin
            </a>
          </span>
        ) : null}
      </>
    );
  };

  // @ts-expect-error TS7031
  trackAddShift = ({ shiftType }) => {
    EventTracker.send({
      eventName: Event.Name.HOSPITAL_ADD_SHIFT,
      eventType: Event.Type.CLICK,
      entityType: Event.Entity.HOSPITAL,
      entityId: this.props.hospitalId,
      context: {
        shiftType,
        location: "add_shift_navbar",
      },
    });
  };

  // @ts-expect-error TS7006
  trackClickNavItem = (entity, entityId, linkCTA, location) => {
    EventTracker.send({
      eventName: Event.Name.NAVBAR,
      eventType: Event.Type.CLICK,
      entityType: entity,
      entityId: entityId,
      context: {
        location: location || "navbar",
        linkCTA,
      },
    });
  };

  // @ts-expect-error TS7031
  handleAddShift = ({ isVet }) => {
    const {
      hospitalId,
      updateAddShiftClick,
      setLoader,
      setEnterpriseHospitalSession,
      myHospitalsList,
      history,
    } = this.props;
    if (!hospitalId && myHospitalsList?.length) {
      updateAddShiftClick({ isVet, isSet: true });
      setLoader();
      setEnterpriseHospitalSession({ hospitalId: myHospitalsList?.[0]?.id });
    } else if (hospitalId) {
      history.push(isVet ? "/hospital/addshift" : "/hospital/addtechshift");
      this.trackAddShift({ shiftType: isVet ? "vet" : "tech" });
    }
  };

  // @ts-expect-error TS7006
  getLinks = (userTypeId, route) => {
    const {
      enterpriseName,
      fullName,
      firstName,
      lastName,
      hospitalId,
      userRoleId,
      location,
      unsetEnterpriseHospitalSession,
      isInternalReliefEnabled,
      hideToggler,
      vetId,
      techId,
    } = this.props;
    const { pathname } = location;
    const { authContext } = this.state;

    const profileImage = this.profileImage;
    const userName = this.userName;
    const isHospital = userTypeId === userTypes.HOSPITAL_USER_TYPE;
    const isVet = userTypeId === userTypes.VET_USER_TYPE;
    const isTech = userTypeId === userTypes.TECH_USER_TYPE;
    const isRooUniStudent = userTypeId === userTypes.STUDENT_USER_TYPE;
    const isContractor = isVet || isTech;
    const contractorId = isVet ? vetId : techId;
    const contractorEntity = isVet ? Event.Entity.VET : Event.Entity.TECH;
    const isGroupAdmin = this.isGroupAdmin;
    const isRooSuperAdmin = userRoleId === userRoles.ROO_SUPER_ADMIN;
    const isRooSuperUser = userTypeId === userTypes.ROO_SUPER_USER_TYPE;
    const isRooFinanceAdmin = userRoleId === userRoles.ROO_FINANCE_ADMIN;
    const isRooAdmin = this.isRooAdmin;
    const imageTag = profileImage ? <img src={profileImage} /> : null;
    // @ts-expect-error TS2367
    const isPendingUser = localStorage.getItem("isActive") == userStatusTypes.PENDING;

    const showChatNotifications =
      !hideToggler &&
      window.location.href.indexOf("enterprise") === -1 &&
      !isRooAdmin &&
      !isRooUniStudent &&
      !isPendingUser &&
      AuthUtility.isAuthorisedUser();

    const showPromoPage = isRooFeatureEnabled(FEATURE_FLAGS.NEW_PROMO_PAGE);
    const isNewSearchExperienceEnabled = isRooFeatureEnabled(FEATURE_FLAGS.NEW_SEARCH_EXPERIENCE);

    const settingsDropdown = this.getSettingsDropdown(
      route,
      isHospital,
      isContractor, // vet or tech
      isTech,
      isRooUniStudent,
      isRooSuperUser,
      authContext,
      isVet,
      vetId,
      techId,
      isNewSearchExperienceEnabled
    );

    let topNameInMenu = enterpriseName
      ? enterpriseName
      : firstName
      ? `${firstName} ${lastName}`
      : fullName;
    topNameInMenu = topNameInMenu || localStorage.getItem("fullName");

    const isShowEnterpriseMenu = isGroupAdmin || this.isEnterpriseAdmin;
    const isEnterpriseBilling = isGroupAdmin || this.isEnterpriseAdmin;

    const handleUniCalloutStatus = () => {
      if (this.props?.isShowUniCallOutModal == 1) {
        this.props?.updateRooUniCalloutStatus();
      }
    };

    return (
      <div className="menu-top-menu-container inner-page-menu header-top-menu">
        <ul id="top-menu" className={`nav navbar-nav ${isShowEnterpriseMenu ? "long-menus" : ""}`}>
          <li className="sm-user d-block d-xl-none">
            <span className="user-pic">{imageTag}</span>
            <a className="nav-link dropdown-toggle">
              <span>{topNameInMenu}</span>
              {isHospital ? (
                <>
                  <hr className="mt-0 mb-0" />
                  <span className="userNameLink">{userName}</span>
                </>
              ) : null}
            </a>
          </li>
          {isHospital && isShowEnterpriseMenu ? (
            <li className="nav-item dropdown">
              <NavLink
                exact
                activeClassName={
                  pathname == route.enterprise ||
                  pathname == route.vetAnalytics ||
                  pathname == route.internalReliefRoute
                    ? "is-active"
                    : ""
                }
                className="nav-item nav-link dropdown-toggle"
                to={"#"}
                id="menu-enterprise"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
                onClick={() =>
                  this.trackClickNavItem(Event.Entity.HOSPITAL, hospitalId, "Enterprise")
                }
              >
                Enterprise
              </NavLink>
              <div
                onClick={() => {
                  unsetEnterpriseHospitalSession();
                }}
                className="dropdown-menu dropdown-menu-right"
                aria-labelledby="menu-enterprise"
                style={{ right: "initial" }}
              >
                <Link
                  className="dropdown-item"
                  to={route.enterprise}
                  onClick={() =>
                    this.trackClickNavItem(Event.Entity.HOSPITAL, hospitalId, "My Hospitals")
                  }
                >
                  My Hospitals
                </Link>
                <Link
                  className="dropdown-item"
                  to={route.vetAnalytics}
                  onClick={() =>
                    this.trackClickNavItem(Event.Entity.HOSPITAL, hospitalId, "Analytics")
                  }
                >
                  Analytics
                </Link>
                {isInternalReliefEnabled ? (
                  <Link className="dropdown-item" to={route.internalReliefRoute}>
                    Internal Relief
                  </Link>
                ) : null}
              </div>
            </li>
          ) : null}
          {(isContractor || isHospital || isRooUniStudent) && this.isRegistrationComplete == 1 ? (
            <>
              {isHospital ? (
                <>
                  <li data-testid="calendarTab">
                    <NavLink
                      exact
                      activeClassName="is-active"
                      to={route.calendar}
                      onClick={() =>
                        this.trackClickNavItem(Event.Entity.HOSPITAL, hospitalId, "Calendar")
                      }
                    >
                      Calendar
                    </NavLink>
                  </li>
                  <li data-testid="manageShiftsTab">
                    <NavLink
                      exact
                      activeClassName="is-active"
                      to={route.manageShifts}
                      onClick={() =>
                        this.trackClickNavItem(Event.Entity.HOSPITAL, hospitalId, "Manage Shifts")
                      }
                    >
                      Manage Shifts
                    </NavLink>
                  </li>
                </>
              ) : null}
              {isContractor ? (
                <li data-testid="findShiftsTab">
                  <NavLink
                    exact
                    activeClassName="is-active"
                    to={route.userRootRoute}
                    onClick={() => {
                      this.trackClickNavItem(contractorEntity, contractorId, "Find Shifts");
                    }}
                  >
                    Find Shifts
                  </NavLink>
                </li>
              ) : null}
              {isContractor && showPromoPage ? (
                <li data-testid="promosTab">
                  <PromoNavLink route={route} />
                  <TrackingPixel
                    eventName={Event.Name.PROMO_PAGE_NAV_VIEW}
                    eventType={Event.Type.IMPRESSION}
                    entityType={Event.Entity.PROMO}
                    entityId={-1}
                  />
                </li>
              ) : null}
              {isRooUniStudent ? (
                <>
                  <li className="nav-item" data-testid="findExternshipsTab">
                    <NavLink exact activeClassName="is-active" to={route.externships}>
                      Find Externships
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink exact activeClassName="is-active" to={route.myexternships}>
                      My Externships
                    </NavLink>
                  </li>
                </>
              ) : null}

              {isHospital ? (
                <li
                  className={this.props?.isShowUniCallOutModal ? "highlight-uni-callout " : ""}
                  id="uni-callout-link"
                  onClick={handleUniCalloutStatus}
                >
                  <NavLink
                    exact
                    activeClassName="is-active"
                    to={route.externships}
                    onClick={() =>
                      this.trackClickNavItem(Event.Entity.HOSPITAL, hospitalId, "Externships")
                    }
                  >
                    Externships
                  </NavLink>
                </li>
              ) : null}
              {!isRooUniStudent && !isPendingUser ? (
                <li data-testid={isEnterpriseBilling ? "billingTab" : "historyTab"}>
                  <NavLink
                    exact
                    activeClassName="is-active"
                    to={isEnterpriseBilling ? route.billing : route.history}
                    onClick={() =>
                      this.trackClickNavItem(
                        isContractor ? contractorEntity : Event.Entity.HOSPITAL,
                        isContractor ? contractorId : hospitalId,
                        isContractor ? "History & Earnings" : "Billing"
                      )
                    }
                  >
                    {isContractor ? "History & Earnings" : "Billing"}
                  </NavLink>
                </li>
              ) : null}

              {isContractor ? (
                <li>
                  <NavLink exact activeClassName="is-active" to={route.referNewUser}>
                    Refer & Earn
                  </NavLink>
                </li>
              ) : null}
            </>
          ) : null}

          {isHospital && this.isRegistrationComplete == 1 ? (
            <li
              className="nav-item dropdown d-none d-xl-block mt-1 pl-0"
              style={{ alignSelf: "center" }}
            >
              <NavLink
                exact
                className="nav-item nav-link dropdown-toggle-white cta-button-with-toggle mr-md-2 pt-0 pr-0 button-primary"
                to={"#"}
                id="bd-versions"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
              >
                <RooButton
                  primary
                  small
                  title="Add Shift"
                  style={{ minWidth: "9rem", alignSelf: "center", paddingLeft: "1rem" }}
                  onClick={() => this.trackAddShift({ shiftType: "" })}
                />
              </NavLink>

              <div
                className="dropdown-menu dropdown-menu-right"
                aria-labelledby="bd-versions"
                style={{ right: "initial", minWidth: "9rem" }}
                id="add-shift-dropdown"
              >
                <div
                  className="dropdown-item add-shift cursor-pointer"
                  onClick={() => this.handleAddShift({ isVet: true })}
                >
                  Add Vet Shift
                </div>
                <PostHogWrapper flag={FEATURE_FLAGS.DISABLE_ADD_TECH_SHIFT} match={false}>
                  <Link
                    className="dropdown-item add-shift"
                    to="/hospital/addtechshift"
                    onClick={() => this.handleAddShift({ isVet: false })}
                  >
                    Add Tech Shift
                  </Link>
                </PostHogWrapper>
              </div>
            </li>
          ) : null}

          {/* ROO ADMIN */}

          {isRooAdmin ? (
            <>
              <li data-testid="mapTab">
                <NavLink exact activeClassName="is-active" to={route.map}>
                  Map
                </NavLink>
              </li>
              <li className="nav-item dropdown" data-testid="bonusDropdown">
                <NavLink
                  exact
                  activeClassName={
                    this.props.location.pathname === route.referrals ? "is-active" : ""
                  }
                  className="nav-item nav-link dropdown-toggle mr-md-2"
                  to={"#"}
                  id="bd-versions"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true"
                >
                  Bonus
                </NavLink>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="bd-versions">
                  {isRooAdmin ? (
                    <Link className="dropdown-item" to={route.promos} data-testid="promosLink">
                      Promos
                    </Link>
                  ) : null}
                  <Link className="dropdown-item" to={route.referrals} data-testid="referralsLink">
                    Referrals
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={route.referralCampaign}
                    data-testid="referralCampaignLink"
                  >
                    Referral Campaigns
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={route.travelStipends}
                    data-testid="travelStipendsLink"
                  >
                    Travel Stipends
                  </Link>
                </div>
              </li>
              <li className="nav-item dropdown">
                <NavLink
                  exact
                  activeClassName={
                    this.props.location.pathname == route.userRootRoute ||
                    this.props.location.pathname == route.vetTech ||
                    this.props.location.pathname == route.rooUniStudent ||
                    this.props.location.pathname == route.hospitals ||
                    this.props.location.pathname == route.enterprises ||
                    this.props.location.pathname == route.rooAdmin ||
                    this.props.location.pathname == route.rooSuperUser
                      ? "is-active"
                      : ""
                  }
                  className="nav-item nav-link dropdown-toggle mr-md-2"
                  to={"#"}
                  id="bd-versions"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true"
                  data-testid="usersDropdown"
                >
                  Users
                </NavLink>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="bd-versions">
                  <Link className="dropdown-item" to={route.userRoute} data-testid="usersLink">
                    Users
                  </Link>
                  <Link className="dropdown-item" to={route.userRootRoute} data-testid="vetsLink">
                    Vets
                  </Link>
                  <Link className="dropdown-item" to={route.vetTech} data-testid="techsLink">
                    Techs
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={route.rooUniStudent}
                    data-testid="studentsLink"
                  >
                    Students
                  </Link>
                  <Link className="dropdown-item" to={route.hospitals} data-testid="hospitalsLink">
                    Hospitals
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={route.enterprises}
                    data-testid="enterprisesLink"
                  >
                    Enterprises
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={route.contactOwners}
                    data-testid="contactOwnersLink"
                  >
                    Contact Owners
                  </Link>
                  {isRooSuperAdmin ? (
                    <>
                      <Link className="dropdown-item" to={route.rooAdmin} data-testid="adminLink">
                        Roo Admin
                      </Link>
                      <Link
                        className="dropdown-item"
                        to={route.rooSuperUser}
                        data-testid="superLink"
                      >
                        Roo Super Users
                      </Link>
                    </>
                  ) : null}
                </div>
              </li>
              <li className="nav-item dropdown">
                <NavLink
                  exact
                  activeClassName={
                    this.props.location.pathname == route.shift ||
                    this.props.location.pathname == route.techShifts ||
                    this.props.location.pathname == route.techCallouts
                      ? "is-active"
                      : ""
                  }
                  to={"#"}
                  className="nav-item nav-link dropdown-toggle mr-md-2"
                  id="bd-versions"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true"
                  data-testid="shiftsDropdown"
                >
                  Shifts
                </NavLink>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="bd-versions">
                  <Link className="dropdown-item" to={route.shift} data-testid="vetShiftsLink">
                    Vet Shifts
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={route.techShifts}
                    data-testid="techShiftsLink"
                  >
                    Tech Shifts
                  </Link>
                  <Link className="dropdown-item" to={route.techCallouts}>
                    Callout SOS
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={route.externships}
                    data-testid="externshipsLink"
                  >
                    Externships
                  </Link>
                </div>
              </li>
              <li className="nav-item dropdown">
                <NavLink
                  exact
                  activeClassName={
                    this.props.location.pathname == route.validateShiftPricing ? "is-active" : ""
                  }
                  to={"#"}
                  className="nav-item nav-link dropdown-toggle mr-md-2"
                  id="bd-versions"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true"
                  data-testid="pricingDropdown"
                >
                  Pricing
                </NavLink>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="bd-versions">
                  <Link
                    className="dropdown-item"
                    to={route.validateShiftPricing}
                    data-testid="validateLink"
                  >
                    Validate
                  </Link>
                </div>
              </li>
              <li className="nav-item dropdown">
                <NavLink
                  exact
                  activeClassName={
                    this.props.location.pathname == route.events ||
                    this.props.location.pathname == route.eventLogs
                      ? "is-active"
                      : ""
                  }
                  className="nav-item nav-link dropdown-toggle mr-md-2"
                  to={"#"}
                  id="bd-versions"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true"
                >
                  Events
                </NavLink>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="bd-versions">
                  <Link className="dropdown-item" to={route.addEvent}>
                    Add Event
                  </Link>
                  <Link className="dropdown-item" to={route.eventLogs}>
                    Event Logs
                  </Link>
                </div>
              </li>
              <li className="nav-item dropdown">
                <NavLink
                  exact
                  activeClassName={
                    this.props.location.pathname == route.W9 ||
                    this.props.location.pathname == route.sendSMS ||
                    this.props.location.pathname == route.fullTimePlacement
                      ? "is-active"
                      : ""
                  }
                  className="nav-item nav-link dropdown-toggle mr-md-2"
                  to={"#"}
                  id="bd-versions"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true"
                >
                  Other
                </NavLink>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="bd-versions">
                  {isRooFinanceAdmin ? (
                    <>
                      <Link className="dropdown-item" to={route.W9}>
                        W-9
                      </Link>
                    </>
                  ) : null}
                  <Link className="dropdown-item" to={route.sendSMS}>
                    Comms
                  </Link>
                  <Link className="dropdown-item" to={route.fullTimePlacement}>
                    Full-Time Placement
                  </Link>
                  <Link className="dropdown-item" to={route.zapierSms}>
                    Zapier Sms
                  </Link>
                  <Link className="dropdown-item" to={route.conferenceEvents}>
                    Conference Events
                  </Link>
                </div>
              </li>
            </>
          ) : null}

          {showChatNotifications && (
            <div className="d-xl-flex d-none align-items-center">
              <ChatNotifications />
            </div>
          )}

          {/* Settings Dropdown (profile, settings, etc)  */}

          {settingsDropdown && (
            <li className="nav-item dropdown d-none d-xl-block pr-0">
              <span
                className="user-pic"
                onClick={() =>
                  this.trackClickNavItem(
                    isContractor ? contractorEntity : Event.Entity.HOSPITAL,
                    isContractor ? contractorId : hospitalId,
                    "Profile Image/User Name"
                  )
                }
              >
                {imageTag}
              </span>
              <a
                className="nav-link dropdown-toggle"
                id="profile-menu"
                data-toggle="dropdown"
                onClick={() =>
                  this.trackClickNavItem(
                    isContractor ? contractorEntity : Event.Entity.HOSPITAL,
                    isContractor ? contractorId : hospitalId,
                    "Profile Image/User Name"
                  )
                }
              >
                <span>{topNameInMenu}</span>
                {isHospital ? (
                  <>
                    <hr className="mt-0 mb-0" />
                    <span className="userNameLink">{userName}</span>
                  </>
                ) : null}
              </a>
              <div className="dropdown-menu">{settingsDropdown}</div>
            </li>
          )}

          <li className="p-0">
            <div className="dropdown-sm-menu d-block d-xl-none">{settingsDropdown}</div>
          </li>
        </ul>
      </div>
    );
  };

  // @ts-expect-error TS7006
  getHeaderClassName(isPendingUser) {
    let className = "site-header ";
    if (
      this.props?.backSideHeader &&
      this.props?.isShowUniCallOutModal &&
      Number(this.props?.isInternshipsAvailable)
    ) {
      className += "backSideHeader uni-callout-site-header ";
    } else if (this.props?.backSideHeader) {
      className += "backSideHeader ";
    } else if (this.props?.isShowUniCallOutModal && Number(this.props?.isInternshipsAvailable)) {
      className += "uni-callout-site-header ";
    } else if (isPendingUser && this.props.enterpriseName) {
      className += "pending-enterprise-header ";
    }
    return className;
  }
  render() {
    const { isMenuOpen } = this.state;
    const {
      hideToggler,
      isGroupAdmin,
      isEnterpriseAdmin,
      enterpriseId,
      enterpriseLogo,
      enterpriseEnabled,
      isRegistrationComplete,
      isMultiHospitalAccess,
      profileImage,
      userName,
      isStudentFourthYear,
      enterpriseName,
    } = this.props;

    const isEnterprise =
      isMultiHospitalAccess ||
      isGroupAdmin ||
      isEnterpriseAdmin ||
      (enterpriseId && enterpriseEnabled)
        ? true
        : false;

    let userTypeId = this.props.userTypeId;
    if (!userTypeId) {
      userTypeId = localStorage.getItem("userTypeId");
    }
    if (userTypeId) {
      userTypeId = parseInt(userTypeId);
    }
    this.isRooAdmin = this.checkisRooAdmin(userTypeId);
    this.isGroupAdmin = isGroupAdmin || localStorage.getItem("isGroupAdmin");
    this.isEnterpriseAdmin = isEnterpriseAdmin || localStorage.getItem("isEnterpriseAdmin");
    const isRooUniStudent = userTypeId === userTypes.STUDENT_USER_TYPE;
    this.isRegistrationComplete =
      isRegistrationComplete || localStorage.getItem("isRegistrationComplete");
    const isProfileComplete = String(this.isRegistrationComplete) === "1";
    this.isStudentFourthYear = isStudentFourthYear;

    this.isMultiHospitalAccess =
      isMultiHospitalAccess || localStorage.getItem("isMultiHospitalAccess");

    this.profileImage = profileImage || localStorage.getItem("profileImage");
    this.userName = userName;

    const route = this.setRoutePathsForLinks(userTypeId, isProfileComplete, isStudentFourthYear);
    const links = this.getLinks(userTypeId, route);

    const isTablet = isMobileDevice();
    // @ts-expect-error TS2345
    const isPendingUser = parseInt(localStorage.getItem("isActive")) === userStatusTypes.PENDING;

    const showChatNotifications =
      !hideToggler &&
      window.location.href.indexOf("enterprise") === -1 &&
      !this.isRooAdmin &&
      !isRooUniStudent &&
      !isPendingUser &&
      AuthUtility.isAuthorisedUser();

    // IMPORTANT: **** "site-header" needs page containing this HeaderLoggedIn
    // to have an "inner-container" wrapper for this header to appear as a fixed header

    return (
      <>
        {/* <header className={this.props.trainingModal === 0 ? "site-header" : "site-header ifTrainingModal" } > */}
        <header className={this.getHeaderClassName(isPendingUser)}>
          <div className="container" style={{ marginLeft: isTablet ? 0 : "" }}>
            <nav className="navbar navbar-expand-lg">
              {/* @ts-expect-error TS2339 */}
              <a className="nabar-brand text-style-none" href={route.userRootRoute}>
                <img src={rooLogo} />
              </a>
              {isEnterprise && enterpriseLogo ? (
                <img src={enterpriseLogo} width={40} className="ml-4" />
              ) : null}
              <div className="d-flex d-xl-none d-m-none">
                {showChatNotifications && (
                  <div className="mr-3">
                    <ChatNotifications />
                  </div>
                )}
                <button
                  className={`navbar-toggler${hideToggler ? " d-none" : ""}`}
                  type="button"
                  onClick={this.toggleMenuDrawer}
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
              </div>
              <div
                className={`menu-overlay${isMenuOpen ? "" : " d-none"}`}
                onClick={this.toggleMenuDrawer}
              ></div>
              <div className={`collapse navbar-collapse${isMenuOpen ? " show" : ""}`}>
                <span className="close-menu" onClick={this.toggleMenuDrawer}></span>
                <div className="ml-auto">{links}</div>
              </div>
            </nav>
          </div>
          {isPendingUser && enterpriseName && window.location.pathname === "/hospital" && (
            <PendingEnterpriseHospitalBanner enterpriseName={enterpriseName} />
          )}
        </header>
      </>
    );
  }
}

// @ts-expect-error TS7006
const mapStateToProps = (state) => {
  return {
    fullName: state.login.fullName,
    firstName: state.login.firstName,
    lastName: state.login.lastName,
    enterpriseName: state.login.enterpriseName,
    enterpriseLogo: state.login.enterpriseLogo,
    userRoleId: state.login.userRoleId,
    hospitalId: state.login.hospitalId,
    vetId: state.login.vetId,
    techId: state.login.techId,
    enterpriseId: state.login.enterpriseId,
    enterpriseEnabled: state.login.enterpriseEnabled,
    isInternalReliefEnabled: state.login.isInternalReliefEnabled,
    isRegistrationComplete: state.login.isRegistrationComplete,
    userTypeId: state.login.userTypeId,
    profileImage: state.login.profileImage,
    isHospitalAdmin: state.login.isHospitalAdmin,
    isGroupAdmin: state.login.isGroupAdmin,
    isEnterpriseAdmin: state.login.isEnterpriseAdmin,
    isMultiHospitalAccess: state.login.isMultiHospitalAccess,
    userName: state.login.userName,
    isInternshipsAvailable: state.login.isInternshipsAvailable,
    // techPaymentInfo: state.techDashboard.techPaymentInfo,
    // vetPaymentInfo: state.vetDashboard.vetPaymentInfo,
    trainingStatus: state.login.trainingStatus,
    isStudentFourthYear: state.registerStudent.isStudentFourthYear,
    myHospitalsList: state.login.myHospitalsList,
    addVetShiftClicked: state.login.addVetShiftClicked,
    addTechShiftClicked: state.login.addTechShiftClicked,
  };
};

// @ts-expect-error TS7006
const mapDispatchToProps = (dispatch) => {
  return {
    initializeState: () => dispatch(calendarActionCreators.initializeState()),
    initializeSearchResultState: () =>
      dispatch(vetDashboardActions.initializeVetDashboardStateRequestAction()),
    initializeTechSearchResultState: () =>
      dispatch(techDashboardActions.initializeTechDashboardStateRequestAction()),
    intializeVetSettings: () => dispatch(initializeVetSettingsAction()),
    intializeTechSettings: () => dispatch(initializeTechSettingsAction()),
    initializeHospitalSettings: () => dispatch(initializeHospitalSettingsAction()),
    // @ts-expect-error TS7006
    setEnterpriseHospitalSession: (data) => dispatch(setEnterpriseHospitalSession(data)),
    unsetEnterpriseHospitalSession: () => dispatch(unsetEnterpriseHospitalSession()),
    clearRedirect: () => dispatch(clearRedirect()),
    getTechPaymentInfo: () => dispatch(techDashboardActions.getTechPaymentInfo()),
    getVetPaymentInfo: () => dispatch(vetDashboardActions.getVetPaymentInfo()),
    updateRooUniCalloutStatus: () => dispatch(updateRooUniCalloutStatus()),
    setLoader: () => dispatch(setLoaderAction()),
    // @ts-expect-error TS7006
    updateAddShiftClick: (data) => dispatch(updateAddShiftClick(data)),
  };
};

// eslint-disable-next-line no-restricted-exports
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderLoggedIn));
