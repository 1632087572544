import { VET_ROUTES, TECH_ROUTES, COMMON_ROUTES } from "@RooBeta/constants";
import { MediumText } from "@RooUI";
import React from "react";
import { ProviderType } from "@RooBeta/constants/provider";

const getRoutes = (providerType: ProviderType) =>
  providerType === "VET" ? VET_ROUTES : TECH_ROUTES;

export const NAVIGATION_ITEMS = {
  DESIGN: {
    OLD: {
      icon: "rocket",
      label: <MediumText>Switch to Old Design</MediumText>,
    },
    RATE: {
      icon: "star",
      label: <MediumText>Rate New Design</MediumText>,
    },
  },
  MAIN: {
    SCHEDULE: {
      icon: "calendar_month",
      label: <MediumText>Schedule</MediumText>,
      path: (providerType: ProviderType) => getRoutes(providerType).SCHEDULE,
    },
    SHIFTS: {
      icon: "search",
      label: <MediumText>Shifts</MediumText>,
      path: (providerType: ProviderType) => getRoutes(providerType).SHIFTS,
    },
    EARNINGS: {
      icon: "payment",
      label: <MediumText>Earnings</MediumText>,
      path: (providerType: ProviderType) => getRoutes(providerType).EARNINGS,
    },
  },
  ACCOUNT: {
    PROFILE: {
      icon: "account",
      label: <MediumText>Profile</MediumText>,
      path: (providerType: ProviderType) => getRoutes(providerType).PROFILE,
    },
    PROMOS: {
      icon: "promo",
      label: <MediumText>Promos</MediumText>,
      path: (providerType: ProviderType) => getRoutes(providerType).PROMOS,
    },
    REFER: {
      icon: "referral",
      label: <MediumText>Refer a Veterinarian</MediumText>,
      path: (providerType: ProviderType) => getRoutes(providerType).REFER,
    },
    HOSPITAL_REFER: {
      icon: "business",
      label: <MediumText>Introduce Hospitals</MediumText>,
      path: (providerType: ProviderType) => getRoutes(providerType).REFER_HOSPITAL,
    },
  },
  RESOURCES: {
    POLICIES: {
      icon: "document",
      label: <MediumText>Policies</MediumText>,
      path: (providerType: ProviderType) => getRoutes(providerType).POLICIES,
    },
    TOOLKIT: {
      icon: "info",
      label: <MediumText>Relief Resource Toolkit</MediumText>,
      path: COMMON_ROUTES.TOOLKIT,
    },
  },
  SETTINGS: {
    icon: "settings",
    label: <MediumText>Settings</MediumText>,
    path: COMMON_ROUTES.SETTINGS,
  },
  ADMIN: {
    BACK: {
      icon: "arrow_back",
      label: <MediumText>Back to admin</MediumText>,
    },
  },
  LOGOUT: {
    icon: "exit",
    label: <MediumText>Log out</MediumText>,
  },
} as const;
