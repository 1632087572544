import { purpleBase, purpleLight400 } from "@RooUI";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { ProviderRestricted, RooIcon } from "@RooBeta/components";
import { useViewport } from "@RooBeta/store";
import {
  Event,
  EventTracker,
  Provider,
  SearchShifts,
  ShiftWithIndex,
  ShiftTags,
} from "@RooBeta/types";
import { getShiftDetailsPath } from "@RooBeta/utils";

import { ShiftCardPointOfEntry } from "@Roo/constants/posthogEventContextTypes";

import { ShareShiftButton } from "./ShareShiftButton";
import {
  Card,
  CardChevron,
  CardDateTime,
  CardDetails,
  CardDetailsWrapper,
  CardLogo,
  CardLogoIcon,
  CardMetadata,
  CardPricing,
  CrossedOutPrice,
  DateText,
  DefaultHospitalIcon,
  HospitalImage,
  HospitalName,
  Price,
  ShareShiftButtonContainer,
  ShiftMetadata,
  StatusTag,
  Tag,
  Tags,
  TimeText,
} from "./styles";
import { ShiftCardVariant, useShiftMetadata } from "./useShiftMetadata";
import { useImpressionTracking } from "@RooBeta/hooks/tracking/useImpressionTracking";
import moment from "moment";

interface ShiftCardProps {
  shift: ShiftWithIndex;
  count: number;
  variant: ShiftCardVariant;
  pointOfEntry: ShiftCardPointOfEntry;
  searchParameters?: SearchShifts;
  provider: Provider;
}

export const ShiftCard = ({
  shift: { index: position, ...shift },
  count,
  variant = "list",
  pointOfEntry,
  searchParameters,
  provider,
}: ShiftCardProps) => {
  const viewport = useViewport();

  const {
    shiftId,
    shiftGroupId,
    hospitalName,
    hospitalImage,
    shiftDateFormatted,
    startTime,
    endTime,
    priceFormatted,
    previousPriceFormatted,
    shiftStatus,
    bookmarkId,
  } = shift;

  const { providerId, providerType } = provider;
  const isVet = providerType === "VET";

  // Most display logic is handled in the useShiftMetadata hook.
  // Ideally this can be server-driven in the future.
  const { listItems, tags, elements } = useShiftMetadata({
    shift,
    viewport,
    variant,
    pointOfEntry,
  });

  const isFirstToRequest = tags.some((tag) => tag.label === ShiftTags.FirstToRequest); //Check if shift card has "Be the first to request" as a tag for event tracking

  const ref = useImpressionTracking({
    event: {
      eventName: isVet ? Event.Name.VET_SHIFT_CARD_VIEW : Event.Name.TECH_SHIFT_CARD_VIEW,
      eventType: Event.Type.IMPRESSION,
      entityType: isVet ? Event.Entity.VET_SHIFT : Event.Entity.TECH_SHIFT,
      entityId: shiftId,
      context: {
        [isVet ? "vet_id" : "tech_id"]: providerId,
        position_index: position,
        search_params: searchParameters ?? null,
        point_of_entry: pointOfEntry,
        isFirstRequest: isFirstToRequest,
      },
    },
    onEndOfResults: () => {
      if (position === count - 1) {
        EventTracker.send({
          eventName: Event.Name.VET_VIEW_SEARCH_END_OF_RESULT,
          eventType: Event.Type.IMPRESSION,
          entityType: Event.Entity.VET_SHIFT,
          entityId: shiftId,
        });
      }
    },
  });

  const history = useHistory();

  const onClick = useCallback(() => {
    EventTracker.send({
      eventName: isVet ? Event.Name.VET_SHIFT_CARD_CLICK : Event.Name.TECH_SHIFT_CARD_CLICK,
      eventType: Event.Type.CLICK,
      entityType: isVet ? Event.Entity.VET_SHIFT : Event.Entity.TECH_SHIFT,
      entityId: shift.shiftId,
      context: {
        [isVet ? "vet_id" : "tech_id"]: providerId,
        position_index: position,
        search_params: searchParameters ?? null,
        point_of_entry: pointOfEntry,
        isFirstRequest: isFirstToRequest,
      },
    });

    EventTracker.send({
      eventName: Event.Name.VET_HOSPITAL_PROFILE_LINK,
      eventType: Event.Type.CLICK,
      entityType: Event.Entity.VET_SHIFT,
      entityId: shift.shiftId,
      context: { location: "shift_card", shiftStatus: shift.shiftStatus, isNewExperience: true },
    });

    history.push(getShiftDetailsPath(shift.shiftGroupId));
  }, [
    isFirstToRequest,
    pointOfEntry,
    isVet,
    providerId,
    shift,
    history,
    position,
    searchParameters,
  ]);

  return (
    <Card $variant={variant} ref={ref} onClick={onClick} data-testid={`shiftCard${shiftGroupId}`}>
      {elements.has("date_column") && (
        <CardDateTime>
          {elements.has("date") && (
            <DateText>{moment(shiftDateFormatted).format("ddd, MMM D")}</DateText>
          )}
          <TimeText>{startTime} -</TimeText>
          <TimeText>{endTime}</TimeText>
          {elements.has("flexible_time") && <DateText>Flexible</DateText>}
        </CardDateTime>
      )}
      <CardDetails>
        <CardDetailsWrapper>
          {elements.has("hospital_logo") && (
            <CardLogo>
              {elements.has("favorite") && (
                <CardLogoIcon>
                  <RooIcon icon="favorite_filled" size="s" color={purpleBase} />
                </CardLogoIcon>
              )}
              {hospitalImage ? (
                <HospitalImage src={hospitalImage} alt={hospitalName} />
              ) : (
                <DefaultHospitalIcon>
                  <RooIcon icon="business" size="l" />
                </DefaultHospitalIcon>
              )}
            </CardLogo>
          )}
          <CardMetadata>
            {elements.has("hospital_name") && <HospitalName>{hospitalName}</HospitalName>}
            {listItems.map(({ icon, label }) => {
              const labelArray = label.split(" ");
              const prefix = labelArray[0].toLowerCase();
              return (
                <ShiftMetadata
                  key={label}
                  data-testid={`${prefix}${labelArray.slice(1).join("")}ShiftMetadata`}
                >
                  <RooIcon icon={icon} /> {label}
                </ShiftMetadata>
              );
            })}
            {tags.length > 0 && (
              <Tags data-testid="shiftCardTags">
                {tags.map(({ icon, label }) => {
                  const labelArray = label.split(" ");
                  const prefix = labelArray[0].toLowerCase();
                  return (
                    <Tag key={label} data-testid={`${prefix}${labelArray.slice(1).join("")}Tag`}>
                      <RooIcon icon={icon} color={purpleLight400} />
                      {label}
                    </Tag>
                  );
                })}
              </Tags>
            )}
          </CardMetadata>
        </CardDetailsWrapper>
      </CardDetails>
      {previousPriceFormatted || priceFormatted ? (
        <CardPricing>
          {previousPriceFormatted && <CrossedOutPrice>{previousPriceFormatted}</CrossedOutPrice>}
          <Price>{priceFormatted}</Price>
          {elements.has("status") && (
            <StatusTag $status={shiftStatus} data-testid="statusTag">
              {shiftStatus.toLowerCase()}
            </StatusTag>
          )}
          {elements.has("currently_viewing") && (
            <StatusTag $status="VIEWING">Currently Viewing</StatusTag>
          )}
        </CardPricing>
      ) : null}

      {elements.has("chevron") && (
        <CardChevron>
          <RooIcon icon="chevron_right" size="l" />
        </CardChevron>
      )}
      <ShareShiftButtonContainer $bookmarked={!!bookmarkId} data-testid="sharedShiftBtn">
        <ProviderRestricted providerType="VET">
          <ShareShiftButton shiftGroupId={shiftGroupId} bookmarkId={bookmarkId} />
        </ProviderRestricted>
      </ShareShiftButtonContainer>
    </Card>
  );
};
