export const GET_CONVO_INFO = "GET_CONVO_INFO";
export const GET_CONVO_INFO_SUCCESS = "GET_CONVO_INFO_SUCCESS";
export const GET_CONVO_INFO_FAIL = "GET_CONVO_INFO_FAIL";

export const GET_CHAT_HISTORY = "GET_CHAT_HISTORY";
export const GET_CHAT_HISTORY_SUCCESS = "GET_CHAT_HISTORY_SUCCESS";
export const GET_CHAT_HISTORY_FAIL = "GET_CHAT_HISTORY_FAIL";

export const CREATE_CONVERSATION = "CREATE_CONVERSATION";
export const CREATE_CONVERSATION_SUCCESS = "CREATE_CONVERSATION_SUCCESS";
export const CREATE_CONVERSATION_FAIL = "CREATE_CONVERSATION_FAIL";

export const CONVERSATION_NOT_FOUND_ERROR = "NO_CONVERSATION_FOUND";
export const CHAT_RESET_STATE = "CHAT_RESET_STATE";
export const START_CHAT_WINDOW_LOADING = "START_CHAT_WINDOW_LOADING";
export const STOP_CHAT_WINDOW_LOADING = "STOP_CHAT_WINDOW_LOADING";
export const CREATE_CONVERSATION_LOADING = "CREATE_CONVERSATION_LOADING";
export const STOP_CREATE_CONVERSATION_LOADING = "STOP_CREATE_CONVERSATION_LOADING";
export const TOGGLE_CHAT = "TOGGLE_CHAT";
export const OPEN_CHAT_PANEL = "OPEN_CHAT_PANEL";
export const CLOSE_CHAT_PANEL = "CLOSE_CHAT_PANEL";
export const OPEN_CHAT_NOTIFICATIONS = "OPEN_CHAT_NOTIFICATIONS";
export const CLOSE_CHAT_NOTIFICATIONS = "CLOSE_CHAT_NOTIFICATIONS";
