import { isRooFeatureEnabled } from "../Common/Wrappers/isRooFeatureEnabled";
import {
  MIN_HOSPITAL_CUSTOM_PRICE_BOOST_AMOUNT,
  MIN_SHIFT_BOOST_AMOUNT,
} from "../constants/constants";
import { FEATURE_FLAGS } from "../constants/postHogFeatureFlags";

export const getMinimumBoostAmount = (
  isVetShift: boolean,
  setYourPriceEnrolledOn: boolean
): number => {
  let minBoostAmount = 0;

  if (isVetShift) {
    minBoostAmount =
      setYourPriceEnrolledOn || isRooFeatureEnabled(FEATURE_FLAGS.HOSPITAL_CUSTOM_PRICE)
        ? MIN_HOSPITAL_CUSTOM_PRICE_BOOST_AMOUNT
        : MIN_SHIFT_BOOST_AMOUNT;
  } else {
    minBoostAmount = MIN_SHIFT_BOOST_AMOUNT;
  }

  return minBoostAmount;
};
