import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "@RooUI";
import { Z_INDEX } from "@Roo/constants/zIndex";
import { hideToast } from "../action/ToastActions";

export const ToastContainerRedux = () => {
  const dispatch = useDispatch();
  const toastData = useSelector((state: { toast: any }) => state.toast.data);
  return (
    <>
      {toastData && (
        <Toast
          {...toastData}
          style={{ zIndex: Z_INDEX.TOAST, ...(toastData.style ?? {}) }}
          onHide={() => dispatch(hideToast())}
        />
      )}
    </>
  );
};
