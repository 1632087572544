import React, { HTMLAttributes, CSSProperties } from "react";
import styled from "styled-components";

import { LoaderIcon } from "./LoaderIcon";
import { spacingXL } from "@RooUI";

export type LoaderVariant = "fullscreen" | "inline";

interface LoaderProps extends HTMLAttributes<HTMLDivElement> {
  $variant?: LoaderVariant;
  style?: CSSProperties;
}

export const Loader = ({ $variant = "fullscreen", style, ...props }: LoaderProps) => (
  <LoaderContainer $variant={$variant} style={style} {...props} data-testid="spinnerLoader">
    <LoaderIcon />
  </LoaderContainer>
);

const LoaderContainer = styled.div<{ $variant: "fullscreen" | "inline" }>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ $variant }) =>
    $variant === "fullscreen"
      ? `
    height: 100dvh;

    @supports not (height: 100dvh) {
      height: 100vh;
    }
  `
      : `
    padding: ${spacingXL};
    min-height: 40px;
  `}
`;
