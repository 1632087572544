import {
  Button,
  FlexLayout,
  MediumText,
  Modal,
  SmallText,
  Text,
  grayWhite,
  spacing,
  tealDark600,
} from "@RooUI";
import React from "react";

import { usePromoProposalManagement } from "./usePromoProposalManagement";
import styled from "styled-components";
import { RooIcon } from "../RooIcon";

import seekUnderstanding from "../../../static/CoreValueIllustrations/seekUnderstanding.svg";
import { VStack } from "../Flex";
import { useImpressionTracking } from "@RooBeta/hooks/tracking/useImpressionTracking";
import { Event } from "@RooBeta/types";

export const ClaimPromoProposalCard = () => {
  const {
    showClaimPromoProposalModal,
    showClaimPromoProposalConfirmationModal,
    showLoading,
    claimPromoProposalMutation,
    handleOpenModal,
    handleDismiss,
    dismissModals,
    activePromoProposalForDashboard,
  } = usePromoProposalManagement();

  const ref = useImpressionTracking({
    event: {
      eventName: Event.Name.PROMO_PROPOSAL_CARD_VIEW,
      eventType: Event.Type.IMPRESSION,
      entityType: Event.Entity.PROMO_PROPOSAL,
      entityId: activePromoProposalForDashboard.promoProposalId,
    },
  });

  if (!activePromoProposalForDashboard.promoProposalId) return null;

  return (
    <>
      <Modal
        showModal={showClaimPromoProposalModal}
        onCloseModal={() => dismissModals()}
        $size="s"
        header="Your special offer is on the way!"
        body={
          <VStack $gap="xxl" $alignItems="center">
            <Text>
              Your Roo Account Manager will reach out to you within the next week to create your
              promo.
            </Text>
            <Button
              $buttonType="primary"
              title="Got It"
              $fullWidth
              onClick={() => dismissModals()}
            />
          </VStack>
        }
      />
      <Modal
        showModal={showClaimPromoProposalConfirmationModal}
        onCloseModal={() => dismissModals()}
        $size="s"
        header="Your limited-time special offer"
        body={
          <VStack $gap="xxl" $alignItems="center">
            <Text>
              Based on your high shift volume, you are qualified for an exclusive promo tailor-made
              for you! Request one now, and your Roo Account Manager will reach out to negotiate the
              details.
            </Text>
            <Button
              $fullWidth
              $buttonType="primary"
              title="Request Offer"
              onClick={() => {
                claimPromoProposalMutation(activePromoProposalForDashboard.promoProposalId);
              }}
              loading={showLoading}
            />
          </VStack>
        }
      />
      <ClaimPromoProposalCardContainer
        ref={ref}
        data-testid={`promoProposal${activePromoProposalForDashboard.promoProposalId}`}
      >
        <PromoProposalContent>
          <MediumText color={grayWhite}>{activePromoProposalForDashboard.bannerTitle}</MediumText>
          <SmallText color={grayWhite}>
            {activePromoProposalForDashboard.bannerDescription}
          </SmallText>
          <Image src={seekUnderstanding} alt="Seek Understanding" height={94} width={73} />
          <FlexLayout style={{ maxWidth: "160px" }}>
            <Button
              data-testid={`reviewPromoProposal${activePromoProposalForDashboard.promoProposalId}`}
              onClick={() => handleOpenModal(activePromoProposalForDashboard.promoProposalId)}
              title="Learn More"
            />
          </FlexLayout>
        </PromoProposalContent>
        <RooIcon
          icon="clear"
          size="l"
          color={grayWhite}
          onClick={() => handleDismiss(activePromoProposalForDashboard.promoProposalId)}
          data-testid="dismissPromoProposalButton"
        />
      </ClaimPromoProposalCardContainer>
    </>
  );
};

export const ClaimPromoProposalCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing.m};
  background-color: ${tealDark600};
  padding: ${spacing.l};
  position: relative;
  overflow: hidden;
`;

export const PromoProposalContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: ${spacing.s};
`;

export const Image = styled.img`
  position: absolute;
  bottom: -20px;
  right: ${spacing.l};
`;
