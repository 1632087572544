import dayjs, { Dayjs } from "dayjs";
import _, { filter, find, forEach, map, round } from "lodash";
import moment from "moment";
import validator from "validator";

import { License } from "../api/vet";
import { getISODateNDaysFromNow } from "../Common/dateUtils";
import { validateZipcode } from "../Common/validateZipcode";
import {
  anyOptionValue,
  centerUsLatLng,
  countryZoom,
  metroZoom,
  onlyNoneOptionValue,
  stateZoom,
} from "../constants/adminForms";
import { SPECIAL_SURGERY_IDS } from "../constants/checkBoxConstants";
import { BATCH_UPLOAD_FILE_HEADERS } from "../constants/constants";
import {
  aAverageNoOfDvmOptions,
  aDressCodeOptions,
  aMaintainRecordsOptions,
  aTypeOfHospitalOptions,
} from "../constants/dropDownConstants";
import { breakpoints } from "../constants/responsiveBreakpoints";

import { formatPhoneNumber } from "./textUtility";

type MetroArea = {
  centerLat: string;
  centerLng: string;
  id: number;
  name: string;
  stateId: number;
};

type StateOptions = {
  calendly_link_hospital: string;
  calendly_link_tech: string;
  calendly_link_vet: string;
  center_latitude: number | string;
  center_longitude: number | string;
  id: number;
  roo_tech_state: any;
  roo_tech_state_for_hospitals: any;
  roo_tech_state_for_techs: any;
  roo_vet_state: any;
  state_code: string;
  state_name: string;
  tech_registration_flow: any;
};

type formattedStateList = {
  value: number;
  label: string;
  name: string;
  center: {
    lat: number | string;
    lng: number | string;
  };
  zoom: number;
  rooVetState: number;
  rooTechStateForTechs: number;
  rooTechStateForHospitals: number;
}[];
export function createDictionary<T, TKey extends keyof T>(
  arr: T[],
  sKey: TKey
): { [key: string | number]: T } {
  const o = {} as any;
  const l = arr.length;
  for (let i = 0; i < l; i++) {
    o[arr[i][sKey]] = { ...arr[i] };
  }
  return o;
}

export function convertNumberListToArray(sList: string) {
  if (sList) {
    return sList.split(",").map((s) => parseInt(s));
  } else {
    return [];
  }
}

// only meant to calculate within 200 miles so not accounting for earth being round.
export function getShortDistance(xA: number, yA: number, xB: number, yB: number) {
  const milesPerLatLngDegree = 69;
  const xDiff = xA - xB;
  const yDiff = yA - yB;

  return Math.sqrt(xDiff * xDiff + yDiff * yDiff) * milesPerLatLngDegree;
}

export const delay = (fn: Function, ms: number) => {
  setTimeout(fn, ms);
};

export const getHourlyRate = (startTime: string, endTime: string, amount: number) => {
  const todayDate = new Date().toISOString().slice(0, 10);
  const startShiftTime = moment(`${todayDate} ${startTime}`, "YYYY-MM-DD hh:mm A");
  const endShiftTime = moment(`${todayDate} ${endTime}`, "YYYY-MM-DD hh:mm A");
  const duration = moment.duration(endShiftTime.diff(startShiftTime));

  return duration?.asHours() === 0
    ? amount
    : (amount / (duration?.asHours() < 0 ? 24 + duration?.asHours() : duration?.asHours()))
        .toFixed(2)
        .replace(/\.0+$/, "");
};

export const getExpiresOnDate = (shiftDate: string, lastRenewShiftDate: string): string => {
  let expiresOnDate: string;
  const newShiftRenewDate = getISODateNDaysFromNow(25, lastRenewShiftDate);
  if (shiftDate >= newShiftRenewDate) {
    const days53BeforeShiftDate = getISODateNDaysFromNow(-53, shiftDate);
    if (days53BeforeShiftDate > newShiftRenewDate) {
      expiresOnDate = days53BeforeShiftDate;
    } else {
      expiresOnDate = newShiftRenewDate;
    }
  }
  // @ts-expect-error TS2454
  return expiresOnDate ? moment(expiresOnDate, "YYYY-MM-DD").format("MMM D") : "";
};

export const getMetroAreasOptions = (metroAreas: MetroArea[]) => {
  const formattedMetroAreaList: Array<any> = [];
  const metroAreasByStateId = {} as Record<number, Array<number>>;
  forEach(metroAreas, (metroArea: MetroArea) => {
    const { id, name, stateId, centerLat, centerLng } = metroArea;
    formattedMetroAreaList.push({
      value: id,
      label: name,
      stateId,
      name: "metroAreaSelection",
      center: { lat: centerLat, lng: centerLng },
      zoom: metroZoom,
    });
    if (metroAreasByStateId[stateId]) {
      metroAreasByStateId[stateId].push(id);
    } else {
      metroAreasByStateId[stateId] = [id];
    }
  });

  formattedMetroAreaList.sort(function (a, b) {
    const metroA = a.label.toUpperCase();
    const metroB = b.label.toUpperCase();
    return metroA < metroB ? -1 : metroA > metroB ? 1 : 0;
  });

  formattedMetroAreaList.push({
    value: onlyNoneOptionValue,
    label: "No Metro Area Assigned",
    name: "metroAreaSelection",
  });
  formattedMetroAreaList.push({
    value: anyOptionValue,
    label: "Any Metro Area",
    name: "metroAreaSelection",
  });

  return { formattedMetroAreaList, metroAreasByStateId };
};

export const getRooStatesOptions = (states: StateOptions[]) => {
  const formattedStateList: formattedStateList = [];
  forEach(states, (state: StateOptions) => {
    const {
      id,
      state_name,
      roo_vet_state,
      roo_tech_state_for_techs,
      roo_tech_state_for_hospitals,
      center_latitude,
      center_longitude,
    } = state;
    formattedStateList.push({
      value: id,
      label: state_name,
      name: "stateSelection",
      center: { lat: center_latitude, lng: center_longitude },
      zoom: stateZoom,
      rooVetState: roo_vet_state,
      rooTechStateForTechs: roo_tech_state_for_techs,
      rooTechStateForHospitals: roo_tech_state_for_hospitals,
    });
  });

  formattedStateList.push({
    value: onlyNoneOptionValue,
    label: "Non-Roo States",
    name: "stateSelection",
    center: centerUsLatLng,
    zoom: countryZoom,
    rooVetState: 1,
    rooTechStateForTechs: 1,
    rooTechStateForHospitals: 1,
  });
  formattedStateList.push({
    value: anyOptionValue,
    label: "Any State",
    name: "stateSelection",
    center: centerUsLatLng,
    zoom: countryZoom,
    rooVetState: 1,
    rooTechStateForTechs: 1,
    rooTechStateForHospitals: 1,
  });
  return formattedStateList;
};

export const hyphenateString = (str: string, isLowerCase: boolean = true) => {
  let hyphStr = str;
  if (isLowerCase) {
    hyphStr = hyphStr.toLowerCase();
  }
  return hyphStr.replace(/\s/g, "-");
};

export const sortList = <T, TKey extends keyof T>(list: T[], key: TKey) => {
  return list.sort((a: any, b: any) =>
    a[key]?.localeCompare(b[key], undefined, { sensitivity: "base" })
  );
};

export const getHospitalOverallRatingsToDisplay = (overallRating: string) => {
  let displayRating = Number(overallRating);
  let overallRatingPercent = 0;
  if (overallRating?.length) {
    if (overallRating.split(".")[1] == "00") displayRating = Math.round(Number(overallRating));
    overallRatingPercent = (Number(overallRating) / 5) * 100;
  }
  return { displayRating, overallRatingPercent };
};

export const getRatingProgressColor = (value: number) => {
  let color = "";
  if (value >= 90) color = "primary-color";
  else if (value >= 80) color = "yellowgreen";
  else if (value >= 70) color = "gold";
  else if (value >= 60) color = "orange";
  else color = "red";
  return color;
};

export const removeQueryStringFromUrl = () => {
  const url = window.location.href;
  const urlNoQueryString = url.split("?")[0];
  if (url !== urlNoQueryString) {
    window.location.replace(urlNoQueryString);
  }
};

export const isInvoicedAPIEnabled = () => {
  const isProdHostname = ["roo.vet"].includes(window.location.hostname);

  // enabled for dev and staging
  if (!isProdHostname) {
    return true;
  }

  // TODO: enable on prod when ready
  const isInvoicedEnabledOnProdHostname = true;
  if (isProdHostname && isInvoicedEnabledOnProdHostname) {
    return true;
  }

  return false;
};

export const checkIsSRPEnabled = () => {
  return true;
};

export const isLocalHost = () => {
  const isLocalhost = ["localhost"].includes(window.location.hostname);
  return isLocalhost;
};

export const checkIfBulkUploadEnabled = () => {
  return isLocalHost();
};

const validateBulkUploadHospitals = (data: any[]) => {
  const validation: any = {};

  if (data?.length >= 1) {
    map(data, (itm: any) => {
      const {
        hospitalName,
        address,
        city,
        stateCode,
        inputStateCode,
        zipcode,
        hospitalTypeLabel,
        inputHospitalType,
        primaryContactName,
        unformattedPrimaryContactMobileNumber,
        primaryContactMobileNumber,
        emailId,
        hospitalPhoneNumber,
      } = itm;
      const isInvalidZipcode =
        !validator.matches(zipcode, /^[0-9]{5}(?:-[0-9]{4})?$/) ||
        !zipcode ||
        validateZipcode(zipcode)
          ? true
          : false;
      const isEmailValid = validator.isEmail(emailId);
      const formattedPhoneNumber = formatPhoneNumber(primaryContactMobileNumber);
      const formattedHospitalPhoneNumber = formatPhoneNumber(hospitalPhoneNumber);
      const avgNoOfDVMPerDayOption = filter(
        aAverageNoOfDvmOptions,
        (ele) => ele.value === parseInt(itm.averageNoOfDVMWorkPerDay)
      );
      const maintainRecordsOption = filter(
        aMaintainRecordsOptions,
        (ele) => ele.value === itm.hospitalMaintainRecords
      );
      if (formattedPhoneNumber) itm.primaryContactMobileNumber = formattedPhoneNumber;
      if (formattedHospitalPhoneNumber) itm.hospitalPhoneNumber = formattedHospitalPhoneNumber;

      if (!hospitalName?.length) {
        itm.isMissingHospitalName = true;
        itm.isInvalidData = true;
      }
      if (!address?.length) {
        itm.isMissingAddress = true;
        itm.isInvalidData = true;
      }
      if (!city?.length) {
        itm.isMissingCity = true;
        itm.isInvalidData = true;
      }
      if (!inputStateCode?.length) {
        itm.isMissingState = true;
        itm.isInvalidData = true;
      }
      if (!stateCode?.length) {
        itm.isInvalidState = true;
        itm.isInvalidData = true;
      }
      if (!zipcode?.length) {
        itm.isMissingZipcode = true;
        itm.isInvalidData = true;
      }
      if (isInvalidZipcode) {
        itm.isInvalidZipcode = true;
        itm.isInvalidData = true;
      }
      if (!inputHospitalType?.length) {
        itm.isMissingHospitalType = true;
        itm.isInvalidData = true;
      }
      if (!hospitalTypeLabel || !hospitalTypeLabel?.length) {
        itm.isInvalidHospitalType = true;
        itm.isInvalidData = true;
      }
      if (emailId && !primaryContactName?.length) {
        itm.isMissingPrimaryName = true;
        itm.isInvalidData = true;
      }
      if (emailId && !isEmailValid) {
        itm.isInvalidPrimaryEmail = true;
        itm.isInvalidData = true;
      }
      if (unformattedPrimaryContactMobileNumber?.length && !primaryContactMobileNumber?.length) {
        itm.isInvalidPhone = true;
        itm.isInvalidData = true;
      }
      if (hospitalPhoneNumber?.length && !formattedHospitalPhoneNumber?.length) {
        itm.isInvalidHospitalPhone = true;
        itm.isInvalidData = true;
      }
      if (itm.averageNoOfDVMWorkPerDay && !avgNoOfDVMPerDayOption.length) {
        itm.isInvalidAvgNoOfDVMs = true;
        itm.isInvalidData = true;
      }
      if (itm.hospitalMaintainRecords && !maintainRecordsOption.length) {
        itm.isInvalidMaintainRecords = true;
        itm.isInvalidData = true;
      }
      return itm;
    });
  } else {
    validation.isDataMissing = true;
  }
  validation.isInvalidData = find(data, { isInvalidData: true }) ? true : false;

  return { ...validation, bulkUploadHospitalList: data };
};

function formatZipcode(zipcode: string) {
  if (zipcode?.length === 4) {
    return "0" + zipcode;
  }
  return zipcode;
}

export function trimObjectValues(obj: any): any {
  if (typeof obj === "string") {
    return obj.trim();
  } else if (Array.isArray(obj)) {
    return obj.map((x) => trimObjectValues(x));
  } else if (typeof obj === "object" && obj !== null) {
    const newObj: any = {};
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        newObj[key] = trimObjectValues(obj[key]);
      }
    }
    return newObj;
  } else {
    return obj;
  }
}

export const processedBatchUploadedFile = (data: any, additionalData: any) => {
  const { states } = additionalData;
  const result: any = { validation: {}, fileData: [] };
  if (data[0][0].indexOf("Table") > -1) {
    data.shift();
  }

  const headers = data[0];

  let usersData = data.slice(1);
  usersData = usersData.filter((a: any) => a.length > 1);

  const hospitalsData = usersData.map((userData: { [key: string]: string }) => {
    return Object.values(BATCH_UPLOAD_FILE_HEADERS).reduce((value, current) => {
      const index = headers.findIndex(
        (header: string) => header.toLowerCase() === current.toLowerCase()
      );
      value[current.toLowerCase()] = userData[index];
      return value;
    }, {} as { [key: string]: string });
  });

  const hospitalList: any[] = [];
  forEach(hospitalsData, (itm: any) => {
    itm = trimObjectValues(itm);

    if (!itm["hospital name"]?.toLowerCase()?.includes("example")) {
      const state = find(states, { state_code: itm["state"].trim() });
      const typeOfHospital = filter(aTypeOfHospitalOptions, (ele) => {
        return itm["hospital type"]?.toLowerCase() === ele.label.toLowerCase().replace(/,/g, "");
      });
      const zipcode = itm["zip"];
      const formattedPhoneNumber = formatPhoneNumber(itm["primary contact number"]);
      const formattedHospitalPhoneNumber = itm["hospital phone number"]?.length
        ? formatPhoneNumber(itm["hospital phone number"])
        : "";
      const avgNoOfDVMPerDay = filter(aAverageNoOfDvmOptions, (ele) => {
        return itm["# of associate vets"] === ele.label.toLowerCase();
      });
      if (
        !avgNoOfDVMPerDay.length &&
        itm["# of associate vets"] &&
        !isNaN(itm["# of associate vets"]) &&
        itm["# of associate vets"] >= 5
      ) {
        avgNoOfDVMPerDay.push(aAverageNoOfDvmOptions[4]);
      }
      const hospitalMaintainRecordOption = filter(aMaintainRecordsOptions, (ele) => {
        return itm["record maintenance"]?.toLowerCase() === ele.label.toLowerCase();
      });
      const dressCodeIds: Number[] = [];
      const dressCodeArr = itm["dress code"]?.split("+");
      if (dressCodeArr?.length) {
        dressCodeArr.forEach((dressCode: string) => {
          const dressCodeOption = filter(aDressCodeOptions, (ele) => {
            return dressCode.trim()?.toLowerCase() === ele.label.toLowerCase();
          });
          if (dressCodeOption?.length) {
            dressCodeIds.push(dressCodeOption[0].value);
          }
        });
      }
      hospitalList.push({
        hospitalName: itm["hospital name"],
        address: itm["street address"],
        city: itm["city"],
        stateId: state?.id,
        stateCode: state?.state_code,
        inputStateCode: itm["state"],
        zipcode: formatZipcode(zipcode),
        typeOfHospital: typeOfHospital[0]?.value,
        hospitalTypeLabel: typeOfHospital[0]?.label,
        inputHospitalType: itm["hospital type"],
        hospitalGroupName: itm["group"],
        primaryContactName: itm["primary contact name"],
        unformattedPrimaryContactMobileNumber: itm["primary contact number"],
        primaryContactMobileNumber: formattedPhoneNumber,
        emailId: itm["primary contact email"],
        hospitalPhoneNumber: formattedHospitalPhoneNumber,
        averageNoOfDVMWorkPerDay: avgNoOfDVMPerDay[0]?.value,
        hospitalMaintainRecords: hospitalMaintainRecordOption[0]?.value,
        practiceManagementSoftware: itm["pms used"],
        hospitalDressCodes: dressCodeIds?.length ? _.uniq(dressCodeIds) : [],
      });
    }
  });
  const { isInvalidData, isDataMissing, bulkUploadHospitalList } =
    validateBulkUploadHospitals(hospitalList);
  if (isInvalidData || isDataMissing) {
    result.validation = {
      isInvalidData,
      hasError: true,
    };
  }
  result.fileData = { hospitalList: bulkUploadHospitalList };

  return result;
};

export const simulateMouseClick = (element: HTMLElement) => {
  const mouseClickEvents = ["mousedown", "click", "mouseup"];
  mouseClickEvents.forEach((mouseEventType) =>
    element.dispatchEvent(
      new MouseEvent(mouseEventType, {
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1,
      })
    )
  );
};

export const getShiftHoursFromShiftTime = (startTime: any, endTime: any) => {
  let totalHours: number;
  if (startTime === endTime) {
    totalHours = 24;
  } else if (startTime < endTime) {
    totalHours = (endTime - startTime) / 2;
  } else {
    startTime = 48 - startTime;
    totalHours = (endTime + startTime) / 2;
  }
  return totalHours;
};

export const getPerHourExpectedAppointments = (
  expectedNoOfAppointments: string,
  totalHours: number
) => {
  let perHourExpectedAppts: number;
  switch (expectedNoOfAppointments) {
    case "1-5":
      perHourExpectedAppts = 2.5;
      break;
    case "5-10":
      perHourExpectedAppts = 7.5;
      break;
    case "10-15":
      perHourExpectedAppts = 12.5;
      break;
    case "15-20":
      perHourExpectedAppts = 17.5;
      break;
    case "20-25":
      perHourExpectedAppts = 22.5;
      break;
    case "25+":
      perHourExpectedAppts = 27.5;
      break;
  }
  // @ts-expect-error TS2454
  return round(perHourExpectedAppts / totalHours, 2);
};

export const waitForElm = (selector: string) => {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
};

export const swap = (input: any, indexA: any, indexB: any) => {
  const temp = input[indexA];

  input[indexA] = input[indexB];
  input[indexB] = temp;

  return input;
};

export const toNodeList = (arrayOfNodes: any) => {
  const fragment = document.createDocumentFragment();
  arrayOfNodes.forEach((item: any) => {
    fragment.appendChild(item.cloneNode());
  });
  return fragment;
};

export const uniqueObjectArrayByMultipleKeys = (array: any, keys: any) => {
  return array.filter((obj: any, pos: any, arr: any) => {
    return (
      arr
        .map((mapObj: any) => keys.map((key: any) => mapObj[key]).join(""))
        .indexOf(keys.map((key: any) => obj[key]).join("")) === pos
    );
  });
};

export const getTierSelectionDetailsFromSurgeries = (
  isSurgeryExpected: number,
  surgeryIds: number[],
  currentTier: number
) => {
  let disabledTiers: number[] = [];
  let selectedTier: number | undefined;

  if (isSurgeryExpected === 1) {
    if (surgeryIds?.length > 0) {
      const hasSpecialSurgery = surgeryIds.some((id) => SPECIAL_SURGERY_IDS.includes(Number(id)));

      if (hasSpecialSurgery) {
        disabledTiers = [1, 2];
        selectedTier = !currentTier || currentTier <= 2 ? 3 : currentTier;
      } else {
        disabledTiers = [1];
        selectedTier = !currentTier || currentTier <= 1 ? 2 : currentTier;
      }
    } else {
      disabledTiers = [1];
      selectedTier = currentTier && currentTier > 2 ? currentTier : 2;
    }
  }

  return { disabledTiers, selectedTier };
};

export const isWithinNext2BusinessDays = (
  dateToCheck: Dayjs | string,
  referenceDate: Dayjs | string
) => {
  let formattedReferenceDate = dayjs(referenceDate);
  const formattedInputDate = dayjs(dateToCheck);

  if (
    formattedReferenceDate &&
    formattedInputDate &&
    !formattedInputDate.isBefore(formattedReferenceDate, "day")
  ) {
    let count = 0;
    while (count < 2) {
      formattedReferenceDate = formattedReferenceDate.add(1, "day");
      if (formattedReferenceDate.day() != 0 && formattedReferenceDate.day() !== 6) {
        count += 1;
      }
    }
    return (
      formattedInputDate.isSame(formattedReferenceDate, "day") ||
      formattedInputDate.isBefore(formattedReferenceDate, "day")
    );
  } else return false;
};

export const checkContractorHasLicenseRequiredForState = (
  licenses: License[],
  hospitalStateId: number
) => {
  const contractorLicenseStateIds = licenses
    .map((license: any) => Number(license.id))
    .filter(Boolean);
  const hasLicenseRequiredForState = contractorLicenseStateIds.includes(hospitalStateId);
  return hasLicenseRequiredForState;
};

export const handleSuperUserLogin = (userId: string | number) => {
  if (!userId) return;
  // @ts-expect-error TS2531
  window.open(`${window.location.origin}/superuser?userId=${userId}`, "_self").focus();
};

export const isMobileDevice = () => window.innerWidth < breakpoints.tablet;
