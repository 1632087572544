import { Button, FormFields, FormLabel, Modal, Radio } from "@RooUI";
import React from "react";
import styled from "styled-components";
import { HiringPreference } from "../../constants/HiringPreference";
import { useFormContext as useVetContext } from "../../vet/profile/components/vetEditProfileTabbed/vetEditProfileContext";
import { useFormContext as useTechContext } from "../../tech/profile/components/techEditProfileTabbed/techEditProfileContext";

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 12px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 24px;
`;

const StyledButton = styled(Button)<{ $outline?: boolean }>`
  width: 250px;
  ${(props) =>
    props.$outline &&
    `
    background: white;
    border: 2px solid #57357F;
    color: #57357F;
    &:hover {
      background: #F5F0FA;
    }
  `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #57357f;
  font-size: 18px;
  font-weight: 500;
`;

interface UpdateHiringModalProps {
  showModal: boolean;
  onClose: () => void;
  isVet: boolean;
}

export const UpdateHiringModal: React.FC<UpdateHiringModalProps> = ({
  showModal,
  onClose,
  isVet,
}) => {
  const vetContext = useVetContext();
  const techContext = useTechContext();

  // Get the form state based on context
  const wantsFullTime = isVet
    ? vetContext.form?.wantsFullTime ?? null
    : techContext.form?.wantsFullTime ?? null;

  const handleOptionClick = (value: string) => {
    const preference = parseInt(value) as HiringPreference;
    if (isVet && vetContext.onChange) {
      vetContext.onChange("wantsFullTime", preference);
    } else if (!isVet && techContext.onChange) {
      techContext.onChange("wantsFullTime", preference);
    }
  };

  const handleSave = async () => {
    if (isVet && vetContext.onSave) {
      await vetContext.onSave();
    } else if (!isVet && techContext.onSave) {
      await techContext.onSave();
    }
    onClose();
  };

  return (
    <Modal
      showModal={showModal}
      onCloseModal={onClose}
      $size="m"
      body={
        <FormFields>
          <HeaderContainer>
            <span role="img" aria-label="update">
              🔁
            </span>
            Update your hiring opportunity preference
          </HeaderContainer>
          <div>
            <FormLabel>Are you looking for hiring opportunities?</FormLabel>
            <RadioGroup>
              <Radio
                name="hiringPreference"
                label="Yes, I'm actively looking"
                value={String(HiringPreference.ACTIVELY_LOOKING)}
                checked={wantsFullTime === HiringPreference.ACTIVELY_LOOKING}
                onChange={() => handleOptionClick(String(HiringPreference.ACTIVELY_LOOKING))}
                data-testid="modalActively"
              />
              <Radio
                name="hiringPreference"
                label="I'm open to it"
                value={String(HiringPreference.OPEN_TO_IT)}
                checked={wantsFullTime === HiringPreference.OPEN_TO_IT}
                onChange={() => handleOptionClick(String(HiringPreference.OPEN_TO_IT))}
                data-testid="modalOpen"
              />
              <Radio
                name="hiringPreference"
                label="Not right now"
                value={String(HiringPreference.NOT_LOOKING)}
                checked={wantsFullTime === HiringPreference.NOT_LOOKING}
                onChange={() => handleOptionClick(String(HiringPreference.NOT_LOOKING))}
                data-testid="modalNot"
              />
            </RadioGroup>
            <ButtonContainer>
              <StyledButton onClick={handleSave} data-testid="savePreference">
                Save
              </StyledButton>
              <StyledButton $outline onClick={onClose} data-testid="remindLater">
                Remind Me Later
              </StyledButton>
            </ButtonContainer>
          </div>
          <div style={{ color: "grayLight400", fontSize: "14px" }}>
            This answer will NOT be made publicly available and is only intended for Roo's internal
            purposes.
          </div>
        </FormFields>
      }
    />
  );
};
