import { useMutation } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";
import { v4 as uuidv4 } from "uuid";
import { get } from "@RooBeta/api";
import { getOrCreateConversation } from "@Roo/constants/apiRoutes";
import { useRegisterParticipant } from "./useRegisterParticipant";
import { Messaging } from "@roo-dev/roo-node-types";

export interface ParticipantInfo {
  id: number;
  userId: number;
  name: string;
  email: string;
  userTypeId: number;
  phoneNumber?: string;
}

export interface MessageInfo {
  id: number;
  text: string;
  sentOn: string;
  read: boolean;
  readOn?: string;
  participant: ParticipantInfo;
  channelTypeId?: number;
  textSid?: string;
  slackNotificationSent?: string;
  reminderNotificationSentOn?: string;
}

export interface ConversationResponse {
  id: number;
  shiftId?: string;
  expiresOn?: string;
  participants: ParticipantInfo[];
  messages: MessageInfo[];
  hospitalId: number;
}

export interface BulkMessageRequest {
  hospitalIds: number[];
  messageContent: string;
  channelTypeId: number;
  vetUserId: number;
  hospitalUserId?: number;
  email: string;
  fullName: string;
  userTypeId: number;
  messageType: Messaging.MessageType;
}

export interface MessageStatus {
  total: number;
  sent: number;
  failed: number;
  failedHospitalIds: number[];
  successHospitalIds: number[];
}

const sendSocketMessage = (
  conversationId: string | number,
  payload: {
    messageContent: string;
    fullName: string;
    participantId: string | number;
    hospitalId: number;
    vetUserId: number;
    channelTypeId: number;
    participantUserTypeId: number;
    messageType: Messaging.MessageType;
  }
) => {
  if (!window.socket) {
    throw new Error("Socket not available");
  }

  const socketPayload = {
    ...payload,
    markerId: uuidv4(),
  };

  window.socket.emit("to-server-message", socketPayload, conversationId);
};

export const useSendMessage = (options?: {
  onSuccess?: (status: MessageStatus) => void;
  onError?: (error: Error) => void;
}) => {
  const registerParticipant = useRegisterParticipant();

  return useMutation<void, Error, BulkMessageRequest>(
    async (request) => {
      try {
        const participantPayload = {
          fullName: request.fullName,
          email: request.email,
          userId: request.hospitalUserId || request.vetUserId,
          userTypeId: request.userTypeId,
        };
        const participant = await registerParticipant.mutateAsync(participantPayload);

        if (!participant || participant.isFailed) {
          throw new Error("Failed to register participant");
        }

        const status: MessageStatus = {
          total: request.hospitalIds.length,
          sent: 0,
          failed: 0,
          successHospitalIds: [],
          failedHospitalIds: [],
        };

        // Get conversations for all hospital IDs
        const conversationPromises = request.hospitalIds.map(async (hospitalId) => {
          try {
            const result = await get<ConversationResponse>(
              getOrCreateConversation(hospitalId, request.vetUserId)
            );

            if (!result.id) {
              throw new Error(`Failed to get conversation for hospital ${hospitalId}`);
            }

            return result;
          } catch (error) {
            status.failed++;
            status.failedHospitalIds.push(hospitalId);
            Sentry.captureException(error, {
              extra: {
                context: "Failed to get conversation",
                hospitalId,
                vetUserId: request.vetUserId,
              },
            });
            return null;
          }
        });

        const conversations = (await Promise.all(conversationPromises)).filter(
          (result): result is ConversationResponse => result !== null
        );

        // Send message to each conversation via socket
        for (const conversation of conversations) {
          try {
            sendSocketMessage(conversation.id, {
              messageContent: request.messageContent,
              fullName: request.fullName,
              participantId: participant.id,
              hospitalId: conversation.hospitalId,
              vetUserId: request.vetUserId,
              channelTypeId: request.channelTypeId,
              participantUserTypeId: request.userTypeId,
              messageType: request.messageType,
            });

            status.sent++;
            status.successHospitalIds.push(conversation.hospitalId);
          } catch (error) {
            status.failed++;
            status.failedHospitalIds.push(conversation.hospitalId);
            Sentry.captureException(error, {
              extra: {
                context: "Failed to send socket message",
                hospitalId: conversation.hospitalId,
                vetUserId: request.vetUserId,
              },
            });
          }
        }

        options?.onSuccess?.(status);
      } catch (error) {
        Sentry.captureException(error, {
          extra: {
            context: "useSendMessage error",
            request,
          },
        });
        throw error;
      }
    },
    {
      onError: (error) => {
        options?.onError?.(error);
      },
    }
  );
};
