import React, { ComponentProps, useEffect } from "react";
import { Select, SelectProps, Input } from "@RooUI";
import RooSelect from "../RooSelect";
import * as Sentry from "@sentry/react";

import { useSchools } from "./hooks/useSchools";
import { SchoolOption } from "@Roo/api/utility/schoolTypes";

type InputPropsType = ComponentProps<typeof Input>;

interface SchoolSelectProps extends Omit<SelectProps<SchoolOption>, "options"> {
  oldSelect?: boolean;
  otherSchoolProps?: Partial<InputPropsType>;
}

export const SchoolSelect = ({
  oldSelect = false,
  otherSchoolProps = {},
  onChange,
  ...props
}: SchoolSelectProps) => {
  const { schoolOptions, loading, isSuccess } = useSchools();
  const showOtherInput = props.value === 1;

  // Wait for both schools to load and school id from student profile to be ready
  const isReady = isSuccess && schoolOptions.length > 0;
  const selectedOption =
    isReady && props.value ? schoolOptions.find((opt) => opt.value === props.value) : undefined;

  useEffect(() => {
    if (isReady && props.value && !selectedOption) {
      Sentry.captureException(new Error(`School with ID ${props.value} not found in options`));
    }
  }, [isReady, props.value, selectedOption]);

  const handleSchoolChange = (option: SchoolOption) => {
    if (option) {
      onChange?.(option, option.value);
    }
  };

  const selectProps = {
    ...props,
    options: schoolOptions,
    disabled: props.disabled || loading || !isReady,
    placeholder: loading ? "Loading schools..." : props.placeholder,
    onChange: handleSchoolChange,
    value: isReady && props.value ? props.value : undefined,
    persistingSearchOptions: [schoolOptions[0]], //Other - Please Specify option will always be shown
  };

  return oldSelect ? (
    <RooSelect {...selectProps} />
  ) : (
    <>
      <Select label="Vet school" searchable {...selectProps} />
      {showOtherInput && (
        <Input
          label="Please specify"
          name="otherStudentSchool"
          data-testid="otherStudentSchool"
          {...otherSchoolProps}
        />
      )}
    </>
  );
};
