import React from "react";

export const MdDiamond = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path d="M11.9998 19.8996C11.7678 19.8996 11.544 19.8531 11.3285 19.7603C11.1132 19.6673 10.9248 19.5234 10.7633 19.3286L3.26704 10.3323C3.14271 10.1875 3.04946 10.0291 2.98729 9.85731C2.92513 9.68548 2.89404 9.50215 2.89404 9.30731C2.89404 9.18298 2.90971 9.0609 2.94104 8.94106C2.97254 8.82106 3.01713 8.70081 3.07479 8.58031L4.85354 4.99006C4.99854 4.72073 5.19954 4.50531 5.45654 4.34381C5.71354 4.18231 5.99529 4.10156 6.30179 4.10156H17.6978C18.0043 4.10156 18.286 4.18231 18.543 4.34381C18.8 4.50531 19.001 4.72073 19.146 4.99006L20.9248 8.58031C20.9825 8.70081 21.027 8.82106 21.0585 8.94106C21.0899 9.0609 21.1055 9.18298 21.1055 9.30731C21.1055 9.50215 21.0745 9.68548 21.0123 9.85731C20.9501 10.0291 20.8569 10.1875 20.7325 10.3323L13.2363 19.3286C13.0748 19.5234 12.8864 19.6673 12.671 19.7603C12.4555 19.8531 12.2318 19.8996 11.9998 19.8996ZM8.81704 9.10156H15.1825L13.1825 5.10156H10.817L8.81704 9.10156ZM11.4998 18.6611V10.1016H4.39204L11.4998 18.6611ZM12.4998 18.6611L19.6075 10.1016H12.4998V18.6611ZM16.292 9.10156H20.0575L18.2305 5.44781C18.1792 5.34515 18.1023 5.26181 17.9998 5.19781C17.8973 5.13365 17.7883 5.10156 17.6728 5.10156H14.292L16.292 9.10156ZM3.94204 9.10156H7.70754L9.70754 5.10156H6.32679C6.21129 5.10156 6.10229 5.13365 5.99979 5.19781C5.89729 5.26181 5.82038 5.34515 5.76904 5.44781L3.94204 9.10156Z" />
  </svg>
);
MdDiamond.isCustomSvg = true;
