import { useQuery } from "@tanstack/react-query";
import { get } from "../../api/common/rooFetch";
import { CACHE } from "../../api/utility/constants";
import { getAutoConfirmContractorList as getAutoConfirmContractorListRoute } from "../../constants/apiRoutes";

interface AutoConfirmContractor {
  vetId?: string;
  techId?: string;
  isDisabled?: string;
}

interface ApiResponse {
  data: {
    autoConfirmList: AutoConfirmContractor[];
  };
}

const getAutoConfirmContractorList = async (
  hospitalId: number
): Promise<AutoConfirmContractor[]> => {
  const response = await get<ApiResponse>(getAutoConfirmContractorListRoute(hospitalId));
  return response?.data?.autoConfirmList ?? [];
};

export const useAutoConfirmContractorList = (hospitalId?: number) => {
  return useQuery<AutoConfirmContractor[], Error>(
    [CACHE.AUTO_CONFIRM_CONTRACTOR_LIST, hospitalId],
    () => (hospitalId ? getAutoConfirmContractorList(hospitalId) : Promise.resolve([])),
    {
      enabled: !!hospitalId,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
    }
  );
};
