import { Modal, Text } from "@RooUI";
import React from "react";

import { StyledList, StyledListItem, TextContainer } from "./styles";

interface PolicyInterruptModalProps {
  showModal: boolean;
  onCloseModal: () => void;
}

export const PolicyInterruptModal: React.FC<PolicyInterruptModalProps> = ({
  showModal,
  onCloseModal,
}) => {
  return (
    <Modal
      showModal={showModal}
      onCloseModal={onCloseModal}
      $size="m"
      header="Looks like you've hopped a bit too fast!"
      body={
        <>
          <TextContainer>
            <Text>
              To continue, check the boxes to acknowledge you've read our policies on the following
              pages:
            </Text>
          </TextContainer>
          <StyledList>
            <StyledListItem>
              <Text>Cancellation Policy</Text>
            </StyledListItem>
            <StyledListItem>
              <Text>Code of Conduct</Text>
            </StyledListItem>
            <StyledListItem>
              <Text>Full-Time Placement</Text>
            </StyledListItem>
          </StyledList>
        </>
      }
      $primaryButtonProps={{
        title: "Got It",
        onClick: onCloseModal,
      }}
      $buttonVariant="confirmation"
    />
  );
};
