import { useMutation } from "@tanstack/react-query";

import { APIError, patch } from "../../api/common/rooFetch";
import { updateShiftProposal } from "../../constants/apiRoutes";

interface useUpdateShiftProposalOptions {
  onSuccess?: () => void;
  onError?: (error: APIError<{ message: string }>) => void;
}

export const useUpdateShiftProposal = (options?: useUpdateShiftProposalOptions) => {
  return useMutation<
    void,
    APIError<{ message: string }>,
    { shiftProposalId: number; commsSent: true }
  >(
    (params) => {
      const updateParams = {
        commsSent: params.commsSent,
      };
      return patch(updateShiftProposal(params.shiftProposalId), updateParams);
    },
    {
      onSuccess: () => {
        options?.onSuccess?.();
      },
      onError: (error) => {
        options?.onError?.(error);
      },
    }
  );
};
