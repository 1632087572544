import * as ActionTypes from "../../../constants/hospitalUsers";

const initialState = {
  hospitalUserList: [],
  isUserListFetched: false,
  isError: false,
};

/* eslint-disable default-param-last */
// @ts-expect-error TS7006
const ManageHospitalUsersReducer = (state = initialState, action) => {
  /* eslint-enable default-param-last */
  switch (action.type) {
    case ActionTypes.GET_HOSPITAL_USER_LIST_REQUEST:
      return {
        ...state,
        hospitalUserList: [],
        isUserListFetched: false,
      };

    case ActionTypes.GET_HOSPITAL_USER_LIST_SUCCESS:
      return {
        ...state,
        hospitalUserList: action.payload.data,
        isUserListFetched: true,
        isUserExist: false,
      };

    case ActionTypes.GET_HOSPITAL_USER_LIST_FAIL:
      return {
        ...state,
        hospitalUserList: [],
        isUserListFetched: false,
      };

    case ActionTypes.INITIALIZE_HOSPITAL_USER_LIST:
      return {
        ...state,
        hospitalUserList: [],
        isUserListFetched: false,
        isHospitalUserBlocked: false,
        isHospitalUserDeleted: false,
        isError: false,
      };

    default:
      return {
        ...state,
      };
  }
};

// eslint-disable-next-line no-restricted-exports
export default ManageHospitalUsersReducer;
