import { Milestone } from "@roo-dev/roo-node-types";

export const hospitalAccountBidEnablement = (hospitalId: number) =>
  `api/hospital/${hospitalId}/setting/account/shiftDetail/bidding`;

export const shiftBidEnablement = (shiftId: number) => `api/shifts/${shiftId}/biddingEnablement`;

export const getUserPromos = (userId: number) => `api/promo/userPromos/${userId}`;

export const createPromoTracking = () => "api/promoTracking";

export const dismissPromoTracking = (promoTrackingId: number) =>
  `api/promoTracking/updatePromoMessageSeen/${promoTrackingId}`;

export const approvePendingShiftRequests = () => `api/admin/users/approvePendingShiftRequests`;
export const rejectPendingShiftRequests = () => `api/admin/users/rejectPendingShiftRequests`;

export const getPromoEligibleUsers = (promoId: number) =>
  `api/promoEligibleUser/allByPromo/${promoId}`;

export const promoDetailsById = (promoId: number) => `api/promo/${promoId}`;

export const createPromo = () => `api/promo/`;

export const updatePromo = (promoId: number) => `api/promo/${promoId}`;

export const getAllPromos = (page: number, limit: number) =>
  `api/promo/all?page=${page}&limit=${limit}`;

export const getOrCreateConversation = (
  hospitalId: number,
  vetUserId: number,
  hospitalUserId?: number
) =>
  `${
    window.RooConfig.MESSAGING_API_URL
  }/api/v2/conversation/info?hospitalId=${hospitalId}&vetUserId=${vetUserId}${
    hospitalUserId ? `&hospitalUserId=${hospitalUserId}` : ""
  }`;

export const registerParticipant = () =>
  `${window.RooConfig.MESSAGING_API_URL}/api/conversation/register/participant`;

export const getPastPromos = (page: number, limit: number) =>
  `api/promo/past?page=${page}&limit=${limit}`;

export const publishPromo = () => `api/promo/publish`;

export const stopPromo = () => `api/promo/stop`;

export const getVetShiftDetails = (shiftGroupId: number, vetId: number) =>
  `api/vet/shift/getLinkedShift?shiftGroupId=${shiftGroupId}&vetId=${vetId}`;

export const getTechShiftDetails = (shiftGroupId: number, techId: number) =>
  `api/tech/shift/getLinkedShift?shiftGroupId=${shiftGroupId}&techId=${techId}`;

export const sendTestCommunication = () => `api/promoEligibleUser/sendTestCommunication`;

export const pendingVetShifts = (vetId: number) => `api/admin/getPendingShifts/${vetId}`;

export const dismissPromoReminder = () => `api/promoTracking/dismissPromoReminder`;

export const setUserTestAccount = (userId: number) => `api/admin/user/${userId}/setTestAccount`;

export const getHospitalUserDetails = () => `api/enterprise/getHospitalUserDetails`;

export const getSearchHistory = (userId: number, page: number, limit: number) =>
  `api/user/${userId}/searchHistory?page=${page}&limit=${limit}`;

export const getFavorites = () => `api/vet/favorites`;

export const updateFavorites = () => `api/vet/updateFavorites`;

export const getBlockedHospitalList = (vetId: number) => `api/vet/getBlockedHospitalList/${vetId}`;

export const blockHospital = () => `api/vet/blockHospital`;

export const removeBlockedHospital = () => `api/vet/removeBlockedHospital`;

export const getRatingSummary = (
  userId?: number,
  userTypeId?: number,
  isFromAdmin?: boolean,
  hospitalId?: number,
  vetId?: number,
  techId?: number
) =>
  `api/user/${userId}/ratingSummary?userTypeId=${userTypeId}&isFromAdmin=${isFromAdmin}&hospitalId=${hospitalId}&vetId=${vetId}&techId=${techId}`;

export const addShiftsToPromo = () => `api/admin/addShiftsToPromo`;

export const getPromoDetailsById = (promoId: number) => `api/promo/${promoId}`;

export const getPromoTrackingByPromoId = (promoId: number) =>
  `api/promoTracking/allByPromo/${promoId}`;

export const submitManualPromoPayout = (promoTrackingId: number) =>
  `api/promoTracking/submitManualPayment/${promoTrackingId}`;

export const adminCreatePromoTracking = () => `api/admin/promoTracking`;

export const vetBidShift = () => `api/pricing/vetBidShift`;

export const contactDetails = () => `api/zapier/contactDetails`;

export const sendZapierSms = () => `api/zapier/sendSms`;

export const getHospitalRatings = (isVet: boolean) =>
  isVet ? "api/vet/shift/hospitalRatings" : "api/tech/shift/hospitalRatings";

export const updateRating = (isVet: boolean) =>
  isVet ? "api/vet/updateRating" : "api/tech/updateRating";

export const deferHospitalRating = (isVet: boolean) =>
  isVet ? "api/vet/deferRating" : "api/tech/deferRating";

export const deferAllHospitalRatings = (isVet: boolean) =>
  isVet ? "api/vet/deferAllRatings" : "api/tech/deferAllRatings";

export const addBookmark = () => `api/bookmark`;

export const removeBookmark = (bookmarkId: number) => `api/bookmark/${bookmarkId}`;

export const getBookmarks = () => `api/bookmark`;

export const getAllVets = () => `api/vet/vet`;

export const setAvailableDayRoute = (vetId: number) => `api/vet/${vetId}/setAvailableDay`;

export const getWorkedHospitals = (minRating: number) =>
  `api/vet/workedHospitals?minRating=${minRating}`;

export const updateVet = () => `api/vet/updateVet`;

export const getMilestoneStatus = (milestone: Milestone.UserMilestone) =>
  `api/milestone/status?milestone=${milestone}`;

export const getUserPromoProposals = (status?: string) => `api/promoProposal?status=${status}`;

export const expressInterestInPromoProposal = (id: number) =>
  `api/promoProposal/${id}/expressInterest`;

export const cancelShift = () => `api/hospital/cancelShift`;

export const uploadPromoProposals = () => `api/promoProposal/upload`;

export const payoutCancelledShifts = (isVet: boolean) =>
  `api/admin/transfer${isVet ? "" : "/tech"}/batch`;

export const getAllPromoProposals = () => `api/promoProposal/all`;

export const adminShiftCancellation = (isVet: boolean, shiftGroupId?: number) =>
  isVet ? `api/admin/shifts/${shiftGroupId}/cancel` : `api/tech/shift/removeTech`;

export const createEntityEventCompletion = () => `api/entityEvent`;

export const updateShiftProposal = (shiftProposalId: number) =>
  `api/shiftProposal/${shiftProposalId}`;

export const getAutoConfirmContractorList = (hospitalId: number) =>
  `api/hospital/getAutoConfirmContractorList/${hospitalId}`;

export const getWebAppVersion = () => "api/utility/getWebAppVersion";
