import React, { useMemo } from "react";

import { useGetTravelStipends } from "../../../../Admin/TravelStipend/hooks/useGetTravelStipends";
import { ShiftDetails } from "@RooBeta/types";
import { VStack } from "@RooBeta/components/Flex";
import { InlineContainer, SmallBoldText, SmallText } from "@RooUI";

interface FlexibilityBannerProps {
  shiftDetails: ShiftDetails;
}

export const FlexibilityBanner = ({ shiftDetails }: FlexibilityBannerProps) => {
  const { data: travelStipends } = useGetTravelStipends();

  const isTravelStipendEnabled = useMemo(() => {
    if (!shiftDetails?.metroAreaId || !travelStipends?.data) return false;
    return travelStipends.data.some(
      (stipend) => stipend.metro_area_id === shiftDetails.metroAreaId && !stipend.deleted_on
    );
  }, [shiftDetails?.metroAreaId, travelStipends?.data]);

  const getFlexibilityText = () => {
    const flexibilities = [];
    if (shiftDetails.isTimeBiddingEnabled || shiftDetails.isFlexibleTiming)
      flexibilities.push("time");
    if (shiftDetails.isRateBiddingEnabled) flexibilities.push("pay");
    if (isTravelStipendEnabled) flexibilities.push("travel stipend");
    if (shiftDetails.isFlexibleSurgery) flexibilities.push("surgery");

    if (flexibilities.length === 0) return null;
    if (flexibilities.length === 1) return `${flexibilities[0]} modifications`;
    if (flexibilities.length === 2)
      return `${flexibilities[0]} and ${flexibilities[1]} modifications`;

    const lastItem = flexibilities.pop();
    return `${flexibilities.join(", ")}, and ${lastItem} modifications`;
  };

  const flexibilityText = getFlexibilityText();

  const isModifiable = shiftDetails.canModifyShift || isTravelStipendEnabled;

  if (!isModifiable) return null;

  return (
    <InlineContainer iconName="Edit">
      <VStack $gap="xs">
        <SmallBoldText>This shift has flexible requirements</SmallBoldText>
        <SmallText>You can request {flexibilityText}</SmallText>
      </VStack>
    </InlineContainer>
  );
};
