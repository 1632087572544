import { zodResolver } from "@hookform/resolvers/zod";
import { Drawer, DrawerProps } from "antd";
import React, { useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

import { useNotification, useShiftCancelMutation } from "@RooBeta/hooks";
import { useShiftDetailsStore, useViewport } from "@RooBeta/store";
import {
  ShiftCancellation as FormData,
  ShiftCancellationReason,
  ViewportSize,
  shiftCancellationSchema,
} from "@RooBeta/types";

import { ShiftCancellation } from "./ShiftCancellation";
import { ShiftCancellationFooter } from "./ShiftCancellationFooter";
import { Z_INDEX } from "@Roo/constants/zIndex";
const STYLES: DrawerProps["styles"] = {
  body: { padding: 0, scrollbarGutter: "stable" },
  header: {
    alignSelf: "flex-end",
    borderBottom: "none",
    paddingBottom: "0",
  },
};

export const createSchema = (isConfirmed: boolean) =>
  shiftCancellationSchema.superRefine(({ reasonCode, comment = "" }, ctx) => {
    if (
      (isConfirmed || reasonCode === ShiftCancellationReason.Other) &&
      comment.trim().length === 0
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Please specify a reason for cancellation.",
        path: ["comment"],
      });
    }
  });

export const ShiftCancellationDrawer = () => {
  const viewport = useViewport();
  const notification = useNotification();

  const { shiftDetails, drawer, setDrawer } = useShiftDetailsStore();
  const open = drawer === "cancel";

  const schema = createSchema(shiftDetails?.shiftStatus === "CONFIRMED");

  const form = useForm<FormData>({ resolver: zodResolver(schema) });
  const { handleSubmit } = form;

  const shiftCancellation = useShiftCancelMutation({ shiftDetails });

  const onSubmit = handleSubmit(async (data) => {
    try {
      await shiftCancellation.mutateAsync(data);

      onClose();
    } catch (error) {
      notification.error({ message: "Error", description: error.message });
    }
  });

  const onClose = useCallback(() => {
    setDrawer(undefined);
  }, [setDrawer]);

  useEffect(() => {
    if (open) {
      form.reset();
    }
  }, [open, form]);

  return (
    <Drawer
      open={open}
      zIndex={Z_INDEX.SHIFT_CANCELLATION_DRAWER}
      onClose={onClose}
      placement={viewport === ViewportSize.s ? "bottom" : "right"}
      height={viewport === ViewportSize.s ? "100dvh" : undefined}
      footer={
        <ShiftCancellationFooter onClick={onSubmit} isLoading={shiftCancellation.isLoading} />
      }
      styles={STYLES}
    >
      <FormProvider {...form}>
        <form onSubmit={onSubmit}>
          <ShiftCancellation />
        </form>
      </FormProvider>
    </Drawer>
  );
};
