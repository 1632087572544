import { createDictionary } from "../helpers/jsUtility";
import { Rank } from "../vetTechDashboard/components/types";

import {
  HOSPITAL_USER_TYPE,
  STUDENT_USER_TYPE,
  TECH_USER_TYPE,
  VET_USER_TYPE,
} from "./UserTypeConstants";

// --- HOSPITAL DROP DOWN OPTIONS

export const ahospitalUserSignUpSelfDefinedRoleOptions = [
  { value: 1, label: "Owner", name: "roleId" },
  { value: 2, label: "Administrator", name: "roleId" },
  { value: 3, label: "Practice Manager", name: "roleId" },
  { value: 4, label: "Medical Director", name: "roleId" },
];
export const ohospitalUserSignUpSelfDefinedRoleOptions = createDictionary(
  ahospitalUserSignUpSelfDefinedRoleOptions,
  "value"
);

export const aTypeOfHospitalOptions = [
  {
    value: 0,
    label: "Primarily small animal",
    name: "typeOfHospital",
    shortName: "Small animal",
    icon: "SoundDetectionDogBarkingRound",
  },
  {
    value: 1,
    label: "Primarily exotic animal",
    name: "typeOfHospital",
    shortName: "Exotic animal",
    icon: "OutlineSavings",
  },
  {
    value: 2,
    label: "Primarily large animal",
    name: "typeOfHospital",
    shortName: "Large animal",
    icon: "RavenOutlined",
  },
  {
    value: 3,
    label: "Animal welfare, shelter, or non-profit",
    name: "typeOfHospital",
    shortName: "Shelter",
    icon: "Pets",
  },
  {
    value: 4,
    label: "Emergency or Specialty",
    name: "typeOfHospital",
    shortName: "Emergency",
    icon: "Emergency",
  },
  {
    value: 5,
    label: "Other",
    name: "typeOfHospital",
    shortName: "Other",
    icon: "SoundDetectionDogBarkingRound",
  },
];
export const oTypeOfHospitalOptions = createDictionary(aTypeOfHospitalOptions, "value");

// Intentional for shift fullfilment prediction
export const aHospitalTypeLabelsMapping = [
  { value: 0, label: "Primarily Small animal" },
  { value: 1, label: "Primarily Exotic animal" },
  { value: 2, label: "Primarily Large animal" },
  { value: 3, label: "Animal Welfare, Shelter, or Non-Profit" },
  { value: 4, label: "Emergency or Specialty" },
  { value: 5, label: "Other" },
];

export const aAverageNoOfDvmOptions = [
  { value: -1, label: "0", name: "averageNoOfDVMWorkPerDay" },
  { value: 0, label: "1", name: "averageNoOfDVMWorkPerDay" },
  { value: 1, label: "2", name: "averageNoOfDVMWorkPerDay" },
  { value: 2, label: "3", name: "averageNoOfDVMWorkPerDay" },
  { value: 3, label: "4", name: "averageNoOfDVMWorkPerDay" },
  { value: 4, label: "5 or more", name: "averageNoOfDVMWorkPerDay" },
];
export const oAverageNoOfDvmOptions = createDictionary(aAverageNoOfDvmOptions, "value");

export const aAverageTransactionVolumeDayOptions = [
  { value: 0, label: "Less than 10", name: "averageTransactionVolumePerDay" },
  { value: 1, label: "10-20", name: "averageTransactionVolumePerDay" },
  { value: 2, label: "20-30", name: "averageTransactionVolumePerDay" },
  { value: 3, label: "30-40", name: "averageTransactionVolumePerDay" },
  { value: 4, label: "40-50", name: "averageTransactionVolumePerDay" },
  { value: 5, label: "More than 50", name: "averageTransactionVolumePerDay" },
];
export const oAverageTransactionVolumeDayOptions = createDictionary(
  aAverageTransactionVolumeDayOptions,
  "value"
);

export const aAverageTransactionValueOptions = [
  { value: 0, label: "Less than $125", name: "averageTransactionValue" },
  { value: 1, label: "$125-$150", name: "averageTransactionValue" },
  { value: 2, label: "$150-$175", name: "averageTransactionValue" },
  { value: 3, label: "$175-$200", name: "averageTransactionValue" },
  { value: 4, label: "$200-$250", name: "averageTransactionValue" },
  { value: 5, label: "$250-$300", name: "averageTransactionValue" },
  { value: 6, label: "$300-$350", name: "averageTransactionValue" },
  { value: 7, label: "$350-$400", name: "averageTransactionValue" },
  { value: 8, label: "$400-$450", name: "averageTransactionValue" },
  { value: 9, label: "$450-$500", name: "averageTransactionValue" },
  { value: 10, label: "$500-$550", name: "averageTransactionValue" },
  { value: 11, label: "$550-$600", name: "averageTransactionValue" },
  { value: 12, label: "$600-$1,000", name: "averageTransactionValue" },
  { value: 13, label: "$1,000-$2,000", name: "averageTransactionValue" },
  { value: 14, label: "Over $2,000", name: "averageTransactionValue" },
];
export const oAverageTransactionValueOptions = createDictionary(
  aAverageTransactionValueOptions,
  "value"
);

export const aAverageSurgeryVolumeDayOptions = [
  { value: 0, label: "Less than 3", name: "averageSurgeryVolumePerDay" },
  { value: 1, label: "3-6", name: "averageSurgeryVolumePerDay" },
  { value: 2, label: "7-10", name: "averageSurgeryVolumePerDay" },
  { value: 3, label: "11-15", name: "averageSurgeryVolumePerDay" },
  { value: 4, label: "More than 15", name: "averageSurgeryVolumePerDay" },
];
export const oAverageSurgeryVolumeDayOptions = createDictionary(
  aAverageSurgeryVolumeDayOptions,
  "value"
);

export const aAverageNoTechPerDVMOptions = [
  { value: 0, label: "1", name: "averageNoOfTechPerDvmPerDay" },
  { value: 1, label: "2", name: "averageNoOfTechPerDvmPerDay" },
  { value: 2, label: "3", name: "averageNoOfTechPerDvmPerDay" },
  { value: 3, label: "4 or more", name: "averageNoOfTechPerDvmPerDay" },
];
export const oAverageNoTechPerDVMOptions = createDictionary(aAverageNoTechPerDVMOptions, "value");

export const aLunchBreakOptions = [
  { value: 0, label: "Usually 30 minute lunch with breaks", name: "typicalLunchBreakSetup" },
  { value: 4, label: "Usually 30 mins to one hour lunch only", name: "typicalLunchBreakSetup" },
  { value: 1, label: "Usually one hour lunch with breaks", name: "typicalLunchBreakSetup" },
  { value: 2, label: "Usually two hour lunch/break combined", name: "typicalLunchBreakSetup" },
  { value: 5, label: "Usually flexible breaks", name: "typicalLunchBreakSetup" },
  { value: 3, label: "Other", name: "typicalLunchBreakSetup" },
];
export const oLunchBreakOptions = createDictionary(aLunchBreakOptions, "value");

export const aMinReliefPlanningOptions = [
  { value: 1, label: "1 day before the relief date", name: "hospitalReliefWork" },
  { value: 2, label: "2 days before the relief date", name: "hospitalReliefWork" },
  { value: 3, label: "3 days before the relief date", name: "hospitalReliefWork" },
  { value: 4, label: "4 days before the relief date", name: "hospitalReliefWork" },
  { value: 5, label: "5 + days before the relief date", name: "hospitalReliefWork" },
];
export const oMinReliefPlanningOptions = createDictionary(aMinReliefPlanningOptions, "value");

export const aMaintainRecordsOptions = [
  { value: 1, label: "Paperless", name: "hospitalMaintainRecords" },
  { value: 2, label: "Paper-light", name: "hospitalMaintainRecords" },
  { value: 3, label: "Handwritten charts", name: "hospitalMaintainRecords" },
];
export const oMaintainRecordsOptions = createDictionary(aMaintainRecordsOptions, "value");

export const aDressCodeOptions = [
  { value: 1, id: 1, label: "Business Casual", name: "hospitalDressCodes" },
  { value: 2, id: 2, label: "Scrubs", name: "hospitalDressCodes" },
  { value: 3, id: 3, label: "White Coat", name: "hospitalDressCodes" },
  { value: 4, id: 4, label: "Coveralls", name: "hospitalDressCodes" },
  { value: 5, id: 5, label: "Other", name: "hospitalDressCodes" },
];
export const oDressCodeOptions = createDictionary(aDressCodeOptions, "value");

export const get24HourBy30minOptions = (key: string) => {
  const aOptions = [];

  aOptions.push({ value: 0, label: "12:00 AM", name: key });
  aOptions.push({ value: 0, label: "12:30 AM", name: key });

  for (let i = 1; i < 12; i++) {
    aOptions.push({ value: 0, label: `${i}:00 AM`, name: key });
    aOptions.push({ value: 0, label: `${i}:30 AM`, name: key });
  }

  aOptions.push({ value: 0, label: "12:00 PM", name: key });
  aOptions.push({ value: 0, label: "12:30 PM", name: key });

  for (let i = 1; i < 12; i++) {
    aOptions.push({ value: 0, label: `${i}:00 PM`, name: key });
    aOptions.push({ value: 0, label: `${i}:30 PM`, name: key });
  }

  aOptions.forEach((o, i) => (o.value = i));
  return aOptions;
};

export const getTechBreakOptions = [
  { value: 1, label: "10 min", name: "expectedBreakTime" },
  { value: 2, label: "20 min", name: "expectedBreakTime" },
  { value: 3, label: "30 min", name: "expectedBreakTime" },
  { value: 4, label: "45 min", name: "expectedBreakTime" },
  { value: 5, label: "1 hour", name: "expectedBreakTime" },
  { value: 6, label: "1.5 hour", name: "expectedBreakTime" },
  { value: 7, label: "No breaks", name: "expectedBreakTime" },
];

// --- OTHER DROP DOWN OPTIONS

export const PROCEDURE_TYPE = [
  { id: 1, procedureType: "Dental" },
  { id: 2, procedureType: "Internal" },
  { id: 3, procedureType: "Ultrasound" },
  { id: 4, procedureType: "Soft-tissue" },
  { id: 5, procedureType: "Orthopedics" },
  { id: 6, procedureType: "Elective" },
  { id: 7, procedureType: "Emergent" },
];

export const ANIMAL_TYPE = [
  { id: 1, type: "Small animals" },
  { id: 2, type: "Mixed" },
  { id: 3, type: "Feline-only" },
  { id: 4, type: "Large animals" },
  { id: 5, type: "Exotic" },
];

export const SPECIALIZATION_TYPE = [
  { id: 1, specialization_type: "None", label: "None" },
  { id: 2, specialization_type: "Surgery", label: "Surgery" },
  { id: 3, specialization_type: "Cardiology", label: "Cardiology" },
  { id: 4, specialization_type: "Dentistry", label: "Dentistry" },
  { id: 5, specialization_type: "Dermatology", label: "Dermatology" },
  {
    id: 6,
    specialization_type: "Emergency and Critical Care",
    label: "Emergency and Critical Care",
  },
  { id: 7, specialization_type: "Internal Medicine", label: "Internal Medicine" },
  { id: 8, specialization_type: "Neurology", label: "Neurology" },
  { id: 9, specialization_type: "Oncology", label: "Oncology" },
  { id: 10, specialization_type: "Ophthalmology", label: "Ophthalmology" },
  { id: 11, specialization_type: "Pathology - Anatomic", label: "Pathology - Anatomic" },
  { id: 12, specialization_type: "Pathology - Clinical", label: "Pathology - Clinical" },
  { id: 13, specialization_type: "Radiology", label: "Radiology" },
];

export const TAXPAYER_TYPE = [
  { value: "1", label: "Individual, Sole Proprietor or Single-member LLC", name: "signNowTaxType" },
  { value: "2", label: "C Corporation", name: "signNowTaxType" },
  { value: "3", label: "S Corporation", name: "signNowTaxType" },
  { value: "4", label: "Partnership", name: "signNowTaxType" },
  { value: "5", label: "Trust", name: "signNowTaxType" },
  { value: "6", label: "LLC", name: "signNowTaxType" },
  { value: "7", label: "Other", name: "signNowTaxType" },
];

export const EXEMPT_PAYEE_CODE = [
  { value: "1", label: "None", name: "signNowExemptions" },
  {
    value: "2",
    label:
      "1 - An organization exempt from tax under section 501(a), any IRA, or a custodial acccount under section 403(b)(7) if the account satisfies the requirements of section 401(f)(2)",
    name: "signNowExemptions",
  },
  {
    value: "3",
    label: "2 - The United States or any of its agencies or instrumentalities ",
    name: "signNowExemptions",
  },
  {
    value: "4",
    label:
      "3 - A state, the District of Columbia, a U.S. commonwealth or possession, or any of their political subdivisions or instrumentalities",
    name: "signNowExemptions",
  },
  {
    value: "5",
    label:
      "4 - A foreign goverment or any of its political subdivisions, agencies, or instrumentalities",
    name: "signNowExemptions",
  },
];

export const REMOVE_SHIFT_REASONS = [
  { value: 1, label: "Low appointment flow" },
  { value: 2, label: "Coverage not found in time" },
  { value: 3, label: "Outside relief coverage found" },
  { value: 4, label: "Internal staff coverage found" },
  { value: 5, label: "Shift created in error" },
  { value: 8, label: "I hired permanent staff (found on Roo)" },
  { value: 9, label: "I hired permanent staff (not found on Roo)" },
  { value: 7, label: "COVID-19 related" },
];

export const REMOVE_TECH_SHIFT_REASONS = [
  { value: 1, label: "Low appointment flow" },
  { value: 2, label: "Coverage not found in time" },
  { value: 3, label: "Outside relief coverage found" },
  { value: 4, label: "Internal staff coverage found" },
  { value: 5, label: "Shift created in error" },
  { value: 8, label: "I hired permanent staff (found on Roo)" },
  { value: 9, label: "I hired permanent staff (not found on Roo)" },
  { value: 7, label: "COVID-19 related" },
];

export const aDaysPerMonthWorkOptions = [
  { value: 6, label: "1-2 times/month", name: "freeDaysInMonthToWork" },
  { value: 7, label: "3-5 times/month", name: "freeDaysInMonthToWork" },
  { value: 2, label: "6-10 times/month", name: "freeDaysInMonthToWork" },
  { value: 8, label: "11+ times/month", name: "freeDaysInMonthToWork" },
];
//Legacy values but still valid in DB
// [
//   { value: 1, label: "1-5 times/month", name: "freeDaysInMonthToWork" },
//   { value: 2, label: "6-10 times/month", name: "freeDaysInMonthToWork" },
//   { value: 3, label: "11-15 times/month", name: "freeDaysInMonthToWork" },
//   { value: 4, label: "16-20 times/month", name: "freeDaysInMonthToWork" },
//   { value: 5, label: "Over 20 times/month", name: "freeDaysInMonthToWork" },
// ]
export const oDaysPerMonthWorkOptions = createDictionary(aDaysPerMonthWorkOptions, "value");

export const aVetAvailabilityOptions = [
  { value: 1, label: "I can work anytime and last minute", name: "vetAvailability" },
  {
    value: 2,
    label: "It depends, I need some planning but have flexibility",
    name: "vetAvailability",
  },
  { value: 3, label: "I need proper advanced planning & scheduling", name: "vetAvailability" },
];
export const oVetAvailabilityOptions = createDictionary(aVetAvailabilityOptions, "value");

export const aTechAvailabilityOptions = [
  { value: 1, label: "I can work anytime and last minute", name: "techAvailability" },
  {
    value: 2,
    label: "It depends, I need some planning but have flexibility",
    name: "techAvailability",
  },
  { value: 3, label: "I need proper advanced planning & scheduling", name: "techAvailability" },
];
export const oTechAvailabilityOptions = createDictionary(aTechAvailabilityOptions, "value");

export const aTrackInSchoolOptions = [
  { value: 1, label: "Small Animal", name: "trackInSchool" },
  { value: 2, label: "Large Animal", name: "trackInSchool" },
  { value: 3, label: "Mixed Animal", name: "trackInSchool" },
  { value: 4, label: "Equine", name: "trackInSchool" },
  { value: 5, label: "Research/Lab Animal", name: "trackInSchool" },
];

export const aStudentLearningMethodOptions = [
  { value: 1, label: "Hands-on", name: "prefLearningMethod" },
  { value: 2, label: "Clinical Rounds", name: "prefLearningMethod" },
  { value: 3, label: "1 on 1 mentorship", name: "prefLearningMethod" },
];

export const aStudentMentorshipTypeOptions = [
  { value: 1, label: "Dive in", name: "prefMentorshipType" },
  { value: 2, label: "See one do one teach one", name: "prefMentorshipType" },
  { value: 3, label: "Direct supervision", name: "prefMentorshipType" },
];

export const aShiftRepeatOptions = [
  { value: 0, label: "Does not repeat", name: "shiftRepeat" },
  { value: 1, label: "Daily", name: "shiftRepeat" },
  { value: 2, label: "Every weekday (Monday to Friday)", name: "shiftRepeat" },
  { value: 3, label: "Weekly on ", name: "shiftRepeat" },
  { value: 4, label: "Monthly on the", name: "shiftRepeat" },
];

export const nthDayOfMonthNumberMapping = {
  0: "last",
  1: "first",
  2: "second",
  3: "third",
  4: "fourth",
  5: "last",
};

export const TOTAL_ROO_VET_TRANSACTION = [
  { value: "<5", label: "<5", name: "rooVetTransaction" },
  { value: "5-10", label: "5-10", name: "rooVetTransaction" },
  { value: "10-15", label: "10-15", name: "rooVetTransaction" },
  { value: "15-20", label: "15-20", name: "rooVetTransaction" },
  { value: "20-25", label: "20-25", name: "rooVetTransaction" },
  { value: "25-30", label: "25-30", name: "rooVetTransaction" },
  { value: "30-35", label: "30-35", name: "rooVetTransaction" },
  { value: "35-40", label: "35-40", name: "rooVetTransaction" },
  { value: "40-45", label: "40-45", name: "rooVetTransaction" },
  { value: "45-50", label: "45-50", name: "rooVetTransaction" },
  { value: ">50", label: ">50", name: "rooVetTransaction" },
];

export const TOTAL_ROO_VET_PRODUCTION = [
  { value: "<$500", label: "<$500", name: "rooVetProduction" },
  { value: "$500-$1,000", label: "$500-$1,000", name: "rooVetProduction" },
  { value: "$1,000-$1,500", label: "$1,000-$1,500", name: "rooVetProduction" },
  { value: "$1,500-$2,000", label: "$1,500-$2,000", name: "rooVetProduction" },
  { value: "$2,000-$2,500", label: "$2,000-$2,500", name: "rooVetProduction" },
  { value: "$2,500-$3,000", label: "$2,500-$3,000", name: "rooVetProduction" },
  { value: "$3,000-$3,500", label: "$3,000-$3,500", name: "rooVetProduction" },
  { value: "$3,500-$4,000", label: "$3,500-$4,000", name: "rooVetProduction" },
  { value: "$4,000-$4,500", label: "$4,000-$4,500", name: "rooVetProduction" },
  { value: "$4,500-$5,000", label: "$4,500-$5,000", name: "rooVetProduction" },
  { value: ">$5,000", label: ">$5,000", name: "rooVetProduction" },
];

export const aExpectedNoOfAppointmentsOptions = [
  { value: 0, label: "1-5", name: "expectedNoOfAppointments" },
  { value: 1, label: "5-10", name: "expectedNoOfAppointments" },
  { value: 2, label: "10-15", name: "expectedNoOfAppointments" },
  { value: 3, label: "15-20", name: "expectedNoOfAppointments" },
  { value: 4, label: "20-25", name: "expectedNoOfAppointments" },
  { value: 5, label: "25+", name: "expectedNoOfAppointments" },
];

export const aApptTypesOptions = [
  { value: 6, label: "General practice", name: "apptTypes" },
  { value: 1, label: "General practice", name: "apptTypes" },
  { value: 2, label: "General practice", name: "apptTypes" },
  { value: 3, label: "Emergency", name: "apptTypes" },
  { value: 4, label: "Urgent care", name: "apptTypes" },
  { value: 5, label: "Vaccine Only", name: "apptTypes" },
];
export const oApptTypesOptions = createDictionary(aApptTypesOptions, "value");

export const aSearchApptTypesOptions = [
  {
    value: "1",
    label: "Mostly well",
    name: "expectedAppointmentTypeId",
  },
  {
    value: "2",
    label: "Mostly non-well",
    name: "expectedAppointmentTypeId",
  },
  {
    value: "3",
    label: "Emergency",
    name: "expectedAppointmentTypeId",
  },
  {
    value: "#surgery",
    label: "Surgery expected",
    name: "expectedAppointmentTypeId",
  },
  {
    value: "#noSurgery",
    label: "Surgery not expected",
    name: "expectedAppointmentTypeId",
  },
  {
    value: "4",
    label: "Urgent care",
    name: "expectedAppointmentTypeId",
  },
  {
    value: "5",
    label: "Vaccine only",
    name: "expectedAppointmentTypeId",
  },
];

export const aAvgWellnessApptLenOptions = [
  { value: 1, label: "Less than 15 min", name: "avgWellnessApptLen" },
  { value: 2, label: "15-30 min", name: "avgWellnessApptLen" },
  { value: 3, label: "30-45 min", name: "avgWellnessApptLen" },
  { value: 4, label: "Over 45 min", name: "avgWellnessApptLen" },
];
export const oAvgWellnessApptLenOptions = createDictionary(aAvgWellnessApptLenOptions, "value");

export const VET_REQUEST_DECLINE_REASONS = [
  { value: 1, label: "Seeking more experience" },
  { value: 2, label: "Seeking alternate skill sets" },
  { value: 3, label: "Seeking better culture fit" },
  { value: 4, label: "Shift no longer needed" },
  { value: 5, label: "Not a match for this location" },
  { value: 6, label: "Requested price is too high" },
];

export const aMilesOptions = [
  { value: "2", label: "2", name: "miles" },
  { value: "5", label: "5", name: "miles" },
  { value: "10", label: "10", name: "miles" },
  { value: "15", label: "15", name: "miles" },
  { value: "20", label: "20", name: "miles" },
  { value: "30", label: "30", name: "miles" },
  { value: "50", label: "50", name: "miles" },
  { value: "75", label: "75", name: "miles" },
  { value: "100", label: "100", name: "miles" },
];
export const oMilesOptions = createDictionary(aMilesOptions, "value");

export const externMilesOptions = [
  { value: "2", label: "2 miles", name: "miles" },
  { value: "5", label: "5 miles", name: "miles" },
  { value: "10", label: "10 miles", name: "miles" },
  { value: "15", label: "15 miles", name: "miles" },
  { value: "20", label: "20 miles", name: "miles" },
  { value: "30", label: "30 miles", name: "miles" },
  { value: "50", label: "50 miles", name: "miles" },
  { value: "100", label: "100 miles", name: "miles" },
];

// roo4you posting topics
export const aPostTopicOptions = [
  { value: 1, urlSegment: "recent", label: "Recent Posts", name: "categoryType", description: "" }, // only for posting view (removed in form)
  {
    value: 2,
    urlSegment: "work_life_balance",
    label: "Work/Life Balance Tips",
    name: "categoryType",
    description:
      "We understand your passion for caring for animals but we also think it's important to care for ourselves. Read more about Roo's commitment to well being.",
  },
  {
    value: 3,
    urlSegment: "veterinary_practice_tips",
    label: "Veterinary Practice Tips",
    name: "categoryType",
    description:
      "Want to know how much a vet makes, or how to build a successful veterinary practice? Roo gives our advice on all things veterinary. Learn more. ",
  },
  {
    value: 4,
    urlSegment: "industry_news",
    label: "Industry News",
    name: "categoryType",
    description:
      "Get the latest news on veterinarian care, trends, and how veterinary staffing is being utilized in our field to better our practices and animal care.",
  },
  {
    value: 5,
    urlSegment: "veterinary_cases",
    label: "Veterinary Case Studies",
    name: "categoryType",
    description:
      "At Roo we are animal and veterinary professionals. Get answers to your common animal questions and read about cases related to animal care on our website.",
  },
  {
    value: 6,
    urlSegment: "roo_news",
    label: "Roo News",
    name: "categoryType",
    description:
      "Get the latest news from Roo and stay up to date with the latest developments to our veterinary staffing platform.",
  },
  {
    value: 7,
    urlSegment: "medical_success_and_rescue",
    label: "Medical Success & Rescue Stories",
    name: "categoryType",
    description:
      "At Roo we not only provide veterinary staffing solutions, we are animal lovers. Read more about our medical and rescue success stories!",
  },
  {
    value: 8,
    urlSegment: "events",
    label: "Events",
    name: "categoryType",
    description:
      "Looking for animal and rescue events in your area? Check out Roo events on our website!",
  },
];

export const contractorTypeOfBonus = [
  { value: "1", label: "First shift", name: "typeOfBonus" },
  { value: "2", label: "Core Roo Vet", name: "typeOfBonus" },
  { value: "3", label: "End of Month", name: "typeOfBonus" },
  { value: "4", label: "Special Promo", name: "typeOfBonus" },
  { value: "6", label: "Sign Up", name: "typeOfBonus" },
  { value: "7", label: "Brand Influencer", name: "typeOfBonus" },
  { value: "5", label: "Other", name: "typeOfBonus" },
] as const;

// First shift bonus is no longer initiated from admin, but can still appear in earnings statements, so we must preserve the ID
export const contractorHistoricalTypeOfBonus = [
  { value: "1", label: "First Shift", name: "typeOfBonus" },
  ...contractorTypeOfBonus,
];

export const oPostTopicOptions = createDictionary(aPostTopicOptions, "value");
export const oPostTopicOptionsbyUrlSegment = createDictionary(aPostTopicOptions, "urlSegment");

export const aShiftAdditionalPayoutOptions = [
  { value: "1", label: "Overtime", name: "shiftPayoutType" },
  { value: "2", label: "Gratuity", name: "shiftPayoutType" },
  { value: "3", label: "RTTR", name: "shiftPayoutType" },
  { value: "4", label: "Other", name: "shiftPayoutType" },
  { value: "5", label: "Reduce Charge/Payout", name: "shiftPayoutType" },
];

export const ReputationOptions = [
  { value: 1, label: "Unfavorable", name: "Unfavorable" },
  { value: 2, label: "Neutral", name: "Neutral" },
  { value: 3, label: "Favorable", name: "Favorable" },
];

export const LocationFavorabilityOptions = [
  { value: 1, label: "Unfavorable-low supply", name: "Unfavorable-low supply" },
  { value: 2, label: "Neutral", name: "Neutral" },
  { value: 3, label: "Favorable-High supply", name: "Favorable-High supply" },
];

export const aMonthsOfTheYearStart = [
  { value: "01-01", label: "Jan.", name: "invoiceStartMonth", index: 0 },
  { value: "02-01", label: "Feb.", name: "invoiceStartMonth", index: 1 },
  { value: "03-01", label: "Mar.", name: "invoiceStartMonth", index: 2 },
  { value: "04-01", label: "Apr.", name: "invoiceStartMonth", index: 3 },
  { value: "05-01", label: "May", name: "invoiceStartMonth", index: 4 },
  { value: "06-01", label: "Jun.", name: "invoiceStartMonth", index: 5 },
  { value: "07-01", label: "Jul.", name: "invoiceStartMonth", index: 6 },
  { value: "08-01", label: "Aug.", name: "invoiceStartMonth", index: 7 },
  { value: "09-01", label: "Sept.", name: "invoiceStartMonth", index: 8 },
  { value: "10-01", label: "Oct.", name: "invoiceStartMonth", index: 9 },
  { value: "11-01", label: "Nov.", name: "invoiceStartMonth", index: 10 },
  { value: "12-01", label: "Dec.", name: "invoiceStartMonth", index: 11 },
];

export const aMonthsOfTheYearEnd = [
  { value: "01-31", label: "Jan.", name: "invoiceEndMonth", index: 0 },
  { value: "02-29", label: "Feb.", name: "invoiceEndMonth", index: 1 },
  { value: "03-31", label: "Mar.", name: "invoiceEndMonth", index: 2 },
  { value: "04-31", label: "Apr.", name: "invoiceEndMonth", index: 3 },
  { value: "05-31", label: "May", name: "invoiceEndMonth", index: 4 },
  { value: "06-30", label: "Jun.", name: "invoiceEndMonth", index: 5 },
  { value: "07-31", label: "Jul.", name: "invoiceEndMonth", index: 6 },
  { value: "08-31", label: "Aug.", name: "invoiceEndMonth", index: 7 },
  { value: "09-30", label: "Sept.", name: "invoiceEndMonth", index: 8 },
  { value: "10-31", label: "Oct.", name: "invoiceEndMonth", index: 9 },
  { value: "11-30", label: "Nov.", name: "invoiceEndMonth", index: 10 },
  { value: "12-31", label: "Dec.", name: "invoiceEndMonth", index: 11 },
];

// Internship Dropdowns
export const aProgramLengthOptions = [
  { value: 1, label: "1-2 weeks", name: "expectedProgramLength" },
  { value: 2, label: "3-4 weeks", name: "expectedProgramLength" },
  { value: 3, label: "5+ weeks", name: "expectedProgramLength" },
  { value: 4, label: "Flexible", name: "expectedProgramLength" },
];
export const oProgramLengthOptions = createDictionary(aProgramLengthOptions, "value");

export const aNoOfVetsInPracticeOptions = [
  { value: 1, label: "1-2", name: "noOfVetsInPractice" },
  { value: 2, label: "3-5", name: "noOfVetsInPractice" },
  { value: 3, label: "6+", name: "noOfVetsInPractice" },
];
export const oSizefPracticeOptions = createDictionary(aNoOfVetsInPracticeOptions, "value");

export const userTypes = [
  { value: HOSPITAL_USER_TYPE, label: "Hospital", name: "userType" },
  { value: VET_USER_TYPE, label: "Vet", name: "userType" },
  { value: TECH_USER_TYPE, label: "Tech", name: "userType" },
  { value: STUDENT_USER_TYPE, label: "Student", name: "userType" },
];

export type VetShiftSortOption = { value: number; label: string; rank: Rank; name: string };

export const vetShiftSortOptions: VetShiftSortOption[] = [
  { value: 0, label: "Date", rank: "date", name: "shiftSortBy" },
  { value: 1, label: "Distance", rank: "distance", name: "shiftSortBy" },
  { value: 2, label: "Price", rank: "price", name: "shiftSortBy" },
  { value: 3, label: "Rating", rank: "rating", name: "shiftSortBy" },
  { value: 4, label: "Recommended", rank: "recommended", name: "shiftSortBy" },
];

export type TechShiftSortOption = { value: number; label: string; rank: Rank; name: string };

export const techShiftSortOptions: TechShiftSortOption[] = [
  { value: 0, label: "Date", rank: "date", name: "shiftSortBy" },
  { value: 1, label: "Distance", rank: "distance", name: "shiftSortBy" },
  { value: 2, label: "Price", rank: "price", name: "shiftSortBy" },
  { value: 3, label: "Rating", rank: "rating", name: "shiftSortBy" },
];

export const daysOfTheWeek = [
  { value: 0, label: "Sunday" },
  { value: 1, label: "Monday" },
  { value: 2, label: "Tuesday" },
  { value: 3, label: "Wednesday" },
  { value: 4, label: "Thursday" },
  { value: 5, label: "Friday" },
  { value: 6, label: "Saturday" },
];

export const monthsOfTheYear = [
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
];

export const yearQuarters = [
  { value: 1, label: "Q1" },
  { value: 2, label: "Q2" },
  { value: 3, label: "Q3" },
  { value: 4, label: "Q4" },
];

export const externshipResultsFilter = [
  { value: 1, label: "Recommended" },
  { value: 2, label: "Posted Date" },
  { value: 3, label: "Distance" },
];

export const distanceFromHome = [
  { label: "2 miles", value: "2", index: 0 },
  { label: "5 miles", value: "5", index: 1 },
  { label: "10 miles", value: "10", index: 2 },
  { label: "15 miles", value: "15", index: 3 },
  { label: "20 miles", value: "20", index: 4 },
  { label: "20+ miles", value: "20+", index: 5 },
];

export const anticipatedReliefNeedOptions = [
  {
    value: 0,
    label: "ASAP",
  },
  {
    value: 1,
    label: "In 1 month",
  },
  {
    value: 2,
    label: "In 2-3 months",
  },
  {
    value: 3,
    label: "In 4-6 months",
  },
  {
    value: 4,
    label: "In 7+ months",
  },
  {
    value: 5,
    label: "I don't know",
  },
];
