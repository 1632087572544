import { Modal, Text, FlexLayout, BoldText, Checkbox } from "@RooUI";
import React, { useState } from "react";
import { useRooTranslation } from "@Roo/Common/Wrappers/useRooTranslation";
import university from "../../src/static/images/university.svg";
import { ImageContainer, CheckboxContainer } from "./VetStudentModal.styles";

interface VetStudentModalProps {
  showRefreshButton: boolean;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  onClose?: () => void;
}

export function VetStudentModal({
  showRefreshButton,
  showModal,
  setShowModal,
  onClose,
}: VetStudentModalProps) {
  const [checkbox, setCheckbox] = useState(false);

  const { t } = useRooTranslation();

  const handleClose = () => {
    if (checkbox) {
      if (onClose) onClose();
      setShowModal(false);
    }
  };

  return (
    <Modal
      showModal={showModal}
      $size="m"
      $noClose={true}
      header={t("vetStudent.modal.title")}
      body={
        <FlexLayout vertical gap="s">
          <ImageContainer justify="center">
            <img src={university} alt="Roo University" />
          </ImageContainer>
          <FlexLayout vertical>
            <Text>{t("vetStudent.modal.description.part1")}</Text>
            <Text>&nbsp;</Text>
            <Text>{t("vetStudent.modal.description.part2")}</Text>
            <Text>&nbsp;</Text>
            <BoldText>{t("vetStudent.modal.confirmation.label")}</BoldText>
          </FlexLayout>
          <FlexLayout>
            <CheckboxContainer>
              <Checkbox
                data-testid="checkbox"
                checked={checkbox}
                onChange={(e) => {
                  const checked = e.target.checked;
                  setCheckbox(checked);
                }}
              />
            </CheckboxContainer>
            <Text>{t("vetStudent.modal.confirmation.checkbox")}</Text>
          </FlexLayout>
        </FlexLayout>
      }
      $primaryButtonProps={{
        title: t("vetStudent.modal.confirmation.continueButton"),
        size: "small",
        onClick: handleClose,
        disabled: !checkbox,
      }}
      $secondaryButtonProps={
        showRefreshButton
          ? {
              title: t("vetStudent.modal.confirmation.refreshButton"),
              size: "small",
              onClick: () => {
                window.location.reload();
              },
            }
          : undefined
      }
      $buttonVariant="confirmation"
    />
  );
}
