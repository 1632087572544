// @ts-expect-error TS7006
export function processNotesDBdata(oNotesDB) {
  // @ts-expect-error TS7034
  const notes = [];
  // @ts-expect-error TS7006
  oNotesDB.data.map((note) => {
    notes[note.noteAddedOn] = note;
  });
  // @ts-expect-error TS7005
  return notes;
}
