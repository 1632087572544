import * as C from "../../../constants/adminEventConstants";
// @ts-expect-error TS6133
import * as ConfirmMessage from "../../../constants/confirmMessage";
// @ts-expect-error TS6133
import * as ErrorMessage from "../../../constants/errorMessage";

const initialState = {
  isEventAdded: false,
  isEventAddFailed: false,
  isListFetched: false,
  lastInsertedId: null,
  eventList: [],
};

/* eslint-disable default-param-last */
// @ts-expect-error TS7006
const EventReducer = (state = initialState, action) => {
  /* eslint-enable default-param-last */
  switch (action.type) {
    case C.ADD_EVENT:
      return {
        ...state,
        isEventAddFailed: false,
        isEventAdded: false,
        payload: action.payload,
      };
    case C.ADD_EVENT_FAIL:
      return {
        ...state,
        isEventAddFailed: true,
        isEventAdded: false,
        payload: action.payload,
      };
    case C.ADD_EVENT_SUCCESS:
      return {
        ...state,
        isEventAdded: true,
        isEventAddFailed: false,
        lastInsertedId: action.payload.insertId,
        payload: action.payload,
      };
    case C.GET_EVENT:
      return {
        ...state,
        isListFetched: false,
        payload: action.payload,
      };
    case C.GET_EVENT_FAIL:
      return {
        ...state,
        isListFetched: false,
        eventList: [],
      };
    case C.GET_EVENT_SUCCESS:
      return {
        ...state,
        isListFetched: true,
        eventList: action.payload,
      };
    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default EventReducer;
