import { grayWhite } from "@RooUI";
import { Tabs } from "antd";
import React, { useEffect, useMemo } from "react";
import StickyBox from "react-sticky-box";

import { useShiftDetailsStore, useToastStore } from "@RooBeta/store";

import { HospitalInfo } from "./HospitalInfo";
import { HospitalReviews } from "./HospitalReviews";
import { HospitalShifts } from "./HospitalShifts";
import { ShiftInfo } from "./ShiftInfo";
import { Z_INDEX } from "@Roo/constants/zIndex";

export const ShiftDetailsTabs = () => {
  const { tab, setTab } = useShiftDetailsStore();
  const { hide } = useToastStore();

  const items = useMemo(() => {
    return [
      {
        key: "info",
        label: <div data-testid="shiftInfoTab">Shift Info</div>,
        children: <ShiftInfo />,
      },
      {
        key: "hospital",
        label: <div data-testid="hospitalTab">Hospital</div>,
        children: <HospitalInfo />,
      },
      {
        key: "reviews",
        label: <div data-testid="reviewsTab">Reviews</div>,
        children: <HospitalReviews />,
      },
      {
        key: "shifts",
        label: <div data-testid="allShiftsTab">All Shifts</div>,
        children: <HospitalShifts />,
      },
    ];
  }, []);

  useEffect(() => {
    return () => hide();
  }, [hide]);

  return (
    <Tabs
      activeKey={tab}
      defaultActiveKey={"info"}
      items={items}
      onChange={setTab}
      renderTabBar={(props, DefaultTabBar) => (
        <StickyBox
          offsetTop={0}
          style={{ zIndex: Z_INDEX.SHIFT_DETAILS_TABS, background: grayWhite }}
        >
          <DefaultTabBar {...props} />
        </StickyBox>
      )}
      centered
    />
  );
};
