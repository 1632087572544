import { Button, Modal, Text } from "@RooUI";
import React from "react";
import { useHistory } from "react-router-dom";

import { PasswordInput } from "../../../Common/Form/PasswordInput";
import { FORGOT_PASSWORD_SUCCESS } from "../../../constants/confirmMessage";
import HeaderLogIn from "../../HeaderLogIn";

import { useFormContext } from "./resetPasswordFormContext";

interface FormData {
  password: string;
  passwordConfirm: string;
}

interface FormContext {
  form: FormData;
  onChange: (field: keyof FormData, value: string) => void;
  onSave: () => void;
  errors: Record<string, string>;
  loading: boolean;
}

export const ResetPasswordComponent = ({ success }: { success?: boolean }) => {
  const { form, onChange, onSave, errors = {}, loading = false } = useFormContext() as FormContext;

  const history = useHistory();

  return (
    <>
      <div className="page-wrapper">
        <div className="login inner-container d-flex align-items-center align-items-sm-start">
          <HeaderLogIn />
          <div className="container">
            <div className="d-flex justify-content-center">
              <div className="col-md-8 col-lg-6 px-0">
                <div className="well forgot-password-box">
                  <h5 className="form-title text-center">Create New Password</h5>
                  <div className="form-group required">
                    <PasswordInput
                      name="password"
                      label="New Password"
                      password={form.password}
                      error={errors.password}
                      onChange={(value) => onChange("password", value)}
                    />
                  </div>
                  <div className="form-group pt-3 required">
                    <PasswordInput
                      name="passwordConfirm"
                      label="Re-enter New Password"
                      password={form.passwordConfirm}
                      error={errors.passwordConfirm}
                      onChange={(value) => onChange("passwordConfirm", value)}
                    />
                  </div>
                  <div className="d-flex justify-content-center pt-5">
                    <Button
                      title="SUBMIT"
                      $buttonType="primary"
                      onClick={onSave}
                      loading={loading}
                      data-testid="resetPassword"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        showModal={Boolean(success)}
        onCloseModal={() => history.push("/login")}
        $size="m"
        body={
          <div className="d-flex flex-column align-items-center">
            <Text data-testid="resetPasswordSuccess">{FORGOT_PASSWORD_SUCCESS}</Text>
          </div>
        }
        $primaryButtonProps={{
          title: "Okay",
          $buttonType: "primary",
          onClick: () => history.push("/login"),
        }}
      />
    </>
  );
};
