import moment from "moment";
import { DELIMITER_BACKTICK, SECTION_PREFIXES } from "./messageParser";
import { ChipId } from "@RooBeta/types";

/**
 * Converts an object of URL parameters to a query string without encoding commas
 */
export function buildQueryString(params: Record<string, string | number | boolean>): string {
  return Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
}

export function formatAvailableDatesSection({
  dates,
  linkText,
  linkUrl,
}: {
  dates: Date[];
  linkText: string;
  linkUrl: string;
}): string {
  if (!dates.length) {
    return "";
  }

  return (
    `\n${DELIMITER_BACKTICK}${SECTION_PREFIXES.AVAILABLE_DATES}\n` +
    dates
      .sort((a, b) => a.getTime() - b.getTime())
      .map((date) => moment(date).format("MMM Do"))
      .join("\n") +
    `\n${linkText}|${linkUrl}\n${DELIMITER_BACKTICK}`
  );
}

export function formatAvailableDatesAddShiftLink({
  userId,
  availableDates,
  shiftProposalId,
}: {
  userId: number;
  availableDates: Date[];
  shiftProposalId: number;
}): string {
  const formattedDates = availableDates.map((date) => moment(date).format("YYYY-MM-DD")).join(",");

  // Store all parameters in an object for better readability and maintainability
  const params = {
    vetUserId: userId,
    shiftProposalId: shiftProposalId,
    availableDates: formattedDates,
  };

  return `${window.location.origin}/hospital/addShift?${buildQueryString(params)}`;
}

export function formatChipLink({ chipId }: { chipId: ChipId }): string {
  const params = {
    "filters[chipId]": chipId as string,
    sharedSearch: true,
  };

  return `${window.location.origin}/vet?${buildQueryString(params)}`;
}

export function formatLinkSection({ text, url }: { text: string; url: string }): string {
  return (
    `\n${DELIMITER_BACKTICK}${SECTION_PREFIXES.LINK}\n` + `${text}|${url}\n` + DELIMITER_BACKTICK
  );
}
