const initialState = {
  onboardingInfo: [],
  isLoaded: false,
};

/* eslint-disable default-param-last */
// @ts-expect-error TS7006
const vetOnboardingReducer = (state = initialState, action) => {
  /* eslint-enable default-param-last */
  switch (action.type) {
    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default vetOnboardingReducer;
