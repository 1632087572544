import { Action } from "redux";

import * as ActionTypes from "../../../constants/addNewCalendarNote";

type InitialState = {
  notesAdded: boolean;
  notesDeleted: boolean;
};

type CalenderActionType =
  | typeof ActionTypes.ADD_CALENDAR_NOTE_SUCCESS
  | typeof ActionTypes.ADD_CALENDAR_NOTE_FAIL
  | typeof ActionTypes.DELETE_CALENDAR_NOTE_SUCCESS
  | typeof ActionTypes.DELETE_CALENDAR_NOTE_FAIL
  | typeof ActionTypes.INITIALIZE_CALENDAR_NOTE;

const initialState: InitialState = {
  // @ts-expect-error TS2322
  notesAdded: null,
  // @ts-expect-error TS2322
  notesDeleted: null,
};

// eslint-disable-next-line default-param-last
const addCalendarNoteReducer = (state = initialState, action: Action<CalenderActionType>) => {
  switch (action.type) {
    case ActionTypes.ADD_CALENDAR_NOTE_SUCCESS:
      return {
        ...state,
        notesAdded: true,
      };
    case ActionTypes.ADD_CALENDAR_NOTE_FAIL:
      return {
        ...state,
        notesAdded: false,
      };
    case ActionTypes.DELETE_CALENDAR_NOTE_SUCCESS:
      return {
        ...state,
        notesDeleted: true,
      };
    case ActionTypes.DELETE_CALENDAR_NOTE_FAIL:
      return {
        ...state,
        notesDeleted: false,
      };
    case ActionTypes.INITIALIZE_CALENDAR_NOTE:
      return {
        ...state,
        notesAdded: null,
        notesDeleted: null,
      };
    default:
      return {
        ...state,
      };
  }
};

// eslint-disable-next-line no-restricted-exports
export default addCalendarNoteReducer;
