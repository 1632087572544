import React, { useMemo, useCallback, useState, useEffect } from "react";
import { useRooTranslation } from "@Roo/Common/Wrappers/useRooTranslation";
import { Modal } from "@RooUI";
import { useSendMessage } from "@Roo/hooks/api/useSendMessage";
import { MESSAGE_WEB_CHANNEL_ID } from "@Roo/constants/messageConstants";
import {
  formatAvailableDatesAddShiftLink,
  formatAvailableDatesSection,
} from "@Roo/Common/Chat/Utils/messageFormatter";
import { VET_USER_TYPE } from "@Roo/constants/UserTypeConstants";
import * as Sentry from "@sentry/react";
import { HospitalSelect } from "./HospitalSelect";
import { VetAvailability } from "./VetAvailability";
import { ShiftProposalMessageComposer } from "./MessageComposer";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";
import { useUpdateShiftProposal } from "@Roo/hooks/api/useUpdateShiftProposal";
import { useGetShiftProposals } from "@RooBeta/hooks/api/useGetShiftProposals";
import { useCreateShiftProposal } from "@RooBeta/hooks/api/useCreateShiftProposal";
import { useOpenChatNotifications } from "@Roo/hooks/useOpenChatNotifications";
import { useDeleteShiftProposal } from "@RooBeta/hooks/api/useDeleteShiftProposal";
import { Event, EventTracker } from "@Roo/tracking/service/EventTracker/EventTrackerService";
import { Messaging } from "@roo-dev/roo-node-types";

type Step = "hospitalSelect" | "availability" | "chat" | "complete";

export interface ShiftProposalModalManagerProps {
  onClose: () => void;
  onComplete: () => void;
  hospitalIds?: number[];
  source?: "shiftList" | "hospitalAllShifts" | "backfill";
  onModalRendered?: () => void;
}

export const ShiftProposalModalManager = ({
  onClose,
  onComplete,
  hospitalIds = [],
  source,
  onModalRendered,
}: ShiftProposalModalManagerProps) => {
  const isBackfill = !hospitalIds.length;

  const { data: shiftProposals, refetch: refetchShiftProposals } = useGetShiftProposals("active");
  const { vetId, userId, firstName, lastName, emailId } = useRequiredAuthorizedUser();
  const { t } = useRooTranslation();
  const { mutate: updateShiftProposal } = useUpdateShiftProposal();
  const { mutateAsync: createShiftProposal } = useCreateShiftProposal();
  const { openChatNotifications } = useOpenChatNotifications();
  const { mutate: deleteShiftProposal } = useDeleteShiftProposal();

  const [currentStep, setCurrentStep] = useState<Step>(
    isBackfill ? "hospitalSelect" : "availability"
  );
  const [selectedHospitalIds, setSelectedHospitalIds] = useState<number[]>(
    isBackfill ? [] : hospitalIds
  );
  const [message, setMessage] = useState(t("shiftProposal.modal.defaultMessage"));
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);

  const hasLegacyShiftProposals =
    shiftProposals && shiftProposals.some((proposal) => !proposal.commsSentOn);

  useEffect(() => {
    if (hasLegacyShiftProposals || hospitalIds.length) {
      EventTracker.send({
        eventType: Event.Type.MODAL,
        eventName: Event.Name.SHIFT_PROPOSAL_MODAL_VIEW,
        entityId: vetId ?? undefined,
        context: {
          hospitalIds: selectedHospitalIds,
          step: currentStep,
          source,
        },
      });
    }
  }, [
    vetId,
    isBackfill,
    currentStep,
    hasLegacyShiftProposals,
    hospitalIds.length,
    source,
    selectedHospitalIds,
  ]);

  const handleClose = useCallback(() => {
    EventTracker.send({
      eventType: Event.Type.MODAL,
      eventName: Event.Name.SHIFT_PROPOSAL_MODAL_DISMISS,
      entityId: vetId ?? undefined,
      context: {
        hospitalIds: selectedHospitalIds,
        step: currentStep,
        source,
      },
    });
    onClose();
  }, [onClose, vetId, selectedHospitalIds, currentStep, source]);

  const handleHospitalSelect = useCallback((hospitalIds: number[]) => {
    setSelectedHospitalIds(hospitalIds);
  }, []);

  const handleNext = useCallback(() => {
    switch (currentStep) {
      case "hospitalSelect":
        setCurrentStep("availability");
        break;
      case "availability":
        setCurrentStep("chat");
        break;
      case "chat":
        setCurrentStep("complete");
        onComplete();
        break;
    }
  }, [currentStep, onComplete]);

  const handlePrevious = useCallback(() => {
    switch (currentStep) {
      case "availability":
        if (isBackfill) {
          setCurrentStep("hospitalSelect");
        } else {
          handleClose();
        }
        break;
      case "chat":
        setCurrentStep("availability");
        break;
      case "hospitalSelect":
        handleClose();
        break;
    }
  }, [currentStep, handleClose, isBackfill]);

  const { mutateAsync: sendMessageMutation, isLoading } = useSendMessage({
    onSuccess: async (status) => {
      if (status && "sent" in status) {
        if (status.successHospitalIds.length > 0) {
          const refetchedShiftProposalsResponse = await refetchShiftProposals();
          const refetchedShiftProposals = refetchedShiftProposalsResponse.data;
          status.successHospitalIds.forEach(async (hospitalId) => {
            const proposal = refetchedShiftProposals?.find(
              (proposal) => proposal.hospitalId === hospitalId
            );
            if (proposal) {
              updateShiftProposal({
                shiftProposalId: proposal.shiftProposalId,
                commsSent: true,
              });
            }
          });
        }

        // Only proceed if all messages were successful
        if (status.sent === status.total && status.failed === 0) {
          // Open chat notifications popover with latest conversations
          if (source === "shiftList" || source === "backfill") {
            openChatNotifications();
          }

          setMessage("");

          if (isBackfill) {
            // Delete legacy shift proposals for hospitals that were not selected
            const nonSelectedHospitalIds = shiftProposals?.filter(
              (proposal) =>
                !selectedHospitalIds.includes(proposal.hospitalId) && !proposal.commsSentOn
            );
            if (nonSelectedHospitalIds?.length) {
              nonSelectedHospitalIds.forEach((hospital) => {
                deleteShiftProposal({ shiftProposalId: hospital.shiftProposalId });
              });
            }
          }
        }

        handleNext();
      }
    },
    onError: (error) => {
      Sentry.captureException(error);
    },
  });

  const handleSend = useCallback(async () => {
    if (!message.trim() || !selectedHospitalIds.length || !vetId || !selectedDates.length) {
      return;
    }

    for (const hospitalId of selectedHospitalIds) {
      let newShiftProposalId: number;
      const shiftProposal = shiftProposals?.find((proposal) => proposal.hospitalId === hospitalId);
      if (!shiftProposal) {
        const shiftProposalId = await createShiftProposal({
          hospitalId,
          commsSent: false,
        });
        newShiftProposalId = shiftProposalId;
      } else {
        newShiftProposalId = shiftProposal.shiftProposalId;
      }

      const dateSection = formatAvailableDatesSection({
        dates: selectedDates,
        linkText: t("shiftProposal.modal.addShiftLink", { lastName }),
        linkUrl: formatAvailableDatesAddShiftLink({
          userId,
          shiftProposalId: newShiftProposalId,
          availableDates: selectedDates,
        }),
      });

      EventTracker.send({
        eventType: Event.Type.MODAL,
        eventName: Event.Name.VET_SHIFT_PROPOSAL_SUBMIT,
        entityId: vetId ?? undefined,
        context: {
          hospitalId,
          source,
          message,
          dates: selectedDates,
        },
      });

      await sendMessageMutation({
        hospitalIds: [hospitalId],
        messageContent: message.trim() + dateSection,
        channelTypeId: MESSAGE_WEB_CHANNEL_ID,
        vetUserId: userId,
        email: emailId,
        fullName: `${firstName} ${lastName}`,
        userTypeId: VET_USER_TYPE,
        messageType: Messaging.MessageType.VET_AVAILABILITY,
      });
    }
  }, [
    sendMessageMutation,
    message,
    selectedHospitalIds,
    userId,
    firstName,
    lastName,
    vetId,
    emailId,
    selectedDates,
    t,
    shiftProposals,
    source,
    createShiftProposal,
  ]);

  const modalConfig = useMemo(() => {
    const getStepConfig = () => {
      switch (currentStep) {
        case "hospitalSelect":
          return {
            header: t("shiftProposal.modal.headers.sendAvailability"),
            description: t("shiftProposal.modal.headers.descriptions.sendAvailability"),
            content: (
              <HospitalSelect
                selectedHospitalIds={selectedHospitalIds}
                onHospitalSelect={handleHospitalSelect}
              />
            ),
            primaryButton: {
              title: t("shiftProposal.modal.buttons.nextAvailability"),
              onClick: handleNext,
              disabled: selectedHospitalIds.length === 0,
            },
            secondaryButton: {
              title: t("shiftProposal.modal.buttons.cancel"),
              onClick: handlePrevious,
            },
          };
        case "availability":
          return {
            header: t("shiftProposal.modal.headers.whenAvailable"),
            description: t("shiftProposal.modal.headers.descriptions.whenAvailable"),
            content: <VetAvailability onDatesChange={setSelectedDates} />,
            primaryButton: {
              title: t("shiftProposal.modal.buttons.nextMessage"),
              onClick: handleNext,
              disabled: selectedDates.length === 0,
            },
            secondaryButton: {
              title: isBackfill
                ? t("shiftProposal.modal.buttons.previous")
                : t("shiftProposal.modal.buttons.cancel"),
              onClick: handlePrevious,
            },
          };
        case "chat":
          return {
            header: t("shiftProposal.modal.headers.customizeMessage"),
            description: t("shiftProposal.modal.headers.descriptions.customizeMessage"),
            content: <ShiftProposalMessageComposer message={message} setMessage={setMessage} />,
            primaryButton: {
              title: t("shiftProposal.modal.buttons.sendChat"),
              onClick: handleSend,
              disabled: !message.trim() || isLoading || selectedHospitalIds.length === 0 || !vetId,
            },
            secondaryButton: {
              title: t("shiftProposal.modal.buttons.previous"),
              onClick: handlePrevious,
            },
          };
        default:
          return null;
      }
    };

    const config = getStepConfig();
    return config
      ? {
          ...config,
          showModal: true,
          onCloseModal: handleClose,
        }
      : null;
  }, [
    currentStep,
    vetId,
    selectedHospitalIds,
    message,
    isLoading,
    handleNext,
    handlePrevious,
    handleHospitalSelect,
    handleSend,
    handleClose,
    t,
    selectedDates.length,
    isBackfill,
  ]);

  if (!hasLegacyShiftProposals && !hospitalIds.length) {
    return null;
  }

  if (!modalConfig) {
    return null;
  }

  onModalRendered?.();

  const getEyebrowText = () => {
    let step: number;
    const totalSteps = 3;
    const subtractStep = isBackfill ? 0 : 1;
    switch (currentStep) {
      case "hospitalSelect":
        step = 1;
        break;
      case "availability":
        step = 2;
        break;
      case "chat":
        step = 3;
        break;
      default:
        return "";
    }
    return `Step ${step - subtractStep} of ${totalSteps - subtractStep}`;
  };

  return (
    <Modal
      onCloseModal={modalConfig.onCloseModal}
      showModal={modalConfig.showModal}
      header={modalConfig.header}
      description={modalConfig.description}
      $buttonVariant="wizard"
      $primaryButtonProps={modalConfig.primaryButton}
      $secondaryButtonProps={modalConfig.secondaryButton}
      body={modalConfig.content}
      eyebrowText={getEyebrowText()}
    />
  );
};
