import React from "react";
import { BodyText, grayLight400, grayLightest, Button, spacingL, Container } from "@RooUI";
import styled from "styled-components";
import { VStack } from "../Flex";
import { RooIcon } from "../RooIcon";
import { useFavoriteHospital } from "../shiftDetails/shiftDetails/useFavoriteHospital";
import { useGetShiftProposals } from "@RooBeta/hooks/api/useGetShiftProposals";
import { useImpressionTracking } from "@RooBeta/hooks/tracking/useImpressionTracking";
import { Event } from "@Roo/tracking/service/EventTracker/EventTrackerService";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";
import { isRooFeatureEnabled } from "@Roo/Common/Wrappers/isRooFeatureEnabled";
import { FEATURE_FLAGS } from "@Roo/constants/postHogFeatureFlags";
import { useShiftProposalModals } from "./useShiftProposalModals";
import { useGetWorkedHospitals } from "@Roo/hooks/api/useGetWorkedHospitals";

export const HospitalShiftProposal = ({ hospitalId }: { hospitalId: number }) => {
  const { vetId } = useRequiredAuthorizedUser();
  const isVet = typeof vetId === "number";
  const favoriteHospital = useFavoriteHospital({ hospitalId });
  const { data: shiftProposals } = useGetShiftProposals("active");
  const shiftProposalId = shiftProposals?.find(
    (proposal) => proposal.hospitalId === hospitalId
  )?.shiftProposalId;
  const hasShiftProposal = typeof shiftProposalId === "number";
  const isShiftProposalEnabled = isRooFeatureEnabled(FEATURE_FLAGS.SHIFT_PROPOSAL);
  const { openShiftProposalModal, isModalLoading } = useShiftProposalModals();
  const { data: workedHospitals } = useGetWorkedHospitals(1);
  const hasWorkedAtHospital = workedHospitals?.some(
    (hospital) => hospital.hospitalId === hospitalId
  );

  const ref = useImpressionTracking({
    event: {
      eventName: Event.Name.VET_SHIFT_PROPOSAL_HOSPITAL_CARD_VIEW,
      eventType: Event.Type.IMPRESSION,
      entityType: Event.Entity.VET,
      entityId: vetId ?? undefined,
      context: {
        hospitalId,
        source: "hospitalAllShifts",
      },
    },
  });

  const onClickInterest = () => {
    openShiftProposalModal({ hospitalIds: [hospitalId], source: "hospitalAllShifts" });
  };

  return (
    <Container ref={ref} backgroundColor={grayLightest} borderColor={grayLightest}>
      <VStack $alignItems="center" $gap="m">
        <TextContainer>
          <Body>
            Favorite this hospital to get notified when they post new shifts, or let them know your
            available dates so they know to post more shifts.
          </Body>
        </TextContainer>
        <VStack $gap="l" $justifyContent="center" $alignItems="center" $width="100%">
          <Button onClick={favoriteHospital.toggle} data-testid="favoriteButton" $fullWidth>
            <RooIcon
              icon={favoriteHospital.isFavorited ? "favorite_filled" : "favorite"}
              data-testid={`rooIconFavorite${favoriteHospital.isFavorited ? "Filled" : ""}`}
            />
            Favorite
          </Button>
          {isVet && hasWorkedAtHospital && isShiftProposalEnabled ? (
            <Button
              data-testid="interestedButton"
              onClick={onClickInterest}
              $fullWidth
              disabled={hasShiftProposal}
              loading={isModalLoading}
            >
              {hasShiftProposal ? (
                <>
                  <RooIcon icon="check" />
                  Availability Sent
                </>
              ) : (
                "Send Availability"
              )}
            </Button>
          ) : null}
        </VStack>
      </VStack>
    </Container>
  );
};

const Body = styled.div`
  ${BodyText}
  color: ${grayLight400};
  text-align: center;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: ${spacingL};
  text-align: center;
`;
