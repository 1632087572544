export const setWebAppVersion = (version: number): void => {
  if (version > 0) {
    localStorage.setItem("webAppVersion", version.toString());
  }
};

export const getWebAppVersion = (): number | null => {
  const version = localStorage.getItem("webAppVersion");
  return version ? parseInt(version, 10) : null;
};

export const clearWebAppVersion = (): void => {
  localStorage.removeItem("webAppVersion");
};

export const isWebAppVersionOutdated = (currentWebAppVersion: number): boolean => {
  const localWebAppVersion = getWebAppVersion();

  return localWebAppVersion === null || currentWebAppVersion > localWebAppVersion;
};
