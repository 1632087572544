import { create } from "zustand";

interface ScrollStore {
  scrollDirection: "up" | "down";
  setScrollDirection: (scrollDirection: "up" | "down") => void;
}

export const useScrollStore = create<ScrollStore>((set) => ({
  scrollDirection: "up",

  setScrollDirection: (scrollDirection: "up" | "down") => {
    set({ scrollDirection });
  },
}));
