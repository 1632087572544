import { Modal, Text } from "@RooUI";
import React from "react";
import { useHistory } from "react-router-dom";

import { useModal } from "@Roo/Common/Modals/hooks/useModal";

export const PendingModal = ({ isVet }: { isVet: boolean }) => {
  const { closeModal } = useModal();
  const history = useHistory();

  const routeToProfile = () => {
    history.push(`${isVet ? "/vet" : "/tech"}/editprofile`);
    closeModal();
  };

  const headerText = isVet
    ? "Complete your activation call"
    : "You're on your way to getting started";

  const bodyText = isVet
    ? "Your activation call is scheduled. Once you complete the call you can request shifts. In the meantime, get a hop start by completing your profile to improve your chances of landing top shifts."
    : "Once your virtual interview has been reviewed, you'll be notified about next steps, including scheduling your activation call. In the meantime, complete your profile to improve your chances of landing top shifts.";

  return (
    <Modal
      showModal={true}
      $noClose
      $size="m"
      header={headerText}
      body={<Text>{bodyText}</Text>}
      $primaryButtonProps={{
        title: "Complete Profile",
        onClick: routeToProfile,
      }}
      $buttonVariant="confirmation"
      data-testid="pendingActivationCallModal"
    />
  );
};
