import * as Sentry from "@sentry/react";
import { call, put, takeEvery } from "redux-saga/effects";

import { rooFetch } from "../../../api/common/rooFetch";
import * as internshipActions from "../../../constants/adminInternships";
import { EXCEPTION_MESSAGE } from "../../../constants/errorMessage";
import * as LoaderActions from "../../../constants/loader";

// @ts-expect-error TS7006
export function* getInternshipList(action) {
  try {
    const {
      startDate,
      endDate,
      stateId,
      isNonRooStates,
      metroAreaId,
      isNoMetroAreaOnly,
      isOpen,
      isClosed,
      isRemoved,
      isShowFulfilledOnly,
      isShowUnfulfilledOnly,
    } = action.payload;

    const params = { startDate, endDate };
    if (stateId) {
      // @ts-expect-error TS2339
      params.stateId = stateId;
    }
    if (isNonRooStates) {
      // @ts-expect-error TS2339
      params.isNonRooStates = 1;
    }
    if (metroAreaId) {
      // @ts-expect-error TS2339
      params.metroAreaId = metroAreaId;
    }
    if (isNoMetroAreaOnly) {
      // @ts-expect-error TS2339
      params.isNoMetroAreaOnly = 1;
    }
    if (isOpen) {
      // @ts-expect-error TS2339
      params.isOpen = 1;
    }
    if (isClosed) {
      // @ts-expect-error TS2339
      params.isClosed = 1;
    }
    if (isRemoved) {
      // @ts-expect-error TS2339
      params.isRemoved = 1;
    }
    if (isShowFulfilledOnly) {
      // @ts-expect-error TS2339
      params.isShowFulfilledOnly = 1;
    }
    if (isShowUnfulfilledOnly) {
      // @ts-expect-error TS2339
      params.isShowUnfulfilledOnly = 1;
    }

    // @ts-expect-error TS7057
    const response = yield call(rooFetch, `${window.RooConfig.API_URL}api/admin/getInternships`, {
      method: "POST",
      body: JSON.stringify(params),
    });

    // @ts-expect-error TS7057
    const responseData = yield response.json();

    yield put({ type: LoaderActions.UNSET_LOADER });
    if (responseData.status_code === 200) {
      yield put({
        type: internshipActions.GET_INTERNSHIP_LIST_SUCCESS,
        payload: {
          data: responseData.data.internships,
        },
      });
    } else {
      yield put({
        type: internshipActions.GET_INTERNSHIP_LIST_FAIL,
        payload: { status: responseData.status_code, message: EXCEPTION_MESSAGE },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting internship list: " } });
    yield put({
      type: internshipActions.GET_INTERNSHIP_LIST_FAIL,
      payload: { status: error.status_code, message: error.message },
    });
  }
}

// @ts-expect-error TS7006
function* updateInternshipAsync(action) {
  try {
    const { internshipId } = action.payload;
    delete action.payload.internshipId;
    yield put({ type: LoaderActions.SET_LOADER });
    // @ts-expect-error TS7057
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/hospital/internship/updateInternship/${internshipId}`,
      {
        method: "PUT",
        body: JSON.stringify(action.payload),
      }
    );

    // @ts-expect-error TS7057
    const data = yield response.json();
    yield put({ type: LoaderActions.UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: internshipActions.UPDATE_INTERNSHIP_SUCCESS,
        payload: { status: data.status_code },
      });
    } else {
      yield put({
        type: internshipActions.UPDATE_INTERNSHIP_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while updating internship: " } });
    yield put({
      type: internshipActions.UPDATE_INTERNSHIP_FAIL,
      payload: { status: error.status_code, message: EXCEPTION_MESSAGE },
    });
  }
}

// @ts-expect-error TS7006
function* deleteInternshipAsync(action) {
  try {
    yield put({ type: LoaderActions.SET_LOADER });
    // @ts-expect-error TS7057
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/hospital/internship/deleteInternship`,
      {
        method: "PUT",
        body: JSON.stringify(action.payload),
      }
    );

    // @ts-expect-error TS7057
    const data = yield response.json();
    yield put({ type: LoaderActions.UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: internshipActions.DELETE_INTERNSHIP_SUCCESS,
        payload: { status: data.status_code },
      });
    } else {
      yield put({
        type: internshipActions.DELETE_INTERNSHIP_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while deleting internship: " } });
    yield put({
      type: internshipActions.DELETE_INTERNSHIP_FAIL,
      payload: { status: error.status_code, message: EXCEPTION_MESSAGE },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootAdminShiftSaga() {
  yield takeEvery(internshipActions.GET_INTERNSHIP_LIST, getInternshipList);
  yield takeEvery(internshipActions.UPDATE_INTERNSHIP_REQUEST, updateInternshipAsync);
  yield takeEvery(internshipActions.DELETE_INTERNSHIP_REQUEST, deleteInternshipAsync);
}
