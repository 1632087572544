import { z } from "zod";

import { getUserInfo } from "@Roo/api/user/useGetUserInfo";
import { AuthUtility } from "@Roo/helpers/AuthUtility";
import { AuthenticatedUser, UserType } from "@RooBeta/types";
import { setWebAppVersion } from "@Roo/utility/services";

export const authenticate = async (): Promise<AuthenticatedUser | null> => {
  const session = await AuthUtility.getCurrentSession();

  if (!session) {
    return null;
  }

  const payload = session.getIdToken().decodePayload();

  const schema = z.object({
    email: z.string(),
    userTypeId: z.coerce.number(),
  });

  let { email, userTypeId } = schema.parse({
    email: payload.email,
    userTypeId: payload["custom:usertype"],
  });

  const isSuperUser = userTypeId === UserType.Super;

  if (isSuperUser) {
    const localStorageAuthentication = schema.parse({
      email: localStorage.getItem("email"),
      userTypeId: localStorage.getItem("userTypeId"),
    });

    email = localStorageAuthentication.email;
    userTypeId = localStorageAuthentication.userTypeId;
  }

  const {
    data: {
      results: [user],
      webAppVersion,
    },
  } = await getUserInfo({ email, userTypeId });

  if (webAppVersion) {
    setWebAppVersion(webAppVersion);
  }

  if (user.userTypeId === UserType.Vet) {
    return { ...user, provider: { providerType: "VET", providerId: user.vetId! }, isSuperUser };
  } else if (user.userTypeId === UserType.Tech) {
    return { ...user, provider: { providerType: "TECH", providerId: user.techId! }, isSuperUser };
  } else if (user.userTypeId === UserType.Hospital) {
    return {
      ...user,
      provider: { providerType: "HOSPITAL", providerId: user.userId! },
      isSuperUser,
    };
  } else {
    return null;
  }
};
