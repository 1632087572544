import { ConfigProvider, Slider } from "antd";
import React from "react";
import styled from "styled-components";

import {
  blueBackground,
  blueDark600,
  blueLight400,
  grayLight200,
  grayLightest,
  primaryFont,
} from "../../styles/constants";

import { SliderProps } from ".";

export const StyledSlider = (props: SliderProps) => {
  const isWhiteVariant = props.type === "white";
  const railBg = isWhiteVariant ? grayLight200 : blueBackground;
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimaryBorderHover: blueLight400,
          fontFamily: primaryFont,
        },
        components: {
          Slider: {
            trackBg: blueDark600,
            trackHoverBg: blueLight400,
            railBg,
            railHoverBg: grayLightest,
            handleColor: blueDark600,
            handleActiveColor: blueDark600,
            dotBorderColor: grayLightest,
            dotActiveBorderColor: blueDark600,
            handleSize: 24,
            handleSizeHover: 24,
            railSize: 8,
            dotSize: 16,
            margin: 0,
          },
        },
      }}
    >
      <CustomSlider {...props} tooltip={{ color: blueDark600, ...props.tooltip }} />
    </ConfigProvider>
  );
};

const CustomSlider = styled(Slider)<SliderProps>`
  margin-top: 0px;

  .ant-slider-rail,
  .ant-slider-track,
  .ant-slider-step {
    border-radius: 16px !important;
  }
`;
