import { HospitalDocument } from "@Roo/hooks/api/hospitalDocuments";

export const SECTION_PREFIXES = {
  AVAILABLE_DATES: "availableDates",
  LINK: "link",
  ONBOARDING_DOCUMENTS: "onboardingDocuments",
} as const;

const SECTION_PREFIXES_VALUES = Object.values(SECTION_PREFIXES).join("|");
export const DELIMITER_BACKTICK = "```";
type SectionPrefixValues = (typeof SECTION_PREFIXES)[keyof typeof SECTION_PREFIXES];

type MessageType = "text" | SectionPrefixValues;

interface MessagePart {
  type: MessageType;
  content: string;
}

interface ParsedDateSection {
  dates: string[];
  link: {
    text: string;
    url: string;
  };
}

type ParsedOnboardingDocumentsSection = {
  name: string;
  url: string;
}[];

/**
 * Parses a message string into an array of parts, separating regular text from available dates sections.
 * Available dates sections are denoted by ```availableDates content``` syntax.
 *
 * Format for availableDates content:
 * Date 1
 * Date 2
 * ...
 * Link Text|URL
 *
 * @param message The message string to parse
 * @returns Array of message parts with type and content
 *
 * @example
 * const message = `Hello\n\`\`\`${SECTION_PREFIXES.AVAILABLE_DATES}\nFeb 18th\nFeb 24th\nAdd Shift|/shifts/new\`\`\`\nGoodbye`;
 * const parts = parseMessage(message);
 * // Returns:
 * // [
 * //   { type: 'text', content: 'Hello\n' },
 * //   { type: 'availableDates', content: 'Feb 18th\nFeb 24th\nAdd Shift|/shifts/new' },
 * //   { type: 'text', content: '\nGoodbye' }
 * // ]
 */
export function parseMessage(message: string): MessagePart[] {
  // Split message into regular text and sections
  const parts = message.split(
    new RegExp(
      `(${DELIMITER_BACKTICK}(?:${SECTION_PREFIXES_VALUES})[\\s\\S]*?${DELIMITER_BACKTICK})`
    )
  );
  // Explanation of the regex:
  // 1. `DELIMITER_BACKTICK` → Matches the opening backtick (```).
  // 2. `(?: ... )` → Creates a non-capturing group.
  // 3. `${SECTION_PREFIXES_VALUES}` → Matches one of the predefined section prefixes (e.g., "AVAILABLE_DATES" or "LINK").
  // 4. `[\\s\\S]*?` → Matches any characters (whitespace or non-whitespace),
  //    but in a **lazy** (non-greedy) way to stop at the next closing backtick.
  // 5. `DELIMITER_BACKTICK` → Matches the closing backtick (```).
  //
  // The regex is wrapped in parentheses `(...)` to capture the matched sections
  // so they are included in the split results.
  //
  // Example:
  // Input: "Hello ```link|View all shifts|http://example.com``` there"
  // Split result will be:
  // [
  //   "Hello ",
  //   "```link|View all shifts|http://example.com```",
  //   " there"
  // ]

  return parts.map((part) => {
    const prefix = Object.values(SECTION_PREFIXES).find(
      (prefix) => part.startsWith(DELIMITER_BACKTICK + prefix) && part.endsWith(DELIMITER_BACKTICK)
    );
    if (prefix) {
      const content = part
        .slice(DELIMITER_BACKTICK.length + prefix.length, -DELIMITER_BACKTICK.length)
        .trim();
      return { type: prefix, content };
    }

    return { type: "text", content: part };
  });
}

/**
 * Parses the content of an available dates section into structured data.
 *
 * @param content The content string from an availableDates section
 * @returns Parsed dates and link information
 */
export function parseDateSectionContent(content: string): ParsedDateSection {
  const lines = content
    .split("\n")
    .map((line) => line.trim())
    .filter(Boolean);

  // Last line should be the link in format "text|url"
  const linkLine = lines[lines.length - 1];
  const [linkText, linkUrl] = linkLine.split("|");

  // All other lines are dates
  const dates = lines.slice(0, -1);

  return {
    dates,
    link: {
      text: linkText,
      url: linkUrl,
    },
  };
}

/**
 * Formats an intro message by replacing the onboarding documents section with the actual documents.
 *
 * @param message The message string to format
 * @param documents The list of hospital documents
 * @returns The formatted message
 */
export function formatIntroMessage(message: string, documents: HospitalDocument[]): string {
  if (documents.length === 0) {
    return message;
  }
  return (
    message +
    DELIMITER_BACKTICK +
    SECTION_PREFIXES.ONBOARDING_DOCUMENTS +
    "\n" +
    documents.map((document) => `${document.name}|${document.s3Path}`).join("\n") +
    DELIMITER_BACKTICK
  );
}

/**
 * Parses the content of an onboarding documents section into structured data.
 *
 * E.x:
 * ```onboardingDocuments\nDocument 1|https://example.com/document1\nDocument 2|https://example.com/document2```
 *
 * @param content The content string from an onboarding documents section
 * @returns Parsed documents information
 */
export function parseOnboardingDocumentsSectionContent(
  content: string
): ParsedOnboardingDocumentsSection {
  const lines = content
    .split("\n")
    .map((line) => line.trim())
    .filter(Boolean);

  return lines.map((line) => {
    const [name, url] = line.split("|");
    return { name, url };
  });
}

export function isVetLink(url: string): boolean {
  return url.includes(`${window.location.origin}/vet`);
}

export type { MessagePart, ParsedDateSection, MessageType };
