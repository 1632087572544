type IsFlexParamsModifiedOptions = {
  startTime: string;
  endTime: string;
  requestedStartTime?: string;
  requestedEndTime?: string;
  isRequestWithoutSurgery?: boolean;
  needsTravelStipend?: boolean;
  travelStipendAmount?: number;
};

export const isFlexParamsModified = ({
  startTime,
  endTime,
  requestedStartTime,
  requestedEndTime,
  isRequestWithoutSurgery,
  needsTravelStipend,
  travelStipendAmount,
}: IsFlexParamsModifiedOptions) =>
  !!(
    (requestedStartTime && requestedStartTime !== startTime) ||
    (requestedStartTime && requestedEndTime !== endTime) ||
    isRequestWithoutSurgery ||
    needsTravelStipend ||
    travelStipendAmount
  );
