import React from "react";

import downloadIcon from "../static/images/cloud_download.svg";
import plusIcon from "../static/images/plus_icon.svg";

// @ts-expect-error TS7006
const RooLink = (props) => {
  const {
    title,
    sm,
    href,
    target,
    rel,
    className,
    color = "green",
    isAddLink,
    isDownloadLink,
    onClick,
    // @ts-expect-error TS6133
    disabled,
    // @ts-expect-error TS6133
    style,
  } = props;

  const linkColorClass = color;

  let icon = null;
  if (isAddLink) icon = plusIcon;
  else if (isDownloadLink) icon = downloadIcon;

  return (
    <span>
      {icon ? (
        <span className="mr-1">
          <img src={icon} />
        </span>
      ) : null}
      <a
        href={href}
        className={`action-link ${className} ${linkColorClass} ${sm ? "small-link" : ""}`}
        onClick={onClick}
        target={target}
        rel={rel}
      >
        {title}
      </a>
    </span>
  );
};

// eslint-disable-next-line no-restricted-exports
export default RooLink;
