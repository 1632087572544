import { SurveyQuestionType } from "posthog-js";
import { usePostHog } from "posthog-js/react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { clearNavigationReasonModal } from "../../../Common/NavigationReason/action/NavigationReasonActions";
import {
  SHIFT_PRICING_SURVEY_ID,
  hasSubmittedSurvey,
  surveyDismissed as postHogSurveyDismissed,
  surveyShown as postHogSurveyShown,
  submitSurveyResponse,
} from "../../../Common/postHogSurvey";
import { Event, EventTracker } from "../../../tracking/service/EventTracker/EventTrackerService";

export const useShiftPricingSurvey = () => {
  const dispatch = useDispatch();

  const [showShiftPricingSurveyModal, setShowShiftPricingSurveyModalInternal] = useState(false);
  const [hasSubmittedShiftPricingSurvey, setHasSubmittedShiftPricingSurvey] = useState(false);

  const posthog = usePostHog();
  const [surveyId, setSurveyId] = useState("");
  const [surveyName, setSurveyName] = useState("");

  const [shiftPricingSurveyQuestion, setShiftPricingSurveyQuestion] = useState("");
  const [shiftPricingSurveyDescription, setShiftPricingSurveyDescription] = useState("");
  const [shiftPricingSurveyChoices, setShiftPricingSurveyChoices] = useState<string[]>([]);

  const [shiftPricingSurveyAnswer, setShiftPricingSurveyAnswer] = useState("");

  const fetchSurveyData = useCallback(() => {
    if (surveyId) {
      // if survey data is already populated, return
      return;
    }
    posthog.getActiveMatchingSurveys((surveys) => {
      for (const survey of surveys.filter((survey) => survey.type === "api")) {
        if (survey.id === SHIFT_PRICING_SURVEY_ID) {
          setSurveyId(survey.id);
          setSurveyName(survey.name);
          // we expect one single choice mc question in this survey
          const [mcQuestion] = survey.questions;
          if (mcQuestion.type === SurveyQuestionType.SingleChoice) {
            setShiftPricingSurveyQuestion(mcQuestion.question);
            // @ts-expect-error TS2345
            setShiftPricingSurveyDescription(mcQuestion.description);
            setShiftPricingSurveyChoices(mcQuestion.choices);
          }
        }
      }
    });
  }, [
    posthog,
    surveyId,
    setSurveyId,
    setSurveyName,
    setShiftPricingSurveyQuestion,
    setShiftPricingSurveyDescription,
    setShiftPricingSurveyChoices,
  ]);

  useEffect(() => {
    fetchSurveyData();
  }, [fetchSurveyData]);

  useEffect(() => {
    if (hasSubmittedSurvey(surveyId)) {
      setHasSubmittedShiftPricingSurvey(true);
    }
  }, [surveyId]);

  const setShowShiftPricingSurveyModal = (
    show: boolean,
    fields?: { hospitalId: number; shiftType: "vet" | "tech" }
  ) => {
    // if show modal is being set to true, refetch survey and log event
    if (show && fields) {
      fetchSurveyData();
      const { hospitalId, shiftType } = fields;
      EventTracker.send({
        eventName: Event.Name.HOSPITAL_SHIFT_PRICING_SURVEY,
        eventType: Event.Type.IMPRESSION,
        entityType: Event.Entity.HOSPITAL,
        entityId: hospitalId,
        context: { shiftType },
      });
      postHogSurveyShown(surveyId, surveyName);
    }
    setShowShiftPricingSurveyModalInternal(show);
  };

  const onShiftPricingSurveyModalSubmit = (
    surveyResponse: string,
    hospitalId: number,
    shiftType: "vet" | "tech"
  ) => {
    submitSurveyResponse(surveyId, surveyName, surveyResponse);
    setHasSubmittedShiftPricingSurvey(true);
    setShowShiftPricingSurveyModal(false);
    dispatch(clearNavigationReasonModal());
    EventTracker.send({
      eventName: Event.Name.HOSPITAL_SHIFT_PRICING_SURVEY_SUBMIT,
      eventType: Event.Type.CLICK,
      entityType: Event.Entity.HOSPITAL,
      entityId: hospitalId,
      context: { shiftType },
    });
  };

  const onShiftPricingSurveyModalDismiss = (hospitalId: number, shiftType: "vet" | "tech") => {
    // we count dismissing the survey as a "submission", and do not show it again for the current session
    postHogSurveyDismissed(surveyId, surveyName);
    setHasSubmittedShiftPricingSurvey(true);
    setShowShiftPricingSurveyModal(false);
    dispatch(clearNavigationReasonModal());
    EventTracker.send({
      eventName: Event.Name.HOSPITAL_SHIFT_PRICING_SURVEY_DISMISS,
      eventType: Event.Type.CLICK,
      entityType: Event.Entity.HOSPITAL,
      entityId: hospitalId,
      context: { shiftType },
    });
  };

  return {
    showShiftPricingSurveyModal,
    setShowShiftPricingSurveyModal,
    onShiftPricingSurveyModalSubmit,
    onShiftPricingSurveyModalDismiss,
    shiftPricingSurveyQuestion,
    shiftPricingSurveyDescription,
    shiftPricingSurveyChoices,
    shiftPricingSurveyAnswer,
    setShiftPricingSurveyAnswer,
    hasSubmittedShiftPricingSurvey,
  };
};
