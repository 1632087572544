import React from "react";
import { purpleBase, Checkbox } from "@RooUI";
import { HStack, VStack } from "../Flex";
import { RooIcon } from "../RooIcon";
import {
  CustomCardDetails,
  CustomCardDetailsWrapper,
  CustomShiftMetadata,
} from "./ShiftProposalHospitalList";
import {
  Card,
  CardLogo,
  CardLogoIcon,
  HospitalImage,
  DefaultHospitalIcon,
  HospitalName,
} from "./styles";
import { WorkedHospital } from "@Roo/hooks/api/useGetWorkedHospitals";
import { useGetShiftProposals } from "@RooBeta/hooks/api/useGetShiftProposals";
import { useImpressionTracking } from "@RooBeta/hooks/tracking/useImpressionTracking";
import { Event } from "@RooBeta/types";
import { useRooTranslation } from "@Roo/Common/Wrappers/useRooTranslation";
import moment from "moment";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";

export const ShiftProposalHospitalCard = ({
  hospital,
  onSelect,
  isSelected,
  hideBorder,
  showCheckIcon = true,
  hideHoverBackground = false,
}: {
  hospital: WorkedHospital;
  onSelect: (hospitalId: number) => void;
  isSelected: boolean;
  hideBorder?: boolean;
  showCheckIcon?: boolean;
  hideHoverBackground?: boolean;
}) => {
  const { vetId } = useRequiredAuthorizedUser();
  const { t } = useRooTranslation();

  const fullAddress = (hospital: WorkedHospital) =>
    `${hospital.hospitalAddress}, ${hospital.hospitalCity}, ${hospital.stateCode}`;

  const { data: activeShiftProposals } = useGetShiftProposals("active");
  const shiftProposalsMap = activeShiftProposals?.reduce((acc, proposal) => {
    acc[proposal.hospitalId] = proposal.shiftProposalId;
    return acc;
  }, {} as Record<number, number>);
  const hasShiftProposal = !!shiftProposalsMap?.[hospital.hospitalId];

  const ref = useImpressionTracking({
    event: {
      eventName: Event.Name.VET_SHIFT_PROPOSAL_HOSPITAL_CARD_VIEW,
      eventType: Event.Type.IMPRESSION,
      entityType: Event.Entity.VET,
      entityId: vetId ?? undefined,
      context: {
        hospitalId: hospital.hospitalId,
        source: "shiftList",
      },
    },
  });

  const lastDateWorkedText = `${
    hospital.totalRatings > 1
      ? t("shiftProposal.card.hospitalDetails.timesWorked.plural", {
          count: hospital.totalRatings,
        })
      : t("shiftProposal.card.hospitalDetails.timesWorked.singular", {
          count: hospital.totalRatings,
        })
  } (${t("shiftProposal.card.hospitalDetails.lastWorkedDate", {
    date: moment(hospital.lastDateWorked).format("MMM D"),
  })})`;

  return (
    <Card
      key={hospital.hospitalId}
      data-testid={`hospitalcard${hospital.hospitalId}`}
      $variant="action_list"
      $hideBorder={hideBorder}
      $hideHoverBackground={hideHoverBackground}
      ref={ref}
    >
      <CustomCardDetails>
        <HStack $gap="xxl">
          {hasShiftProposal && showCheckIcon ? (
            <RooIcon icon="check" size="m" color={purpleBase} data-testid="rooIconCheck" />
          ) : (
            <Checkbox checked={isSelected} onChange={() => onSelect(hospital.hospitalId)} />
          )}
          <CustomCardDetailsWrapper>
            <CardLogo>
              {hospital.isFavorite ? (
                <CardLogoIcon>
                  <RooIcon
                    icon="favorite_filled"
                    size="s"
                    color={purpleBase}
                    data-testid="rooIconFavoriteFilled"
                  />
                </CardLogoIcon>
              ) : null}
              {hospital.hospitalProfileImagePath ? (
                <HospitalImage
                  src={hospital.hospitalProfileImagePath}
                  alt={hospital.hospitalName}
                />
              ) : (
                <DefaultHospitalIcon>
                  <RooIcon icon="business" size="l" data-testid="rooIconBusiness" />
                </DefaultHospitalIcon>
              )}
            </CardLogo>
            <VStack $gap="xxs">
              <HospitalName>{hospital.hospitalName}</HospitalName>
              <CustomShiftMetadata>{fullAddress(hospital)}</CustomShiftMetadata>
              <CustomShiftMetadata>{lastDateWorkedText}</CustomShiftMetadata>
            </VStack>
          </CustomCardDetailsWrapper>
        </HStack>
      </CustomCardDetails>
    </Card>
  );
};
