import { useQuery } from "@tanstack/react-query";
import { get } from "../common/rooFetch";
import { CACHE } from "./constants";

import { GetSchoolsResponse } from "./schoolTypes";

export const getSchools = async () => {
  const response = await get<GetSchoolsResponse>("api/utility/schools", true);
  return response.data;
};

export const useGetSchools = () =>
  useQuery({
    queryKey: [CACHE.GET_SCHOOLS],
    queryFn: getSchools,
  });
