import * as Sentry from "@sentry/react";
import { z } from "zod";

import { rooResponseSchema } from "../../../api/common/response";
import { get } from "../../../api/common/rooFetch";
import { CACHE } from "../../../api/utility/constants";
import { createUseQueryHook } from "../../../Common/hookUtils";

export const getTravelStipendsResponseSchema = rooResponseSchema.extend({
  data: z.array(
    z.object({
      id: z.number(),
      metro_area_id: z.number(),
      created_on: z.date(),
      deleted_on: z.date().nullable(),
    })
  ),
});

export type GetTravelStipendsResponse = z.infer<typeof getTravelStipendsResponseSchema>;

export const getTravelStipends = async () => {
  const response = await get<GetTravelStipendsResponse>("api/travelStipends");

  const result = getTravelStipendsResponseSchema.safeParse(response);

  if (result.success) {
    return result.data;
  } else {
    Sentry.captureException(result.error);
    return response;
  }
};

export const useGetTravelStipends = createUseQueryHook({
  queryKey: CACHE.TRAVEL_STIPENDS,
  queryFn: () => getTravelStipends(),
});
