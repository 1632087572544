import isEqual from "lodash/isEqual";
import React, { ComponentProps, createContext, useCallback, useMemo, useState } from "react";
import { AuthenticatedContent } from "@RooBeta/components/AuthenticatedContent";
import { AuthorizedUserProvider } from "@RooBeta/contexts";

interface ModalItem {
  component: React.ReactElement;
  id?: TypedModalIds;
}

interface TypedModalIds {
  HospitalRatingModal?: number;
  ShiftProposalModal?: number;
}

interface ModalOptions {
  showLoader?: boolean;
  isAuthenticatedContent?: boolean;
}

const ModalRenderer = <T,>({
  component,
  props,
}: {
  component: React.FunctionComponent<T>;
  props: ComponentProps<typeof component>;
}) => component(props);

type ModalContextValue = {
  closeModal: () => void;
  openModal: <T extends object>(
    modalKey: React.FunctionComponent<T>,
    props: ComponentProps<typeof modalKey>,
    id?: TypedModalIds,
    options?: ModalOptions
  ) => void;
  modalList?: ModalItem[];
};

export const ModalContext = createContext<ModalContextValue>({
  closeModal: () => {},
  openModal: () => {},
  modalList: [],
});

export const ModalProvider = (props: { children: React.ReactElement }) => {
  const [modalList, setModalList] = useState<ModalItem[]>([]);

  const closeModal = useCallback(() => setModalList((prevModalList) => prevModalList.slice(1)), []);

  const applyWrappers = useCallback(
    <T,>(
      modal: React.FunctionComponent<T & object>,
      modalProps: ComponentProps<typeof modal> & object,
      options: ModalOptions = {}
    ) => {
      const baseComponent = (
        <ModalRenderer component={modal} props={modalProps} key={`modal-${modal.name}`} />
      );

      if (options.isAuthenticatedContent) {
        return (
          <AuthorizedUserProvider>
            <AuthenticatedContent showLoader={options.showLoader}>
              {baseComponent}
            </AuthenticatedContent>
          </AuthorizedUserProvider>
        );
      }
      return baseComponent;
    },
    []
  );

  const openModal = useCallback(
    <T,>(
      modal: React.FunctionComponent<T & object>,
      modalProps: ComponentProps<typeof modal> & object,
      id?: TypedModalIds,
      options: ModalOptions = {}
    ) => {
      setModalList((currentModalList) => {
        if (
          id &&
          currentModalList.some((modal) => {
            return isEqual(modal.id, id);
          })
        ) {
          return currentModalList;
        }

        const component = applyWrappers(modal, modalProps, options);

        return [
          ...currentModalList,
          {
            component,
            id,
          },
        ];
      });
    },
    [applyWrappers]
  );

  const value = useMemo(
    () => ({
      closeModal,
      openModal,
      modalList,
    }),
    [closeModal, openModal, modalList]
  );

  return (
    <ModalContext.Provider value={value}>
      {modalList.length > 0 && modalList[0].component}
      {props.children}
    </ModalContext.Provider>
  );
};
