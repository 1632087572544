import React, { ReactNode } from "react";

import { Icon, IconProps } from "../Icon";

import { ChipStyle, TagContainer, TagIconContainer, TagText } from "./ChipStyles";
import { blueDark600, grayWhite } from "../../styles/constants";

export interface ChipProps extends React.HTMLAttributes<HTMLDivElement> {
  type?: "clickable" | "label" | "tag";
  text?: string;
  borderColor?: string;
  textColor?: string;
  backgroundColor?: string;
  children?: ReactNode;
  onClick?: () => void;
  icon?: IconProps["name"];
  iconColor?: string;
  selected?: boolean;
}

export const Chip: React.FC<ChipProps> = ({
  text,
  borderColor,
  backgroundColor,
  children,
  textColor,
  onClick,
  icon,
  iconColor,
  type = "label",
  selected = false,
  ...props
}) => {
  const MAX_LENGTH = 25;

  // Truncate text if it exceeds the max length
  const truncatedText =
    text && text.length > MAX_LENGTH ? text.substring(0, MAX_LENGTH - 3) + "..." : text;

  // Add ARIA attributes for accessibility
  const ariaProps = {
    role: type === "tag" ? "button" : undefined,
    "aria-pressed": type === "tag" ? selected : undefined,
  };

  return (
    <ChipStyle
      borderColor={borderColor}
      backgroundColor={backgroundColor}
      textColor={textColor}
      selected={selected}
      onClick={onClick}
      type={type}
      {...ariaProps}
      {...props}
    >
      {type === "tag" ? (
        <TagContainer selected={selected} backgroundColor={backgroundColor}>
          <TagIconContainer selected={selected}>
            <Icon name={icon || "Add"} color={selected ? grayWhite : blueDark600} />
          </TagIconContainer>
          {text && <TagText>{truncatedText}</TagText>}
          {children}
        </TagContainer>
      ) : (
        <>
          {icon && <Icon name={icon} color={iconColor} />}
          {text && <span>{truncatedText}</span>}
          {children}
        </>
      )}
    </ChipStyle>
  );
};
