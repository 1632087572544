import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Container,
  Heading,
  SmallText,
  spacingXL,
  spacingXXL,
  ThreeColumnGrid,
  TwoColumnGrid,
  TwoColumnLayout,
} from "@RooUI";
import { ControlledPhoneInput } from "../../../../Common/Controllers/ControlledPhoneInput";
import * as Sentry from "@sentry/react";
import {
  ControlledInput,
  ControlledPasswordInput,
  ControlledSelect,
} from "../../../../Common/Controllers";
import styled from "styled-components";
import { ButtonGroup, FooterGroup } from "../styles";
import { EventSignUpFooter } from "../common/EventSignUpFooter";
import { getInputErrorMessage } from "../../../../Common/Form/service";
import { FieldErrors, FormProvider, UseFormReturn } from "react-hook-form";
import { RegistrationForm } from "../../../../RegistrationUpdated/types";
import {
  anticipatedReliefNeedOptions,
  aTypeOfHospitalOptions,
} from "../../../../constants/dropDownConstants";
import { SingleInputAddressAutocomplete } from "@Roo/Common/Form/AddressAutocomplete/SingleInputAddressAutocomplete";
import { ControlledRadioGroup } from "@Roo/Common/Controllers/ControlledRadioGroup";
import { checkForDuplicateHospital } from "@Roo/api/hospital";
import { Auth } from "@aws-amplify/auth";
import { Event, EventTracker } from "../../../../tracking/service/EventTracker/EventTrackerService";

const StyledContainer = styled(Container)({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
});

const Section = styled.div({
  display: "flex",
  flexDirection: "column",
  marginBottom: spacingXL,
  marginTop: spacingXL,
});

interface Props {
  onSave?: (data: RegistrationForm) => void;
  onCancel?: () => void;
  loading?: boolean;
  methods: UseFormReturn<RegistrationForm>;
}

export const EventHospitalSignUpForm: React.FC<Props> = ({
  onSave,
  onCancel,
  loading = false,
  methods,
}): React.ReactElement => {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    getValues,
  } = methods;

  const [duplicateAddressWarning, setDuplicateAddressWarning] = useState<boolean>(false);

  const hospitalAddress = watch("hospitalAddress");
  const hospitalCity = watch("hospitalCity");
  const hospitalState = watch("hospitalState");
  const hospitalZipcode = watch("hospitalZipcode");

  const checkCognitoAccount = async (email: string) => {
    try {
      await Auth.signIn(email, "dummyPassword");
      // If sign-in is successful (which it shouldn't be with a dummy password), the user already exists
      return true;
    } catch (error) {
      if (error.code === "UserNotFoundException") {
        // User doesn't exist, which is what we want for a new registration
        return false;
      } else if (error.code === "NotAuthorizedException") {
        // User exists but password is incorrect, which means the account exists
        return true;
      } else {
        // Other errors
        Sentry.captureException(error);
        return false;
      }
    }
  };

  const handleBlur = async () => {
    const email = getValues("emailId");

    if (email) {
      const userExists = await checkCognitoAccount(email);
      if (userExists) {
        methods.setError("emailId", {
          type: "manual",
          message: "An account with this email already exists.",
        });
        EventTracker.send({
          eventName: Event.Name.DUPLICATE_EMAIL_REGISTRATION_ATTEMPT,
          eventType: Event.Type.CLICK,
          entityType: Event.Entity.HOSPITAL,
          entityId: undefined,
          context: {
            email,
          },
        } as const);
      } else {
        methods.clearErrors("emailId");
      }
    }
  };

  const handleFormSubmit = async (data: RegistrationForm) => {
    try {
      if (onSave) {
        onSave(data);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const getErrorMessage = (error: string | null | undefined): string | undefined => {
    return error || undefined;
  };

  const handleError = (formErrors: FieldErrors) => {
    if (
      formErrors.hospitalAddress ||
      formErrors.hospitalCity ||
      formErrors.hospitalState ||
      formErrors.hospitalZipcode
    ) {
      methods.setError("hospitalFullAddress", {
        message: "Please select an address from the dropdown.",
      });
    }
  };

  useEffect(() => {
    const checkForHospitalDupe = async () => {
      if (hospitalAddress && hospitalCity && hospitalState && hospitalZipcode) {
        const isDuplicateHospital = await checkForDuplicateHospital(
          hospitalAddress,
          hospitalCity,
          hospitalZipcode,
          hospitalState
        );
        if (isDuplicateHospital) {
          setDuplicateAddressWarning(true);
        }
      }
    };
    checkForHospitalDupe();
  }, [hospitalAddress, hospitalCity, hospitalState, hospitalZipcode]);

  return (
    <FormProvider {...methods}>
      <StyledContainer data-testid="hospitalSignUpForm">
        <form
          onSubmit={handleSubmit(handleFormSubmit, handleError)}
          id="hospitalSignUpForm"
          noValidate
        >
          <Heading level={3}>About you</Heading>
          <Section>
            <ThreeColumnGrid>
              <ControlledInput
                name="firstName"
                data-testid="firstName"
                control={control}
                label="First Name"
                error={getErrorMessage(getInputErrorMessage(errors?.firstName?.message))}
              />

              <ControlledInput
                name="lastName"
                data-testid="lastName"
                control={control}
                label="Last Name"
                error={getErrorMessage(getInputErrorMessage(errors?.lastName?.message))}
              />

              <ControlledPhoneInput
                data-testid="mobilePhoneNumber"
                control={control}
                name="mobilePhoneNumber"
                label="Mobile Phone Number"
                error={getErrorMessage(getInputErrorMessage(errors?.mobilePhoneNumber?.message))}
              />
            </ThreeColumnGrid>
          </Section>
          <TwoColumnGrid>
            <ControlledInput
              data-testid="emailId"
              name="emailId"
              control={control}
              label="Email"
              type="email"
              error={getErrorMessage(getInputErrorMessage(errors?.emailId?.message))}
              onBlur={() => handleBlur()}
            />

            <ControlledPasswordInput
              data-testid="password"
              name="password"
              control={control}
              label="Password"
              password={watch("password")}
              error={getErrorMessage(getInputErrorMessage(errors?.password?.message))}
            />
          </TwoColumnGrid>
          <hr />

          <Heading level={3} style={{ marginTop: spacingXXL }}>
            About your hospital
          </Heading>
          {duplicateAddressWarning && (
            <div style={{ marginTop: spacingXXL }}>
              <Alert
                $alertStatus="warning"
                alertBody="We found a hospital that appears to be similar to the one you're registering. If your hospital is already registered with Roo, please reach out to to your team for an invitation to join the account. If you think this may be an error, a Roo team member will be happy to assist you. "
              />
            </div>
          )}
          <TwoColumnLayout style={{ marginTop: spacingXXL }}>
            <ControlledInput
              data-testid="hospitalName"
              name="hospitalName"
              control={control}
              label="Hospital name"
              error={getErrorMessage(getInputErrorMessage(errors?.hospitalName?.message))}
            />

            <ControlledPhoneInput
              data-testid="hospitalPhoneNumber"
              control={control}
              name="hospitalPhoneNumber"
              label="Hospital phone number"
              error={getErrorMessage(getInputErrorMessage(errors?.hospitalPhoneNumber?.message))}
            />

            <SingleInputAddressAutocomplete
              data-testid="hospitalFullAddress"
              control={control}
              onAddressSelected={({ stateId, city, zipcode, fullAddress }) => {
                setValue("hospitalState", stateId, { shouldValidate: true });
                setValue("hospitalCity", city, { shouldValidate: true });
                setValue("hospitalZipcode", zipcode, { shouldValidate: true });
                setValue("hospitalAddress", fullAddress, { shouldValidate: true });
              }}
              name="hospitalFullAddress"
              error={getErrorMessage(getInputErrorMessage(errors?.hospitalFullAddress?.message))}
            />

            <ControlledInput
              data-testid="selfIdentifiedEnterpriseName"
              name="selfIdentifiedEnterpriseName"
              control={control}
              label="Corporate group name (if applicable)"
              error={getErrorMessage(
                getInputErrorMessage(errors?.selfIdentifiedEnterpriseName?.message)
              )}
            />
            <div>
              <ControlledSelect
                data-testid="typeOfHospital"
                id="typeOfHospital"
                name="typeOfHospital"
                control={control}
                label="Type of hospital"
                options={aTypeOfHospitalOptions}
                error={getErrorMessage(getInputErrorMessage(errors?.typeOfHospital?.message))}
              />
              <SmallText>
                Please select the correct hospital type as any future updates will have to be
                completed by a Roo rep.
              </SmallText>
            </div>
            <ControlledSelect
              data-testid="anticipatedReliefNeed"
              id="anticipatedReliefNeed"
              name="anticipatedReliefNeed"
              control={control}
              label="When do you anticipate first needing relief help?"
              options={anticipatedReliefNeedOptions}
              error={getErrorMessage(getInputErrorMessage(errors?.anticipatedReliefNeed?.message))}
            />

            <ControlledRadioGroup
              data-testid="onboardingWithRooTeamMember"
              label="Will you be onboarding with a Roo team member at the booth?"
              name="onboardingWithRooTeamMember"
              control={control}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
              type="classic"
              error={getErrorMessage(
                getInputErrorMessage(errors?.onboardingWithRooTeamMember?.message)
              )}
            />

            <ControlledRadioGroup
              data-testid="isLookingToHireProvider"
              label="Is your hospital currently looking to hire a provider? (optional)"
              name="isLookingToHireProvider"
              control={control}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
              type="classic"
            />
          </TwoColumnLayout>
          <FooterGroup>
            <EventSignUpFooter />
            <ButtonGroup>
              <Button
                type="submit"
                role="button"
                name="submit"
                title="Create My Account"
                $buttonType="primary"
                loading={loading}
                data-testid="createHospitalAccount"
                form="hospitalSignUpForm"
              />
              <Button title="Cancel" onClick={onCancel} loading={loading} data-testid="cancel" />
            </ButtonGroup>
          </FooterGroup>
        </form>
      </StyledContainer>
    </FormProvider>
  );
};
