import style from "styled-components";

import {
  blueDark600,
  blueLightest,
  grayBase,
  grayWhite,
  purpleBackground,
  SmallBodyText,
  SmallMediumBodyText,
  spacingS,
  spacingXS,
} from "../../styles/constants";

interface BaseChipProps {
  borderColor?: string;
  backgroundColor?: string;
  textColor?: string;
  selected?: boolean;
}

interface ChipDivProps extends BaseChipProps {
  type: "clickable" | "label" | "tag";
}

const getBorderColor = (props: BaseChipProps) => props.borderColor || props.backgroundColor;

export const TagContainer = style.div<BaseChipProps>`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacingS};
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  background: ${(props) => (props.backgroundColor ? props.backgroundColor : grayWhite)};

`;

export const TagIconContainer = style.div<BaseChipProps>`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.selected ? blueDark600 : blueLightest)};
  transition: background-color 0.2s ease;
`;

export const TagText = style.span`
  ${SmallMediumBodyText};
  text-align: center;
`;

export const ChipStyle = style.div<ChipDivProps>`
  display: inline-flex;
  font-family: "Poppins", sans-serif;
  padding: ${(props) =>
    props.type === "tag"
      ? "0"
      : props.type === "clickable"
      ? `${spacingXS} ${spacingS}`
      : `0px ${spacingS}`};
  justify-content: center;
  align-items: center;
  gap: ${spacingXS};
  border-radius: ${(props) => (props.type === "clickable" ? "20px" : spacingXS)};
  background: ${(props) =>
    props.selected ? purpleBackground : props.backgroundColor ? props.backgroundColor : "unset"};
  border: ${(props) =>
    props.type === "tag"
      ? "none"
      : `${props.type === "clickable" ? "2px" : "1px"} solid ${getBorderColor(props)}`};
  cursor: ${(props) => (props.type === "clickable" ? "pointer" : "unset")};

  span {
    ${(props) => (props.type === "clickable" ? SmallMediumBodyText : SmallBodyText)};
    text-align: center;
    color: ${(props) =>
      props.textColor ? props.textColor : props.borderColor ? props.borderColor : grayBase};
  }
`;
