import * as ErrorMessages from "../../../constants/errorMessage";
import * as C from "../../../constants/hospitalConstants";
const initialState = {
  plaidToken: null,
  paymentMethodAdded: false,
  isAddBillingEmailsSuccess: false,
  isError: false,
  message: "",
};

/* eslint-disable default-param-last */
// @ts-expect-error TS7006
const hospitalBillingReducer = (state = initialState, action) => {
  /* eslint-enable default-param-last */
  switch (action.type) {
    case C.GET_PLAID_TOKEN: {
      return {
        ...state,
        plaidToken: null,
      };
    }

    case C.GET_PLAID_TOKEN_SUCCESS: {
      return {
        ...state,
        plaidToken: action.payload,
      };
    }

    case C.ADD_PAYMENT_METHOD_SUCCESS: {
      return {
        ...state,
        paymentMethodAdded: true,
        isError: false,
      };
    }

    case C.ADD_PAYMENT_METHOD_FAIL: {
      return {
        ...state,
        paymentMethodAdded: false,
        isError: true,
        message:
          action.payload !== undefined ? action.payload.message : ErrorMessages.EXCEPTION_MESSAGE,
      };
    }

    case C.INITIALIZE_HOSPITAL_BILLING_STATE: {
      return {
        ...state,
        paymentMethodAdded: false,
        isAddBillingEmailsSuccess: false,
        isError: false,
      };
    }

    case C.SAVE_BILLING_EMAILS: {
      return {
        ...state,
        paymentMethodAdded: false,
      };
    }

    case C.SAVE_BILLING_EMAILS_SUCCESS: {
      return {
        ...state,
        isAddBillingEmailsSuccess: true,
        isError: false,
      };
    }

    case C.SAVE_BILLING_EMAILS_FAIL: {
      return {
        ...state,
        isAddBillingEmailsSuccess: false,
        isError: true,
        message:
          action.payload !== undefined ? action.payload.message : ErrorMessages.EXCEPTION_MESSAGE,
      };
    }

    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default hospitalBillingReducer;
