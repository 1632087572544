import * as Sentry from "@sentry/react";
import lodash from "lodash";
import posthog from "posthog-js";
import { call, put, takeEvery } from "redux-saga/effects";

import { GET } from "@roo-dev/roo-node-types";

import { showToast } from "../../../Common/Toasts/action/ToastActions";
import { APIResponse } from "../../../api/common/response";
import { rooFetch } from "../../../api/common/rooFetch";
import * as loginActionTypes from "../../../constants/Login";
import { INIT_AUTHORISE_USER } from "../../../constants/Login";
import * as UserTypes from "../../../constants/UserTypeConstants";
import * as adminActionTypes from "../../../constants/adminUsersList";
import { EXCEPTION_MESSAGE } from "../../../constants/errorMessage";
import { SET_LOADER, UNSET_LOADER } from "../../../constants/loader";
import * as RegC from "../../../constants/registerTechConstants";
import { VET_SUBMIT_PROFILE_REQUEST } from "../../../constants/registerVetConstants";
import { UPDATE_TECH_BASIC_INFO } from "../../../constants/techConstants";
import { ACTIVE } from "../../../constants/userStatusTypes";
import {
  CreateRooInternalUserAction,
  DeleteContractorAction,
  DeleteHospitalAction,
  DeleteRooInternalUserAction,
  DeleteStudentAction,
  DownloadFileAction,
  FetchRatingSummaryAction,
  FetchStatesAction,
  FetchStudentDataAction,
  FetchTechDataAction,
  GetContractorListAction,
  GetFulltimePlacementLogsAction,
  GetHospitalListAction,
  GetStudentListAction,
  GetUserListAction,
  GetVetTechWorkedForHospitalAction,
  LogInAsUserAction,
  SendRequestChangeStatusAction,
  SetDelayPaymentAction,
  SetOfflinePaymentAction,
  SetReputationAndFavorabilityAction,
  SetUnsuspendedDateAction,
  SubmitFulltimePlacementAction,
  ToggleInternshipAvailabilityFeatureAction,
  UpdateHospitalContactAction,
  UpdatePlacementPaymentStatusAction,
  UpdateStripeConnectAccountIdAction,
  UpdateTechDataAction,
  UpdateTechProbationStatusAction,
  UpdateTechTierAction,
  UploadTechProfilePhotoAction,
  ValidateAddressAction,
} from "../actions/UserActions";
import { getUpdateStatusLabel } from "../service/UserService";

// roo super user view user by making login api call (skipping step of going through cognito)
export function* logInAsUserAsync(action: LogInAsUserAction) {
  try {
    const { userTypeId, email, rooSuperUserEmail, userId } = action.payload;

    const api = "api/admin/users/loginAsUser";

    const response: Response = yield call(rooFetch, `${window.RooConfig.API_URL}${api}`, {
      method: "POST",
      body: JSON.stringify({
        userId,
        userTypeId,
        email,
        rooSuperUserEmail,
        posthogSessionId: posthog.get_session_id(),
      }),
    });

    const data: APIResponse<any> = yield response.json();

    if (data.status_code === 200) {
      // use jwtToken from roo super user login
      const jwtToken: string = yield localStorage.getItem("jwtToken");
      yield put({
        type: loginActionTypes.LOG_IN_AS_USER_SUCCESS,
        payload: { data, jwtToken },
      });
      // reload without userId to avoid infinite loop
      window.location.href = "/login";
    } else {
      if (data.data.error === "unauthorized") {
        yield put({ type: INIT_AUTHORISE_USER });
      }
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while logging in as user" } });
  }
}

export function* fetchAdminCommonDataAsync() {
  try {
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/utility/loadCommonData"
    );

    const data: APIResponse<any> = yield response.json();

    if (data.status_code === 200) {
      yield put({
        type: adminActionTypes.FETCH_ADMIN_COMMON_DATA_SUCCESS,
        payload: { commonData: data.data },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: adminActionTypes.FETCH_ADMIN_COMMON_DATA_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while fetching admin common data" },
    });
    yield put({
      type: adminActionTypes.FETCH_ADMIN_COMMON_DATA_FAIL,
      payload: { status: error.status_code, message: EXCEPTION_MESSAGE },
    });
  }
}

export function* fetchAdminStatesAsync(action: FetchStatesAction) {
  try {
    const getOnlyRooStates = action?.payload?.getOnlyRooStates ? 1 : 0;
    const getOnlyRooVetStates = action?.payload?.getOnlyRooVetStates ? 1 : 0;
    const getOnlyRooTechStates = action?.payload?.getOnlyRooTechStates ? 1 : 0;
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL +
        `api/utility/getStates?getOnlyRooStates=${getOnlyRooStates}&getOnlyRooVetStates=${getOnlyRooVetStates}&getOnlyRooTechStates=${getOnlyRooTechStates}`
    );

    const data: GET.Utility.GetStatesResponse = yield response.json();

    if (data.status_code === 200) {
      yield put({
        type: adminActionTypes.FETCH_ADMIN_STATES_SUCCESS,
        payload: data.data.data,
      });
    } else if (data.status_code === 400) {
      yield put({
        type: adminActionTypes.FETCH_ADMIN_STATES_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while fetching admin states" } });
    yield put({
      type: adminActionTypes.FETCH_ADMIN_STATES_FAIL,
      payload: { status: error.status_code, message: EXCEPTION_MESSAGE },
    });
  }
}

export function* fetchMetroAreasAsync() {
  try {
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/utility/getMetroAreas"
    );

    const data: APIResponse<any> = yield response.json();
    if (data.status_code === 200) {
      yield put({
        type: adminActionTypes.FETCH_METRO_AREAS_SUCCESS,
        payload: data.data,
      });
    } else if (data.status_code === 400) {
      yield put({
        type: adminActionTypes.FETCH_METRO_AREAS_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while fetching metro areas" } });
    yield put({
      type: adminActionTypes.FETCH_METRO_AREAS_FAIL,
      payload: { status: error.status_code, message: EXCEPTION_MESSAGE },
    });
  }
}

export function* getUserListAsync(action: GetUserListAction) {
  try {
    const {
      userType,
      adminUserEmail, // for security
    } = action.payload;

    const api = "api/admin/users/";

    const response: Response = yield call(
      rooFetch,
      `${window.RooConfig.API_URL}${api}?userType=${userType}&adminUserEmail=${encodeURIComponent(
        adminUserEmail
      )}`
    );

    const responseData: APIResponse<any> & { error: any } = yield response.json();
    const data = responseData.data;

    if (responseData.status_code === 200) {
      yield put({
        type: adminActionTypes.GET_USER_LIST_SUCCESS,
        payload: data,
        UserSearchParam: action.payload.UserSearchParam,
        userType: action.payload.userType,
      });
    } else {
      if (responseData.error === "unauthorized") {
        yield put({ type: INIT_AUTHORISE_USER });
      } else {
        yield put({
          type: adminActionTypes.GET_USER_LIST_FAIL,
          payload: { status: data.status_code, message: data.message },
        });
      }
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting user list" } });
  }
}

export function* getContractorListAsync(action: GetContractorListAction) {
  try {
    const {
      userType,
      statusType,
      contractorSearchParam,
      stateId,
      isNonRooStates,
      metroAreaId,
      isNoMetroAreaOnly,
      isMostRecentLimit,
      minTechTier,
      isShowTestAccounts,
      isMapView,
      activeStatusFilterValue,
      adminUserEmail, // for security
    } = action.payload;

    const isGetVetUsers = userType === UserTypes.VET_USER_TYPE;
    const api = isGetVetUsers ? "api/admin/users/vets/" : "api/admin/users/techs/";

    let searchParams = `?statusType=${statusType}`;

    if (contractorSearchParam) {
      searchParams += `&searchParam=${encodeURIComponent(contractorSearchParam)}`;
    }
    if (stateId) {
      searchParams += `&stateId=${stateId}`;
    }
    if (isNonRooStates) {
      searchParams += `&isNonRooStates=${isNonRooStates}`;
    }
    if (metroAreaId) {
      searchParams += `&metroAreaId=${metroAreaId}`;
    }
    if (isNoMetroAreaOnly) {
      searchParams += `&isNoMetroAreaOnly=${isNoMetroAreaOnly}`;
    }
    if (isMostRecentLimit) {
      searchParams += `&isMostRecentLimit=${isMostRecentLimit}`;
    }
    if (minTechTier) {
      searchParams += `&minTechTier=${minTechTier}`;
    }
    if (isShowTestAccounts) {
      searchParams += `&isShowTestAccounts=${isShowTestAccounts}`;
    }
    if (isMapView) {
      searchParams += `&isMapView=${isMapView}`;
    }

    if (activeStatusFilterValue) {
      searchParams += `&activeStatusFilterValue=${activeStatusFilterValue}`;
    }
    const response: Response = yield call(
      rooFetch,
      `${window.RooConfig.API_URL}${api}${searchParams}&adminUserEmail=${adminUserEmail}`
    );

    const responseData: APIResponse<any> & { error: any } = yield response.json();
    const data = responseData.data;

    if (responseData.status_code === 200) {
      yield put({
        type: adminActionTypes.GET_CONTRACTOR_LIST_SUCCESS,
        payload: data,
        contractorSearchParam: action.payload.contractorSearchParam,
        userType: action.payload.userType,
      });
    } else {
      if (responseData.error === "unauthorized") {
        yield put({ type: INIT_AUTHORISE_USER });
      } else {
        yield put({
          type: adminActionTypes.GET_CONTRACTOR_LIST_FAIL,
          payload: { status: data.status_code, message: data.message },
        });
      }
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting contractor list" } });
  }
}

export function* getStudentListAsync(action: GetStudentListAction) {
  try {
    yield put({ type: SET_LOADER });
    const {
      statusType,
      studentSearchParam,
      stateId,
      isNonRooStates,
      metroAreaId,
      isNoMetroAreaOnly,
      isMostRecentLimit,
      isShowTestAccounts,
      activeStatusFilterValue,
      adminUserEmail, // for security
    } = action.payload;
    let searchParams = `?statusType=${statusType}`;

    if (studentSearchParam) {
      searchParams += `&searchParam=${encodeURIComponent(studentSearchParam)}`;
    }
    if (stateId) {
      searchParams += `&stateId=${stateId}`;
    }
    if (isNonRooStates) {
      searchParams += `&isNonRooStates=${isNonRooStates}`;
    }
    if (metroAreaId) {
      searchParams += `&metroAreaId=${metroAreaId}`;
    }
    if (isNoMetroAreaOnly) {
      searchParams += `&isNoMetroAreaOnly=${isNoMetroAreaOnly}`;
    }
    if (isMostRecentLimit) {
      searchParams += `&isMostRecentLimit=${isMostRecentLimit}`;
    }
    if (isShowTestAccounts) {
      searchParams += `&isShowTestAccounts=${isShowTestAccounts}`;
    }
    if (activeStatusFilterValue) {
      searchParams += `&activeStatusFilterValue=${activeStatusFilterValue}`;
    }
    const response: Response = yield call(
      rooFetch,
      `${window.RooConfig.API_URL}api/admin/users/students/${searchParams}&adminUserEmail=${adminUserEmail}`
    );

    const responseData: APIResponse<any> & { error: any } = yield response.json();
    const data = responseData.data;
    yield put({ type: UNSET_LOADER });
    if (responseData.status_code === 200) {
      yield put({
        type: adminActionTypes.GET_STUDENT_LIST_SUCCESS,
        payload: data,
        studentSearchParam: action.payload.studentSearchParam,
        userType: action.payload.userType,
      });
    } else {
      yield put({
        type: adminActionTypes.GET_STUDENT_LIST_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting student list" } });
  }
}

export function* getHospitalListAsync(action: GetHospitalListAction) {
  try {
    const options = action && action.payload ? action.payload : { isShowTestAccounts: true };
    const {
      statusType,
      searchParam,
      stateId,
      isNonRooStates,
      metroAreaId,
      isNoMetroAreaOnly,
      enterpriseId,
      isMostRecentLimit,
      isShowTestAccounts,
      isMapView,
      isMapVetView,
      isOnlyHospitalsWithShifts,
    } = options;

    let searchParams = `?statusType=${statusType || statusType == 0 ? statusType : ACTIVE}`; // if no statusType in params, only get active hospitals

    if (searchParam) {
      searchParams += `&searchParam=${encodeURIComponent(searchParam)}`;
    }
    if (stateId) {
      searchParams += `&stateId=${stateId}`;
    }
    if (isNonRooStates) {
      searchParams += `&isNonRooStates=${isNonRooStates}`;
    }
    if (metroAreaId) {
      searchParams += `&metroAreaId=${metroAreaId}`;
    }
    if (isNoMetroAreaOnly) {
      searchParams += `&isNoMetroAreaOnly=${isNoMetroAreaOnly}`;
    }
    if (enterpriseId) {
      searchParams += `&enterpriseId=${enterpriseId}`;
    }
    if (isMostRecentLimit) {
      searchParams += `&isMostRecentLimit=${isMostRecentLimit}`;
    }
    if (isOnlyHospitalsWithShifts) {
      searchParams += `&isOnlyHospitalsWithShifts=${isOnlyHospitalsWithShifts}`;
    }
    if (isShowTestAccounts) {
      searchParams += `&isShowTestAccounts=${isShowTestAccounts}`;
    }
    if (isMapView) {
      searchParams += `&isMapView=${isMapView}`;
    }
    if (isMapVetView) {
      searchParams += `&isMapVetView=${isMapVetView}`;
    }

    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/admin/users/hospitals/" + searchParams
    );

    const responseData: APIResponse<any> = yield response.json();

    if (responseData.status_code === 200) {
      yield put({
        type: adminActionTypes.GET_HOSPITAL_LIST_SUCCESS,
        payload: responseData.data,
        searchParam: searchParam,
      });
    } else {
      yield put({
        type: adminActionTypes.GET_HOSPITAL_LIST_FAIL,
        payload: { status: responseData.status_code, message: responseData.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting hospital list" } });
  }
}

export function* getRooAdminListAsync() {
  try {
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/admin/users/rooAdmins"
    );

    const responseData: APIResponse<any> = yield response.json();

    if (responseData.status_code === 200) {
      yield put({
        type: adminActionTypes.GET_ROO_ADMIN_LIST_SUCCESS,
        payload: responseData.data,
      });
    } else {
      yield put({
        type: adminActionTypes.GET_ROO_ADMIN_LIST_FAIL,
        payload: { status: responseData.status_code, message: responseData.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting roo admin list" } });
  }
}

export function* getRooSuperUserListAsync() {
  try {
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/admin/users/rooSuperUsers"
    );

    const responseData: APIResponse<any> = yield response.json();

    if (responseData.status_code === 200) {
      yield put({
        type: adminActionTypes.GET_ROO_SUPER_USER_LIST_SUCCESS,
        payload: responseData.data,
      });
    } else {
      yield put({
        type: adminActionTypes.GET_ROO_SUPER_USER_LIST_FAIL,
        payload: { status: responseData.status_code, message: responseData.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting roo super user list" },
    });
  }
}

export function* changeUserStatusAsync(action: SendRequestChangeStatusAction) {
  const {
    userId,
    emailId,
    statusType,
    userType,
    isFromUnblock,
    isFromUnpaused,
    hospitalId,
    tier,
    rooAdminUserId,
    vetSubmitProfileRequestPayload,
    previousStatusType,
  } = action.payload;
  const isTech = !!tier;

  let apiURL = `api/admin/users/${userId}`;

  if (isTech) {
    apiURL = `api/admin/users/tech/${userId}`;
  }

  try {
    const response: Response = yield call(rooFetch, window.RooConfig.API_URL + apiURL, {
      method: "PUT",
      body: JSON.stringify({
        userId,
        emailId,
        statusType,
        userType,
        isFromUnblock,
        isFromUnpaused,
        hospitalId,
        tier,
        rooAdminUserId,
      }),
    });

    const responseData: APIResponse<any> = yield response.json();

    // @ts-expect-error TS2345
    const userTypeUpdateTo = getUpdateStatusLabel(previousStatusType, statusType);
    if (responseData.status_code === 200) {
      yield put({
        type: adminActionTypes.REQUEST_CHANGE_USER_STATUS_SUCCESS,
        payload: { userTypeUpdateTo },
      });
      if (vetSubmitProfileRequestPayload) {
        yield put({ type: VET_SUBMIT_PROFILE_REQUEST, payload: vetSubmitProfileRequestPayload });
      }
    } else {
      yield put(
        showToast({
          variant: "error",
          message: "Unable to change user status",
          description: responseData.data.data,
        })
      );
      yield put({
        type: adminActionTypes.REQUEST_CHANGE_USER_STATUS_FAIL,
        payload: { status: responseData.status_code, message: responseData.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while changing user status" } });
  }
}

export function* getRatingSummaryAsync(action: FetchRatingSummaryAction) {
  const userId = action.payload.userId;
  const userTypeId = action.payload.userTypeId;
  const isFromAdmin = action.payload.isFromAdmin;
  const hospitalId = action.payload.hospitalId;
  const techId = action.payload.techId;
  const vetId = action.payload.vetId;

  try {
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL +
        "api/user/" +
        userId +
        "/ratingSummary?userTypeId=" +
        userTypeId +
        "&isFromAdmin=" +
        isFromAdmin +
        "&hospitalId=" +
        hospitalId +
        "&vetId=" +
        vetId +
        "&techId=" +
        techId
    );

    const responseData: APIResponse<any> = yield response.json();
    const data = lodash.cloneDeep(responseData.data);
    yield put({ type: UNSET_LOADER });
    if (responseData.status_code === 200) {
      yield put({ type: adminActionTypes.GET_RATING_SUMMARY_SUCCESS, payload: data });
    } else {
      yield put({
        type: adminActionTypes.GET_RATING_SUMMARY_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    yield put({ type: UNSET_LOADER });
    Sentry.captureException(error, { extra: { context: "Error while getting rating summary" } });
  }
}

export function* getHospitalDataAsync() {
  try {
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/hospital/getAllHospitals`
    );

    const responseData: APIResponse<any> = yield response.json();
    const data = lodash.cloneDeep(responseData.data);

    if (responseData.status_code === 200) {
      yield put({ type: adminActionTypes.FETCH_HOSPITAL_DATA_SUCCESS, payload: data.data });
    } else {
      yield put({
        type: adminActionTypes.FETCH_HOSPITAL_DATA_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting hospital list" } });
  }
}

export function* getStudentDataAsync(action: FetchStudentDataAction) {
  try {
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL +
        `api/student/getAllStudents?getActiveOnly=${action.payload.getActiveOnly}`
    );

    const responseData: APIResponse<any> = yield response.json();
    const data = lodash.cloneDeep(responseData.data);

    if (responseData.status_code === 200) {
      yield put({ type: adminActionTypes.FETCH_STUDENT_DATA_SUCCESS, payload: data.data });
    } else {
      yield put({
        type: adminActionTypes.FETCH_STUDENT_DATA_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting student list" } });
  }
}

export function* setOffLinePaymentForHospitalAsync(action: SetOfflinePaymentAction) {
  try {
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/hospital/paymentMethod",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data: APIResponse<any> & { status: any } = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: adminActionTypes.ADMIN_SET_HOSPITAL_OFFILNE_PAYMENT_SUCCESS,
        status: data.status,
      });
    } else {
      yield put({
        type: adminActionTypes.ADMIN_SET_HOSPITAL_OFFILNE_PAYMENT_FAIL,
        payload: { status: data.status, message: data.data },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while setting offline payment for hospital" },
    });
    yield put({ type: adminActionTypes.ADMIN_SET_HOSPITAL_OFFILNE_PAYMENT_FAIL });
  }
}

export function* updateHospitalContactAsync(action: UpdateHospitalContactAction) {
  try {
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/hospital/createNewHospitalPrimaryContact",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data: APIResponse<any> = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      const { errorMessage } = data.data;
      if (errorMessage) {
        yield put({
          type: adminActionTypes.UPDATE_HOSPITAL_CONTACT_FAIL,
          payload: errorMessage,
        });
      } else {
        yield put({
          type: adminActionTypes.UPDATE_HOSPITAL_CONTACT_SUCCESS,
        });
      }
    } else {
      yield put({
        type: adminActionTypes.UPDATE_HOSPITAL_CONTACT_FAIL,
        payload: "Failed to update hospital contact.",
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while updating hospital contact" } });
    yield put({
      type: adminActionTypes.UPDATE_HOSPITAL_CONTACT_FAIL,
      payload: "Failed to update hospital contact.",
    });
  }
}

export function* createRooInternalUserAsync(action: CreateRooInternalUserAction) {
  try {
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/admin/users/createRooInternalUser",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data: APIResponse<any> = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      const { errorMessage } = data.data;
      if (errorMessage) {
        yield put({
          type: adminActionTypes.CREATE_ROO_INTERNAL_USER_FAIL,
          payload: errorMessage,
        });
      } else {
        yield put({
          type: adminActionTypes.GET_ROO_ADMIN_LIST,
        });
        yield put({
          type: adminActionTypes.CREATE_ROO_INTERNAL_USER_SUCCESS,
        });
      }
    } else {
      yield put({
        type: adminActionTypes.CREATE_ROO_INTERNAL_USER_FAIL,
        payload: "Failed to createRooInternalUser.",
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while creating roo internal user" },
    });
    yield put({
      type: adminActionTypes.CREATE_ROO_INTERNAL_USER_FAIL,
      payload: "Failed to createRooInternalUser.",
    });
  }
}

export function* deleteRooInternalUserAsync(action: DeleteRooInternalUserAction) {
  try {
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/admin/users/deleteRooInternalUser",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data: APIResponse<any> = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      const { errorMessage } = data.data;
      if (errorMessage) {
        yield put({
          type: adminActionTypes.DELETE_ROO_INTERNAL_USER_FAIL,
          payload: errorMessage,
        });
      } else {
        yield put({
          type: adminActionTypes.GET_ROO_ADMIN_LIST,
        });
        yield put({
          type: adminActionTypes.DELETE_ROO_INTERNAL_USER_SUCCESS,
        });
      }
    } else {
      yield put({
        type: adminActionTypes.DELETE_ROO_INTERNAL_USER_FAIL,
        payload: "Failed to deleteRooInternalUser.",
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while deleting roo internal user" },
    });
    yield put({
      type: adminActionTypes.DELETE_ROO_INTERNAL_USER_FAIL,
      payload: "Failed to deleteRooInternalUser.",
    });
  }
}

export function* deleteHospitalAsync(action: DeleteHospitalAction) {
  const adminUserId = localStorage.getItem("userId");
  const oDelete = { ...action.payload }; //cloning because passing in object that is part of redux state and need to alter before sending
  oDelete.adminUserId = adminUserId;

  try {
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/admin/deleteHospital",
      {
        method: "POST",
        body: JSON.stringify(oDelete),
      }
    );

    const data: APIResponse<any> = yield response.json();

    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: adminActionTypes.DELETE_HOSPITAL_SUCCESS,
      });
    } else if (data.status_code === 412) {
      // precondition failed
      const { errorMessage } = data.data;
      yield put({
        type: adminActionTypes.DELETE_HOSPITAL_FAIL,
        payload: errorMessage,
      });
    } else {
      yield put({
        type: adminActionTypes.DELETE_HOSPITAL_FAIL,
        payload: "Failed to delete hospital.",
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while deleting hospital" } });
    yield put({
      type: adminActionTypes.DELETE_HOSPITAL_FAIL,
      payload: "Failed to delete hospital.",
    });
  }
}

export function* deleteContractorAsync(action: DeleteContractorAction) {
  const adminUserId = localStorage.getItem("userId");
  const oDelete = { ...action.payload }; //cloning because passing in object that is part of redux state and need to alter before sending
  oDelete.adminUserId = adminUserId;

  try {
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/admin/deleteContractor",
      {
        method: "POST",
        body: JSON.stringify(oDelete),
      }
    );

    const data: APIResponse<any> = yield response.json();

    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: adminActionTypes.DELETE_CONTRACTOR_SUCCESS,
      });
    } else if (data.status_code === 412) {
      // precondition failed
      const { errorMessage } = data.data;
      yield put({
        type: adminActionTypes.DELETE_CONTRACTOR_FAIL,
        payload: errorMessage,
      });
    } else {
      yield put({
        type: adminActionTypes.DELETE_CONTRACTOR_FAIL,
        payload: "Failed to delete user.",
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while deleting user" } });
    yield put({
      type: adminActionTypes.DELETE_CONTRACTOR_FAIL,
      payload: "Failed to delete user.",
    });
  }
}

export function* deleteStudentAsync(action: DeleteStudentAction) {
  const adminUserId = localStorage.getItem("userId");
  const oDelete = { ...action.payload }; //cloning because passing in object that is part of redux state and need to alter before sending
  oDelete.adminUserId = adminUserId;

  try {
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/user/deleteStudent",
      {
        method: "POST",
        body: JSON.stringify(oDelete),
      }
    );

    const data: APIResponse<any> = yield response.json();

    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: adminActionTypes.DELETE_STUDENT_SUCCESS,
      });
    } else if (data.status_code === 412) {
      // precondition failed
      const { errorMessage } = data.data;
      yield put({
        type: adminActionTypes.DELETE_STUDENT_FAIL,
        payload: errorMessage,
      });
    } else {
      yield put({
        type: adminActionTypes.DELETE_STUDENT_FAIL,
        payload: "Failed to delete student.",
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while deleting student" } });
    yield put({
      type: adminActionTypes.DELETE_STUDENT_FAIL,
      payload: "Failed to delete student.",
    });
  }
}

export function* updateTechTierAsync(action: UpdateTechTierAction) {
  try {
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/tech/techTier",
      {
        method: "PUT",
        body: JSON.stringify(action.payload),
      }
    );

    const data: APIResponse<any> = yield response.json();

    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      yield put({
        type: adminActionTypes.UDPATE_TECH_TIER_SUCCESS,
        payload: { status: data.status_code, data: data.data },
      });
    } else {
      yield put({
        type: adminActionTypes.UDPATE_TECH_TIER_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while updating tech tier" } });
    yield put({
      type: adminActionTypes.UDPATE_TECH_TIER_FAIL,
      payload: { status: error.status_code, message: EXCEPTION_MESSAGE },
    });
  }
}

function* downloadFileAsync(action: DownloadFileAction) {
  try {
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/user/download",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data: APIResponse<any> = yield response.json();

    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      // It is a file
      yield put({
        type: adminActionTypes.FILE_DOWNLOAD_SUCCESS,
        payload: data.data.Body.data,
      });
    } else {
      yield put({
        type: adminActionTypes.FILE_DOWNLOAD_FAILED,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while downloading file" } });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: adminActionTypes.FILE_DOWNLOAD_FAILED,
    });
  }
}

function* getTechProfileDataAsync(action: FetchTechDataAction) {
  try {
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL +
        "api/admin/techProfile?userId=" +
        action.payload.userId +
        "&techId=" +
        action.payload.techId
    );

    const data: APIResponse<any> = yield response.json();

    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: adminActionTypes.FETCH_ADMIN_TECH_PROFILE_DATA_SUCCESS,
        payload: {
          status: data.status_code,
          techData: data.data.tech[0],
          techProfileImage: data.data.profileImage,
          techTaxpayerInfo: data.data.techTaxpayerInfo,
        },
      });
      const techResume =
        data.data.tech[0] && data.data.tech[0].resumePath
          ? [{ resumePath: data.data.tech[0].resumePath, resumeName: data.data.tech[0].resumeName }]
          : [];
      yield put({
        type: RegC.UPLOAD_TECH_RESUME_SUCCESS,
        payload: { status: data.status_code, techResume: techResume },
      });
    } else {
      yield put({
        type: adminActionTypes.FETCH_ADMIN_TECH_PROFILE_DATA_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
      yield put({
        type: RegC.UPLOAD_TECH_RESUME_FAIL,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting tech profile data" } });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: adminActionTypes.FETCH_ADMIN_TECH_PROFILE_DATA_FAIL,
      payload: { status: error.status_code, message: EXCEPTION_MESSAGE },
    });
    yield put({
      type: RegC.UPLOAD_TECH_RESUME_FAIL,
    });
  }
}

function* updateTechProfileDataAsync(action: UpdateTechDataAction) {
  try {
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/admin/techProfile",
      {
        method: "PUT",
        body: JSON.stringify(action.payload),
      }
    );

    const data: APIResponse<any> = yield response.json();

    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      yield put({
        type: adminActionTypes.UDPATE_TECH_PROFILE_DATA_SUCCESS,
        payload: { status: data.status_code },
      });
    } else {
      yield put({
        type: adminActionTypes.UDPATE_TECH_PROFILE_DATA_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating tech profile data" },
    });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: adminActionTypes.UDPATE_TECH_PROFILE_DATA_FAIL,
      payload: { status: error.status_code, message: error.message },
    });
  }
}

export function* updateTechProfilePhotoAsync(action: UploadTechProfilePhotoAction) {
  const formData = new FormData();

  for (let i = 0; i < action.payload.files.length; i++) {
    formData.append("document", action.payload.files[i]);
    formData.append("userId", action.payload.techId);
    formData.append("userType", "tech");
    formData.append("userId", action.payload.userId);
    formData.append("fileName", action.payload.fileName);
  }

  try {
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/user/uploadDocument",
      {
        method: "POST",
        body: formData,
      }
    );

    const data: APIResponse<any> = yield response.json();

    if (data.status_code === 200) {
      yield put({
        type: adminActionTypes.UDPATE_TECH_PROFILE_PHOTO_SUCCESS,
        payload: { status: data.status_code, techImage: data.data.data },
      });
    } else {
      yield put({
        type: adminActionTypes.UDPATE_TECH_PROFILE_PHOTO_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating tech profile photo" },
    });

    yield put({
      type: adminActionTypes.UDPATE_TECH_PROFILE_PHOTO_FAIL,
      payload: { status: error.status_code, message: error.message },
    });
  }
}

export function* updateHospitalDelayPaymentSettingAsync(action: SetDelayPaymentAction) {
  try {
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/hospital/delayPaymentSetting",
      {
        method: "PUT",
        body: JSON.stringify(action.payload),
      }
    );
    const data: APIResponse<any> = yield response.json();

    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      yield put({
        type: adminActionTypes.ADMIN_UPDATE_HOSPITAL_DELAY_PAYMENT_SETTING_SUCCESS,
        payload: { status: data.status_code, data: data },
      });
    } else {
      yield put({
        type: adminActionTypes.ADMIN_UPDATE_HOSPITAL_DELAY_PAYMENT_SETTING_FAIL,
        payload: { status_code: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating hospital delay payment setting" },
    });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: adminActionTypes.ADMIN_UPDATE_HOSPITAL_DELAY_PAYMENT_SETTING_FAIL,
      payload: { status_code: error.status_code, message: EXCEPTION_MESSAGE },
    });
  }
}

export function* updateHospitalInternshipAvailabilityFeatureAsync(
  action: ToggleInternshipAvailabilityFeatureAction
) {
  try {
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/hospital/toggleInternshipFeatureForHospital",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );
    const data: APIResponse<any> = yield response.json();

    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      yield put({
        type: adminActionTypes.ADMIN_UPDATE_HOSPITAL_INTERNSHIPS_AVAILABILITY_SUCCESS,
        payload: { status: data.status_code, data: data },
      });
    } else {
      yield put({
        type: adminActionTypes.ADMIN_UPDATE_HOSPITAL_INTERNSHIPS_AVAILABILITY_FAIL,
        payload: { status_code: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating hospital internship availability feature" },
    });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: adminActionTypes.ADMIN_UPDATE_HOSPITAL_INTERNSHIPS_AVAILABILITY_FAIL,
      payload: { status_code: error.status_code, message: EXCEPTION_MESSAGE },
    });
  }
}

export function* updateTechProbStatusAsync(action: UpdateTechProbationStatusAction) {
  try {
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/tech/probationStatus",
      {
        method: "PUT",
        body: JSON.stringify(action.payload),
      }
    );

    const data: APIResponse<any> = yield response.json();

    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      yield put({
        type: adminActionTypes.UDPATE_TECH_PROB_STATUS_SUCCESS,
        payload: { status: data.status_code, data: data.data },
      });
    } else {
      yield put({
        type: adminActionTypes.UDPATE_TECH_PROB_STATUS_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating tech probation status" },
    });
    yield put({ type: UNSET_LOADER });

    yield put({
      type: adminActionTypes.UDPATE_TECH_PROB_STATUS_FAIL,
      payload: { status: error.status_code, message: EXCEPTION_MESSAGE },
    });
  }
}

export function* updateUnSuspendedDateAsync(action: SetUnsuspendedDateAction) {
  try {
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/tech/unsuspendedDate",
      {
        method: "PUT",
        body: JSON.stringify(action.payload),
      }
    );

    const data: APIResponse<any> = yield response.json();

    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      yield put({
        type: adminActionTypes.SET_UNSPENDED_DATE_SUCCESS,
        payload: { status: data.status_code, data: data.data },
      });
    } else {
      yield put({
        type: adminActionTypes.SET_UNSPENDED_DATE_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating tech unsuspended date" },
    });
    yield put({ type: UNSET_LOADER });

    yield put({
      type: adminActionTypes.SET_UNSPENDED_DATE_FAIL,
      payload: { status: error.status_code, message: EXCEPTION_MESSAGE },
    });
  }
}

export function* setReputationAndFavorability(action: SetReputationAndFavorabilityAction) {
  try {
    yield put({ type: SET_LOADER });
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/hospital/setReputationAndFavorability",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data: APIResponse<any> = yield response.json();

    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      yield put({
        type: adminActionTypes.SET_REPUTATION_LOCATION_FAVORABILITY_SUCCESS,
        payload: { status: data.status_code, data: data.data.data },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating hospital reputation and location favorability" },
    });
    yield put({ type: UNSET_LOADER });
  }
}

export function* validateAddress(action: ValidateAddressAction) {
  try {
    yield put({ type: SET_LOADER });
    const { address, states, oSave, city, stateId, zipcode } = action.payload;
    const response: Response = yield call(
      rooFetch,
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        address +
        "&key=" +
        window.RooConfig.MAP_KEY_FRONT_END +
        "&type=json&_=" +
        new Date().getTime()
    );

    const data: APIResponse<any> & { status: any; results: any } = yield response.json();
    // here basically we compare 2 things in address
    // 1. zipcode
    // 2. state
    if (data.status == "OK" && data.results.length) {
      let fullAddress,
        actual_state,
        isCityNotMatching = "";
      // remove address which do not match zipcode
      const results = data.results.filter((res: any) => {
        const postalCode = res.address_components.filter(
          (addr: any) =>
            addr.types.length == 1 && addr.types[0] == "postal_code" && addr.short_name == zipcode
        );
        return postalCode && postalCode.length;
      });

      results.forEach((element: any) => {
        const isState = element.address_components.find(
          (addr: any) => addr.types.length && addr.types[0] == "administrative_area_level_1"
        );
        const locality = element.address_components.find(
          (addr: any) => addr.types.length && addr.types[0] == "locality"
        );
        if (isState) {
          // stateId should match your current state
          actual_state = states.find(
            (state: any) => state.state_code == isState.short_name && stateId == state.id
          );
          if (actual_state) {
            fullAddress = element;
          }
          const locName = locality.long_name.toLowerCase();
          if (locName.indexOf(city) == -1) {
            isCityNotMatching = locName;
          }
        }
      });
      // if fullAddress matches then check for city
      if (isCityNotMatching) {
        // city is not matching
        yield put({ type: UNSET_LOADER });
        yield put({
          type: adminActionTypes.SHOW_INVALID_ADRESS_MODAL,
          payload: { isInvalidAddress: true, isCityNotMatching: isCityNotMatching },
        });
      } else if (fullAddress) {
        // allow user to switch zip code as It's within same state
        yield put({
          type: UPDATE_TECH_BASIC_INFO,
          payload: {
            techInformation: oSave,
          },
        });
      } else {
        // don't allow user to switch zip code as it is from outside of your current state
        yield put({ type: UNSET_LOADER });
        yield put({
          type: adminActionTypes.SHOW_INVALID_ADRESS_MODAL,
          payload: { isInvalidAddress: true },
        });
      }
    } else {
      yield put({ type: UNSET_LOADER });
      yield put({
        type: adminActionTypes.SHOW_INVALID_ADRESS_MODAL,
        payload: { isInvalidAddress: true },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "validateAddress :: Error : " } });
  }
}

export function* getVetTechByHospitalId(action: GetVetTechWorkedForHospitalAction) {
  try {
    yield put({ type: SET_LOADER });

    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/admin/getVetTechByHospitalId/" + action.payload.hospitalId
    );

    const data: APIResponse<any> = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (data.status_code == 200) {
      yield put({ type: adminActionTypes.GET_VET_TECH_BY_HOSPITAL_ID_SUCCESS, payload: data.data });
    }
  } catch (error) {
    yield put({ type: UNSET_LOADER });
    yield put({ type: adminActionTypes.GET_VET_TECH_BY_HOSPITAL_ID_ERROR });
    Sentry.captureException(error, { extra: { context: "getVetTechByHospitalId :: Error :" } });
  }
}

export function* submitFulltimePlacement(action: SubmitFulltimePlacementAction) {
  try {
    yield put({ type: SET_LOADER });
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/admin/submitFulltimePlacement",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );
    const data: APIResponse<any> = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code == 200) {
      yield put({ type: adminActionTypes.SUBMIT_FULLTIME_PLACEMENT_SUCCESS });
      yield put({ type: adminActionTypes.GET_FULLTIME_PLACEMENT_LOGS });
    } else {
      yield put({ type: adminActionTypes.SUBMIT_FULLTIME_PLACEMENT_ERROR });
    }
  } catch (error) {
    yield put({ type: UNSET_LOADER });
    Sentry.captureException(error, { extra: { context: "submitFulltimePlacement :: Error :" } });
    yield put({ type: adminActionTypes.SUBMIT_FULLTIME_PLACEMENT_ERROR });
  }
}

export function* getFulltimePlacementLogs(action: GetFulltimePlacementLogsAction) {
  try {
    yield put({ type: SET_LOADER });

    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL +
        "api/admin/getFulltimePlacementLogs" +
        (action.payload && action.payload.hospitalId
          ? `?hospitalId=${action.payload.hospitalId}`
          : "")
    );

    const data: APIResponse<any> = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (data.status_code == 200) {
      yield put({ type: adminActionTypes.GET_FULLTIME_PLACEMENT_LOGS_SUCCESS, payload: data.data });
    } else {
      yield put({ type: adminActionTypes.GET_FULLTIME_PLACEMENT_LOGS_ERROR });

      Sentry.captureException("getFulltimePlacementLogs :: Error :", {
        extra: { context: "getFulltimePlacementLogs :: Error :", data: data },
      });
    }
  } catch (error) {
    yield put({ type: UNSET_LOADER });
    yield put({ type: adminActionTypes.GET_FULLTIME_PLACEMENT_LOGS_ERROR });
    Sentry.captureException(error, { extra: { context: "getFulltimePlacementLogs :: Error : " } });
  }
}

export function* updatePlacementPaymentStatus(action: UpdatePlacementPaymentStatusAction) {
  try {
    yield put({ type: SET_LOADER });
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/admin/updatePlacementPaymentStatus",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );
    const data: APIResponse<any> = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code == 200) {
      yield put({
        type: adminActionTypes.UPDATE_PLACEMENT_PAYMENT_STATUS_SUCCESS,
        payload: data.data,
      });
      yield put({ type: adminActionTypes.GET_FULLTIME_PLACEMENT_LOGS });
    } else {
      yield put({ type: adminActionTypes.UPDATE_PLACEMENT_PAYMENT_STATUS_ERROR });
      Sentry.captureException("updatePlacementPaymentStatus :: Error : ", {
        extra: { context: "updatePlacementPaymentStatus :: Error : ", data: data },
      });
    }
  } catch (error) {
    yield put({ type: UNSET_LOADER });
    yield put({ type: adminActionTypes.UPDATE_PLACEMENT_PAYMENT_STATUS_ERROR });
    Sentry.captureException(error, {
      extra: { context: "updatePlacementPaymentStatus :: Error : " },
    });
  }
}

export function* updateStripeConnectAccountId(action: UpdateStripeConnectAccountIdAction) {
  try {
    yield put({ type: SET_LOADER });
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/admin/updateStripeConnectAccountId",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );
    const data: APIResponse<any> = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code == 200) {
      yield put({
        type: adminActionTypes.UPDATE_STRIPE_CONNECT_ACCOUNT_ID_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({ type: adminActionTypes.UPDATE_STRIPE_CONNECT_ACCOUNT_ID_FAIL });
      Sentry.captureException("updateStripeConnectAccountIdloginActions :: Error : ", {
        extra: { context: "updateStripeConnectAccountIdloginActions :: Error : ", data: data },
      });
    }
  } catch (error) {
    yield put({ type: UNSET_LOADER });
    yield put({ type: adminActionTypes.UPDATE_STRIPE_CONNECT_ACCOUNT_ID_FAIL });
    Sentry.captureException(error, {
      extra: { context: "updateStripeConnectAccountId :: Error : " },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootUserSaga() {
  yield takeEvery(adminActionTypes.FETCH_ADMIN_COMMON_DATA, fetchAdminCommonDataAsync);
  yield takeEvery(adminActionTypes.FETCH_ADMIN_STATES, fetchAdminStatesAsync);
  yield takeEvery(adminActionTypes.FETCH_METRO_AREAS, fetchMetroAreasAsync);
  yield takeEvery(loginActionTypes.LOG_IN_AS_USER, logInAsUserAsync);
  yield takeEvery(adminActionTypes.GET_USER_LIST, getUserListAsync);
  yield takeEvery(adminActionTypes.GET_CONTRACTOR_LIST, getContractorListAsync);
  yield takeEvery(adminActionTypes.GET_STUDENT_LIST, getStudentListAsync);
  yield takeEvery(adminActionTypes.GET_HOSPITAL_LIST, getHospitalListAsync);

  yield takeEvery(adminActionTypes.GET_ROO_ADMIN_LIST, getRooAdminListAsync);
  yield takeEvery(adminActionTypes.GET_ROO_SUPER_USER_LIST, getRooSuperUserListAsync);
  yield takeEvery(adminActionTypes.CREATE_ROO_INTERNAL_USER, createRooInternalUserAsync);
  yield takeEvery(adminActionTypes.DELETE_ROO_INTERNAL_USER, deleteRooInternalUserAsync);
  yield takeEvery(adminActionTypes.FETCH_HOSPITAL_DATA, getHospitalDataAsync);
  yield takeEvery(adminActionTypes.FETCH_STUDENT_DATA, getStudentDataAsync);
  yield takeEvery(adminActionTypes.REQUEST_CHANGE_USER_STATUS, changeUserStatusAsync);
  yield takeEvery(adminActionTypes.UPDATE_HOSPITAL_CONTACT, updateHospitalContactAsync);
  yield takeEvery(adminActionTypes.GET_RATING_SUMMARY, getRatingSummaryAsync);
  yield takeEvery(
    adminActionTypes.ADMIN_SET_HOSPITAL_OFFILNE_PAYMENT,
    setOffLinePaymentForHospitalAsync
  );
  yield takeEvery(adminActionTypes.UDPATE_TECH_TIER_REQUEST, updateTechTierAsync);
  yield takeEvery(adminActionTypes.FETCH_ADMIN_TECH_PROFILE_DATA_REQUEST, getTechProfileDataAsync);
  yield takeEvery(adminActionTypes.UDPATE_TECH_PROFILE_DATA_REQUEST, updateTechProfileDataAsync);
  yield takeEvery(adminActionTypes.UDPATE_TECH_PROFILE_PHOTO_REQUEST, updateTechProfilePhotoAsync);
  yield takeEvery(
    adminActionTypes.ADMIN_UPDATE_HOSPITAL_DELAY_PAYMENT_SETTING_REQUEST,
    updateHospitalDelayPaymentSettingAsync
  );
  yield takeEvery(
    adminActionTypes.ADMIN_UPDATE_HOSPITAL_INTERNSHIPS_AVAILABILITY_REQUEST,
    updateHospitalInternshipAvailabilityFeatureAsync
  );
  yield takeEvery(adminActionTypes.UDPATE_TECH_PROB_STATUS_REQUEST, updateTechProbStatusAsync);
  yield takeEvery(adminActionTypes.SET_UNSPENDED_DATE_REQUEST, updateUnSuspendedDateAsync);
  yield takeEvery(adminActionTypes.DELETE_HOSPITAL, deleteHospitalAsync);
  yield takeEvery(adminActionTypes.DELETE_CONTRACTOR, deleteContractorAsync);
  yield takeEvery(adminActionTypes.DELETE_STUDENT, deleteStudentAsync);

  yield takeEvery(adminActionTypes.FILE_DOWNLOAD, downloadFileAsync);

  yield takeEvery(
    adminActionTypes.SET_REPUTATION_LOCATION_FAVORABILITY,
    setReputationAndFavorability
  );

  yield takeEvery(adminActionTypes.VALIDATE_ADDRESS, validateAddress);

  yield takeEvery(adminActionTypes.GET_VET_TECH_BY_HOSPITAL_ID, getVetTechByHospitalId);
  yield takeEvery(adminActionTypes.SUBMIT_FULLTIME_PLACEMENT, submitFulltimePlacement);

  yield takeEvery(adminActionTypes.GET_FULLTIME_PLACEMENT_LOGS, getFulltimePlacementLogs);

  yield takeEvery(adminActionTypes.UPDATE_PLACEMENT_PAYMENT_STATUS, updatePlacementPaymentStatus);

  yield takeEvery(adminActionTypes.UPDATE_STRIPE_CONNECT_ACCOUNT_ID, updateStripeConnectAccountId);
}
