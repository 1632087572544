import React, { createContext, useContext, useEffect, useMemo } from "react";

import { MeetYourRep, VetPreferences, WhyAreYouOnRoo } from "../../../api/vet";
import { useMediaSourceOptions } from "../../../Common/Form/hooks/useMediaSourceOptions";
import { usePersistState } from "../../../Common/hookUtils";
import { getReferralCode } from "../../../Common/queryParamsUtils";
import { ValueOf } from "../../../Common/typeUtils";
import {
  IMPROVED_FILTER_APPOINTMENT_TYPES,
  IMPROVED_PREFERRED_PROCEDURES,
  LC_TOPICS_TO_DISCUSS_OPTIONS,
  TYPICAL_AVAILABILITY_OPTIONS,
} from "../../../constants/checkBoxConstants";
import { techRegistrationFlows } from "../../../constants/constants";
import { fetchCommonDataAction } from "../../../Registration/Action/RegisterActions";
import { useAppDispatch, useAppSelector } from "../../../store/service";
import { RegistrationEducationFormType } from "../RegistrationEducation/RegistrationEducationFormContext";
import { RegistrationLandingFormType } from "../RegistrationLanding/RegistrationLandingFormContext";
import { RegistrationLicenseFormType } from "../RegistrationLicense/RegistrationLicenseFormContext";
import { RegistrationLocationFormType } from "../RegistrationLocation/RegistrationLocationFormContext";
import { RegistrationReferralFormType } from "../RegistrationReferral/RegistrationReferralFormContext";
import { RegistrationSignUpFormType } from "../RegistrationSignUp/RegistrationSignUpFormContext";

export type RegistrationContextStateType = {
  registrationContext: {
    // Fields for making API requests
    userId: number;
    entityId: number;

    // Tech license page states - filled with backend response after sending the zipcode
    stateId?: number;
    stateName?: string;
    techRegistrationFlow?: ValueOf<typeof techRegistrationFlows>;

    // Bring progressPercent into context to enable the progress bar animations between pages
    progressPercent: number;

    // Calendly button
    isCalendlyButtonDisabled: boolean;

    // Posthog identity fields
    metroAreaId: number;

    // Attribution fields
    utmSource?: string;
  } & RegistrationLandingFormType &
    RegistrationSignUpFormType &
    RegistrationLocationFormType &
    RegistrationLicenseFormType &
    RegistrationEducationFormType &
    RegistrationReferralFormType &
    VetPreferences &
    WhyAreYouOnRoo &
    MeetYourRep;
};

export type RegistrationContextType = RegistrationContextStateType & {
  setRegistrationContext: React.Dispatch<React.SetStateAction<RegistrationContextStateType>>;
};

const RegistrationContext = createContext<RegistrationContextType | undefined>(undefined);

export const useRegistrationContext = () => {
  return useContext(RegistrationContext);
};

export const RegistrationContextProvider: React.FC<{
  initialContext?: RegistrationContextStateType;
  children?: React.ReactNode;
}> = ({ initialContext, children }) => {
  const dispatch = useAppDispatch();
  const login = useAppSelector((state) => state.login);
  const vetData = useAppSelector((state) => state.registerVet.vetData);
  const techData = useAppSelector((state) => state.registerTech.techData);

  const initialPreferredAreasOfPractice = IMPROVED_FILTER_APPOINTMENT_TYPES;
  const initialPreferredProcedures = IMPROVED_PREFERRED_PROCEDURES;

  // State
  const { referralOptionId } = useMediaSourceOptions();
  const [registrationContext, setRegistrationContext, clearPersistedState] =
    usePersistState<RegistrationContextStateType>(
      "roo-registration-context",
      // @ts-expect-error TS2345
      initialContext || {
        registrationContext: {
          // @ts-expect-error TS2345
          userId: login.userId || parseInt(localStorage.getItem("userId")) || -1,
          entityId:
            login.vetId ||
            login.techId ||
            login.hospitalId ||
            login.studentId ||
            // @ts-expect-error TS2345
            parseInt(localStorage.getItem("vetId")) ||
            // @ts-expect-error TS2345
            parseInt(localStorage.getItem("techId")) ||
            -1,
          progressPercent: 0,
          firstName: login.firstName || vetData.firstName || vetData.firstName || "",
          lastName: login.lastName || vetData.lastName || techData.lastName || "",
          email: login.email || vetData.email || techData.email || "",
          password: "",
          zipcode: login.zipcode,
          travelDistance: login.travelDistance || 35,
          licenses:
            login.licenses && login.licenses.length > 0 ? login.licenses : [{ licenceNumber: "" }],
          deletedLicenses: [],
          school: login.school || "",
          yearsOfPractice: login.yearsOfPractice,
          preferredAreasOfPractice: initialPreferredAreasOfPractice,
          preferredProcedures: initialPreferredProcedures,
          typicalAvailableDays: TYPICAL_AVAILABILITY_OPTIONS.map((dayOption) => ({
            ...dayOption,
            checked: false,
          })),
          topicsToDiscuss: LC_TOPICS_TO_DISCUSS_OPTIONS.map((option) => ({
            ...option,
            checked: false,
          })),
          lookingToStart: undefined,
          currentSituation: undefined,
          typicalAvailableDaysOpenResponse: undefined,
          lookingToStartOpenResponse: undefined,
          // @ts-expect-error TS2345
          userType: login.userTypeId || parseInt(localStorage.getItem("userTypeId")),
          metroAreaId: login.metroAreaId,
          isCalendlyButtonDisabled: true,
        },
      }
    );

  // Store userid in localstorage for event tracking
  localStorage.setItem("userId", String(registrationContext.registrationContext.userId));

  useEffect(() => {
    dispatch(fetchCommonDataAction({ isFromAdmin: false }));
    return clearPersistedState;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Default media source to referral code if the referral link was shared
    const { referralCode } = getReferralCode(window.location.search);
    if (referralCode) {
      setRegistrationContext((prevState) => ({
        registrationContext: {
          ...prevState?.registrationContext,
          referralCode,
          hearAboutRoo: typeof referralOptionId === "number" ? referralOptionId : undefined,
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralOptionId]);

  const memoizedValues = useMemo(
    () => ({
      // General states
      ...registrationContext,
      setRegistrationContext,
    }),
    [
      // General states
      registrationContext,
      setRegistrationContext,
    ]
  );
  return (
    <RegistrationContext.Provider value={memoizedValues}>{children}</RegistrationContext.Provider>
  );
};
