export const Z_INDEX = {
  SHIFT_DETAILS_TABS: 1,
  EDIT_SHIFT_PRICE_ICON: 1,
  CUSTOM_TOAST: 2,
  ADMIN_SHIFT_ROW: 10,
  ZENDESK_WIDGET: 101,
  ADMIN_PROMO_EXPORT_CSV: 999,
  TECH_ONBOARDING_MODAL: 1_000,
  SHIFT_ADJUSTMENTS_DRAWER: 1_001,
  SHIFT_CANCELLATION_DRAWER: 1_002,
  MODAL: 1_050,
  CHAT_PANEL: 1_051,
  NEW_FILTERS_POPOVER: 1_051,
  MARS_AGREEMENT_POPOVER: 1_051,
  FEAR_FREE_POPOVER: 1_051,
  TOAST: 1_060,
  DEA_UPDATE_TOAST: 1_060,
  CHAT_TEMPLATES: 1_600,
  ADMIN_CONFERENCE_EVENTS_TOAST: 9_999,
  NOTIFICATION_SETTINGS_TOAST: 9_999,
  SHIFT_CANCELLATION_TOAST: 9_999,
  NEW_FEATURE_CALLOUT: 10_000,
};
