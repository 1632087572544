import React from "react";
import { useAuthorizedUser } from "@RooBeta/hooks";
import { Loader, LoaderVariant } from "./Loader";

export const AuthenticatedContent = ({
  children,
  showLoader = true,
  loaderVariant = "inline",
}: {
  children: React.ReactNode;
  showLoader?: boolean;
  loaderVariant?: LoaderVariant;
}) => {
  const { isLoggedIn, isLoading } = useAuthorizedUser();

  if (isLoading || !isLoggedIn) {
    return showLoader ? <Loader $variant={loaderVariant} /> : null;
  }

  return <>{children}</>;
};
