import React from "react";

import { Button, ButtonProps } from "../Button";
import { Icon, IconProps } from "../Icon";
import { SmallText, SmallBoldText } from "../Text";

import {
  InlineContainer as InlineContainerStyle,
  InlineContainerActionArea,
  InlineContainerContent,
  InlineContainerLeftContainer,
  InlineContainerMainContent,
  InlineContainerRightContainer,
  DismissButton,
} from "./InlineContainerStyles";

export interface InlineContainerProps {
  title?: React.ReactNode;
  description?: React.ReactNode;
  actionButtonProps?: ButtonProps;
  iconName?: IconProps["name"];
  showDismiss?: boolean;
  onDismiss?: () => void;
  children?: React.ReactNode;
}

export const InlineContainer = ({
  title,
  description,
  actionButtonProps,
  iconName,
  showDismiss,
  onDismiss,
  children,
  ...rest
}: InlineContainerProps) => {
  return (
    <InlineContainerStyle {...rest}>
      <InlineContainerMainContent>
        <InlineContainerLeftContainer>
          {iconName && <Icon name={iconName} size="l" />}
          <InlineContainerContent>
            {title && <SmallBoldText>{title}</SmallBoldText>}
            {description && <SmallText>{description}</SmallText>}
            {children}
          </InlineContainerContent>
        </InlineContainerLeftContainer>
        <InlineContainerActionArea>
          {actionButtonProps && (
            <Button {...actionButtonProps} $buttonType="primary" size="small" />
          )}
        </InlineContainerActionArea>
      </InlineContainerMainContent>
      {showDismiss && (
        <InlineContainerRightContainer>
          <DismissButton
            onClick={onDismiss}
            aria-label="Dismiss"
            data-testid="inlineContainerDismissButton"
          >
            <Icon name="Close" size="s" />
          </DismissButton>
        </InlineContainerRightContainer>
      )}
    </InlineContainerStyle>
  );
};
