import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { EventTechSignUpForm, createTechDataAccount } from "../../../../api/tech";
import { TECH_USER_TYPE } from "../../../../constants/UserTypeConstants";
import { loginRequestAction } from "../../../../Login/Action/LoginActions";
import { REGISTRATION_PATHS } from "../../../../RegistrationNew/Components/Common/service";
import { useAppDispatch, useAppSelector } from "../../../../store/service";
import { OnTechSignedUp } from "../common/EventSignUp";
import { useValidate } from "../common/useValidate";
import { getUtmParams } from "../common/util";

import { TechSignUp } from "./TechSignUp";
import { FormProvider } from "./useFormContext";

export const TechSignUpContainer = ({
  booth,
  onSuccess,
  eventSlug,
}: {
  booth: boolean;
  onSuccess: OnTechSignedUp;
  eventSlug: string;
}) => {
  const [defaultForm, setDefaultForm] = useState<EventTechSignUpForm>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { userId, userTypeId } = useAppSelector(({ login }) => login);
  const {
    utm_source: utmSource,
    utm_campaign: utmCampaign,
    utm_medium: utmMedium,
  } = getUtmParams(eventSlug, booth);
  const { validate } = useValidate();

  useEffect(() => {
    setDefaultForm({
      userTypeId: TECH_USER_TYPE,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      phoneNumber: "",
      zipcode: "",
      travelDistance: 35,
      isCertified: false,
      isLicensed: false,
      isRegistered: false,
      isVTS: false,
      licenseNumber: "",
      utmSource,
      utmCampaign,
      utmMedium,
    });
  }, [utmSource, utmCampaign, utmMedium]);

  useEffect(() => {
    if (userId && userTypeId === TECH_USER_TYPE) {
      history.push({ pathname: REGISTRATION_PATHS.Hireflix, search: "?fromEvent=1" });
    }
  }, [userId]); // eslint-disable-line react-hooks/exhaustive-deps

  const save = async (form: EventTechSignUpForm) => {
    const { signInRequest, ...result } = await createTechDataAccount(form);

    if (!result.success) {
      throw new Error(result.error);
    }

    if (booth) {
      return onSuccess(result.data);
    }

    const { email, password } = form;
    dispatch(loginRequestAction({ email, password, signInRequest }));
  };

  return (
    <FormProvider
      // @ts-expect-error TS2322
      defaultForm={defaultForm}
      save={save}
      validate={(form: EventTechSignUpForm) => validate({ providerType: "tech", form })}
    >
      <TechSignUp booth={booth} />
    </FormProvider>
  );
};
