import { CheckboxGroup, Select, Tooltip } from "@RooUI";
import React, { useEffect, useState } from "react";

import { createForm } from "../../../../../Common/EditProfile/formContext";
import { getStates } from "../../../../../Common/EditProfile/service";
import { Mutable } from "../../../../../Common/typeUtils";

import { TechCredentialItemsContainer, TechCredentialsContainer } from "./styles";

interface TechCredentialsProps {
  isDisabled?: boolean;
  hideCredentialStates?: boolean;
  useFormContext: ReturnType<
    typeof createForm<{
      isCertified?: boolean | number;
      isRegistered?: boolean | number;
      isLicensed?: boolean | number;
      isVTS?: boolean | number;
      credentialStates?: number[];
    }>
  >["useFormContext"];
  flex?: boolean;
}
export const TechCredentials = (props: TechCredentialsProps) => {
  const { isDisabled, useFormContext, hideCredentialStates, flex } = props;
  const {
    onChange,
    errors,
    // @ts-expect-error TS2339
    form: { isCertified, isRegistered, isLicensed, isVTS, credentialStates },
  } = useFormContext();

  const [states, setStates] = useState([]);

  useEffect(() => {
    /* eslint-disable promise/prefer-await-to-then */
    // @ts-expect-error TS2345
    getStates().then(setStates);
    /* eslint-enable promise/prefer-await-to-then */
  }, []);

  const renderElement = (element: React.ReactNode) => {
    if (isDisabled) {
      return (
        <Tooltip title="Please contact Roo to make any updates to your licensure information.">
          {element}
        </Tooltip>
      );
    } else {
      return element;
    }
  };

  const checkboxes = [
    {
      label: "LVT",
      value: isLicensed ? 1 : 0,
      id: "isLicensed",
      checked: !!isLicensed,
      // @ts-expect-error TS18048
      error: errors["isLicensed"],
    },
    {
      label: "CVT",
      value: isCertified ? 1 : 0,
      id: "isCertified",
      checked: !!isCertified,
      // @ts-expect-error TS18048
      error: errors["isCertified"],
    },
    {
      label: "RVT",
      value: isRegistered ? 1 : 0,
      id: "isRegistered",
      checked: !!isRegistered,
      // @ts-expect-error TS18048
      error: errors["isRegistered"],
    },
    {
      label: "VTS",
      value: isVTS ? 1 : 0,
      id: "isVTS",
      checked: !!isVTS,
      // @ts-expect-error TS18048
      error: errors["isRegistered"],
    },
  ] as const;

  return (
    <TechCredentialsContainer>
      <div>
        <TechCredentialItemsContainer>
          {renderElement(
            <CheckboxGroup
              label="Technician credentials (select any that apply)"
              // as const narrows the type but makes properties read-only: we need them them to be mutable
              checkboxes={checkboxes as Mutable<typeof checkboxes>}
              onChange={(_event, updatedCheckboxes, _value) => {
                updatedCheckboxes.forEach((checkbox) => {
                  // @ts-expect-error TS2722
                  onChange(checkbox.id, checkbox.checked);
                });
              }}
              flex={flex}
              disabled={isDisabled}
            />
          )}
        </TechCredentialItemsContainer>
      </div>
      {!hideCredentialStates &&
        renderElement(
          <Select
            label="Which states are you credentialed in?"
            options={states}
            value={credentialStates}
            multi
            // @ts-expect-error TS18048
            error={errors["credentialStates"]}
            onChange={(_option, value) => {
              // @ts-expect-error TS2722
              onChange("credentialStates", value);
            }}
            data-testid="credentialStates"
            disabled={isDisabled}
          />
        )}
    </TechCredentialsContainer>
  );
};
