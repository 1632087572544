import React, { useState, useEffect } from "react";
import { OpenText, SmallMediumText, SmallText, blueDark600, grayWhite, grayBase } from "@RooUI";
import styled from "styled-components";
import { useAvailableDaysQuery } from "@RooBeta/hooks";
import moment from "moment";
import { HStack } from "@RooBeta/components";

const TextAreaContainer = styled.div`
  position: relative;
  width: 100%;
`;

const StickyContainer = styled(HStack)`
  align-items: flex-start;
  position: absolute;
  bottom: 2px;
  left: 5px;
  right: 11px;
  background-color: ${grayWhite};
  padding: 8px 0px 10px 6px;
  z-index: 1;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
`;

const MIN_HEIGHT = 180;

interface ShiftProposalMessageComposerProps {
  message: string;
  setMessage: (message: string) => void;
}

export const ShiftProposalMessageComposer = ({
  message,
  setMessage,
}: ShiftProposalMessageComposerProps) => {
  const { data: availability } = useAvailableDaysQuery();
  const stickyRef = React.useRef<HTMLDivElement>(null);
  const [stickyContainerHeight, setStickyContainerHeight] = useState(0);
  const maxDate = moment().add(3, "month").endOf("month").endOf("day").toDate();

  const fourMonthAvailability = availability?.filter(
    (day) =>
      moment(day.date).isSameOrAfter(moment().startOf("day")) &&
      moment(day.date).isSameOrBefore(maxDate)
  );

  const fourMonthAvailabilityString = fourMonthAvailability
    ?.map((day) => moment(day.date).format("MMM D"))
    .join(", ");

  useEffect(() => {
    if (stickyRef.current) {
      const height = stickyRef.current.getBoundingClientRect().height;
      setStickyContainerHeight(height);
    }
  }, [fourMonthAvailabilityString]);

  return (
    <TextAreaContainer>
      <OpenText
        label="Message"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        data-testid="messageInput"
        style={{
          paddingBottom: stickyContainerHeight + 4,
          // stickyContainer should not be more than 1/2 the height of the OpenText
          height: stickyContainerHeight * 2 > MIN_HEIGHT ? stickyContainerHeight * 2 : MIN_HEIGHT,
        }}
      />
      <StickyContainer ref={stickyRef} $gap="xs">
        <SmallMediumText color={blueDark600}>Available:</SmallMediumText>
        <SmallText color={grayBase}>{fourMonthAvailabilityString}</SmallText>
      </StickyContainer>
    </TextAreaContainer>
  );
};
