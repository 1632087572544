import { Alert, SmallText } from "@RooUI";
import React from "react";

import { useRequiredAuthorizedUser } from "@RooBeta/hooks";
import { useShiftDetailsStore } from "@RooBeta/store";

import { FlexibilityBanner } from "./FlexibilityBanner";
import { ShiftDetailsMetadata } from "./ShiftDetailsMetadata";
import { ShiftMetadataLoading } from "./ShiftMetadataLoading";
import { TabContentContainer } from "./styles";
import { WarningSection } from "./WarningSection";
import { VStack } from "@RooBeta/components/Flex";
import { Notes } from "./Notes";

const ErrorState = () => (
  <TabContentContainer>
    <Alert $alertStatus="error" alertBody={<SmallText>Oops, something went wrong.</SmallText>} />
  </TabContentContainer>
);

const LoadingState = () => (
  <TabContentContainer data-testid="skeleton">
    <ShiftMetadataLoading count={8} />
  </TabContentContainer>
);

export const ShiftInfo = () => {
  const user = useRequiredAuthorizedUser();
  const { shiftDetails, contractStatus, loading, errors } = useShiftDetailsStore();

  if (loading.shiftDetails) return <LoadingState />;
  if (errors.shiftDetails) return <ErrorState />;
  if (!shiftDetails) return null;

  const isDEALicenseSatisfied = !shiftDetails.isDEALicenseRequired || user.hasDEA;
  const isNDASatisfied = !contractStatus?.enterpriseContractId || contractStatus.signature;

  return (
    <>
      <FlexibilityBanner shiftDetails={shiftDetails} />
      <WarningSection
        shiftDetails={shiftDetails}
        isDEALicenseSatisfied={isDEALicenseSatisfied}
        isNDASatisfied={isNDASatisfied}
      />
      <ShiftDetailsMetadata metadata={shiftDetails.metadata} />
      <VStack $gap="m" $padding="l" $paddingTop="xs">
        <Notes label="More information" content={shiftDetails.additionalComments} />
      </VStack>
    </>
  );
};
