import styled from "styled-components";

import { blueBase, purpleBackground, spacingL, spacingS } from "../../styles/constants";

import { InlineContainerProps } from ".";

export const InlineContainer = styled.div<InlineContainerProps>`
  display: flex;
  width: 100%;
  padding: ${spacingL};
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  background: ${purpleBackground};
  gap: ${spacingS};
`;

export const InlineContainerMainContent = styled.div`
  display: flex;
  flex: 1;
  gap: ${spacingS};
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const InlineContainerLeftContainer = styled.div`
  display: flex;
  align-items: flex-start;

  > *:first-child {
    flex-shrink: 0;
  }
`;

export const InlineContainerActionArea = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  gap: ${spacingS};
  margin-left: auto;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const InlineContainerRightContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const DismissButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus-visible {
    outline: 2px solid ${blueBase};
  }
`;

export const InlineContainerContent = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  margin-left: ${spacingS};
`;
