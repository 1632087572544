import { BoldText, Button, Link, SmallText, grayLight300 } from "@RooUI";
import React, { useState } from "react";

import { HStack, RooIcon, VStack } from "@RooBeta/components";
import { useRequiredAuthorizedUser, useShiftRequestMutation, useRoutes } from "@RooBeta/hooks";
import { useShiftDetailsStore, useToastStore } from "@RooBeta/store";

import { shiftNotAvailableToasts } from "@Roo/Common/Toasts/constants";
import { SHIFT_CATEGORY } from "@Roo/constants/shiftConstants";
import { MarsNDAModal } from "@Roo/HospitalProfilePanels/MarsNDA/MarsNDAModal";
import { PromoToast } from "./PromoToast";
import { TOAST_DURATION, TOAST_PRIORITIES } from "@RooBeta/store/useToastStore";

interface ShiftRequestBody {
  promoId?: number | null;
  isWorkingInterviewAcknowledged?: boolean;
}

export const UnfilledShiftCTA = () => {
  const user = useRequiredAuthorizedUser();
  const { show } = useToastStore();
  const [isNDAModalOpen, setIsNDAModalOpen] = useState(false);
  const routes = useRoutes();

  const {
    shiftDetails,
    contractStatus,
    setDrawer,
    promoId,
    workingInterviewChecked,
    setWorkingInterviewChecked,
  } = useShiftDetailsStore();

  const isDEALicenseSatisfied = !shiftDetails?.isDEALicenseRequired || user.hasDEA;
  const isWorkingInterview = shiftDetails?.shiftCategory === SHIFT_CATEGORY.WORKING_INTERVIEW;

  const shiftRequest = useShiftRequestMutation(shiftDetails);

  const isShiftModifiable = shiftDetails?.canModifyShift && isDEALicenseSatisfied;

  const createShiftRequestBody = (): ShiftRequestBody => ({
    promoId,
    isWorkingInterviewAcknowledged: isWorkingInterview ? workingInterviewChecked : undefined,
  });

  const handleSubmitSignature = async () => {
    setIsNDAModalOpen(false);

    const requestBody = createShiftRequestBody();
    const { success, message } = await shiftRequest.mutateAsync(requestBody);

    if (!success) {
      show({
        variant: "error",
        title: shiftNotAvailableToasts(message, "").toastMessage,
        message: shiftNotAvailableToasts(message, "").toastDescription,
        region: "panel",
        priority: TOAST_PRIORITIES.SERVER_RESPONSE,
        duration: TOAST_DURATION,
      });
    } else {
      show({
        variant: "success",
        title: "Your request has been submitted!",
        message: (
          <SmallText>
            You can review or download your signed Mars agreement at any time in your{" "}
            <Link linkSize="small" href={routes.PROFILE}>
              Roo Profile
            </Link>
            .
          </SmallText>
        ),
        region: "panel",
        priority: TOAST_PRIORITIES.SERVER_RESPONSE,
        duration: TOAST_DURATION,
      });

      // Reset the acknowledgment checkbox after successful submission
      setWorkingInterviewChecked(false);
    }
  };

  return shiftDetails ? (
    <HStack $justifyContent="space-between" $padding="m">
      <VStack>
        <BoldText data-testid="totalPay">{shiftDetails.priceFormatted}</BoldText>
        <SmallText color={grayLight300}>Total pay</SmallText>
      </VStack>
      <HStack $justifyContent="flex-end" $gap="s">
        {contractStatus?.enterpriseContractId && !contractStatus.signature ? (
          <>
            <Button
              $buttonType="primary"
              onClick={() => setIsNDAModalOpen(true)}
              disabled={
                shiftDetails.isStateLicensedRequired ||
                (isWorkingInterview && !workingInterviewChecked)
              }
              data-testid="reviewAgreementBtn"
              loading={shiftRequest.isLoading}
            >
              Review Agreement
            </Button>
            <MarsNDAModal
              onClose={() => setIsNDAModalOpen(false)}
              isShowModal={isNDAModalOpen}
              getRequestShiftFormData={() => ({})}
              contractId={contractStatus.enterpriseContractId}
              handleRequestSubmit={handleSubmitSignature}
            />
          </>
        ) : (
          <>
            {isShiftModifiable && (
              <Button
                $buttonType="secondary"
                style={{ padding: 0, width: 40, flexShrink: 0 }}
                onClick={() => setDrawer("adjust")}
                disabled={
                  shiftDetails.isStateLicensedRequired ||
                  (isWorkingInterview && !workingInterviewChecked)
                }
                data-testid="editShiftBtn"
              >
                <RooIcon icon="edit" size="l" title="Edit" />
              </Button>
            )}
            <Button
              $buttonType="primary"
              onClick={async () => {
                const requestBody = createShiftRequestBody();
                const { success, message } = await shiftRequest.mutateAsync(requestBody);

                if (!success) {
                  show({
                    variant: "error",
                    title: shiftNotAvailableToasts(message, "").toastMessage,
                    message: shiftNotAvailableToasts(message, "").toastDescription,
                    region: "panel",
                  });
                } else {
                  // Reset the acknowledgment checkbox after successful submission
                  setWorkingInterviewChecked(false);
                }
              }}
              disabled={
                !isDEALicenseSatisfied ||
                shiftDetails.isStateLicensedRequired ||
                (isWorkingInterview && !workingInterviewChecked)
              }
              loading={shiftRequest.isLoading}
              style={{ minWidth: 150 }}
              data-testid="requestBtn"
            >
              {shiftDetails.isInstantBookable ? "Book Now" : "Request"}
            </Button>
          </>
        )}
      </HStack>
      <PromoToast />
    </HStack>
  ) : null;
};
