import { z } from "zod";

export enum ShiftCancellationReason {
  Accident = 10,
  HiredFulltime = 9,
  HiredFulltimeFromRoo = 13,
  ScheduleChanges = 11,
  PersonalEmergency = 12,
  Covid19 = 14,
  Other = 15,
}

export const shiftCancellationSchema = z
  .object({
    reasonCode: z.coerce.number({
      required_error: "Please select a reason for cancellation",
      invalid_type_error: "Please select a reason for cancellation",
    }),
    comment: z.string().min(50).optional(),
    hiredHospitalId: z.number().optional(),
  })
  .superRefine((data, ctx) => {
    if (data.reasonCode === ShiftCancellationReason.HiredFulltimeFromRoo && !data.hiredHospitalId) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Please select the hospital you were hired by",
        path: ["hiredHospitalId"],
      });
    }
  });

export type ShiftCancellation = z.infer<typeof shiftCancellationSchema>;

export interface CancelRequestedShift {
  userId: number;
  shiftGroupId: number;
  shiftId: number;
  vets?: Array<number>;
  techs?: Array<number>;
  removeRequestReasons: number;
  requestAdditionalComment?: string;
  hiredHospitalId?: number;
}

export interface CancelConfirmedShift {
  shiftId: number;
  shiftGroupId: number;
  hospitalId: number;
  contractorCallout?: string;
  shiftActivity?: string | null;
  userInitiated?: string;
  contractorRemovalReason?: string;
  prevContractorConfirmed?: number;
  replacement?: number | null;
  rttrNeeded?: number | null;
  shiftNotNedded?: number | null;
  creditRefund?: string;
  isSendDataToHubspot?: boolean | null;
  postSlackMsg?: boolean | null;
  additionalComments?: string | null;
  vetId?: number;
  techId?: number;
  removedByUserId?: number;
  shiftEndDatetime?: number;
  removalReasonId?: number;
  amountCredit?: number | null;
  amountCreditPercent?: number | null;
  amountRefund?: number | null;
  amountRefundPercent?: number | null;
  partialRefundReason?: string | null;
}

export interface RequestCancelConfirmedShift {
  contractorRemovalReason: string;
  additionalComments: string;
  userName: string;
  email: string;
  contractorId: number;
  shiftDate: string;
  hospitalName: string;
  hospitalId: number;
  shiftGroupId: number;
  isVet: boolean;
  isTestTicket: boolean;
  hiredHospitalId?: number;
}
