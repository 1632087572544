import * as Sentry from "@sentry/react";
import { Auth } from "aws-amplify";
import { call, put, takeEvery } from "redux-saga/effects";

import { identifyPosthog } from "../../Common/Wrappers/identifyPosthog";
import { cognitoLogin } from "../../Login/Saga/LoginSagas";
import { rooFetch } from "../../api/common/rooFetch";
import * as LoginAction from "../../constants/Login";
import { SET_AUTHORISE_USER } from "../../constants/Login";
import * as userTypes from "../../constants/UserTypeConstants";
import * as actionTypes from "../../constants/constants";
import * as errorMessage from "../../constants/errorMessage";
import * as LoaderConstants from "../../constants/loader";
import * as studentActionTypes from "../../constants/registerStudent";
import * as techActionTypes from "../../constants/registerTechConstants";
import * as vetActionTypes from "../../constants/registerVetConstants";
import { formatPhoneNumber } from "../../helpers/textUtility";

// @ts-expect-error TS7006
function* putHospitalSignUpData(userData, loginDetails) {
  try {
    // @ts-expect-error TS7057
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/user/createHospitalAccount",
      {
        method: "POST",
        body: JSON.stringify(userData),
      }
    );

    // @ts-expect-error TS7057
    const data = yield response.json();

    if (data.status_code === 200) {
      yield put({ type: SET_AUTHORISE_USER }); //TO set authorize flag once user registered

      yield put({
        type: LoginAction.LOGIN_REQUEST,
        payload: { email: loginDetails.email, password: loginDetails.password },
      });

      yield call(cognitoLogin, loginDetails);
      identifyPosthog({
        userId: data.data.data.userId,
        email: userData.email,
        userType: userTypes.HOSPITAL_USER_TYPE,
      });

      yield put({
        type: actionTypes.CREATE_HOSPITAL_ACCOUNT_SUCCESS,
        payload: {
          status: data.status_code,
          userId: data.data.data.userId,
          hospitalId: data.data.data.hospitalId,
          email: userData.email, // to pass along to hubspot
        },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.CREATE_HOSPITAL_ACCOUNT_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while creating hospital account: " },
    });
    yield put({
      type: actionTypes.CREATE_HOSPITAL_ACCOUNT_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// @ts-expect-error TS7006
function* putVetSignUpData(userData, loginDetails) {
  try {
    // @ts-expect-error TS7057
    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/user/createVetAccount", {
      method: "POST",
      body: JSON.stringify(userData),
    });

    // @ts-expect-error TS7057
    const data = yield response.json();

    if (data.status_code === 200) {
      yield call(cognitoLogin, loginDetails);

      identifyPosthog({
        userId: data.data.data.userId,
        email: loginDetails.email,
        userType: userTypes.VET_USER_TYPE,
      });

      yield put({
        type: vetActionTypes.CREATE_VET_ACCOUNT_SUCCESS,
        payload: {
          userId: data.data.data.userId,
          vetId: data.data.data.vetId,
          zipcode: loginDetails.zipcode,
          email: loginDetails.email,
        },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: vetActionTypes.CREATE_VET_ACCOUNT_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while creating vet account: " } });
    yield put({
      type: vetActionTypes.CREATE_VET_ACCOUNT_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// @ts-expect-error TS7006
function* putTechSignUpData(userData, loginDetails) {
  try {
    // @ts-expect-error TS7057
    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/user/createTechAccount", {
      method: "POST",
      body: JSON.stringify(userData),
    });

    // @ts-expect-error TS7057
    const data = yield response.json();

    if (data.status_code === 200) {
      yield put({ type: SET_AUTHORISE_USER }); //TO set authorize flag once user registered
      yield call(cognitoLogin, loginDetails);

      identifyPosthog({
        userId: data.data.data.userId,
        email: loginDetails.email,
        userType: userTypes.TECH_USER_TYPE,
      });

      yield put({
        type: techActionTypes.CREATE_TECH_ACCOUNT_SUCCESS,
        payload: {
          userId: data.data.data.userId,
          techId: data.data.data.techId,
          zipcode: loginDetails.zipcode,
          email: loginDetails.email,
        },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: techActionTypes.CREATE_TECH_ACCOUNT_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while creating tech account: " } });
    yield put({
      type: techActionTypes.CREATE_TECH_ACCOUNT_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// @ts-expect-error TS7006
function* putStudentSignUpData(userData, loginDetails) {
  try {
    // @ts-expect-error TS7057
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/user/createStudentAccount",
      {
        method: "POST",
        body: JSON.stringify(userData),
      }
    );

    // @ts-expect-error TS7057
    const data = yield response.json();

    if (data.status_code === 200) {
      yield call(cognitoLogin, loginDetails);

      identifyPosthog({
        userId: data.data.data.userId,
        email: loginDetails.email,
        userType: userTypes.STUDENT_USER_TYPE,
      });

      yield put({
        type: studentActionTypes.CREATE_STUDENT_ACCOUNT_SUCCESS,
        payload: {
          userId: data.data.data.userId,
          studentId: data.data.data.studentId,
          zipcode: loginDetails.zipcode,
          email: loginDetails.email,
        },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: studentActionTypes.CREATE_STUDENT_ACCOUNT_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while creating student account: " },
    });
    yield put({
      type: studentActionTypes.CREATE_STUDENT_ACCOUNT_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// @ts-expect-error TS7006
function* signUpAsync(action) {
  const userData = action.payload;
  const isHospital = userData.userTypeId === userTypes.HOSPITAL_USER_TYPE;
  const isVet = userData.userTypeId === userTypes.VET_USER_TYPE;
  const isTech = userData.userTypeId === userTypes.TECH_USER_TYPE;
  const isRooUniStudent = userData.userTypeId === userTypes.STUDENT_USER_TYPE;
  try {
    let signupIdp;
    if (userData) {
      // @ts-expect-error TS7057
      signupIdp = yield Auth.signUp({
        username: userData.email.toLowerCase(),
        password: userData.password,
        attributes: {
          "custom:usertype": userData.userTypeId.toString(),
        },
      });
      userData.userSub = signupIdp.userSub;
    }
    const data = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      phoneNumber: formatPhoneNumber(userData.phoneNumber),
      email: userData.email.toLowerCase(),
      cognitoId: userData.userSub,
      zipcode: userData.zipcode,
      stateId: userData.stateId,
      referralCodeId: userData.referralCodeId ? encodeURIComponent(userData.referralCodeId) : null,
      userTypeId: userData.userTypeId,
      isFromMobile: userData.isFromMobile,
      hospitalId: userData.hospitalId,
      userId: userData.userId, // user id of the person who referred
    };
    userData.isFromRegistration = true;
    if (userData.utmSource) {
      // @ts-expect-error TS2339
      data.utmSource = userData.utmSource;
      // @ts-expect-error TS2339
      data.utmMedium = userData.utmMedium;
      // @ts-expect-error TS2339
      data.utmCampaign = userData.utmCampaign;
      // @ts-expect-error TS2339
      data.utmContent = userData.utmContent;
      // @ts-expect-error TS2339
      data.utmTerm = userData.utmTerm;
    }
    yield put({ type: SET_AUTHORISE_USER }); //TO set authorize flag once user registered
    if (isHospital) {
      yield putHospitalSignUpData(data, userData);
    } else if (isVet) {
      yield putVetSignUpData(data, userData);
    } else if (isTech) {
      yield putTechSignUpData(data, userData);
    } else if (isRooUniStudent) {
      yield putStudentSignUpData(data, userData);
    }

    yield put({ type: LoaderConstants.UNSET_LOADER });
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while signing up: " } });

    if (error.code) {
      let actionType;

      if (isHospital) {
        actionType = actionTypes.CREATE_HOSPITAL_ACCOUNT_FAIL;
      } else if (isVet) {
        actionType = vetActionTypes.CREATE_VET_ACCOUNT_FAIL;
      } else if (isTech) {
        actionType = techActionTypes.CREATE_TECH_ACCOUNT_FAIL;
      } else if (isRooUniStudent) {
        actionType = studentActionTypes.CREATE_STUDENT_ACCOUNT_FAIL;
      }

      if (actionType) {
        yield put({ type: actionType, payload: { status: 409, message: error.message } });
      }
    }
  }
}

function* fetchStateAsync() {
  try {
    // @ts-expect-error TS7057
    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/utility/getStates");
    // @ts-expect-error TS7057
    const data = yield response.json();

    if (data.status_code === 200) {
      yield put({ type: actionTypes.FETCH_STATES_SUCCESS, payload: { states: data.data.data } });
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.FETCH_STATES_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while fetching states: " } });
    yield put({
      type: actionTypes.FETCH_STATES_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// @ts-expect-error TS7006
function* fetchCommonDataAsync(action) {
  try {
    // @ts-expect-error TS7057
    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/utility/loadCommonData", {
      method: "POST",
      body: JSON.stringify(action.payload),
    });

    // @ts-expect-error TS7057
    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({
        type: actionTypes.FETCH_COMMON_DATA_SUCCESS,
        payload: { commonData: data.data },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.FETCH_COMMON_DATA_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while fetching common data: " } });
    yield put({
      type: actionTypes.FETCH_COMMON_DATA_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootSignUpSaga() {
  yield takeEvery(actionTypes.SIGN_UP_REQUESTED, signUpAsync);
  yield takeEvery(actionTypes.FETCH_STATES_REQUEST, fetchStateAsync);
  yield takeEvery(actionTypes.FETCH_COMMON_DATA, fetchCommonDataAsync);
}
