import { cloneDeep, map } from "lodash";

import * as C from "../../../constants/enterpriseConstants";
import * as ErrorMessages from "../../../constants/errorMessage";
import { createDictionary } from "../../../helpers/jsUtility";
import {
  Enterprise,
  EnterpriseDetails,
  EnterpriseHospital,
  HospitalGroupDetails,
  PendingEnterprise,
} from "../types";

const initialState = {
  isEnterpriseListFetched: true,
  enterpriseList: [] as Enterprise[],
  pendingHospitalListForEnterprise: [] as PendingEnterprise[],
  enterpriseId: null as number | null,
  enterpriseName: "",
  aEnterpriseHospitals: [] as EnterpriseHospital[],
  aEnterpriseGroups: [] as string[],
  oEnterpriseGroupsById: {} as {
    [key: string]: {
      hospitalGroupId?: number;
      hospitalIds: string;
      groupAdminIds: string;
      groupAdminNames: string;
      hospitalGroupName?: string;
    };
  },
  groupDetails: [] as HospitalGroupDetails[],

  newEnterpriseId: null as number | null,
  newEnterpriseLeadUserId: null as number | null,
  newEnterpriseName: "",

  isHospitalRejectedSuccess: null as boolean | null,
  isCreateEnterpriseSuccess: false,
  isCreateEnterpriseFail: false,
  isSaveEnterpriseLeadSuccess: false,
  isDeleteEnterpriseSuccess: false,
  isDeleteEnterpriseFail: false,
  isSaveImportedHospitalsSuccess: false,
  errorMessage: "",
  enterpriseLogo: null as string | null,
  isEnterpriseLogoDeleted: false,
};

const userReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: {
    type: any;
    payload: {
      data?: {
        enterpriseDetails?: EnterpriseDetails;
        enterpriseHospitals?: EnterpriseHospital[];
        groupDetails?: HospitalGroupDetails[];
      };
      message?: string;
      newEnterpriseId?: number;
      newEnterpriseName?: string;
      newEnterpriseLeadUserId?: number;
      enterpriseId?: number;
      enterpriseName?: string;
      logoPath?: string;
      errorMessage?: string;
      isInternalReliefEnabled?: number;
      enterpriseLogo?: string;
      isEnterpriseLogoDeleted?: boolean;
    };
  }
) => {
  switch (action.type) {
    case C.INITIALIZE_ROO_ADMIN_ENTERPRISE_FLAGS:
      return {
        ...state,
        isCreateEnterpriseSuccess: false,
        isCreateEnterpriseFail: false,
        isSaveEnterpriseLeadSuccess: false,
        isDeleteEnterpriseSuccess: false,
        isDeleteEnterpriseFail: false,
        isSaveImportedHospitalsSuccess: false,
        isHospitalRejectedSuccess: null,
        errorMessage: null,
      };

    case C.GET_ENTERPRISE_LIST:
    case C.GET_ENTERPRISE_LIST_FAIL:
      return {
        ...state,
        isEnterpriseListFetched: false,
      };

    case C.GET_ENTERPRISE_LIST_SUCCESS:
      return {
        ...state,
        isEnterpriseListFetched: true,
        enterpriseList: action.payload.data,
      };

    case C.GET_PENDING_HOSPITALS_FOR_ENTERPRISE:
    case C.GET_PENDING_HOSPITALS_FOR_ENTERPRISE_FAIL:
      return {
        ...state,
        pendingHospitalListForEnterprise: [],
      };

    case C.GET_PENDING_HOSPITALS_FOR_ENTERPRISE_SUCCESS:
      return {
        ...state,
        pendingHospitalListForEnterprise: action.payload.data,
      };

    case C.REJECT_HOSPITAL_FOR_ENTERPRISE:
      return {
        ...state,
        isHospitalRejectedSuccess: null,
        errorMessage: null,
      };

    case C.REJECT_HOSPITAL_FOR_ENTERPRISE_SUCCESS:
      return {
        ...state,
        isHospitalRejectedSuccess: true,
      };

    case C.REJECT_HOSPITAL_FOR_ENTERPRISE_FAIL:
      return {
        ...state,
        isHospitalRejectedSuccess: false,
        errorMessage: action.payload?.message || ErrorMessages.EXCEPTION_MESSAGE,
      };

    case C.GET_ENTERPRISE_DETAILS_SUCCESS: {
      const enterpriseDetails = action.payload.data?.enterpriseDetails;
      const enterpriseId = enterpriseDetails?.enterpriseId;
      const enterpriseName = enterpriseDetails?.enterpriseName;
      const enterpriseGroups = enterpriseDetails?.enterpriseGroups;
      const aEnterpriseGroups = enterpriseGroups
        ? enterpriseGroups.map((group) => ({
            ...group,
            ...action.payload.data?.groupDetails?.find(
              (groupDetails) => groupDetails.hospitalGroupId === group.hospitalGroupId
            ),
          }))
        : [];

      const oEnterpriseGroupsById = createDictionary(aEnterpriseGroups, "hospitalGroupId");

      return {
        ...state,
        enterpriseId,
        enterpriseName,
        enterpriseLogo: enterpriseDetails?.enterpriseLogo,
        aEnterpriseHospitals: action?.payload?.data?.enterpriseHospitals,
        aEnterpriseGroups,
        oEnterpriseGroupsById,
        groupDetails: action.payload.data?.groupDetails,
      };
    }

    case C.CREATE_ENTERPRISE_SUCCESS:
      return {
        ...state,
        isCreateEnterpriseSuccess: true,
        newEnterpriseId: action.payload.newEnterpriseId,
        newEnterpriseName: action.payload.newEnterpriseName,
        newEnterpriseLeadUserId: action.payload.newEnterpriseLeadUserId,
      };

    case C.CREATE_ENTERPRISE_FAIL:
      return {
        ...state,
        isCreateEnterpriseFail: true,
      };

    case C.DELETE_ENTERPRISE_LOGO_REQUEST:
    case C.DELETE_ENTERPRISE_LOGO_FAIL:
    case C.CLOSE_FEEDBACK_RESEST_FLAGS:
      return {
        ...state,
        isEnterpriseLogoDeleted: false,
      };

    case C.DELETE_ENTERPRISE_HOSPITAL_SUCCESS:
      return {
        ...state,
        isEnterpriseLogoDeleted: true,
        enterpriseLogo: null as string | null,
      };

    case C.UPDATE_ENTERPRISE_NAME: {
      const updatedEnterpriseList = map(cloneDeep(state.enterpriseList), (itm) => {
        if (itm.enterpriseId === action.payload.enterpriseId) {
          // @ts-expect-error TS2322
          itm.enterpriseName = action.payload.enterpriseName;
        }
        return itm;
      });
      return {
        ...state,
        enterpriseList: updatedEnterpriseList,
      };
    }

    case C.UPDATE_ENTERPRISE_LOGO_SUCCESS:
      return {
        ...state,
        enterpriseLogo: action.payload.logoPath,
      };

    case C.SAVE_ENTERPRISE_LEAD_SUCCESS:
      return {
        ...state,
        isSaveEnterpriseLeadSuccess: true,
      };

    case C.SAVE_ENTERPRISE_LEAD_FAIL:
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
      };

    case C.SAVE_IMPORTED_HOSPITALS_SUCCESS:
      return {
        ...state,
        isSaveImportedHospitalsSuccess: true,
      };

    case C.DELETE_ENTERPRISE_SUCCESS:
      return {
        ...state,
        isDeleteEnterpriseSuccess: true,
      };

    case C.DELETE_ENTERPRISE_FAIL:
      return {
        ...state,
        isDeleteEnterpriseFail: true,
      };

    case C.UPDATE_INTERNAL_RELIEF_SUCCESS: {
      const filteredEnterpriseList = map(state.enterpriseList, (itm) => {
        if (itm.enterpriseId === action.payload.enterpriseId) {
          itm.isInternalReliefEnabled = action.payload.isInternalReliefEnabled;
        }
        return itm;
      });
      return {
        ...state,
        enterpriseList: filteredEnterpriseList,
      };
    }

    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default userReducer;
