import isEqual from "lodash/isEqual";
import React, { useMemo } from "react";
import Select, { components, createFilter } from "react-select";

// @ts-expect-error TS7006
const RooSelect = (props) => {
  const {
    options,
    value,
    onChange,
    searchable,
    clearable,
    placeholder,
    multi,
    disabled,
    height,
    menuPlacement,
    defaultValue,
    isNewDesign,
    "data-testid": dataTestId = "",
    hasHeaderLabel,
    key,
    persistingSearchOptions,
  } = props;
  const onMenuOpen = () => {
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName("RooSelect__option--is-selected")[0];
      if (selectedEl) {
        // @ts-expect-error TS18047, TS2339
        selectedEl.parentNode.scrollTop = selectedEl.offsetTop;
      }
    }, 100);
  };

  const customeStyles = {
    indicatorSeparator: () => ({ display: "none" }),
    // @ts-expect-error TS7006
    control: (provided) => {
      const obj = {
        ...provided,
        border: "2px solid #e8eaf7",
        minHeight: height | 48,
        overflow: "auto",
      };
      if (isNewDesign) {
        obj.border = "1px solid #dbdffa";
        obj.borderRadius = "8px";
        obj.fontWeight = 500;
      }
      return obj;
    },
    // @ts-expect-error TS7006
    container: (provided) => ({ ...provided, height: "100%" }),
    // @ts-expect-error TS7006
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
    // @ts-expect-error TS7006
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    // @ts-expect-error TS7006
    placeholder: (defaultStyles) => {
      if (isNewDesign) {
        return {
          ...defaultStyles,
          color: "#59595A",
        };
      } else {
        return {
          ...defaultStyles,
        };
      }
    },
    // @ts-expect-error TS6133, TS7006, TS7031
    multiValueLabel: (styles, { data }) => {
      if (isNewDesign) {
        return {
          ...styles,
          color: "#007A4C",
        };
      } else {
        return {
          ...styles,
        };
      }
    },
    // @ts-expect-error TS7006
    dropdownIndicator: (styles) => {
      if (isNewDesign) {
        return {
          ...styles,
          color: "#007A4C",
        };
      } else {
        return {
          ...styles,
        };
      }
    },
    // @ts-expect-error TS7006
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#412894" : "#fff",
    }),
  };

  const selectedValue = useMemo(() => {
    if (typeof value === "object") {
      // @ts-expect-error TS7006
      return options?.find((option) => isEqual(option, value));
    }
    // @ts-expect-error TS7006
    return options?.find((option) => option.value === value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const multiSelectedValue = useMemo(() => {
    if (!Array.isArray(value)) {
      return null;
    }
    if (value.length > 0 && typeof value[0] === "object") {
      // @ts-expect-error TS7006
      return options?.filter((option) => value.find((eachVal) => isEqual(eachVal, option)));
    }
    // @ts-expect-error TS7006
    return options?.filter((option) => value.includes(option.value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const valueProps = value
    ? { value: multi ? multiSelectedValue || [] : hasHeaderLabel ? value : selectedValue || "" }
    : {};

  // @ts-expect-error TS7006
  const MenuPortal = (props) => {
    return (
      <components.MenuPortal {...props}>
        <div data-testid={dataTestId}></div>
        {props.children}
      </components.MenuPortal>
    );
  };

  // @ts-expect-error TS7006
  const filterOption = (option, inputValue) => {
    // If option is in persistingSearchOptions, always show it
    if (
      persistingSearchOptions?.some(
        // @ts-expect-error TS7006
        (persistingOption) => isEqual(persistingOption, option.data)
      )
    ) {
      return true;
    }

    // Default filtering
    return createFilter()(option, inputValue);
  };

  return (
    <Select
      key={key}
      components={{
        MenuPortal,
      }}
      options={options}
      styles={customeStyles}
      onChange={(e) => {
        onChange(e);
      }}
      isSearchable={searchable}
      isClearable={clearable}
      isDisabled={disabled}
      isMulti={multi}
      placeholder={placeholder}
      menuPlacement={menuPlacement ? menuPlacement : "auto"}
      menuPortalTarget={document.body}
      defaultValue={defaultValue ? defaultValue : null}
      onMenuOpen={onMenuOpen}
      className={"RooSelect"}
      classNamePrefix={"RooSelect"}
      filterOption={filterOption}
      {...valueProps}
    />
  );
};

// eslint-disable-next-line no-restricted-exports
export default RooSelect;
