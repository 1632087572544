import { find } from "lodash";

import * as C from "../../../constants/techConstants";
const initialState = {
  techDetails: {
    firstName: "",
    lastName: "",
    address: "",
    aptUnitSuite: "",
    city: "",
    state: "",
    zipcode: "",
    email: "",
    phoneNumber: "",

    stateId: "",
    accountType: "",
    aStripeConnectBankAccounts: [],
    plaidMicroDepositStatus: "",
  },
  isBasicInfoUpdateSuccess: false,

  w9Info: null,
  isW9UpdateSuccess: false,
  isW9UpdateError: false,
  blockedHospitalCount: 0,
  blockedHospitalList: [],
  isShowBlockingHospSuccessModal: false,
};

/* eslint-disable default-param-last */
// @ts-expect-error TS7006
const techSettingReducer = (state = initialState, action) => {
  /* eslint-enable default-param-last */
  switch (action.type) {
    case C.GET_TECH_SETTING_INFORMATION_SUCCESS:
      return {
        ...state,
        techDetails: {
          ...state.techDetails,
          firstName: action.payload.data.data.tech[0].firstName,
          lastName: action.payload.data.data.tech[0].lastName,
          birthday: action.payload.data.data.tech[0].birthday,
          pronouns: action.payload.data.data.tech[0].pronouns,
          address: action.payload.data.data.user[0].address,
          aptUnitSuite:
            action.payload.data.data.user[0].aptUnitSuite !== null
              ? action.payload.data.data.user[0].aptUnitSuite.toString()
              : "",
          city: action.payload.data.data.user[0].city,
          stateId: action.payload.data.data.user[0].stateId,
          zipcode: action.payload.data.data.user[0].zipcode.toString(),
          email: action.payload.data.data.user[0].emailId,
          phoneNumber: action.payload.data.data.user[0].mobilePhoneNumber.toString(),
          accountType:
            action.payload.data.data.tech[0].accountType !== null
              ? action.payload.data.data.tech[0].accountType
              : "",
          aStripeConnectBankAccounts: action.payload.data.data.aStripeConnectBankAccounts,
          plaidMicroDepositStatus: action.payload.data.data.tech[0].plaidMicroDepositStatus,
        },
        isBasicInfoUpdateSuccess: false,
      };

    case C.SET_LOCAL_TECH_MICRO_DEPOSITS_STATUS:
      return {
        ...state,
        techDetails: { ...state.techDetails, plaidMicroDepositStatus: action.payload },
      };

    case C.UPDATE_TECH_BASIC_INFO_SUCCESS:
      return {
        ...state,
        isBasicInfoUpdateSuccess: true,
      };

    case C.INITIALIZE_TECH_SETTINGS:
      const blockedHospitalCount = state.blockedHospitalCount;
      return {
        ...initialState,
        blockedHospitalCount: blockedHospitalCount,
        techDetails: {
          ...initialState.techDetails,
        },
        isBasicInfoUpdateSuccess: false,
        isW9UpdateSuccess: false,
      };

    case C.GET_TECH_W9_INFO_SUCCESS:
      return {
        ...state,
        w9Info: action.payload,
      };

    case C.UPDATE_TECH_W9_SUCCESS:
      return {
        ...state,
        isW9UpdateSuccess: true,
      };

    case C.RESET_TECH_W9_UPDATE_SUCCESS:
      return {
        ...state,
        isW9UpdateSuccess: false,
      };

    case C.RESET_TECH_BASIC_INFO_UPDATE_SUCCESS:
      return {
        ...state,
        isBasicInfoUpdateSuccess: false,
      };

    case C.GET_BLOCKED_HOSPITAL_LIST: {
      return { ...state, blockedHospitalList: [] };
    }

    case C.GET_BLOCKED_HOSPITAL_LIST_SUCCESS: {
      return {
        ...state,
        blockedHospitalList: action.payload.blockedHospitalList,
        blockedHospitalCount: action.payload.blockedHospitalList.length,
      };
    }

    case C.REMOVE_BLOCKED_HOSPITAL_SUCCESS:
      // @ts-expect-error TS7005
      const updatedList = state.blockedHospitalList.filter(
        // @ts-expect-error TS2339
        (o) => o.hospitalId !== action.payload.hospitalId
      );
      return {
        ...state,
        blockedHospitalList: updatedList,
        blockedHospitalCount: updatedList.length,
      };

    case C.BLOCK_HOSPITAL_SUCCESS:
      const { hospitalId, internalNote, isNewlyAdded } = action.payload.data;
      // @ts-expect-error TS7005
      const blockedHospitalList = Object.assign([], state.blockedHospitalList);
      const blockHospitalDetails = find(blockedHospitalList, { hospitalId: hospitalId });
      if (!isNewlyAdded && blockHospitalDetails) {
        blockHospitalDetails.isNewlyAdded = false;
        blockHospitalDetails.internalNote = internalNote;
      } else {
        blockedHospitalList.splice(0, 0, action.payload.data);
      }
      return {
        ...state,
        blockedHospitalList: blockedHospitalList,
        blockedHospitalCount: blockedHospitalList.length,
        isShowBlockingHospSuccessModal: true,
      };

    case C.UPDATE_BLOCKED_HOSPITAL_COUNT:
      if (action.payload.isSet) {
        return {
          ...state,
          blockedHospitalCount: action.payload.blockedHospitalCount,
        };
      } else {
        const blockedHospitals = state.blockedHospitalCount;
        return {
          ...state,
          blockedHospitalCount: blockedHospitals + 1,
        };
      }
    case C.HIDE_BLOCK_HOSPITAL_CONFIRMATION_MODAL:
      return {
        ...state,
        isShowBlockingHospSuccessModal: action.payload.showSuccessModal,
      };

    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default techSettingReducer;
