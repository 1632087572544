import * as Sentry from "@sentry/react";
import { call, put, takeEvery } from "redux-saga/effects";

import { rooFetch } from "../../../api/common/rooFetch";
import * as errorMessage from "../../../constants/errorMessage";
import { UNSET_LOADER } from "../../../constants/loader";
import * as actionTypes from "../../../constants/techConstants";

// @ts-expect-error TS7006
function* fetchTechShiftHistoryAsync(action) {
  try {
    const { techId, startDate, endDate, isFetchReferralBonus, shiftGroupId, userId } =
      action.payload;

    let urlParams = `?techId=${techId}&userId=${userId}&startDate=${startDate}&endDate=${endDate}&isFetchReferralBonus=${isFetchReferralBonus}`;

    if (techId && shiftGroupId) {
      // this will fetch only 1 shift
      urlParams = `?techId=${techId}&userId=${userId}&isFetchReferralBonus=${isFetchReferralBonus}&shiftGroupId=${shiftGroupId}&isFromWeb=${true}`;
    }

    // @ts-expect-error TS7057
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/tech/shift/getShiftsHistory" + urlParams
    );

    // @ts-expect-error TS7057
    const data = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      if (data.data.data === undefined) {
        yield put({
          type: actionTypes.TECH_SHIFT_HISTORY_SUCCESS,
          payload: {
            status: data.status_code,
            shiftHistory: [],
            totalCount: 0,
            totalShiftEarning: 0,
            totalReferralBonus: 0,
            totalBonus: 0,
            yearToDateEarning: 0,
            bonusLog: [],
            referralLog: [],
            shiftBonusLog: [],
            promoLog: [],
            totalOvertime: 0,
            totalEarnings: 0,
          },
        });
      } else {
        yield put({
          type: actionTypes.TECH_SHIFT_HISTORY_SUCCESS,
          payload: {
            status: data.status_code,
            shiftHistory: data.data.data,
            totalCount: data.data.totalCount,
            totalShiftEarning: data.data.totalShiftEarning,
            totalReferralBonus: data.data.totalReferralBonus,
            totalBonus: data.data.totalBonus,
            yearToDateEarning: data.data.yearToDateEarning,
            isOvertime: shiftGroupId && data.data.data && data.data.data.length == 1,
            bonusLog: data.data.bonusLog,
            referralLog: data.data.referralLog,
            shiftBonusLog: data.data.shiftBonusLog,
            promoLog: data.data.promoLog,
            totalOvertime: data.data.totalOvertime,
            totalEarnings: data.data.totalEarnings,
          },
        });
      }
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.TECH_SHIFT_HISTORY_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while fetching tech shift history: " },
    });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: actionTypes.TECH_SHIFT_HISTORY_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootTechShiftHistorySaga() {
  yield takeEvery(actionTypes.TECH_SHIFT_HISTORY_REQUEST, fetchTechShiftHistoryAsync);
}
