import React from "react";

import { Button, ButtonProps } from "../Button";
import { Heading } from "../Heading";
import { Icon } from "../Icon";
import { Link, LinkTextProps } from "../Link";

import {
  ButtonContainer,
  CloseIconContainer,
  ModalBody,
  ModalContent,
  ModalSize,
  ModalType,
  StyledModal,
} from "./ModalStyles";
import { MediumText, Text } from "../Text";
import { FlexLayout } from "../FlexLayout";
import { disabledTextColor, grayLight400 } from "../../styles/constants/colors";
import { Z_INDEX } from "../../../constants/zIndex";

export interface ModalProps {
  $size?: ModalSize;
  showModal?: boolean;
  onCloseModal?: () => void;
  $topPosition?: number;
  $primaryButtonProps?: ButtonProps;
  $secondaryButtonProps?: ButtonProps;
  $secondaryLinkProps?: LinkTextProps;
  header?: string;
  body?: React.ReactNode;
  $modalType?: ModalType;
  $noClose?: boolean;
  $noPadding?: boolean;
  "data-testid"?: string;
  description?: string;
  $buttonVariant?: "wizard" | "confirmation";
  eyebrowText?: string;
  zIndex?: number;
}

export const Modal = (props: ModalProps) => {
  const {
    $size,
    showModal,
    onCloseModal,
    $modalType,
    $primaryButtonProps,
    $secondaryButtonProps,
    $secondaryLinkProps,
    header,
    body,
    $noClose,
    $noPadding,
    "data-testid": dataTestId = "modal",
    $topPosition = 100,
    description,
    $buttonVariant,
    eyebrowText,
    zIndex = Z_INDEX.MODAL,
  } = props;

  const renderButtons = () => {
    if (!$primaryButtonProps && !$secondaryButtonProps) return null;

    const primaryButton = $primaryButtonProps ? (
      <Button
        $buttonType="primary"
        {...$primaryButtonProps}
        data-testid={`${dataTestId}PrimaryButton`}
      />
    ) : null;

    const secondaryButton = $secondaryButtonProps ? (
      <Button
        $buttonType="secondary"
        {...$secondaryButtonProps}
        data-testid={`${dataTestId}SecondaryButton`}
      />
    ) : null;

    const secondaryLink = $secondaryLinkProps ? (
      <Link {...$secondaryLinkProps} data-testid={`${dataTestId}SecondaryLink`} />
    ) : null;

    return (
      <ButtonContainer
        $primaryButtonProps={$primaryButtonProps}
        $secondaryButtonProps={$secondaryButtonProps}
        $buttonVariant={$buttonVariant}
      >
        {$buttonVariant === "wizard" ? (
          <>
            {secondaryButton}
            {primaryButton}
          </>
        ) : (
          <>
            {primaryButton}
            {secondaryButton}
            {secondaryLink}
          </>
        )}
      </ButtonContainer>
    );
  };

  const modalButtons = renderButtons();

  return (
    <StyledModal
      open={showModal}
      data-testid="modalContainer"
      closeIcon={false}
      footer={null}
      $size={$size}
      width="auto"
      zIndex={zIndex}
      $topPosition={$topPosition}
    >
      <ModalContent $modalType={$modalType} $noPadding={$noPadding} data-testid={dataTestId}>
        <CloseIconContainer $noClose={$noClose} $noPadding={$noPadding}>
          <Icon name="Close" onClick={onCloseModal} data-testid="modalCloseIcon" />
        </CloseIconContainer>

        {eyebrowText && <MediumText color={disabledTextColor}>{eyebrowText}</MediumText>}
        <ModalBody>
          <FlexLayout gap="xl" style={{ flexDirection: "column" }}>
            <FlexLayout gap="l" style={{ flexDirection: "column" }}>
              {header && (
                <Heading level={3} data-testid={`${dataTestId}Header`}>
                  {header}
                </Heading>
              )}
              {description && <Text color={grayLight400}>{description}</Text>}
            </FlexLayout>
            {body && <>{body}</>}
            {($primaryButtonProps || $secondaryButtonProps || $secondaryLinkProps) && modalButtons}
          </FlexLayout>
        </ModalBody>
      </ModalContent>
    </StyledModal>
  );
};
