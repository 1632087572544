import { useMutation } from "@tanstack/react-query";

import { post, queryClient } from "@RooBeta/api";
import { createShiftProposal } from "./endpoints";
import { CacheKey } from "@RooBeta/types";

export const useCreateShiftProposal = (options?: {
  onSuccess: () => void;
  onError: () => void;
}) => {
  return useMutation({
    mutationFn: async (params: { hospitalId: number; commsSent: boolean }) => {
      const { data } = await post<{ hospitalId: number; commsSent: boolean }, { data: number }>(
        createShiftProposal(),
        params
      );
      return data;
    },
    onSuccess: () => {
      options?.onSuccess();
      queryClient.invalidateQueries({ queryKey: [CacheKey.ShiftProposals, "active"] });
    },
    onError: options?.onError,
  });
};
