import styled, { css } from "styled-components";

import {
  BodyText,
  BoldBodyText,
  Color,
  LargeBodyText,
  LargeMediumBodyText,
  LargeBoldBodyText,
  MediumBodyText,
  SmallBodyText,
  SmallMediumBodyText,
  SmallBoldBodyText,
  disabledTextColor,
  greenBase,
  greenDark600,
  redBase,
} from "../../styles/constants";

const colorExtension = (props: { color?: unknown; disabled?: boolean; error?: boolean }) => {
  if (props.disabled) {
    return css`
      color: ${disabledTextColor};
    `;
  } else if (props.error) {
    return css`
      color: ${redBase};
    `;
  }
  return props.color
    ? css`
        color: ${props.color as Color};
      `
    : "";
};

export const Text = styled.p.attrs((props) => ({
  className: props.className,
}))`
  ${BodyText};
  margin-bottom: 0 !important;
  & a {
    ${BoldBodyText};
    color: ${greenBase} !important;
    text-decoration: underline !important;
    &:hover {
      color: ${greenDark600} !important;
    }
  }
  ${colorExtension}
`;

export const BoldText = styled.p.attrs((props) => ({
  className: props.className,
}))`
  ${BoldBodyText};
  margin-bottom: 0;
  ${colorExtension}
`;

export const MediumText = styled.p.attrs((props) => ({
  className: props.className,
}))`
  ${MediumBodyText};
  margin-bottom: 0;
  & a {
    ${BoldBodyText};
    color: ${greenBase} !important;
    text-decoration: underline !important;
    &:hover {
      color: ${greenDark600} !important;
    }
  }
  ${colorExtension}
`;

export const SmallText = styled.p`
  ${SmallBodyText};
  margin-bottom: 0;
  ${colorExtension}
`;

export const SmallMediumText = styled.p`
  ${SmallMediumBodyText}
  margin-bottom: 0;
  ${colorExtension}
`;

export const SmallBoldText = styled.p`
  ${SmallBoldBodyText}
  margin-bottom: 0;
  ${colorExtension}
`;

export const LargeText = styled.p`
  ${LargeBodyText};
  margin-bottom: 0;
  ${colorExtension}
`;

export const LargeMediumText = styled.p`
  ${LargeMediumBodyText}
  margin-bottom: 0;
  ${colorExtension}
`;

export const LargeBoldText = styled.p`
  ${LargeBoldBodyText}
  margin-bottom: 0;
  ${colorExtension}
`;
