// @ts-expect-error TS6133
import BaseJoi from "joi";
// import Extension from "joi-us-zipcode";

// @ts-expect-error TS6133, TS7006
export function validateZipcode(zipcode) {
  // ISSUE: commented out code since BaseJoi.extend(Extension) is creating
  // a type error when this code is reached at runtime
  // also, it is confusing that this fn would return "false" as the response when it the value is valid
  // END ISSUE DESCRIPTION

  // const Joi = BaseJoi.extend(Extension);

  // const schema = {
  //   zip: Joi.string().usZipCode(),
  // };
  // const object = {
  //   zip: zipcode,
  // };

  // return Joi.validate(object, schema).error !== null;
  return false;
}
