import * as ActionTypes from "../../constants/calendar";

const initialState = {
  shiftListFullData: [],
  shiftListTrimmedData: [],
  requestShiftDetails: [],
  shiftData: [],
  isShiftRequest: false,
  isShowShiftNotAvailableModal: false,
  requestedHospOfShiftNotAvailable: "",
  shiftRequestResponseMessage: "",
};

/* eslint-disable default-param-last */
// @ts-expect-error TS7006
const reducer = (state = initialState, action) => {
  /* eslint-enable default-param-last */
  if (action.type === ActionTypes.GET_MY_TECH_SHIFTS_SUCCESS) {
    const fullShiftListData = [];
    const trimmedShiftListData = [];
    let oShiftsDB = action.payload;
    // @ts-expect-error TS6133
    let startTime;
    let favorites;
    // @ts-expect-error TS6133
    let endTime;
    let oEventsDB;
    if (action.eventListForTech && action.eventListForTech.length) {
      oEventsDB = action.eventListForTech;
      // @ts-expect-error TS7006
      oEventsDB.map((event) => {
        const eventData = {
          ...event,
          isEvent: true,
          end: event.event_unix_date * 1000 + new Date().getTimezoneOffset(),
          endTime: JSON.parse(event.event_end_time).label,
          hospitalTimezone: event.hospital_timezone,
          shiftTime:
            JSON.parse(event.event_start_time).label +
            " -" +
            JSON.parse(event.event_end_time).label,
          start: event.event_unix_date * 1000 + new Date().getTimezoneOffset(),
          startTime: JSON.parse(event.event_start_time).label,
          title: event.event_title,
        };

        trimmedShiftListData.push(eventData);
      });
    }

    if (action.payload.searchShiftResult) {
      oShiftsDB = action.payload.searchShiftResult;
      favorites = action.payload.favorites;
      for (const shift in oShiftsDB) {
        fullShiftListData.push(oShiftsDB[shift]);
      }
    } else {
      for (const shift in oShiftsDB) {
        fullShiftListData.push(oShiftsDB[shift][0]);
      }
    }

    for (const i in fullShiftListData) {
      const oShift = fullShiftListData[i];
      if (
        oShift.confirmedTechId !== null &&
        // @ts-expect-error TS2345
        oShift.confirmedTechId !== parseInt(localStorage.getItem("techId"))
      ) {
        continue;
      }
      let requested = false;
      let shiftRequestedOn = "";
      let shiftRequestEndTimestamp = null;

      startTime = "";
      endTime = "";
      if (oShift.shiftRequestDetails !== undefined) {
        const requestShiftDetails = JSON.parse("[" + oShift.shiftRequestDetails + "]")[0];

        // @ts-expect-error TS7006
        requestShiftDetails.map((element) => {
          // @ts-expect-error TS2345
          if (element.techId === parseInt(localStorage.getItem("techId"))) {
            requested = true;
            shiftRequestedOn = element.shiftRequestedOn;
            shiftRequestEndTimestamp = element.shiftRequestEndTimestamp;
          }
        });
      }
      const oShiftTrimmed = {
        title: oShift.hospitalName,
        shiftTime: oShift.startTime + "-" + oShift.endTime,
        shiftId: oShift.shiftId,
        shiftDate: oShift.shiftDate,
        startTime: oShift.startTime,
        endTime: oShift.endTime,
        shiftStartDatetime: oShift.shiftStartDatetime,
        shiftEndDatetime: oShift.shiftEndDatetime,
        shiftCreatedOn: oShift.shiftCreatedOn,
        start: oShift.shiftStartDatetime * 1000 + new Date().getTimezoneOffset(),
        end: oShift.shiftStartDatetime * 1000 + new Date().getTimezoneOffset(),
        confirmedTechId: oShift.confirmedTechId,
        shiftGroupId: oShift.shiftGroupId,
        shiftRequestedOn: shiftRequestedOn,
        shiftRequestEndTimestamp,
        requested: requested || !!oShift.shiftRequestedOn,
        isSurgeryExpected: oShift.isSurgeryExpected,
        typeOfSurgeryIds: oShift.typeOfSurgeryIds,
        hospitalId: oShift.hospitalId,
        hospitalTimezone: oShift.hospitalTimezone,
      };

      if (favorites && favorites.length) {
        // if favorites, then give all shifts for the date the same "start" and "end"
        // for the sake of the calendar view so that they are ordered with favorites
        // at the top (already sorted list by date and favorites)
        const aDateParts = oShift.shiftDate.split("-");
        const hr = 60 * 60 * 1000;
        oShiftTrimmed.start =
          new Date(aDateParts[0], aDateParts[1] - 1, aDateParts[2]).getTime() + hr; // generate same bogus start for date
        oShiftTrimmed.end = oShiftTrimmed.start + 2 * hr; // generate same bogus end for date
      }
      trimmedShiftListData.push(oShiftTrimmed);

      shiftRequestedOn = "";
    }

    return {
      ...state,
      shiftListFullData: fullShiftListData,
      shiftListTrimmedData: trimmedShiftListData,
      isShiftRequest: false,
    };
  }
  if (action.type === ActionTypes.GET_MY_TECH_SHIFTS_DETAILS_SUCCESS) {
    const shiftData1 = [];
    let requested = false;
    // const multipleShiftIds = [];

    // @ts-expect-error TS7006
    const shiftDetails = action.payload.map((element) => {
      return element;
    });

    if (shiftDetails["0"].shiftRequestDetails !== null) {
      const requestShiftDetails = JSON.parse("[" + shiftDetails["0"].shiftRequestDetails + "]")[0];
      // @ts-expect-error TS7006
      requestShiftDetails.map((element) => {
        // @ts-expect-error TS2345
        if (element.techId === parseInt(localStorage.getItem("techId"))) {
          requested = true;
        }
      });
    }

    shiftData1.push({
      shiftDate: shiftDetails["0"].shiftDate,
      startTime: shiftDetails["0"].startTime,
      endTime: shiftDetails["0"].endTime,
      shiftStartDatetime: shiftDetails["0"].shiftStartDatetime,
      shiftEndDatetime: shiftDetails["0"].shiftEndDatetime,
      shiftCreatedOn: shiftDetails["0"].shiftCreatedOn,
      tier: shiftDetails["0"].tier,
      shiftAmount: shiftDetails["0"].shiftAmount,
      techShiftAmount: shiftDetails["0"].techShiftAmount,
      shiftId: shiftDetails["0"].shiftId,
      shiftGroupId: shiftDetails["0"].shiftGroupId,
      confirmedTechId: shiftDetails["0"].confirmedTechId,
      shiftRequestedOn: shiftDetails["0"].shiftRequestedOn,
      shiftRequestEndTimestamp: shiftDetails["0"].shiftRequestEndTimestamp,
      hospitalId: shiftDetails["0"].hospitalId,
      hospitalProfileImage:
        shiftDetails["0"].hospitalProfileImage || shiftDetails["0"].hospitalImage,
      hospitalName: shiftDetails["0"].hospitalName,
      hospitalStateId: shiftDetails["0"].hospitalStateId,
      requested: requested,
      hospitalTimezone: shiftDetails["0"].hospitalTimezone,
      techShiftAmountUpdated: shiftDetails["0"].techShiftAmountUpdated,
      userId: shiftDetails["0"].userId,
      expectedBreakTime: shiftDetails["0"].expectedBreakTime,
    });
    return {
      ...state,
      shiftData: shiftData1,
      isShiftRequest: false,
    };
  }

  if (action.type === ActionTypes.REQUEST_TECH_SHIFT_SUCCESS) {
    let updateIndex = null;

    state.shiftData.map((element, index) => {
      // @ts-expect-error TS2339
      if (element.shiftGroupId === action.payload.data.shiftGroupId) {
        updateIndex = index;
      }
    });
    const currentTimeStamp = (new Date().getTime() / 1000 + "").split(".")[0];
    // @ts-expect-error TS7005
    const updateSearchShift = [...state.shiftData];
    // @ts-expect-error TS2538
    updateSearchShift[updateIndex].shiftRequestedOn = currentTimeStamp;
    // @ts-expect-error TS2538
    updateSearchShift[updateIndex].shiftRequestEndTimestamp = action.payload.data.expiresTimeStamp;
    return {
      ...state,
      shiftData: [...updateSearchShift],
      isShiftRequest: true,
    };
  }

  if (action.type === ActionTypes.REQUEST_TECH_SHIFT_NOT_AVAILABLE) {
    return {
      ...state,
      requestedHospOfShiftNotAvailable: action.payload.hospitalName,
      isShowShiftNotAvailableModal: true,
      shiftRequestResponseMessage: action.payload.message,
      requestShiftDetails: [],
      shiftData: [],
      isShiftRequest: false,
    };
  }

  if (action.type === ActionTypes.CLOSE_SHIFT_NOT_AVAILABLE_MODAL) {
    return {
      ...state,
      isShowShiftNotAvailableModal: false,
      requestedHospOfShiftNotAvailable: "",
      shiftRequestResponseMessage: "",
    };
  }

  if (action.type === ActionTypes.INITIALIZE_STATE) {
    return {
      ...state,
      shiftData: [],
      requestShiftDetails: [],
    };
  }
  return state;
};

// eslint-disable-next-line no-restricted-exports
export default reducer;
