import { Alert, ErrorText, FormLabel, OpenText, Radio, Select, SmallText } from "@RooUI";
import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { VStack } from "@RooBeta/components";
import { SHIFT_CANCELLATION_REASONS } from "@RooBeta/constants";
import { useHospitals } from "@RooBeta/store";
import { ShiftCancellation as FormData, ShiftCancellationReason } from "@RooBeta/types";

import { ShiftCancellationPolicy } from "./ShiftCancellationPolicy";
import { useShiftCancellation } from "./useShiftCancellation";

export const ShiftCancellation = () => {
  const {
    isShiftConfirmed,
    isCancellationPolicyAcknowledgementRequired,
    isCancellationRequestRequired,
  } = useShiftCancellation();

  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<FormData>();

  const reasonCode = watch("reasonCode");
  const comment = watch("comment");
  const hiredHospitalId = watch("hiredHospitalId");
  const commentProgress = (comment?.length ?? 0) < 50 ? `(${comment?.length ?? 0} / 50)` : "";

  const hospitals = useHospitals();

  const hospitalOptions = useMemo(() => {
    return Array.from(hospitals.entries()).map(([id, name]) => ({
      value: id,
      label: name,
    }));
  }, [hospitals]);

  const isHiredFulltimeFromRoo = reasonCode === ShiftCancellationReason.HiredFulltimeFromRoo;

  return (
    <VStack $gap="s" $padding="l">
      {isCancellationPolicyAcknowledgementRequired ? (
        <ShiftCancellationPolicy />
      ) : (
        <>
          <FormLabel>Cancellation reason</FormLabel>
          {SHIFT_CANCELLATION_REASONS.map((reason) => (
            <>
              <Radio
                key={reason.value}
                name="reasonCode"
                value={reason.value}
                label={reason.label}
                checked={reason.value === reasonCode}
                onChange={() => setValue("reasonCode", reason.value)}
              />
              {isHiredFulltimeFromRoo &&
                reason.value === ShiftCancellationReason.HiredFulltimeFromRoo && (
                  <>
                    <Select
                      options={hospitalOptions}
                      value={hiredHospitalId}
                      onChange={(_, value) => setValue("hiredHospitalId", value as number)}
                      placeholder="Select hospital"
                      searchable
                      data-testid="hiredHospitalSelect"
                    />
                    {errors.hiredHospitalId && (
                      <ErrorText>{errors.hiredHospitalId.message}</ErrorText>
                    )}
                  </>
                )}
            </>
          ))}
          {errors.reasonCode && <ErrorText>{errors.reasonCode.message}</ErrorText>}
          {(isShiftConfirmed || reasonCode === ShiftCancellationReason.Other) && (
            <OpenText
              $error={errors.comment?.message}
              name="reason"
              label="Comment"
              subText={`Minimum 50 characters required ${commentProgress}`}
              value={comment}
              data-testid="commentInput"
              onChange={(e) => setValue("comment", e.target.value)}
            />
          )}
          {isCancellationRequestRequired && (
            <Alert
              $alertStatus="warning"
              alertBody={
                <SmallText>
                  Due to our policies, only a cancellation request can be submitted at this time.
                  This is not a cancellation confirmation. A Roo team member will reach out when
                  your cancellation request is confirmed.
                </SmallText>
              }
            />
          )}
        </>
      )}
    </VStack>
  );
};
