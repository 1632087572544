import _ from "lodash";

import * as registerStudentConstant from "../../constants/registerStudent";
import * as C from "../../constants/student";
import { convertNumberListToArray } from "../../helpers/jsUtility";

const studentProfileDetails = { aResumes: [], aProfileImages: [] };

const initialState = {
  isStudentProfileFetched: false,
  isStudentProfileUpdated: false,
  studentProfileDetails: studentProfileDetails,
  isSettingsUpdated: false,
  studentBasicInfo: {},
};

/* eslint-disable default-param-last */
// @ts-expect-error TS7006
const studentProfileReducer = (state = initialState, action) => {
  /* eslint-enable default-param-last */
  switch (action.type) {
    case C.FETCH_STUDENT_PROFILE_REQUEST:
      return {
        ...state,
        isStudentProfileFetched: false,
      };

    case C.FETCH_STUDENT_PROFILE_SUCCESS:
      return {
        ...state,
        studentProfileDetails: {
          ...action.payload,
          subspecialityInterest: convertNumberListToArray(action.payload.subspecialityInterestIds),
          aProfileImages: action.payload.profilePhotoPath
            ? [{ imagePath: action.payload.profilePhotoPath }]
            : [],
          aResumes: action.payload.resumePath
            ? [
                {
                  resumeName: action.payload.resumeName,
                  resumePath: action.payload.resumePath,
                },
              ]
            : [],
        },
        isStudentProfileFetched: true,
      };

    case C.FETCH_STUDENT_PROFILE_FAIL:
      return {
        ...state,
      };

    case C.UPDATE_STUDENT_PROFILE_REQUEST:
      return {
        ...state,
        studentProfileDetails: studentProfileDetails,
        isStudentProfileFetched: false,
        isStudentProfileUpdated: false,
      };

    case C.UPDATE_STUDENT_PROFILE_SUCCESS:
      return {
        ...state,
        isStudentProfileUpdated: true,
      };

    case registerStudentConstant.UPLOAD_STUDENT_IMAGE_SUCCESS:
      return {
        ...state,
        studentProfileDetails: {
          ...state.studentProfileDetails,
          aProfileImages: action.payload.studentImage,
        },
      };

    case registerStudentConstant.DELETE_STUDENT_IMAGE_SUCCESS:
      return {
        ...state,
        studentProfileDetails: {
          ...state.studentProfileDetails,
          aProfileImages: [],
        },
      };

    case registerStudentConstant.UPLOAD_STUDENT_RESUME_SUCCESS:
      return {
        ...state,
        studentProfileDetails: {
          ...state.studentProfileDetails,
          aResumes: action.payload.studentResume,
        },
      };

    case registerStudentConstant.DELETE_STUDENT_RESUME_SUCCESS:
      return {
        ...state,
        studentProfileDetails: {
          ...state.studentProfileDetails,
          aResumes: [],
        },
      };

    case C.FETCH_STUDENT_SETTINGS_BASIC_INFO_REQUEST:
      return {
        ...state,
        studentBasicInfo: {},
        isSettingsUpdated: false,
      };

    case C.FETCH_STUDENT_SETTINGS_BASIC_INFO_SUCCESS:
      return {
        ...state,
        studentBasicInfo: {
          ...state.studentBasicInfo,
          fullName: action.payload.fullName,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          address: action.payload.address,
          aptUnitSuite: action.payload.aptUnitSuite,
          city: action.payload.city,
          stateId: action.payload.stateId,
          zipcode: action.payload.zipcode,
          mobilePhoneNumber: action.payload.mobilePhoneNumber,
          email: action.payload.emailId,
        },
      };

    case C.UPDATE_STUDENT_SETTINGS_REQUEST:
      return {
        ...state,
        isSettingsUpdated: false,
      };

    case C.UPDATE_STUDENT_SETTINGS_SUCCESS:
      localStorage.setItem("fullName", action.payload.fullName);
      return {
        ...state,
        studentBasicInfo: {
          ...state.studentBasicInfo,
          fullName: action.payload.fullName,
          address: action.payload.address,
          aptUnitSuite: action.payload.aptUnitSuite,
          city: action.payload.city,
          stateId: action.payload.stateId,
          zipcode: action.payload.zipcode,
          mobilePhoneNumber: action.payload.mobilePhoneNumber,
          email: action.payload.emailId,
        },
        isSettingsUpdated: true,
      };

    case C.UPDATE_STUDENT_SETTINGS_FAIL:
      return {
        ...state,
        isSettingsUpdated: false,
      };

    case C.RESET_STUDENT_SETTINGS_INFO_UPDATE_SUCCESS:
      return {
        ...state,
        isSettingsUpdated: false,
      };

    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default studentProfileReducer;
