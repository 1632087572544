import Cookies from "js-cookie";
import posthog from "posthog-js";

import { constants, utils, types } from "@roo-dev/common";

import { getUtmParamsWithReferrerFallback } from "../../../Common/queryParamsUtils";
import { USER_TYPE, UserType } from "../../../constants/UserTypeConstants";
import { AuthUtility } from "../../../helpers/AuthUtility";
import { type PlatformProperties, detectPlatform } from "../../helper/PlatformDetector";

export const Event = constants.Event;
export type TrackingData = types.TrackingData;

let staticBaseTrackingParams: PlatformProperties | undefined = undefined;

export const getEventEntityFromUserType = (userTypeId: UserType) => {
  switch (userTypeId) {
    case USER_TYPE.VET_USER_TYPE:
      return constants.Event.Entity.VET;
    case USER_TYPE.TECH_USER_TYPE:
      return constants.Event.Entity.TECH;
    case USER_TYPE.HOSPITAL_USER_TYPE:
      return constants.Event.Entity.HOSPITAL;
    case USER_TYPE.STUDENT_USER_TYPE:
      return constants.Event.Entity.STUDENT;
    default:
      return null;
  }
};

const getDynamicBaseTrackingParams: any = async () => {
  // localStorage
  const userIdFromLocalStorage = localStorage.getItem("userId");
  const userParams = {
    userId: userIdFromLocalStorage ? parseInt(userIdFromLocalStorage) : 0,
  };

  // window
  const windowParams = {
    screenHeight: window.screen.height,
    screenWidth: window.screen.width,
    pathName: window.location.pathname,
    domainName: window.location.hostname,
    gitSha: window.RooConfig.GITHUB_SHA,
  };

  // session
  const rsessionCookie = Cookies.get("rsession");
  const sessionInfo = rsessionCookie ? JSON.parse(window.atob(rsessionCookie)) : null;

  const utmParams = sessionInfo?.utm_source // source is required if any utm params exist
    ? {
        utmSource: sessionInfo?.utm_source,
        utmMedium: sessionInfo?.utm_medium,
        utmCampaign: sessionInfo?.utm_campaign,
        utmContent: sessionInfo?.utm_content,
        utmTerm: sessionInfo?.utm_term,
        adSetId: sessionInfo?.ad_set_id,
        adGroupId: sessionInfo?.ad_group_id,
        adId: sessionInfo?.ad_id,
      }
    : // Avoid UTM race condition: if the cookie from /startSession is not set yet, then we need to fallback
      getUtmParamsWithReferrerFallback();

  const sessionParams = {
    sessionId: sessionInfo?.id,
    searchSessionId: sessionInfo?.ssid,
    ipAddress: sessionInfo?.ip,
    referrerUri: sessionInfo?.rf,
    ...utmParams,
  };

  // superuser
  let superUserEmail = null;
  const authContext = await AuthUtility.getAuthContext();
  if (authContext?.userTypeId === USER_TYPE.ROO_SUPER_USER_TYPE) {
    superUserEmail = authContext.email;
  }

  return {
    ...userParams,
    ...windowParams,
    ...sessionParams,
    superUserEmail,
  };
};

const getBaseTrackingParams: any = async () => {
  if (!staticBaseTrackingParams) {
    staticBaseTrackingParams = detectPlatform();
  }

  return { ...staticBaseTrackingParams, ...(await getDynamicBaseTrackingParams()) };
};

const beforeSendEventCallbackFn = async (eventTracker: utils.EventTracker) => {
  eventTracker.updateBaseTrackingParams(await getBaseTrackingParams());
};

const trackGoogleAnalyticsEvent: utils.TrackGoogleAnalyticsEvent = async (event) => {
  window.dataLayer?.push({ event: event.en, ...event, email: localStorage.getItem("email") });
};

const trackPostHogEvent: utils.TrackPostHogEvent = async (event) => {
  posthog.capture(event.en, event);
};

const trackMetaEvent: utils.TrackMetaEvent = (event) => {
  if (!window.fbq) {
    return;
  }

  switch (event.en) {
    case constants.Event.Name.VET_SCHEDULE_ACTIVATION_CALL_COMPLETE:
    case constants.Event.Name.TECH_CLICK_HIREFLIX:
    case constants.Event.Name.VET_SIGNUP_COMPLETE:
    case constants.Event.Name.TECH_SIGNUP_COMPLETE:
    case constants.Event.Name.TECH_CLICK_LICENSE:
    case constants.Event.Name.VET_CLICK_LICENSE:
    case constants.Event.Name.HOSPITAL_SIGNUP_COMPLETE:
    case constants.Event.Name.HOSPITAL_SAVE_FIRST_SHIFT:
      window.fbq("trackCustom", event.en, { ...event });
      break;
  }
};

const ET = new utils.EventTracker(
  getBaseTrackingParams(),
  trackPostHogEvent,
  trackGoogleAnalyticsEvent,
  trackMetaEvent,
  ["VET_SHIFT_CARD_VIEW", "TECH_SHIFT_CARD_VIEW"],
  beforeSendEventCallbackFn
);

export { ET as EventTracker };
