import React from "react";
import { Container, spacingXL } from "@RooUI";
import { ShiftProposalHospitalCard } from "@RooBeta/components/shiftCard/ShiftProposalHospitalCard";
import { useGetWorkedHospitals } from "@Roo/hooks/api/useGetWorkedHospitals";
import { isRooFeatureEnabled } from "@Roo/Common/Wrappers/isRooFeatureEnabled";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";
import { FEATURE_FLAGS } from "@Roo/constants/postHogFeatureFlags";
import { useGetShiftProposals } from "@RooBeta/hooks/api/useGetShiftProposals";

interface HospitalSelectProps {
  selectedHospitalIds: number[];
  onHospitalSelect: (hospitalIds: number[]) => void;
}

export const HospitalSelect = ({ selectedHospitalIds, onHospitalSelect }: HospitalSelectProps) => {
  const { vetId } = useRequiredAuthorizedUser();
  const isVet = typeof vetId === "number";
  const isShiftProposalEnabled = isRooFeatureEnabled(FEATURE_FLAGS.SHIFT_PROPOSAL);

  const { data: workedHospitals = [] } = useGetWorkedHospitals(1, {
    enabled: isVet,
  });

  const { data: activeShiftProposals = [] } = useGetShiftProposals("active");
  const activeShiftProposalsMap = activeShiftProposals.reduce((acc, proposal) => {
    acc[proposal.hospitalId] = proposal.shiftProposalId;
    return acc;
  }, {} as Record<number, number>);

  const hospitalsWithActiveShiftProposals = workedHospitals.filter(
    (hospital) => activeShiftProposalsMap?.[hospital.hospitalId]
  );

  const handleHospitalSelect = (hospitalId: number) => {
    if (selectedHospitalIds.includes(hospitalId)) {
      onHospitalSelect(selectedHospitalIds.filter((id) => id !== hospitalId));
    } else {
      onHospitalSelect([...selectedHospitalIds, hospitalId]);
    }
  };

  if (!isVet || !workedHospitals.length || !isShiftProposalEnabled) {
    return null;
  }

  return (
    <Container style={{ padding: `0px ${spacingXL}` }}>
      {hospitalsWithActiveShiftProposals?.map((hospital, index) => (
        <ShiftProposalHospitalCard
          key={hospital.hospitalId}
          hospital={hospital}
          onSelect={() => handleHospitalSelect(hospital.hospitalId)}
          isSelected={selectedHospitalIds.includes(hospital.hospitalId)}
          hideBorder={index === hospitalsWithActiveShiftProposals.length - 1}
          showCheckIcon={false}
          hideHoverBackground={true}
        />
      ))}
    </Container>
  );
};
