import * as Sentry from "@sentry/react";
import { call, put, takeEvery } from "redux-saga/effects";

import { rooFetch } from "../../../api/common/rooFetch";
import * as ActionTypes from "../../../constants/addNewCalendarNote";
import * as ErrorMessages from "../../../constants/errorMessage";
import { EXCEPTION_MESSAGE } from "../../../constants/errorMessage";
import { UNSET_LOADER } from "../../../constants/loader";

// @ts-expect-error TS7006
export function* addNewCalendarNoteAsync(action) {
  try {
    // @ts-expect-error TS7057
    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/calendarNotes/", {
      method: "POST",
      body: JSON.stringify(action.payload),
    });

    // @ts-expect-error TS7057
    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: ActionTypes.ADD_CALENDAR_NOTE_SUCCESS,
        payload: { status: data.status_code },
      });
    } else {
      yield put({
        type: ActionTypes.ADD_CALENDAR_NOTE_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while adding new calendar note: " },
    });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: ActionTypes.ADD_CALENDAR_NOTE_FAIL,
      payload: { status: error.status_code, message: ErrorMessages.EXCEPTION_MESSAGE },
    });
  }
}

// @ts-expect-error TS7006
export function* fetchCalendarNotesAsync(action) {
  try {
    // @ts-expect-error TS7057
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/calendarNotes?date=" + action.payload.date + "&"
    );

    // @ts-expect-error TS7057
    const data = yield response.json();

    if (data.status_code === 200) {
      yield put({
        type: ActionTypes.FETCH_CALENDAR_NOTE_SUCCESS,
        payload: { status: data.status_code, data: data.data },
      });
    } else {
      yield put({
        type: ActionTypes.FETCH_CALENDAR_NOTE_FAIL,
        payload: { status: data.status_code, data: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while fetching calendar notes: " } });

    yield put({
      type: ActionTypes.FETCH_CALENDAR_NOTE_FAIL,
      payload: { status: error.status_code, data: EXCEPTION_MESSAGE },
    });
  }
}

// @ts-expect-error TS7006
export function* deleteCalendarNotesAsync(action) {
  try {
    // @ts-expect-error TS7057
    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/calendarNotes/", {
      method: "DELETE",
      body: JSON.stringify(action.payload),
    });
    // @ts-expect-error TS7057
    const data = yield response.json();

    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      yield put({
        type: ActionTypes.DELETE_CALENDAR_NOTE_SUCCESS,
        payload: { status: data.status_code, data: data.data },
      });
    } else {
      yield put({
        type: ActionTypes.DELETE_CALENDAR_NOTE_FAIL,
        payload: { status: data.status_code, data: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while deleting calendar notes: " } });
    yield put({ type: UNSET_LOADER });

    yield put({
      type: ActionTypes.DELETE_CALENDAR_NOTE_FAIL,
      payload: { status: error.status_code, data: EXCEPTION_MESSAGE },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootCalendarNote() {
  yield takeEvery(ActionTypes.ADD_CALENDAR_NOTE_REQUEST, addNewCalendarNoteAsync);
  yield takeEvery(ActionTypes.FETCH_CALENDAR_NOTE_REQUEST, fetchCalendarNotesAsync);
  yield takeEvery(ActionTypes.DELETE_CALENDAR_NOTE_REQUEST, deleteCalendarNotesAsync);
}
