import { Button, FlexLayout, Link, MediumText, Modal, SmallText, Text, grayWhite } from "@RooUI";
import React from "react";

import { useRequiredAuthorizedUser } from "@RooBeta/hooks";

import { RooIcon } from "../RooIcon";

import { ClaimPromoCardContainer, IconContainer, PromoContent } from "./styles";
import { usePromoManagement } from "./usePromoManagement";

export const ClaimPromoCard = () => {
  const {
    showModal,
    showLoading,
    claimPromoMutation,
    eligiblePromos,
    handleOpenModal,
    handleDismiss,
    setShowModal,
  } = usePromoManagement();
  const { userId } = useRequiredAuthorizedUser();
  const currentPromo = eligiblePromos?.[0];

  if (!currentPromo) return null;

  const handleClaim = () => {
    claimPromoMutation.mutate({
      userId,
      promoId: currentPromo.promoId,
    });
  };

  return (
    <>
      <Modal
        showModal={showModal}
        onCloseModal={() => setShowModal(false)}
        header="Promotion Description"
        data-testid={`modalPromo${currentPromo.promoId}`}
        body={
          <div className="promo-offer-modal">
            <span className="promo-name">{currentPromo.promoName}</span>
            <Text className="banner-text" data-testid="promoBannerText">
              {currentPromo.promoBannerAndEmailText}
            </Text>
            <Text className="description-text" data-testid="promoDescription">
              {currentPromo.promoDescription}
            </Text>
          </div>
        }
        $primaryButtonProps={{
          title: "Claim Now",
          children: "Claim Now",
          $buttonType: "primary",
          disabled: showLoading,
          onClick: handleClaim,
          "data-testid": `modalPromo${currentPromo.promoId}PrimaryButton`,
          style: { margin: "0 auto" },
        }}
      />
      <ClaimPromoCardContainer data-testid={`promo${currentPromo.promoId}`}>
        <PromoContent>
          <MediumText>{currentPromo.promoName}</MediumText>
          <SmallText>{currentPromo.promoBannerAndEmailText}</SmallText>
          <FlexLayout gap="m">
            <Button
              data-testid={`reviewPromo${currentPromo.promoId}`}
              onClick={() => handleOpenModal(currentPromo.promoId)}
            >
              Review Promo
            </Button>
            <Link onClick={() => handleDismiss(currentPromo.promoId)}>No thanks</Link>
          </FlexLayout>
        </PromoContent>
        <div>
          <IconContainer>
            <RooIcon icon="promo" size="l" color={grayWhite} />
          </IconContainer>
        </div>
      </ClaimPromoCardContainer>
    </>
  );
};
