import { ConfigProvider } from "antd";
import Dayjs, { Dayjs as DayjsType } from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import React, { ReactNode, useEffect, useState } from "react";
import { CSSProperties } from "styled-components";

import { blueDark600, disabledTextDark, grayBase, primaryFont } from "../../styles/constants";
import { FormLabel } from "../FormLabel";
import { Icon } from "../Icon";
import { InputContainer } from "../Input/AntInputStyles";
import { SubText } from "../Input/OpenText/AntOpenTextStyles";
import { ErrorText } from "../Text/ErrorText";

import { CustomDatePicker, DatePickerContainer } from "./DatePickerStyles";
import { TaxQuarters } from "./TaxQuarter";

Dayjs.extend(quarterOfYear);

export type PickerType = "date" | "month" | "quarter" | "year";

export interface AntDatePickerProps {
  picker: PickerType;
  taxQuarters?: boolean;
  suffixIcon?: ReactNode;
  placeholder?: string;
  format?: string;
  value?: DayjsType | string;
  allowClear?: boolean;
  className?: string;
  disabled?: boolean;
  disabledDate?: (currentDate: DayjsType) => boolean;
  open?: boolean;
  placement?: "bottomLeft" | "bottomRight" | "topRight" | "topLeft";
  defaultPickerValue?: DayjsType;
  defaultValue?: DayjsType;
  showToday?: boolean;
  onChange?: (e?: any) => void;
  isShowArrows?: boolean;
  nextIcon?: ReactNode;
  prevIcon?: ReactNode;
  superPrevIcon?: ReactNode;
  superNextIcon?: ReactNode;
  label?: string;
  error?: string;
  "data-testid"?: string;
  subText?: string;
  isAllowEmptyState?: boolean;
  disablePastDates?: boolean;
  onClick?: (e?: any) => void;
  style?: CSSProperties;
  inputReadOnly?: boolean;
}
export const DatePicker: React.FC<AntDatePickerProps> = (props) => {
  const {
    picker,
    defaultValue,
    isShowArrows = true,
    onChange,
    value,
    label,
    taxQuarters,
    error,
    format,
    disabled,
    "data-testid": dataTestId,
    isAllowEmptyState,
    subText,
    disablePastDates,
    onClick,
  } = props;

  const getSelectedDate = () => {
    if (defaultValue) return defaultValue;
    else if (!isAllowEmptyState) return Dayjs();
    else return undefined;
  };

  const [selectedDate, setSelectedDate] = useState<DayjsType | undefined>(getSelectedDate());
  const [taxDate, setTaxDate] = useState<DayjsType | undefined>(
    selectedDate ? TaxQuarters.getTaxDate(selectedDate) : undefined
  );

  useEffect(() => {
    const formattedValue = Dayjs(value);
    if (value && formattedValue && selectedDate != formattedValue) {
      setSelectedDate(formattedValue);
      setTaxDate(TaxQuarters.getTaxDate(formattedValue));
    } else if (isAllowEmptyState && !value) {
      setSelectedDate(undefined);
      setTaxDate(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleGoBack = () => {
    if (selectedDate) {
      let newDate;
      switch (picker) {
        case "date":
          newDate = selectedDate.subtract(1, "day");
          break;
        case "month":
          newDate = selectedDate.subtract(1, "month");
          break;
        case "quarter":
          newDate = taxQuarters
            ? TaxQuarters.subtract(selectedDate)
            : selectedDate.subtract(1, "quarter");
          break;
        case "year":
          newDate = selectedDate.subtract(1, "year");
          break;
      }
      if (newDate) {
        if (onChange) {
          onChange(newDate);
        }
        setSelectedDate(newDate);
        setTaxDate(TaxQuarters.getTaxDate(newDate));
      }
    }
  };
  const handleGoForward = () => {
    if (selectedDate) {
      let newDate;
      switch (picker) {
        case "date":
          newDate = selectedDate.add(1, "day");
          break;
        case "month":
          newDate = selectedDate.add(1, "month");
          break;
        case "quarter":
          newDate = taxQuarters ? TaxQuarters.add(selectedDate) : selectedDate.add(1, "quarter");
          break;
        case "year":
          newDate = selectedDate.add(1, "year");
          break;
      }
      if (newDate) {
        if (onChange) {
          onChange(newDate);
        }
        setSelectedDate(newDate);
        setTaxDate(TaxQuarters.getTaxDate(newDate));
      }
    }
  };

  const formatDate = () => {
    switch (picker) {
      case "date":
        return "MM/DD/YYYY";
      case "month":
        return "MMM YYYY";
      case "quarter":
        return "[Q]Q YYYY";
      case "year":
        return "YYYY";
      default:
        return "";
    }
  };

  const disabledDate = (current: DayjsType) => {
    if (disablePastDates) {
      return current && current < Dayjs().startOf("day");
    }
    return props.disabledDate ? props.disabledDate(current) : false;
  };

  const customDatePickerStyledProps = {
    $picker: picker,
    $style: props.style,
  };

  return (
    <InputContainer>
      <FormLabel error={!!error}>{label}</FormLabel>
      <ConfigProvider
        theme={{
          components: {
            DatePicker: {
              fontFamily: primaryFont,
              fontSize: 16,
              colorText: disabled ? disabledTextDark : grayBase,
            },
          },
        }}
      >
        <DatePickerContainer data-testid={dataTestId}>
          {isShowArrows ? (
            <Icon
              name="KeyboardArrowLeft"
              color={disabled ? disabledTextDark : blueDark600}
              onClick={handleGoBack}
              data-testid="keyboardArrowLeft"
            />
          ) : null}
          <CustomDatePicker
            {...props}
            {...customDatePickerStyledProps}
            suffixIcon={
              <Icon
                name="OutlineCalendarMonth"
                color={disabled ? disabledTextDark : blueDark600}
                onClick={handleGoBack}
                data-testid="outlineCalendarMonth"
              />
            }
            format={format || formatDate()}
            allowClear={false}
            disabledDate={disabledDate}
            value={taxQuarters && picker == "quarter" ? taxDate : selectedDate}
            onChange={(e) => {
              // @ts-expect-error TS2345
              setSelectedDate(e);
              if (onChange) onChange(e);
            }}
            prevIcon={<Icon name="KeyboardArrowLeft" data-testid="keyboardArrowLeft" />}
            nextIcon={<Icon name="KeyboardArrowRight" data-testid="keyboardArrowRight" />}
            superPrevIcon={<Icon name="KeyboardArrowLeft" />}
            superNextIcon={<Icon name="KeyboardArrowRight" />}
            data-testid="datePicked"
            onClick={onClick}
          />
          {isShowArrows ? (
            <Icon
              name="KeyboardArrowRight"
              color={disabled ? disabledTextDark : blueDark600}
              onClick={handleGoForward}
              data-testid="keyboardArrowRight"
            />
          ) : null}
        </DatePickerContainer>
      </ConfigProvider>
      {subText && (
        <SubText disabled={disabled} color={grayBase}>
          {subText}
        </SubText>
      )}
      {error && <ErrorText data-testid={`${dataTestId}Error`}>{error}</ErrorText>}
    </InputContainer>
  );
};
