import { useCallback, useEffect, useRef, useState } from "react";
import { useModal } from "@Roo/Common/Modals/hooks/useModal";
import { ShiftProposalModalManager } from "@Roo/modals/ShiftProposal/ShiftProposalModalManager";
import { useDeleteShiftProposal } from "@RooBeta/hooks/api/useDeleteShiftProposal";
import { useGetShiftProposals } from "@RooBeta/hooks/api/useGetShiftProposals";
import { useRooFeatureFlagEnabled } from "@Roo/Common/Wrappers/useRooFeatureFlagEnabled";
import { FEATURE_FLAGS } from "@Roo/constants/postHogFeatureFlags";

interface OpenShiftProposalModalParams {
  hospitalIds?: number[];
  onComplete?: () => void;
  source?: "shiftList" | "hospitalAllShifts";
}

export const useShiftProposalModals = (openBackfillModal = false) => {
  const { openModal, closeModal } = useModal();
  const { mutate: deleteShiftProposal } = useDeleteShiftProposal();
  const { data: activeShiftProposals = [] } = useGetShiftProposals("active");
  const isShiftProposalEnabled = useRooFeatureFlagEnabled(FEATURE_FLAGS.SHIFT_PROPOSAL);

  const hasOpenedBackfillModal = useRef(false);
  const [modalInstanceId, setModalInstanceId] = useState(0);
  const [isModalLoading, setIsModalLoading] = useState(false);

  const activeShiftProposalIds = activeShiftProposals.map((proposal) => proposal.shiftProposalId);

  const hasLegacyShiftProposals =
    activeShiftProposals.length && activeShiftProposals.some((proposal) => !proposal.commsSentOn);

  useEffect(() => {
    if (
      openBackfillModal &&
      hasLegacyShiftProposals &&
      !hasOpenedBackfillModal.current &&
      isShiftProposalEnabled
    ) {
      hasOpenedBackfillModal.current = true;
      const newModalId = modalInstanceId + 1;
      setModalInstanceId(newModalId);
      openModal(
        ShiftProposalModalManager,
        {
          hospitalIds: [],
          source: "backfill",
          onClose: () => {
            closeModal();
            if (activeShiftProposalIds?.length) {
              activeShiftProposalIds.forEach((id) => {
                deleteShiftProposal({
                  shiftProposalId: id,
                });
              });
            }
          },
          onComplete: () => {
            closeModal();
          },
        },
        {
          ShiftProposalModal: newModalId,
        },
        {
          isAuthenticatedContent: true,
          showLoader: false,
        }
      );
    }
  }, [
    openBackfillModal,
    closeModal,
    activeShiftProposalIds,
    deleteShiftProposal,
    openModal,
    hasLegacyShiftProposals,
    modalInstanceId,
    isShiftProposalEnabled,
  ]);

  const openShiftProposalModal = useCallback(
    ({ hospitalIds, onComplete, source }: OpenShiftProposalModalParams) => {
      if ((!hasLegacyShiftProposals && !hospitalIds) || !isShiftProposalEnabled) {
        return;
      }

      const newModalId = modalInstanceId + 1;
      setModalInstanceId(newModalId);
      setIsModalLoading(true);
      openModal(
        ShiftProposalModalManager,
        {
          hospitalIds,
          source,
          onClose: () => {
            closeModal();
            setIsModalLoading(false);
          },
          onComplete: () => {
            onComplete?.();
            closeModal();
            setIsModalLoading(false);
          },
          onModalRendered: () => {
            setIsModalLoading(false);
          },
        },
        {
          ShiftProposalModal: newModalId,
        },
        {
          isAuthenticatedContent: true,
          showLoader: false,
        }
      );
    },
    [openModal, closeModal, hasLegacyShiftProposals, modalInstanceId, isShiftProposalEnabled]
  );

  return {
    openShiftProposalModal,
    isModalLoading,
  };
};
