import { useQuery } from "@tanstack/react-query";
import { getChatMessages, Conversation } from "@Roo/api/messages/messages";
import { CACHE } from "@Roo/api/utility/constants";
import * as Sentry from "@sentry/react";

type UseGetChatMessagesParams = {
  email?: string;
  hospitalId?: number;
  enabled?: boolean;
};

export const useGetChatMessages = ({
  email,
  hospitalId,
  enabled = true,
}: UseGetChatMessagesParams) => {
  return useQuery<Conversation[], Error>({
    queryKey: [CACHE.CONVERSATIONS],
    queryFn: async () => {
      try {
        return await getChatMessages(email || "", hospitalId || 0);
      } catch (error) {
        Sentry.captureException(error, {
          extra: {
            email,
            hospitalId,
            context: "useGetChatMessages",
          },
        });
        throw error;
      }
    },
    enabled: enabled && !!(email || hospitalId),
  });
};
