import { AuthUtility } from "@Roo/helpers/AuthUtility";
import { Event } from "@RooBeta/types";

export const getTrackingOptions = (linkCTA: string) => ({
  eventName: Event.Name.NAVBAR,
  eventType: Event.Type.CLICK,
  context: {
    location: "navbar",
    linkCTA,
  },
});

export const handleBackToAdmin = async () => {
  AuthUtility.clearPreviousSession();
  const authContext = await AuthUtility.getAuthContext();

  if (authContext) {
    AuthUtility.setIsAuthorisedUser(true);
    localStorage.setItem("userTypeId", authContext.userTypeId.toString());
    localStorage.setItem("email", authContext.email);
  }

  window.location.replace(`/admin`);
};
