import { Flex as AntdFlex, FlexProps as AntdFlexProps } from "antd";
import React from "react";
import styled from "styled-components";

import {
  spacing3X,
  spacing4X,
  spacing5X,
  spacing6X,
  spacing7X,
  spacingL,
  spacingM,
  spacingS,
  spacingXL,
  spacingXS,
  spacingXXL,
  spacingXXS,
} from "../../styles/constants";

export interface FlexProps extends Omit<AntdFlexProps, "gap"> {
  gap?: "7x" | "6x" | "5x" | "4x" | "3x" | "xxl" | "xl" | "l" | "m" | "s" | "xs" | "xxs";
}

const StyledFlex = styled(AntdFlex)<FlexProps>`
  gap: ${(props) => {
    switch (props.gap) {
      case "7x":
        return spacing7X;
      case "6x":
        return spacing6X;
      case "5x":
        return spacing5X;
      case "4x":
        return spacing4X;
      case "3x":
        return spacing3X;
      case "xxl":
        return spacingXXL;
      case "xl":
        return spacingXL;
      case "l":
        return spacingL;
      case "m":
        return spacingM;
      case "s":
        return spacingS;
      case "xs":
        return spacingXS;
      case "xxs":
        return spacingXXS;
      default:
        return "0";
    }
  }};
`;

export const FlexLayout = ({ children, ...props }: FlexProps) => {
  return <StyledFlex {...props}>{children}</StyledFlex>;
};
