import * as Sentry from "@sentry/react";
import moment from "moment-timezone"; // same as moment, but with additional timezone features needed when shared code with back end
import { call, put, takeEvery } from "redux-saga/effects";

import { rooFetch } from "../../../api/common/rooFetch";
import * as errorMessage from "../../../constants/errorMessage";
import * as actionTypes from "../../../constants/hospitalConstants";
import { UNSET_LOADER } from "../../../constants/loader";
import { Event, EventTracker } from "../../../tracking/service/EventTracker/EventTrackerService";

// @ts-expect-error TS7006
function* submitVetRatingAsync(action) {
  try {
    const { isGetAllReviews } = action;
    // @ts-expect-error TS7057
    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/hospital/updateRating", {
      method: "POST",
      body: JSON.stringify(action.payload),
    });

    // @ts-expect-error TS7057
    const data = yield response.json();
    if (data.status_code === 200) {
      EventTracker.send({
        eventName: Event.Name.HOSPITAL_REVIEW_MODAL,
        eventType: Event.Type.CLICK,
        entityType: Event.Entity.HOSPITAL,
        entityId: action.payload.hospitalId,
        context: { userType: "Vet", ...action.payload },
      });

      if (action.defaultRating) {
        yield put({
          type: actionTypes.UPDATE_CURRENT_VET_RATING_INDEX,
        });
        yield put({
          type: actionTypes.GET_HOSPITAL_PENDING_VET_RATING_REQUEST,
          payload: { isGetAllReviews },
        });
        if (action.payload.isNoShow) {
          //This is to fetch the calendar data(vet) once hospital adds the rating as NoShow.
          const oDateMoment = moment(Date());
          const year = oDateMoment.format("YYYY");
          const s2DigitMonth = oDateMoment.format("MM");
          const options = {
            year: year,
            month: s2DigitMonth,
            currentCalendarDate: oDateMoment,
          };

          yield put({ type: actionTypes.GET_VET_SHIFTS, payload: options });
        }
      }

      yield put({ type: UNSET_LOADER });

      yield put({
        type: actionTypes.SUBMIT_VET_RATING_SUCCESS,
        payload: {
          status: data.status_code,
          vetRatingCount: action.vetRatingCount,
        },
      });
      if (action.payload.blockVet) {
        yield put({
          type: actionTypes.UPDATE_BLOCKED_CONTRACTOR_COUNT,
          payload: action.payload,
        });
      }
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.SUBMIT_VET_RATING_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while submitting vet rating: " } });
    yield put({
      type: actionTypes.SUBMIT_VET_RATING_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// @ts-expect-error TS7006
function* deferVetRatingAsync(action) {
  try {
    const { isGetAllReviews } = action.payload;
    // @ts-expect-error TS2345
    const hospitalId = parseInt(localStorage.getItem("hospitalId"));
    delete action.payload.isGetAllReviews;
    // @ts-expect-error TS7057
    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/hospital/deferRating", {
      method: "POST",
      body: JSON.stringify(action.payload),
    });

    // @ts-expect-error TS7057
    const data = yield response.json();
    if (data.status_code === 200) {
      EventTracker.send({
        eventName: Event.Name.HOSPITAL_SKIP_REVIEW,
        eventType: Event.Type.CLICK,
        entityType: Event.Entity.HOSPITAL,
        entityId: hospitalId,
        context: { userType: "Vet" },
      });
      yield put({
        type: actionTypes.UPDATE_CURRENT_VET_RATING_INDEX,
      });
      yield put({
        type: actionTypes.GET_HOSPITAL_PENDING_VET_RATING_REQUEST,
        payload: { isGetAllReviews },
      });
      yield put({ type: UNSET_LOADER });
      yield put({
        type: actionTypes.DEFER_VET_RATING_SUCCESS,
        payload: { status: data.status_code, vetRatingCount: action.vetRatingCount },
      });
      yield put({ type: actionTypes.CLOSE_VET_RATING_MODAL });
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.DEFER_VET_RATING_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while deferring vet rating: " } });
    yield put({
      type: actionTypes.DEFER_VET_RATING_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// @ts-expect-error TS7006
function* deferAllRatingsAsync(action) {
  try {
    const { isVet } = action.payload;
    // @ts-expect-error TS7057
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/hospital/deferAllRatings",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    // @ts-expect-error TS7057
    const data = yield response.json();
    if (data.status_code === 200) {
      EventTracker.send({
        eventName: Event.Name.HOSPITAL_SKIP_ALL_REVIEWS,
        eventType: Event.Type.CLICK,
        entityType: Event.Entity.HOSPITAL,
        entityId: action.payload.hospitalId,
        context: { userType: isVet ? "Vet" : "Tech" },
      });
      if (isVet) {
        yield put({
          type: actionTypes.GET_HOSPITAL_PENDING_VET_RATING_REQUEST,
          payload: { isGetAllReviews: 1 },
        });
      } else {
        yield put({
          type: actionTypes.GET_HOSPITAL_PENDING_TECH_RATING_REQUEST,
          payload: { isGetAllReviews: 1 },
        });
      }
      yield put({ type: UNSET_LOADER });
      yield put({
        type: actionTypes.DEFER_ALL_RATING_SUCCESS,
        payload: { status: data.status_code, vetRatingCount: action.vetRatingCount },
      });
      yield put({ type: actionTypes.CLOSE_VET_RATING_MODAL });
    } else if (data.status_code === 400) {
      yield put({ type: UNSET_LOADER });
      yield put({
        type: actionTypes.DEFER_ALL_RATING_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while deferring all ratings: " } });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: actionTypes.DEFER_ALL_RATING_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// @ts-expect-error TS7006
function* submitTechRatingAsync(action) {
  try {
    const { isGetAllReviews } = action;
    // @ts-expect-error TS7057
    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/hospital/updateRating", {
      method: "POST",
      body: JSON.stringify(action.payload),
    });

    // @ts-expect-error TS7057
    const data = yield response.json();
    if (data.status_code === 200) {
      EventTracker.send({
        eventName: Event.Name.HOSPITAL_REVIEW_MODAL,
        eventType: Event.Type.CLICK,
        entityType: Event.Entity.HOSPITAL,
        entityId: action.payload.hospitalId,
        context: { userType: "Tech", ...action.payload },
      });
      if (action.defaultRating) {
        yield put({
          type: actionTypes.UPDATE_CURRENT_TECH_RATING_INDEX,
        });
        yield put({
          type: actionTypes.GET_HOSPITAL_PENDING_TECH_RATING_REQUEST,
          payload: { isGetAllReviews },
        });
        if (action.payload.isNoShow) {
          //This is to fetch the calendar data(tech) once hospital adds the rating as NoShow.
          const oDateMoment = moment(Date());
          const year = oDateMoment.format("YYYY");
          const s2DigitMonth = oDateMoment.format("MM");
          const options = {
            year: year,
            month: s2DigitMonth,
            currentCalendarDate: oDateMoment,
          };

          yield put({ type: actionTypes.GET_TECH_SHIFTS, payload: options });
        }
      }

      yield put({ type: UNSET_LOADER });

      yield put({
        type: actionTypes.SUBMIT_TECH_RATING_SUCCESS,
        payload: { status: data.status_code, techRatingCount: action.techRatingCount },
      });
      if (action.payload.blockTech) {
        yield put({
          type: actionTypes.UPDATE_BLOCKED_CONTRACTOR_COUNT,
          payload: action.payload,
        });
      }
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.SUBMIT_TECH_RATING_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while submitting tech rating: " } });
    yield put({
      type: actionTypes.SUBMIT_TECH_RATING_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// @ts-expect-error TS7006
function* deferTechRatingAsync(action) {
  try {
    const { isGetAllReviews } = action.payload;
    // @ts-expect-error TS2345
    const hospitalId = parseInt(localStorage.getItem("hospitalId"));
    delete action.payload.isGetAllReviews;

    // @ts-expect-error TS7057
    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/hospital/deferRating", {
      method: "POST",
      body: JSON.stringify(action.payload),
    });

    // @ts-expect-error TS7057
    const data = yield response.json();
    if (data.status_code === 200) {
      EventTracker.send({
        eventName: Event.Name.HOSPITAL_REVIEW_MODAL,
        eventType: Event.Type.CLICK,
        entityType: Event.Entity.HOSPITAL,
        entityId: hospitalId,
        context: { userType: "Tech" },
      });
      yield put({
        type: actionTypes.UPDATE_CURRENT_TECH_RATING_INDEX,
      });
      yield put({
        type: actionTypes.GET_HOSPITAL_PENDING_TECH_RATING_REQUEST,
        payload: { isGetAllReviews },
      });
      yield put({ type: UNSET_LOADER });
      yield put({
        type: actionTypes.DEFER_TECH_RATING_SUCCESS,
        payload: { status: data.status_code, techRatingCount: action.techRatingCount },
      });
      yield put({ type: actionTypes.CLOSE_TECH_RATING_MODAL });
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.DEFER_TECH_RATING_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while deferring tech rating: " } });
    yield put({
      type: actionTypes.DEFER_TECH_RATING_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootShiftRatingByHospitalSaga() {
  yield takeEvery(actionTypes.SUBMIT_VET_RATING, submitVetRatingAsync);
  yield takeEvery(actionTypes.DEFER_VET_RATING, deferVetRatingAsync);
  yield takeEvery(actionTypes.DEFER_ALL_RATING, deferAllRatingsAsync);
  yield takeEvery(actionTypes.SUBMIT_TECH_RATING, submitTechRatingAsync);
  yield takeEvery(actionTypes.DEFER_TECH_RATING, deferTechRatingAsync);
}
