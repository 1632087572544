import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Sentry from "@sentry/react";
import { HubSpotForm } from "../common/HubSpotForm";
import { EventHospitalSignUpForm } from "./EventHospitalSignUpForm";
import { RegistrationForm } from "../../../../RegistrationUpdated/types";
import { createHospital } from "../../../../api/hospital";
import { Event, EventTracker } from "../../../../tracking/service/EventTracker/EventTrackerService";
import { posthog } from "posthog-js";
import { useAppDispatch } from "../../../../store/service";
import { HospitalSignUpContainerProps } from "./types";
import { isErrorResponse } from "../../../../api/common/error";
import { createUser, Request as CreateUserRequest } from "../../../../api/user/createUser";
import { HOSPITAL_USER } from "../../../../constants/UserRoleConstants";
import { HOSPITAL_USER_TYPE } from "../../../../constants/UserTypeConstants";
import { showToast } from "../../../../Common/Toasts/action/ToastActions";
import { getUtmParams } from "../common/util";
import { zodResolver } from "@hookform/resolvers/zod";
import { hospitalSignUpSchema } from "./validation";

interface RequiredFields {
  emailId: string;
  firstName: string;
  lastName: string;
  mobilePhoneNumber: string;
  password: string;
  hospitalName: string;
  hospitalAddress: string;
  hospitalState: number;
  hospitalCity: string;
  hospitalZipcode: string;
  hospitalPhoneNumber: string;
  typeOfHospital: number;
}

const validateRequiredFields = (formData: RegistrationForm): RequiredFields => {
  if (
    !formData.emailId ||
    !formData.firstName ||
    !formData.lastName ||
    !formData.mobilePhoneNumber ||
    !formData.password ||
    !formData.hospitalName ||
    !formData.hospitalAddress ||
    !formData.hospitalState ||
    !formData.hospitalCity ||
    !formData.hospitalZipcode ||
    !formData.hospitalPhoneNumber ||
    typeof formData.typeOfHospital !== "number"
  ) {
    throw new Error("Required fields are missing");
  }

  return {
    emailId: formData.emailId,
    firstName: formData.firstName,
    lastName: formData.lastName,
    mobilePhoneNumber: formData.mobilePhoneNumber,
    password: formData.password,
    hospitalName: formData.hospitalName,
    hospitalAddress: formData.hospitalAddress,
    hospitalState: formData.hospitalState,
    hospitalCity: formData.hospitalCity,
    hospitalZipcode: formData.hospitalZipcode,
    hospitalPhoneNumber: formData.hospitalPhoneNumber,
    typeOfHospital: formData.typeOfHospital,
  };
};

export const HospitalSignUpContainer = ({
  onSuccess,
  onHubspotSuccess,
  hospitalHubspotFormId,
  eventSlug,
  booth,
}: HospitalSignUpContainerProps) => {
  const dispatch = useAppDispatch();
  const methods = useForm<RegistrationForm>({
    resolver: zodResolver(hospitalSignUpSchema),
    mode: "onBlur",
    defaultValues: {},
  });
  const {
    utm_source: utmSource,
    utm_campaign: utmCampaign,
    utm_medium: utmMedium,
  } = getUtmParams(eventSlug, booth);
  const [loading, setLoading] = useState<boolean>(false);

  const handleFormSubmit = async (formData: RegistrationForm) => {
    try {
      setLoading(true);
      // Validate and get required fields
      const requiredFields = validateRequiredFields(formData);

      // Create user first
      const userRequest: CreateUserRequest & { password: string } = {
        emailId: requiredFields.emailId.toLowerCase(),
        userTypeId: HOSPITAL_USER_TYPE,
        userRoleId: HOSPITAL_USER,
        isFromMobile: 0,
        firstName: requiredFields.firstName.trim(),
        lastName: requiredFields.lastName.trim(),
        mobilePhoneNumber: requiredFields.mobilePhoneNumber,
        password: requiredFields.password,
        utmSource,
        utmCampaign,
        utmMedium,
      };

      const userResponse = await createUser(userRequest);

      // Check for duplicate user
      if (isErrorResponse(userResponse)) {
        if (
          userResponse?.name === "UsernameExistsException" ||
          userResponse.message === "An account with the given email already exists."
        ) {
          EventTracker.send({
            eventName: Event.Name.DUPLICATE_EMAIL_REGISTRATION_ATTEMPT,
            eventType: Event.Type.CLICK,
            entityType: Event.Entity.HOSPITAL,
            entityId: undefined,
            context: {
              email: requiredFields.emailId,
            },
          } as const);
          methods.setError("emailId", {
            type: "manual",
            message: "An account with this email already exists.",
          });
        } else {
          dispatch(
            showToast({
              variant: "error",
              message: "Error",
              description: "There was an error creating your account. Please try again.",
            })
          );
        }

        setLoading(false);
        return;
      }

      const userId = userResponse.data.userId;
      // Create hospital with the new userId
      const result = await createHospital({
        userId,
        hospitalName: requiredFields.hospitalName,
        hospitalAddress: requiredFields.hospitalAddress,
        hospitalState: requiredFields.hospitalState,
        hospitalCity: requiredFields.hospitalCity,
        hospitalZipcode: requiredFields.hospitalZipcode,
        hospitalPhoneNumber: requiredFields.hospitalPhoneNumber,
        selfIdentifiedEnterpriseName: formData.selfIdentifiedEnterpriseName ?? "",
        anticipatedReliefNeed: formData.anticipatedReliefNeed?.toString() ?? "",
        typeOfHospital: requiredFields.typeOfHospital,
        isLookingToHireProvider: formData.isLookingToHireProvider ?? false,
        utmSource,
        utmCampaign,
        utmMedium,
        eventOnboarded: formData.onboardingWithRooTeamMember ?? false,
      });

      if (isErrorResponse(result)) {
        dispatch(
          showToast({
            variant: "error",
            message: "Error",
            description: "There was an error creating your account. Please try again.",
          })
        );
        setLoading(false);
        return;
      }

      EventTracker.send({
        eventName: Event.Name.VET_CONFERENCE_EVENT_REGISTRATION_COMPLETE,
        eventType: Event.Type.CLICK,
        entityType: Event.Entity.EVENT,
        entityId: undefined,
      } as const);

      posthog.setPersonPropertiesForFlags({ email: requiredFields.emailId.toLowerCase() });
      onSuccess({
        calendlyLink: result.data.calendlyLink,
        hospitalName: requiredFields.hospitalName,
        email: requiredFields.emailId,
        password: requiredFields.password,
        signInRequest: userResponse.data.signInRequest,
        phone: requiredFields.mobilePhoneNumber,
        name: `${requiredFields.firstName} ${requiredFields.lastName}`,
        boothSignUp: booth,
        onboardingWithRooTeamMember: formData.onboardingWithRooTeamMember ?? false,
        hospitalId: result.data.hospitalId,
      });
    } catch (error) {
      Sentry.captureException(error);
      dispatch(
        showToast({
          variant: "error",
          message: "Error",
          description: error instanceof Error ? error.message : "Error creating account",
        })
      );
      setLoading(false);
      throw error;
    }
  };

  if (hospitalHubspotFormId) {
    return (
      <HubSpotForm
        formId={hospitalHubspotFormId}
        onSuccess={() => onHubspotSuccess({ email: "" })}
      />
    );
  }

  return (
    <FormProvider {...methods}>
      <EventHospitalSignUpForm
        onSave={handleFormSubmit}
        methods={methods}
        loading={loading}
        onCancel={() => methods.reset()}
      />
    </FormProvider>
  );
};
