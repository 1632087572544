import { useGetSchools } from "../../../api/utility/getSchools";
import { School } from "../../../api/utility/schoolTypes";

const getSchoolOptions = (schools: School[]) =>
  schools.map((school) => ({
    id: school.id,
    value: school.id,
    label:
      school.id !== 1
        ? school.university_name || school.college_name || ""
        : "Other - Please Specify",
  }));

export const useSchools = () => {
  const { isLoading, isSuccess, data } = useGetSchools();

  return {
    schoolOptions: isSuccess && data ? getSchoolOptions(data) : [],
    loading: isLoading || !data,
    isSuccess: isSuccess && !!data && getSchoolOptions(data).length > 0,
  };
};
