import { types } from "@roo-dev/common";
import {
  HOSPITAL_USER_TYPE,
  TECH_USER_TYPE,
  VET_USER_TYPE,
} from "@Roo/constants/UserTypeConstants";
import { Event, EventTracker as ET } from "@Roo/tracking/service/EventTracker/EventTrackerService";
import { useAuthorizedUser } from "@RooBeta/hooks";

export enum HospitalReferralPointOfEntry {
  ADMIN = "admin",
  FAILED_SUBMISSION = "failed-submission",
  SEND_ANOTHER = "send_another",
  NAVIGATION_MENU = "navigation-menu",
}

type EventContext = {
  pointOfEntry?: HospitalReferralPointOfEntry;
  [key: string]: unknown;
};

export const useReferHospitalEvents = () => {
  const { user } = useAuthorizedUser();
  const { userTypeId, vetId, techId, userId } = user ?? {};
  const isHospital = userTypeId === HOSPITAL_USER_TYPE;

  const getEntityInfo = () => {
    const entityTypeMap: Record<number, types.EntityType> = {
      [HOSPITAL_USER_TYPE]: Event.Entity.USER,
      [VET_USER_TYPE]: Event.Entity.VET,
      [TECH_USER_TYPE]: Event.Entity.TECH,
    };

    return {
      entityType: userTypeId ? entityTypeMap[userTypeId] : Event.Entity.USER,
      entityId: vetId ?? techId ?? userId,
    };
  };

  const sendEvent = async (
    eventName: types.EventName,
    eventType: types.EventType,
    context?: EventContext
  ) => {
    const isVet = userTypeId === VET_USER_TYPE;
    const isTech = userTypeId === TECH_USER_TYPE;

    if (isVet || isTech || isHospital) {
      await ET.send({
        eventName,
        eventType,
        ...getEntityInfo(),
        ...(context || {}),
      });
    }
  };

  // Impression Events
  const trackLandingPageImpression = async (pointOfEntry?: HospitalReferralPointOfEntry) => {
    const eventName = isHospital
      ? Event.Name.HOSPITAL_VIEW_HOSPITAL_REFERRAL_VALIDATION
      : Event.Name.PROVIDER_VIEW_HOSPITAL_REFERRAL_VALIDATION;
    await sendEvent(eventName, Event.Type.PAGE_VIEW, {
      pointOfEntry,
    });
  };

  const trackHospitalDetailsImpression = async () => {
    const eventName = isHospital
      ? Event.Name.HOSPITAL_VIEW_HOSPITAL_REFERRAL_FORM
      : Event.Name.PROVIDER_VIEW_HOSPITAL_REFERRAL_CONTACT_INFO;
    await sendEvent(eventName, Event.Type.IMPRESSION);
  };

  const trackReliefNeedsImpression = async () => {
    await sendEvent(Event.Name.PROVIDER_VIEW_HOSPITAL_REFERRAL_RELIEF_NEEDS, Event.Type.IMPRESSION);
  };

  const trackReviewSendImpression = async () => {
    await sendEvent(Event.Name.PROVIDER_VIEW_HOSPITAL_REFERRAL_SEND_EMAIL, Event.Type.IMPRESSION);
  };

  const trackSuccessImpression = async () => {
    const eventName = isHospital
      ? Event.Name.HOSPITAL_VIEW_HOSPITAL_REFERRAL_SUCCESS
      : Event.Name.PROVIDER_VIEW_HOSPITAL_REFERRAL_SUCCESS;
    await sendEvent(eventName, Event.Type.IMPRESSION);
  };

  // Submit Events
  const trackValidationSubmit = async () => {
    const eventName = isHospital
      ? Event.Name.HOSPITAL_CLICK_HOSPITAL_REFERRAL_VALIDATION
      : Event.Name.PROVIDER_CLICK_HOSPITAL_REFERRAL_VALIDATION;
    await sendEvent(eventName, Event.Type.CLICK);
  };

  const trackContactInfoSubmit = async () => {
    await sendEvent(Event.Name.PROVIDER_CLICK_HOSPITAL_REFERRAL_CONTACT_INFO, Event.Type.CLICK);
  };

  const trackReliefNeedsSubmit = async () => {
    await sendEvent(Event.Name.PROVIDER_CLICK_HOSPITAL_REFERRAL_RELIEF_NEEDS, Event.Type.CLICK);
  };

  const trackSendEmailSubmit = async () => {
    const eventName = isHospital
      ? Event.Name.HOSPITAL_CLICK_HOSPITAL_REFERRAL_FORM
      : Event.Name.PROVIDER_CLICK_HOSPITAL_REFERRAL_SEND_EMAIL;
    await sendEvent(eventName, Event.Type.CLICK);
  };

  const trackSendAnotherSubmit = async () => {
    const eventName = isHospital
      ? Event.Name.HOSPITAL_CLICK_HOSPITAL_REFERRAL_SEND_ANOTHER
      : Event.Name.PROVIDER_CLICK_HOSPITAL_REFERRAL_SEND_ANOTHER;
    await sendEvent(eventName, Event.Type.CLICK);
  };

  // Validation Result Events
  const trackValidationSuccess = async () => {
    const eventName = isHospital
      ? Event.Name.HOSPITAL_HOSPITAL_REFERRAL_VALIDATION_SUCCESS
      : Event.Name.PROVIDER_HOSPITAL_REFERRAL_VALIDATION_SUCCESS;
    await sendEvent(eventName, Event.Type.IMPRESSION);
  };

  const trackValidationFailure = async () => {
    const eventName = isHospital
      ? Event.Name.HOSPITAL_HOSPITAL_REFERRAL_VALIDATION_FAIL
      : Event.Name.PROVIDER_HOSPITAL_REFERRAL_VALIDATION_FAIL;
    await sendEvent(eventName, Event.Type.IMPRESSION);
  };

  return {
    // Impression Events
    trackLandingPageImpression,
    trackHospitalDetailsImpression,
    trackReliefNeedsImpression,
    trackReviewSendImpression,
    trackSuccessImpression,
    // Submit Events
    trackValidationSubmit,
    trackContactInfoSubmit,
    trackReliefNeedsSubmit,
    trackSendEmailSubmit,
    trackSendAnotherSubmit,
    // Validation Result Events
    trackValidationSuccess,
    trackValidationFailure,
  };
};
