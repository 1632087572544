import React from "react";

export const Surgery = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#303031"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_14266_38)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.846189 0.846127C1.02471 0.667146 1.30873 0.649565 1.50796 0.805164L16.432 12.4609C16.4159 12.3259 16.4077 12.1885 16.4077 12.0492C16.4077 10.1459 17.9506 8.60305 19.8538 8.60305C21.7571 8.60305 23.2999 10.1459 23.2999 12.0492C23.2999 13.9524 21.7571 15.4953 19.8538 15.4953C18.9364 15.4953 18.1027 15.1369 17.4852 14.5523L15.5004 13.0022L13.0373 15.4652L14.5962 17.4506C15.1872 18.0693 15.5501 18.9076 15.5501 19.8307C15.5501 21.7339 14.0072 23.2768 12.104 23.2768C10.2007 23.2768 8.65782 21.7339 8.65782 19.8307C8.65782 17.9274 10.2007 16.3845 12.104 16.3845C12.2399 16.3845 12.3741 16.3924 12.5059 16.4077L0.806938 1.508C0.650824 1.30918 0.66767 1.02511 0.846189 0.846127ZM13.8422 18.1096C13.3989 17.6619 12.7838 17.3845 12.104 17.3845C10.753 17.3845 9.65782 18.4797 9.65782 19.8307C9.65782 21.1816 10.753 22.2768 12.104 22.2768C13.4549 22.2768 14.5501 21.1816 14.5501 19.8307C14.5501 19.2036 14.3142 18.6317 13.9263 18.1988C13.8997 18.1767 13.8751 18.1514 13.8528 18.1232L13.8422 18.1096ZM12.4153 14.673L14.7063 12.382L4.13179 4.12323L12.4153 14.673ZM18.2434 13.8905C18.6737 14.2671 19.2371 14.4953 19.8538 14.4953C21.2048 14.4953 22.2999 13.4002 22.2999 12.0492C22.2999 10.6982 21.2048 9.60305 19.8538 9.60305C18.5028 9.60305 17.4077 10.6982 17.4077 12.0492C17.4077 12.7334 17.6886 13.352 18.1414 13.7959L18.1655 13.8148C18.1947 13.8376 18.2207 13.863 18.2434 13.8905Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_14266_38">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

Surgery.isCustomSvg = true;
