import { useQuery } from "@tanstack/react-query";

import { post } from "@RooBeta/api";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";
import { CacheKey, CustomQueryOptions, MyEvent } from "@RooBeta/types";

type UseMyEventsOptions = CustomQueryOptions<{
  data: MyEvent[];
}>;

export const useMyEventsQuery = (options: UseMyEventsOptions = {}) => {
  const {
    userId,
    zipcode,
    vetId,
    provider: { providerType },
  } = useRequiredAuthorizedUser();
  const isVet = providerType === "VET";

  return useQuery({
    queryKey: [CacheKey.MyEvents, userId],
    queryFn: () =>
      post((isVet ? "api/vet/event" : "api/tech/event") + `?userType=${isVet ? 1 : 2}`, {
        userId,
        zipcode,
        ...(isVet ? { vetId, offset: 1 } : {}),
      }),
    retry: false,
    ...options,
  });
};
