import { useQuery } from "@tanstack/react-query";
import { get } from "../../api/common/rooFetch";
import { getMilestoneStatus } from "../../constants/apiRoutes";
import { CACHE } from "../../api/utility/constants";
import { Milestone } from "@roo-dev/roo-node-types";
import { z } from "zod";

const milestoneStatusResponseSchema = z.object({
  data: z.object({
    completed: z.boolean(),
  }),
});

export type MilestoneStatusResponse = z.infer<typeof milestoneStatusResponseSchema>["data"];

interface UseGetMilestoneStatusParams {
  milestone: Milestone.UserMilestone;
  enabled?: boolean;
}

export const useGetMilestoneStatus = ({
  milestone,
  enabled = true,
}: UseGetMilestoneStatusParams) => {
  return useQuery<MilestoneStatusResponse>({
    queryKey: [CACHE.MILESTONE_STATUS, milestone],
    queryFn: async () => {
      const response = await get<MilestoneStatusResponse>(getMilestoneStatus(milestone));
      const parsed = milestoneStatusResponseSchema.parse(response);
      return parsed.data;
    },
    enabled,
  });
};
