import { useMutation } from "@tanstack/react-query";
import { z } from "zod";

import { post, queryClient } from "@RooBeta/api";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";
import {
  AuthenticatedUser,
  CacheKey,
  Event,
  EventTracker,
  FlexParams,
  ShiftDetails,
} from "@RooBeta/types";
import { isFlexParamsModified } from "@RooBeta/utils";
import { useShiftsStore, useToastStore } from "@RooBeta/store";
import { TOAST_DURATION, TOAST_PRIORITIES } from "@RooBeta/store/useToastStore";
import { shiftHasNoRequests } from "@RooBeta/utils/myShifts";

const shiftRequestResponseSchema = z.object({
  data: z.object({
    added: z.number().optional(),
    message: z.string().optional(),
    data: z
      .object({
        shiftGroupId: z.number().optional(),
      })
      .optional(),
  }),
});

// @TODO: The mobile app logs an event to Braze here. Should we also do that?
// I can't tell if the legacy code logs an event to Braze in this case.
const requestShift = async (
  shift: any,
  flexParams: FlexParams,
  user: AuthenticatedUser,
  isVetBid: boolean,
  isWorkingInterviewAcknowledged?: boolean
) => {
  const baseParams = {
    shiftId: shift.shiftId,
    hospitalId: shift.hospitalId,
    shiftGroupId: shift.shiftGroupId,
    startTime: shift.startTime,
    endTime: shift.endTime,
    hospitalName: shift.hospitalName,
    shiftDate: shift.shiftDate,
    email: user.emailId,
    isReRequest: 0,
    currentTimeStamp: parseInt((new Date().getTime() / 1000 + "").split(".")[0]),
    promoId: shift.promoId,
    isWorkingInterviewAcknowledged: isWorkingInterviewAcknowledged,
  };

  const params =
    user.provider.providerType === "VET"
      ? {
          ...baseParams,
          vetId: user.provider.providerId,
          expectedAppointmentTypeId: shift.expectedAppointmentTypeId,
          expectedNoOfAppointments: shift.expectedNoOfAppointments,
          expectedLunchTime: shift.expectedLunchTime,
          isSurgeryExpected: shift.isSurgeryExpected,
          vetShiftAmount: shift.vetShiftAmount,
          procedureTypes: shift.procedureTypes,
          flexStartTimeRange: null,
          isInstantBook: shift.instantBookable && shift.instantBookable !== 3,
          isVetBid,
          travelStipendAmount: flexParams.needsTravelStipend
            ? flexParams.travelStipendAmount
            : undefined,
          ...flexParams,
        }
      : {
          ...baseParams,
          techId: user.provider.providerId,
          tier: shift.tier,
          techShiftAmount: shift.techShiftAmount,
          travelStipendAmount: flexParams.needsTravelStipend
            ? flexParams.travelStipendAmount
            : undefined,
        };

  return post(
    user.provider.providerType === "VET"
      ? "api/vet/shift/requestShift"
      : "api/tech/shift/requestShift",
    params
  );
};

export const useShiftRequestMutation = (shiftDetails: ShiftDetails | undefined) => {
  const user = useRequiredAuthorizedUser();
  const { shiftList } = useShiftsStore();
  const { show } = useToastStore();

  return useMutation({
    mutationFn: async ({
      flexParams,
      promoId,
      isWorkingInterviewAcknowledged,
    }: {
      flexParams?: FlexParams | null;
      promoId?: number | null;
      isWorkingInterviewAcknowledged?: boolean;
    }): Promise<{
      success: boolean;
      message: string;
    }> => {
      if (!shiftDetails) {
        throw new Error();
      }

      // Calculate total amount including travel stipend
      const totalAmount = (amount: number) => {
        const stipend = flexParams?.needsTravelStipend ? flexParams.travelStipendAmount || 0 : 0;
        return amount + stipend;
      };

      // Check if the vet is the first to request the shift
      const searchShiftsList =
        shiftList.format === "grouped"
          ? shiftList.groups.flatMap((group) => group.shifts)
          : shiftList.shifts;
      const currentSearchedShift = searchShiftsList.find(
        (shift) => shift.shiftId === shiftDetails.shiftId
      );
      const isFirstToRequest = currentSearchedShift
        ? shiftHasNoRequests(currentSearchedShift)
        : false;

      const { startTime, endTime } = shiftDetails;
      const {
        requestedStartTime,
        requestedEndTime,
        isRequestWithoutSurgery,
        needsTravelStipend,
        travelStipendAmount,
      } = flexParams || {};

      const isModified = flexParams
        ? isFlexParamsModified({
            startTime,
            endTime,
            requestedStartTime,
            requestedEndTime,
            isRequestWithoutSurgery,
            needsTravelStipend,
            travelStipendAmount,
          })
        : false;

      const params =
        flexParams && (isModified || !!flexParams.bidPrice)
          ? {
              requestedVetShiftAmount:
                flexParams.bidPrice ??
                totalAmount(
                  flexParams.shiftPricingChanges?.vetShiftAmount ||
                    Number(shiftDetails.vetShiftAmount)
                ),
              requestedHospitalShiftAmount: !flexParams.bidPrice
                ? totalAmount(
                    flexParams.shiftPricingChanges?.shiftAmount || shiftDetails.shiftAmount
                  )
                : undefined,
              requestedShiftStartTime: flexParams.requestedStartTime,
              requestedShiftEndTime: flexParams.requestedEndTime,
              isRequestWithoutSurgery: flexParams.isRequestWithoutSurgery,
              travelStipendAmount: flexParams.needsTravelStipend
                ? flexParams.travelStipendAmount
                : undefined,
            }
          : {};

      const response = await requestShift(
        { ...shiftDetails, promoId },
        params,
        user,
        !!flexParams?.bidPrice,
        isWorkingInterviewAcknowledged
      );
      const result = shiftRequestResponseSchema.safeParse(response);

      if (result.success) {
        const {
          data: { message = "Failed to request shift.", data: { shiftGroupId } = {} },
        } = result.data;

        if (result.success) {
          EventTracker.send({
            eventName: !!user.vetId
              ? Event.Name.VET_SHIFT_PANEL_REQUEST
              : Event.Name.TECH_SHIFT_PANEL_REQUEST,
            eventType: Event.Type.CLICK,
            entityType: !!user.vetId ? Event.Entity.VET_SHIFT : Event.Entity.TECH_SHIFT,
            entityId: shiftDetails?.shiftId,
            context: {
              shiftStatus: "unfilled",
              isNewExperience: true,
              isFirstRequest: isFirstToRequest,
            },
          });
        }

        show({
          variant: "success",
          title: "Your request has been submitted!",
          message: "Request up to 3 shifts per date to maximize your chances of getting confirmed.",
          region: "panel",
          priority: TOAST_PRIORITIES.SERVER_RESPONSE,
          duration: TOAST_DURATION,
        });

        return { success: !!shiftGroupId, message };
      } else {
        return {
          success: false,
          message: "Failed to request shift: invalid response from server.",
        };
      }
    },
    onSuccess: () => {
      const { shiftGroupId, hospitalId } = shiftDetails || {};
      queryClient.invalidateQueries({ queryKey: [CacheKey.Search] });
      queryClient.invalidateQueries({ queryKey: [CacheKey.ShiftDetails, { shiftGroupId }] });
      queryClient.invalidateQueries({ queryKey: [CacheKey.HospitalShifts, { hospitalId }] });
    },
  });
};
