import * as Sentry from "@sentry/react";
import { call, put, takeEvery } from "redux-saga/effects";

import { rooFetch } from "../../../api/common/rooFetch";
import * as ErrorMessage from "../../../constants/errorMessage";
import { UNSET_LOADER, UNSET_MAP_LOADER } from "../../../constants/loader";
import * as ActionTypes from "../../../constants/student";

// @ts-expect-error TS7006
function* searchInternshipsAsync(action) {
  try {
    if (action.payload.isCalendarView) {
      action.payload.startDate = "";
      action.payload.endDate = "";
    }

    // @ts-expect-error TS7057
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/student/internships/searchInternships`,
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );
    // @ts-expect-error TS7057
    const data = yield response.json();

    if (data && data.status_code === 200) {
      const { internships, totalCount, latitude, longitude } = data.data;
      yield put({ type: ActionTypes.INITIALIZE_INTERNSHIP_SEARCH_RESULTS });
      yield put({
        type: ActionTypes.SEARCH_INTERNSHIPS_SUCCESS,
        payload: {
          searchInternshipResult: internships,
          totalCount: totalCount,
          latitude: latitude,
          longitude: longitude,
          isSearchClicked: action.payload.isSearchClicked,
        },
      });
    } else {
      yield put({
        type: ActionTypes.SEARCH_INTERNSHIPS_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
    yield put({ type: UNSET_LOADER });
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while searching internships: " } });
    yield put({
      type: ActionTypes.SEARCH_INTERNSHIPS_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// @ts-expect-error TS7006
function* searchExternshipsAsync(action) {
  try {
    if (action.payload.isCalendarView) {
      action.payload.startDate = "";
      action.payload.endDate = "";
    }

    // @ts-expect-error TS7057
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/student/externships/searchExternships`,
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );
    // @ts-expect-error TS7057
    const data = yield response.json();

    if (data && data.status_code === 200) {
      const { externships, totalCount, latitude, longitude } = data.data;
      yield put({ type: ActionTypes.INITIALIZE_EXTERNSHIP_SEARCH_RESULTS });
      yield put({
        type: ActionTypes.SEARCH_EXTERNSHIPS_SUCCESS,
        payload: {
          searchExternshipResult: externships,
          totalCount: totalCount,
          latitude: latitude,
          longitude: longitude,
          isSearchClicked: action.payload.isSearchClicked,
        },
      });
    } else {
      yield put({
        type: ActionTypes.SEARCH_EXTERNSHIPS_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
    yield put({ type: UNSET_LOADER });
    yield put({ type: UNSET_MAP_LOADER });
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while searching externships: " } });
    yield put({
      type: ActionTypes.SEARCH_EXTERNSHIPS_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootStudentDashboardSaga() {
  yield takeEvery(ActionTypes.SEARCH_INTERNSHIPS_REQUEST, searchInternshipsAsync);
  yield takeEvery(ActionTypes.SEARCH_EXTERNSHIPS_REQUEST, searchExternshipsAsync);
}
