import { Link, Modal, Text } from "@RooUI";
import React from "react";
import { useHistory } from "react-router-dom";

import { useModal } from "@Roo/Common/Modals/hooks/useModal";
import { useHeaderHeight } from "@RooBeta/hooks";

import { ModalHeaderOffsetStyles } from "./styles";

export const BlockedModal = ({ isVet }: { isVet: boolean }) => {
  const { closeModal } = useModal();
  const headerHeight = useHeaderHeight();
  const history = useHistory();
  const handleRedirect = () => {
    history.push(`${isVet ? "/vet" : "/tech"}/editprofile`);
    closeModal();
    window?.zE?.("messenger", "open");
  };

  return (
    <>
      <ModalHeaderOffsetStyles $headerHeight={headerHeight} />
      <Modal
        showModal={true}
        $noClose
        $size="m"
        header="Access to your account is currently paused"
        body={
          <Text>
            As a result, you will not be able to see or request any new shifts. To start booking
            shifts again, please <Link onClick={handleRedirect}>contact us</Link> and a team member
            will be happy to assist you in getting back on track.
          </Text>
        }
        data-testid="blockedModal"
      />
    </>
  );
};
