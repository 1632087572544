import { Skeleton } from "antd";
import React from "react";

import { VStack } from "@RooBeta/components";
import { useShiftDetailsStore } from "@RooBeta/store";
import { SHIFT_CATEGORY } from "@Roo/constants/shiftConstants";

import { HospitalCTA } from "./CTA/HospitalCTA";
import { ShiftCTA } from "./CTA/ShiftCTA";
import { Toast } from "@RooBeta/components/Toast";
import {
  Checkbox,
  Container,
  FlexLayout,
  SmallText,
  spacingL,
  spacingM,
  yellowBackground,
} from "@RooUI";
import { FooterContainer } from "./styles";

export const ShiftDetailsFooter = () => {
  const { shiftDetails, tab, loading, workingInterviewChecked, setWorkingInterviewChecked } =
    useShiftDetailsStore();
  const { shiftStatus } = shiftDetails || {};

  const isWorkingInterview = shiftDetails?.shiftCategory === SHIFT_CATEGORY.WORKING_INTERVIEW;

  return (
    <FlexLayout vertical>
      <Toast activeRegion="panel" />

      {shiftDetails && isWorkingInterview && tab === "info" && shiftStatus === "UNFILLED" && (
        <Container
          backgroundColor={yellowBackground}
          style={{
            padding: `${spacingM} ${spacingL}`,
            marginBottom: 0,
            width: "100%",
            borderRadius: 0,
            border: 0,
          }}
        >
          <Checkbox
            label={
              <SmallText>
                By requesting this shift, I acknowledge that I would consider hiring opportunities
                at this hospital.
              </SmallText>
            }
            checked={workingInterviewChecked}
            onChange={() => setWorkingInterviewChecked(!workingInterviewChecked)}
            data-testid="workingInterviewAckCheckbox"
          />
        </Container>
      )}

      <FooterContainer data-testid="sidePanelFooter">
        {!shiftDetails || loading.shiftDetails ? (
          <VStack $alignItems="center" $padding="m">
            <Skeleton.Button active block />
          </VStack>
        ) : (
          <>
            {tab === "info" && <ShiftCTA />}
            {tab === "hospital" && <HospitalCTA />}
          </>
        )}
      </FooterContainer>
    </FlexLayout>
  );
};
