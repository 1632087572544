import React, { useContext } from "react";
import { Toast } from "@RooUI";
import { Z_INDEX } from "@Roo/constants/zIndex";
import { ToastContext } from "../context/ToastContext";

export const ToastContainer = () => {
  const { toastData, hideToast } = useContext(ToastContext);

  return (
    <>
      {toastData && (
        <Toast
          {...toastData}
          style={{ zIndex: Z_INDEX.TOAST, ...(toastData.style ?? {}) }}
          onHide={hideToast}
        />
      )}
    </>
  );
};
