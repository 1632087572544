import * as C from "../../constants/calendar";

// @ts-expect-error TS7006
export const getShiftDetailsAction = (data) => {
  return {
    type: C.GET_SHIFT_DETAILS,
    payload: data,
  };
};

// @ts-expect-error TS7006
export const confirmVet = (data) => {
  return {
    type: C.CONFIRM_VET_SHIFT_REQUEST,
    payload: data,
  };
};

// @ts-expect-error TS7006
export const confirmVetNoModal = (data) => {
  return {
    type: C.CONFIRM_VET_SHIFT_NO_MODAL,
    payload: data,
  };
};

// @ts-expect-error TS7006
export function confirmShift(data) {
  return {
    type: C.CONFIRM_VET_SHIFT_REQUEST,
    payload: data,
  };
}

// @ts-expect-error TS7006
export function renewShiftAction(data) {
  return {
    type: C.RENEW_SHIFT,
    payload: data,
  };
}

// @ts-expect-error TS7006
export function renewMultipleShiftsAction(data) {
  return {
    type: C.RENEW_MULTIPLE_SHIFTS,
    payload: data,
  };
}

// @ts-expect-error TS7006
export function deleteShiftAction(data) {
  return {
    type: C.DELETE_SHIFT,
    payload: data,
  };
}

export function initializeState() {
  return {
    type: C.INITIALIZE_STATE,
  };
}

export function initializePopUpState() {
  return {
    type: C.INITIALIZE_POPUP_STATE,
  };
}

export function renderLoader() {
  return {
    type: C.SHOW_LOADER,
  };
}

export function closeShiftActionResultMessageModal() {
  return {
    type: C.CLOSE_SHIFT_ACTION_RESULT_MESSAGE_MODAL,
  };
}

// --- tech related

// @ts-expect-error TS7006
export const getTechShiftDetailsAction = (shiftGroupId) => {
  return {
    type: C.GET_TECH_SHIFT_DETAILS,
    payload: shiftGroupId,
  };
};

// @ts-expect-error TS7006
export const confirmTech = (data) => {
  return {
    type: C.CONFIRM_TECH_SHIFT_REQUEST,
    payload: data,
  };
};
// @ts-expect-error TS7006
export const confirmTechNoModal = (data) => {
  return {
    type: C.CONFIRM_TECH_SHIFT_NO_MODAL,
    payload: data,
  };
};

// @ts-expect-error TS7006
export function confirmTechShift(data) {
  return {
    type: C.CONFIRM_TECH_SHIFT_REQUEST,
    payload: data,
  };
}

// @ts-expect-error TS7006
export function deleteTechShiftAction(data) {
  return {
    type: C.DELETE_TECH_SHIFT,
    payload: data,
  };
}

// --- end tech related

// @ts-expect-error TS7006
export function getCalendarNotesAction(data) {
  return {
    type: C.GET_CALENDAR_NOTES,
    payload: data,
  };
}

// @ts-expect-error TS7006
export function declineRequestAction(data) {
  return {
    type: C.DECLINE_REQUEST,
    payload: data,
  };
}

// @ts-expect-error TS7006
export function deleteTechRequestAction(data) {
  return {
    type: C.DECLINE_TECH_REQUEST,
    payload: data,
  };
}

export function clearRenewMultipleShiftsData() {
  return {
    type: C.CLEAR_RENEW_MULTIPLE_SHIFTS_DATA,
  };
}

export function getHospitalLatestShiftDetailsAction(data = {}) {
  return {
    type: C.GET_HOSPITAL_LATEST_SHIFT_DETAILS,
    payload: data,
  };
}

export function resetPanelShiftActionflags() {
  return {
    type: C.RESET_PANEL_SHIFT_ACTION_FLAGS,
  };
}
