import React from "react";

export const Labs = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#303031"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12 21C10.7962 21 9.77083 20.5862 8.924 19.7587C8.07717 18.9311 7.65375 17.9218 7.65375 16.7308V7.65375C7.19358 7.65375 6.80292 7.49642 6.48175 7.18175C6.16058 6.86692 6 6.47942 6 6.01925V4.65375C6 4.19358 6.16058 3.80292 6.48175 3.48175C6.80292 3.16058 7.19358 3 7.65375 3H16.327C16.7872 3 17.1811 3.16058 17.5087 3.48175C17.8363 3.80292 18 4.19358 18 4.65375V6.01925C18 6.47942 17.8363 6.86692 17.5087 7.18175C17.1811 7.49642 16.7872 7.65375 16.327 7.65375V17.0193C16.25 18.1461 15.7945 19.0912 14.9605 19.8547C14.1265 20.6182 13.1397 21 12 21ZM7.65375 6.65375H16.327C16.5065 6.65375 16.6635 6.59292 16.798 6.47125C16.9327 6.34942 17 6.19875 17 6.01925V4.65375C17 4.47425 16.9327 4.32042 16.798 4.19225C16.6635 4.06408 16.5065 4 16.327 4H7.65375C7.47425 4 7.32042 4.06408 7.19225 4.19225C7.06408 4.32042 7 4.47425 7 4.65375V6.01925C7 6.19875 7.06408 6.34942 7.19225 6.47125C7.32042 6.59292 7.47425 6.65375 7.65375 6.65375ZM12 20C12.923 20 13.7083 19.6859 14.3558 19.0578C15.0033 18.4296 15.327 17.6539 15.327 16.7308V15.9808H12.6345C12.4923 15.9808 12.3735 15.933 12.278 15.8375C12.1823 15.742 12.1345 15.6231 12.1345 15.4808C12.1345 15.3384 12.1823 15.2195 12.278 15.124C12.3735 15.0285 12.4923 14.9808 12.6345 14.9808H15.327V12.1345H12.6345C12.4923 12.1345 12.3735 12.0868 12.278 11.9913C12.1823 11.8958 12.1345 11.7768 12.1345 11.6345C12.1345 11.4923 12.1823 11.3735 12.278 11.278C12.3735 11.1823 12.4923 11.1345 12.6345 11.1345H15.327V7.65375H8.65375V16.7308C8.65375 17.6539 8.98067 18.4296 9.6345 19.0578C10.2885 19.6859 11.077 20 12 20Z" />
  </svg>
);

Labs.isCustomSvg = true;
