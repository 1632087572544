import moment from "moment";
import { z } from "zod";

import { Icon, iconSchema } from "./icon";
import { shiftStatusSchema } from "./shiftStatus";

/**
 * LatLng
 */

const latLngSchema = z.object({
  latitude: z.coerce.number(),
  longitude: z.coerce.number(),
  description: z.string().optional(),
});

export type LatLng = z.infer<typeof latLngSchema>;

/**
 * LatLngDelta
 */

export type LatLngDelta = { latitudeDelta: number; longitudeDelta: number };

/**
 * Region
 */

export type Region = LatLng & LatLngDelta;

/**
 * BoundingBox
 */

export const boundingBoxSchema = z.object({ min: latLngSchema, max: latLngSchema });

export type BoundingBox = z.infer<typeof boundingBoxSchema>;

/**
 * UserLocation
 */

export type UserLocation = LatLng;

/**
 * MapLocation
 */

export type MapLocation = { boundingBox: BoundingBox; zoom: number };

/**
 * Rank
 */

export const rankSchema = z.enum(["price", "date", "distance"]);

export type Rank = z.infer<typeof rankSchema>;

/**
 * Filters
 */

const chipIdSchema = z.enum([
  "favorites",
  "workedBefore",
  "sharedShifts",
  "postedForYou",
  "requested",
  "confirmed",
]);

export type ChipId = z.infer<typeof chipIdSchema>;

export enum Weekday {
  Sunday = 1,
  Monday = 2,
  Tuesday = 3,
  Wednesday = 4,
  Thursday = 5,
  Friday = 6,
  Saturday = 7,
}

export const filtersSchema = z.object({
  weekdays: z.array(z.nativeEnum(Weekday)).optional(),
  startTime: z.string().optional(),
  endTime: z.string().optional(),
  minPay: z.preprocess(
    (val) => (val === "" ? undefined : val),
    z.coerce.number().min(1).max(9999).optional()
  ),
  // Shift types
  isGeneralPractice: z.coerce.boolean().optional(),
  isVaccineOnly: z.coerce.boolean().optional(),
  isUrgentCare: z.coerce.boolean().optional(),
  isEmergency: z.coerce.boolean().optional(),
  // Surgery expected?
  isSurgeryExpected: z.coerce.boolean().optional(),
  isSurgeryNotExpected: z.coerce.boolean().optional(),
  // Shift criteria
  isDEALicenseNotRequired: z.coerce.boolean().optional(),
  isNoWalkInsAllowed: z.coerce.boolean().optional(),
  isNoDropOffsAllowed: z.coerce.boolean().optional(),
  additionalDoctor: z.coerce.boolean().optional(),
  isStateWhereLicensed: z.coerce.boolean().optional(),
  isBoostedShiftsOnly: z.coerce.boolean().optional(),
  isInstantBookable: z.coerce.boolean().optional(),
  // Hospital types
  isSmallAnimal: z.coerce.boolean().optional(),
  isExoticAnimal: z.coerce.boolean().optional(),
  isShelterOrNonProfit: z.coerce.boolean().optional(),
  isFearFreeCertified: z.coerce.boolean().optional(),
  // Hospital filters
  includeHospitalNames: z.array(z.string()).optional(),
  excludeHospitalNames: z.array(z.string()).optional(),
  // Appointment number
  expectedNoOfAppointments: z.array(z.string()).optional(),
  // Quick filters
  chipId: chipIdSchema.optional(),

  // Tech tier
  isTier1: z.coerce.boolean().optional(),
  isTier2: z.coerce.boolean().optional(),
  isTier3: z.coerce.boolean().optional(),
  isTier4: z.coerce.boolean().optional(),
});

export type Filters = z.infer<typeof filtersSchema>;

/**
 * Shift
 */

export const shiftMetadataSchema = z.object({
  icon: iconSchema,
  label: z.string(),
  display: z.enum(["list", "tag"]),
});

export type ShiftMetadata = z.infer<typeof shiftMetadataSchema>;

export const shiftSchema = z.object({
  shiftId: z.number(),
  shiftGroupId: z.number(),
  hospitalId: z.number(),
  latitude: z.coerce.number(),
  longitude: z.coerce.number(),
  hospitalName: z.string(),
  hospitalImage: z.string().nullable(),
  distance: z.number().nullable(),
  shiftDate: z.string(),
  shiftDateFormatted: z.string(),
  startTime: z.string().nullable(),
  endTime: z.string().nullable(),
  isFlexibleTime: z.coerce.boolean(),
  price: z.number(),
  priceFormatted: z.string(),
  previousPriceFormatted: z.string().nullable(),
  isFavorite: z.boolean(),
  isWorkedBefore: z.boolean(),
  shiftStatus: shiftStatusSchema,
  metadata: z.array(shiftMetadataSchema),
  bookmarkId: z.number().nullable(),
});

export type Shift = z.infer<typeof shiftSchema>;

/**
 * SearchRequest
 */

const providerSchema = z.object({
  providerType: z.enum(["VET", "TECH", "HOSPITAL"]),
  providerId: z.number(),
});

export type Provider = z.infer<typeof providerSchema>;

const dateSchema = z.coerce.date().transform((date) => moment(date).format("YYYY-MM-DD"));

const dateSpanSchema = z.object({
  format: z.literal("range"),
  startDate: dateSchema,
  endDate: dateSchema,
});

export type DateSpan = z.infer<typeof dateSpanSchema>;

const dateListSchema = z.object({ format: z.literal("dates"), dates: z.array(dateSchema) });

export type DateList = z.infer<typeof dateListSchema>;

const dateRangeSchema = z.discriminatedUnion("format", [dateSpanSchema, dateListSchema]);

export type DateRange = z.input<typeof dateRangeSchema>;

export const searchRequestSchema = z.object({
  providerType: z.enum(["VET", "TECH", "HOSPITAL"]),
  providerId: z.coerce.number(),
  currentLocation: latLngSchema.optional(),
  boundingBox: boundingBoxSchema,
  zoom: z.coerce.number(),
  dateRange: dateRangeSchema,
  rank: rankSchema,
  filters: filtersSchema,
});

export const initialSearchRequestSchema = z.object({
  currentLocation: latLngSchema,
  zoom: z.coerce.number().optional(),
  rank: rankSchema.optional(),
  filters: filtersSchema.optional(),
  boundingBox: boundingBoxSchema.optional(),
});

export type InitialSearchRequest = z.infer<typeof initialSearchRequestSchema>;

export type SearchRequest = z.output<typeof searchRequestSchema>;

/**
 * SearchResponse
 */

export type Pin = {
  hospitalId: number;
  hospitalName: string;
  price: string;
  count: number;
  coordinate: LatLng;
  icon?: Icon;
};

export type Cluster = {
  id: number;
  count: number;
  coordinates: [number, number];
  expansionZoom: number;
};

export type ShiftWithIndex = Shift & { index: number };

export type ShiftGroup = {
  label: string;
  subLabel?: string;
  shifts: ShiftWithIndex[];
};

export type GroupedShiftList = {
  format: "grouped";
  groupBy: "date" | "hospital";
  groups: ShiftGroup[];
  count: number;
};

export type UngroupedShiftList = {
  format: "ungrouped";
  shifts: ShiftWithIndex[];
  count: number;
};

export type ShiftList = GroupedShiftList | UngroupedShiftList;

export type Chip = {
  id: ChipId;
  label: string;
  count: number;
};

export type SearchResponse = {
  shiftList: ShiftList;
  hiddenShiftList: ShiftList;
  pins: Pin[];
  clusters: Cluster[];
  chips: Chip[];
  unclusteredCount: number;
  clusteredCount: number;
  shiftCount: number;
  checksum: string;
  savedSearchRequest: SearchRequest;
};

export type SearchShifts = z.input<typeof searchRequestSchema>;
