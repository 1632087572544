export const getToggleConflictingShiftsText = () => {
  return (isShowing: boolean, count: number | undefined): string => {
    if (count === undefined) return "";

    const action = isShowing ? "Hide" : "Show";
    const pluralSuffix = count === 1 ? "" : "s";

    return `${action} ${count} conflicting shift${pluralSuffix}`;
  };
};
