import React from "react";
import { grayBackground, greenBase, Icon, Link, spacingXXL, spacingXXS } from "@RooUI";
import { getToggleConflictingShiftsText } from "@RooBeta/utils/getToggleConflictingShiftsText";

interface ToggleHiddenShiftsRowProps {
  showHiddenShifts: boolean;
  hiddenShiftsCount?: number;
  onToggle: () => void;
}

export const ToggleHiddenShiftsRow: React.FC<ToggleHiddenShiftsRowProps> = ({
  showHiddenShifts,
  hiddenShiftsCount,
  onToggle,
}) => {
  const getToggleText = getToggleConflictingShiftsText();
  const iconName = showHiddenShifts ? "KeyboardArrowUp" : "KeyboardArrowDown";

  return (
    <div
      style={{
        padding: spacingXXL,
        cursor: "pointer",
        borderBottom: `1px solid ${grayBackground}`,
        display: "flex",
        alignItems: "center",
        gap: spacingXXS,
      }}
      onClick={onToggle}
      data-testid="toggleHiddenShiftsRow"
    >
      <Icon name={iconName} color={greenBase} size="s" />
      <Link>{getToggleText(showHiddenShifts, hiddenShiftsCount)}</Link>
    </div>
  );
};
