import { Modal, Text } from "@RooUI";
import React from "react";
import { useSelector } from "react-redux";

import { useCreatePromoTracking } from "../hooks/api/useCreatePromoTracking";
import { RootState } from "../store";

import { useModal } from "./Modals/hooks/useModal";
import { useHandlePromoPanelOpen } from "./Promo/service";

interface PromoPreClaimShiftsModalProps {
  claimedPromoName: string;
  claimedPromoId: number;
}

export const PromoPreClaimShiftsModal = ({
  claimedPromoId,
  claimedPromoName,
}: PromoPreClaimShiftsModalProps) => {
  const { userId } = useSelector((state: RootState) => state.login);
  const { handlePromoPanelOpen } = useHandlePromoPanelOpen({
    promoId: claimedPromoId,
    promoName: claimedPromoName,
  });

  const { reset } = useCreatePromoTracking({ userId });

  const { closeModal } = useModal();

  return (
    <Modal
      showModal={true}
      onCloseModal={() => {
        closeModal();
        reset();
      }}
      header="Hey, high-hopper!"
      body={
        <Text data-testid={`modalAddShifts${claimedPromoId}`}>
          Awesome news! You've already got some shifts that qualify for this promo, and we've added
          them to your progress. Keep leaping toward the finish line!
        </Text>
      }
      $primaryButtonProps={{
        title: "See My Progress",
        onClick: () => {
          handlePromoPanelOpen();
          closeModal();
          reset();
        },
      }}
      $secondaryButtonProps={{
        title: "Review Later",
        onClick: () => {
          closeModal();
          reset();
        },
      }}
      $size="l"
    />
  );
};
