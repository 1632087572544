import React from "react";

const loader = (props: { showLoader: boolean }) => {
  const { showLoader } = props;
  return (
    <div
      className={`custom-loader page-loader${showLoader ? " d-block" : ""}`}
      data-testid="pageLoader"
    />
  );
};

// eslint-disable-next-line no-restricted-exports
export default loader;
