import * as Sentry from "@sentry/react";
import { call, put, takeEvery } from "redux-saga/effects";

import { APIResponse } from "../../../api/common/response";
import { rooFetch } from "../../../api/common/rooFetch";
import * as C from "../../../constants/adminExternships";
import { EXCEPTION_MESSAGE } from "../../../constants/errorMessage";
import * as LoaderActions from "../../../constants/loader";
import {
  DeleteExternshipAction,
  GetExternshipListAction,
  UpdateExternshipAction,
} from "../actions/ExternshipActions";
import {
  DeleteExternshipResponse,
  UpdateExternshipResponse,
} from "../components/ExternshipBulkUpload/types";

export function* getExternshipList(action: GetExternshipListAction) {
  try {
    const {
      startDate,
      endDate,
      stateId,
      isNonRooStates,
      metroAreaId,
      isNoMetroAreaOnly,
      isOpen,
      isClosed,
      isRemoved,
      isShowFulfilledOnly,
      isShowUnfulfilledOnly,
      isFilterByDate,
      isShowTestAccountsOnly,
    } = action.payload;

    const params: any = { startDate, endDate };
    if (stateId) {
      params.stateId = stateId;
    }
    if (isNonRooStates) {
      params.isNonRooStates = 1;
    }
    if (metroAreaId) {
      params.metroAreaId = metroAreaId;
    }
    if (isNoMetroAreaOnly) {
      params.isNoMetroAreaOnly = 1;
    }
    if (isOpen) {
      params.isOpen = 1;
    }
    if (isClosed) {
      params.isClosed = 1;
    }
    if (isRemoved) {
      params.isRemoved = 1;
    }
    if (isShowFulfilledOnly) {
      params.isShowFulfilledOnly = 1;
    }
    if (isShowUnfulfilledOnly) {
      params.isShowUnfulfilledOnly = 1;
    }
    if (isFilterByDate) {
      params.isFilterByDate = 1;
    }
    if (isShowTestAccountsOnly) {
      params.isShowTestAccountsOnly = 1;
    }

    const response: Response = yield call(
      rooFetch,
      `${window.RooConfig.API_URL}api/admin/getExternships`,
      {
        method: "POST",
        body: JSON.stringify(params),
      }
    );

    const responseData: APIResponse<any> = yield response.json();

    yield put({ type: LoaderActions.UNSET_LOADER });
    if (responseData.status_code === 200) {
      yield put({
        type: C.GET_EXTERNSHIP_LIST_SUCCESS,
        payload: {
          data: responseData.data.externships,
        },
      });
    } else {
      yield put({
        type: C.GET_EXTERNSHIP_LIST_FAIL,
        payload: {
          status: responseData.status_code,
          message: responseData.message || EXCEPTION_MESSAGE,
        },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting externship list: " } });
    yield put({
      type: C.GET_EXTERNSHIP_LIST_FAIL,
      payload: { status: error.status_code, message: error.message },
    });
  }
}

function* updateExternshipAsync(action: UpdateExternshipAction) {
  try {
    const { externshipId } = action.payload;
    yield put({ type: LoaderActions.SET_LOADER });
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/student/externships/updateExternship/${externshipId}`,
      {
        method: "PUT",
        body: JSON.stringify(action.payload),
      }
    );
    const data: UpdateExternshipResponse = yield response.json();
    yield put({ type: LoaderActions.UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: C.UPDATE_EXTERNSHIP_SUCCESS,
        payload: { status: data.status_code },
      });
    } else {
      yield put({
        type: C.UPDATE_EXTERNSHIP_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while updating externship: " } });
    yield put({
      type: C.UPDATE_EXTERNSHIP_FAIL,
      payload: { status: error.status_code, message: EXCEPTION_MESSAGE },
    });
  }
}

function* deleteExternshipAsync(action: DeleteExternshipAction) {
  try {
    yield put({ type: LoaderActions.SET_LOADER });
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/student/externships/deleteExternship`,
      {
        method: "PUT",
        body: JSON.stringify(action.payload),
      }
    );

    const data: DeleteExternshipResponse = yield response.json();
    yield put({ type: LoaderActions.UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: C.DELETE_EXTERNSHIP_SUCCESS,
        payload: { status: data.status_code },
      });
    } else {
      yield put({
        type: C.DELETE_EXTERNSHIP_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while deleting externship: " } });
    yield put({
      type: C.DELETE_EXTERNSHIP_FAIL,
      payload: { status: error.status_code, message: EXCEPTION_MESSAGE },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootAdminExternshipSaga() {
  yield takeEvery(C.GET_EXTERNSHIP_LIST, getExternshipList);
  yield takeEvery(C.UPDATE_EXTERNSHIP_REQUEST, updateExternshipAsync);
  yield takeEvery(C.DELETE_EXTERNSHIP_REQUEST, deleteExternshipAsync);
}
