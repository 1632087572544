import React, { useEffect, useState } from "react";

import {
  createStudentDataAccount,
  CreateStudentResponseData,
  EventStudentSignUpForm,
} from "../../../../api/student";
import { STUDENT_USER_TYPE } from "../../../../constants/UserTypeConstants";
import { loginRequestAction } from "../../../../Login/Action/LoginActions";
import { useAppDispatch } from "../../../../store/service";
import { useValidate } from "../common/useValidate";
import { getUtmParams } from "../common/util";

import { StudentSignUp } from "./StudentSignUp";
import { FormProvider } from "./useFormContext";

export type { EventStudentSignUpForm };

const initialForm: EventStudentSignUpForm = {
  userTypeId: STUDENT_USER_TYPE,
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  phoneNumber: "",
  zipcode: "",
  schoolId: 0,
  studentSchool: "",
  graduationYear: "",
  acceptedTerms: false,
  acceptedSmsMessages: false,
  utmSource: "",
  utmCampaign: "",
  utmMedium: "",
};

export const StudentSignUpContainer = ({
  booth,
  onSuccess,
  eventSlug,
}: {
  booth: boolean;
  onSuccess: (data: CreateStudentResponseData) => void;
  eventSlug: string;
}) => {
  const [defaultForm, setDefaultForm] = useState<EventStudentSignUpForm>(initialForm);
  const dispatch = useAppDispatch();
  const {
    utm_source: utmSource,
    utm_campaign: utmCampaign,
    utm_medium: utmMedium,
  } = getUtmParams(eventSlug, booth);
  const { validate } = useValidate();

  // Update UTM params when they change
  useEffect(() => {
    setDefaultForm((prev: EventStudentSignUpForm) => ({
      ...prev,
      utmSource,
      utmCampaign,
      utmMedium,
    }));
  }, [utmSource, utmCampaign, utmMedium]);

  const save = async (form: EventStudentSignUpForm) => {
    if (!form.acceptedTerms) {
      throw new Error("You must accept the Terms & Conditions to continue");
    }

    const { otherStudentSchool, ...formData } = form;

    if (formData.schoolId === 1 && otherStudentSchool) {
      formData.studentSchool = otherStudentSchool; //if other school is selected, copy other school input over
    }

    const result = await createStudentDataAccount(formData);

    if (!result.success) {
      throw new Error(result.error);
    }

    if (booth) {
      return onSuccess(result.data);
    }

    const { email, password } = form;
    dispatch(loginRequestAction({ email, password, signInRequest: result.signInRequest }));
  };

  return (
    <FormProvider
      defaultForm={defaultForm}
      save={save}
      validate={(form: EventStudentSignUpForm) =>
        validate({ providerType: "student" as const, form })
      }
    >
      <StudentSignUp booth={booth} />
    </FormProvider>
  );
};
