import * as C from "../../../constants/referNewUser";

const initialState = {
  isUserReferred: null,
  isUserExist: false,
  userList: [],
  isUserFetch: null,
  isReminderEmailSent: null,
  isUserFetchStarted: null,
  inviteCount: 0,
  currentReferralPromoAmount: "",
  totalReferralPromoAmount: 0,
};

/* eslint-disable default-param-last */
// @ts-expect-error TS7006
const referNewUserReducer = (state = initialState, action) => {
  /* eslint-enable default-param-last */
  switch (action.type) {
    case C.REFER_NEW_USER_SUCCESS:
      return {
        ...state,
        isUserReferred: true,
      };

    case C.REFER_NEW_USER_FAIL:
      return {
        ...state,
        isUserReferred: false,
      };

    case C.INITIALIZE_REFER_USER_STATE:
      return {
        ...state,
        isUserReferred: null,
        isUserExist: false,
        userList: [],
        isUserFetch: null,
        isReminderEmailSent: null,
        isUserFetchStarted: null,
        inviteCount: 0,
      };

    case C.REFER_USER_EXIST:
      return {
        ...state,
        isUserExist: true,
      };
    case C.FETCH_REFERRED_USER_REQUEST:
      return {
        ...state,
        isUserFetchStarted: true,
      };

    case C.FETCH_REFERRED_USER_FAIL:
      return {
        ...state,
        userList: [],
        isUserFetch: false,
        isUserFetchStarted: null,
        inviteCount: 0,
      };

    case C.FETCH_REFERRED_USER_SUCCESS:
      return {
        ...state,
        isUserFetch: true,
        userList: action.payload.userList,
        isUserFetchStarted: null,
        currentReferralPromoAmount: action.payload.amount,
        totalReferralPromoAmount: action.payload.totalAmount,
        inviteCount: action.payload.inviteCount,
      };

    case C.SEND_REMINDER_EMAIL_SUCCESS:
      return {
        ...state,
        isReminderEmailSent: true,
      };

    case C.SEND_REMINDER_EMAIL_FAIL:
      return {
        ...state,
        isReminderEmailSent: false,
      };

    case C.GET_REFERRAL_PROMO_AMOUNT_SUCCESS:
      return {
        ...state,
        currentReferralPromoAmount: action.payload.currentReferralPromoAmount,
      };

    default:
      return {
        ...state,
      };
  }
};

// eslint-disable-next-line no-restricted-exports
export default referNewUserReducer;
