import * as C from "../../../constants/techConstants";

const initialState = {
  onboardingInfo: [],
  isLoaded: false,
};

/* eslint-disable default-param-last */
// @ts-expect-error TS7006
const techOnboardingReducer = (state = initialState, action) => {
  /* eslint-enable default-param-last */
  switch (action.type) {
    case C.UPDATE_TECH_SHOW_ONBOARDING_SUCCESS: {
      return {
        ...state,
        isShowTechReferralModal: true,
      };
    }
    case C.CLOSE_TECH_REFERRAL_MODAL: {
      return {
        ...state,
        isShowTechReferralModal: false,
      };
    }
    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default techOnboardingReducer;
