import * as C from "../../../constants/hospitalConstants";

// @ts-expect-error TS7006
export function fetchHospitalSettingInformationAction(payload) {
  return {
    type: C.FETCH_HOSPITAL_SETTING_INFO,
    payload,
  };
}

// @ts-expect-error TS7006
export function fetchMyAccountSettingInfoAction(payload) {
  return {
    type: C.FETCH_MY_ACCOUNT_SETTING_INFO,
    payload,
  };
}

// @ts-expect-error TS7006
export function updateMyAccountSettingInfoAction(payload) {
  return {
    type: C.UPDATE_MY_ACCOUNT_SETTING_INFO,
    payload,
  };
}

// @ts-expect-error TS7006
export function updateBillingEmails(data) {
  return {
    type: C.UPDATE_BILLING_EMAILS,
    payload: data,
  };
}

// @ts-expect-error TS7006
export function addHospitalCardAction(data) {
  return {
    type: C.ADD_HOSPITAL_CARD_REQUEST,
    payload: data,
  };
}

// @ts-expect-error TS7006
export function deleteHospitalCardAction(data) {
  return {
    type: C.DELETE_HOSPITAL_CARD_REQUEST,
    payload: data,
  };
}

// @ts-expect-error TS7006
export function updateHospitalSettingsAction(data) {
  return {
    type: C.UPDATE_HOSPITAL_SETTING_INFORMATION,
    payload: data,
  };
}

// @ts-expect-error TS7006
export function changePrimaryContactAction(data) {
  return {
    type: C.CHANGE_PRIMARY_CONTACT,
    payload: data,
  };
}

// @ts-expect-error TS7006
export function setHospitalDefaultCardAction(data) {
  return {
    type: C.SET_DEFAULT_CARD_REQUEST,
    payload: data,
  };
}

export function initializeHospitalSettingsAction() {
  return {
    type: C.INITIALIZE_HOSPITAL_SETTINGS,
  };
}

export function resetUpdateBillingEmailsFlag() {
  return {
    type: C.RESET_UPDATE_BILLING_EMAILS_FLAG,
  };
}
