import * as Sentry from "@sentry/react";
// @ts-expect-error TS6133
import lodash from "lodash";
import { call, put, takeEvery } from "redux-saga/effects";

import { checkDST } from "../../../Common/dateUtils";
import * as hospitalSaga from "../../../Hospital/saga/hospitalSaga";
import { ToastType } from "../../../ProfilePanels/ProfilePanelTypes";
import { CLOSE_PROFILE_PANEL, SHOW_TOAST_FOR_PANEL } from "../../../ProfilePanels/constants";
import { rooFetch } from "../../../api/common/rooFetch";
import * as adminShiftActionTypes from "../../../constants/adminShiftsList";
import { SHIFT_REQUEST } from "../../../constants/brazeEventNameConstants";
import { EDIT_SHIFT_CONFIRMED_MORE_THAN_90_DAYS_AGO } from "../../../constants/confirmMessage";
import * as ErrorMessage from "../../../constants/errorMessage";
import * as LoaderActions from "../../../constants/loader";
import { UNSET_LOADER } from "../../../constants/loader";
import { logBrazeCustomEvent } from "../../../helpers/brazeUtility";

// @ts-expect-error TS7006
export function* getShiftList(action) {
  try {
    const {
      isVetShifts,
      startDate,
      endDate,
      stateId,
      isNonRooStates,
      metroAreaId,
      isNoMetroAreaOnly,
      isToday,
      isCompleted,
      isRemoved,
      isCancelled,
      isShowFulfilledOnly,
      isShowUnfulfilledOnly,
      isShowUnpaidOnly,
      contractorId,
      hospitalId,
      shiftGroupId,
      isMostRecentLimit,
      searchText,
    } = action.payload;

    const params = { startDate, endDate };
    if (stateId) {
      // @ts-expect-error TS2339
      params.stateId = stateId;
    }
    if (isNonRooStates) {
      // @ts-expect-error TS2339
      params.isNonRooStates = 1;
    }
    if (metroAreaId) {
      // @ts-expect-error TS2339
      params.metroAreaId = metroAreaId;
    }
    if (isNoMetroAreaOnly) {
      // @ts-expect-error TS2339
      params.isNoMetroAreaOnly = 1;
    }
    if (isToday) {
      // @ts-expect-error TS2339
      params.isToday = 1;
    }
    if (isCompleted) {
      // @ts-expect-error TS2339
      params.isCompleted = 1;
    }
    if (isRemoved) {
      // @ts-expect-error TS2339
      params.isRemoved = 1;
    }
    if (isCancelled) {
      // @ts-expect-error TS2339
      params.isCancelled = 1;
    }
    if (isShowFulfilledOnly) {
      // @ts-expect-error TS2339
      params.isShowFulfilledOnly = 1;
    }
    if (isShowUnfulfilledOnly) {
      // @ts-expect-error TS2339
      params.isShowUnfulfilledOnly = 1;
    }
    if (isShowUnpaidOnly) {
      // @ts-expect-error TS2339
      params.isShowUnpaidOnly = 1;
    }
    if (contractorId) {
      if (isVetShifts) {
        // @ts-expect-error TS2339
        params.vetId = contractorId;
      } else {
        // @ts-expect-error TS2339
        params.techId = contractorId;
      }
    }

    if (hospitalId) {
      // @ts-expect-error TS2339
      params.hospitalId = hospitalId;
    }

    if (shiftGroupId) {
      // @ts-expect-error TS2339
      params.shiftGroupId = shiftGroupId;
    }

    if (isMostRecentLimit) {
      // @ts-expect-error TS2339
      params.isMostRecentLimit = 1;
    }

    if (searchText) {
      // @ts-expect-error TS2339
      params.searchParam = searchText;
    }

    const urlSegment = isVetShifts ? "api/admin/getShifts" : "api/admin/getTechShifts";

    // @ts-expect-error TS7057
    const response = yield call(rooFetch, `${window.RooConfig.API_URL}${urlSegment}`, {
      method: "POST",
      body: JSON.stringify(params),
    });

    // @ts-expect-error TS7057
    const responseData = yield response.json();

    yield put({ type: LoaderActions.UNSET_LOADER });
    if (responseData.status_code === 200) {
      yield put({
        type: adminShiftActionTypes.GET_SHIFT_LIST_SUCCESS,
        payload: {
          data: responseData.data.shifts,
          totalCount: responseData.data.totalCount,
          isVetShifts: isVetShifts,
        },
      });
      sessionStorage.setItem("searchTechFilter", JSON.stringify(action.payload));
    } else {
      yield put({
        type: adminShiftActionTypes.GET_SHIFT_LIST_FAIL,
        payload: {
          status: responseData.status_code,
          message: responseData.message || ErrorMessage.EXCEPTION_MESSAGE,
        },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Admin: Error while getting shift list" },
    });
    yield put({
      type: adminShiftActionTypes.GET_SHIFT_LIST_FAIL,
      payload: { status: error.status_code, message: error.message },
    });
  }
}

// @ts-expect-error TS7006
export function* getShiftLogs(action) {
  try {
    const { shiftGroupId, isVetShift } = action.payload;
    const apiRoute = isVetShift ? "vet" : "tech";

    // @ts-expect-error TS7057
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL +
        "api/" +
        apiRoute +
        "/shift/getShiftLogsById?shiftGroupId=" +
        shiftGroupId
    );

    yield put({ type: LoaderActions.UNSET_LOADER });

    // @ts-expect-error TS7057
    const responseData = yield response.json();
    if (responseData.status_code == 200) {
      yield put({
        type: adminShiftActionTypes.GET_SHIFT_LOGS_SUCCESS,
        payload: {
          data: responseData.data.data,
        },
      });
    } else {
      yield put({
        type: adminShiftActionTypes.GET_SHIFT_LOGS_FAIL,
        payload: {
          status: responseData.status_code,
          message: responseData.message || ErrorMessage.EXCEPTION_MESSAGE,
        },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Admin: Error while getting shift logs" },
    });
    yield put({ type: LoaderActions.UNSET_LOADER });
    yield put({
      type: adminShiftActionTypes.GET_SHIFT_LOGS_FAIL,
      payload: {
        status: error.status_code,
        message: error.message,
      },
    });
  }
}

// @ts-expect-error TS7006
export function* getShiftBillingInfo(action) {
  try {
    const urlSegment = "api/admin/getShiftBillingInfo";
    // @ts-expect-error TS7057
    const response = yield call(rooFetch, `${window.RooConfig.API_URL}${urlSegment}`, {
      method: "POST",
      body: JSON.stringify(action.payload),
    });

    // @ts-expect-error TS7057
    const responseData = yield response.json();
    yield put({ type: LoaderActions.UNSET_LOADER });
    if (responseData.status_code === 200) {
      yield put({
        type: adminShiftActionTypes.GET_SHIFT_BILLING_INFO_SUCCESS,
        payload: {
          data: responseData.data.data,
        },
      });
    } else {
      yield put({
        type: adminShiftActionTypes.GET_SHIFT_BILLING_INFO_FAIL,
        payload: { status: responseData.status_code, message: responseData.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Admin: Error while getting shift billing info" },
    });
    yield put({
      type: adminShiftActionTypes.GET_SHIFT_BILLING_INFO_FAIL,
      payload: { status: error.status_code, message: error.message },
    });
  }
}

// @ts-expect-error TS7006
export function* getShiftAdditionalPayInfo(action) {
  try {
    //getShiftBillingInfo API will serve both OT(isShowOnlyShiftOTAmt is true) and billinginfo(isShowOnlyShiftOTAmt is false)
    const { type } = action.payload;
    if (type === "overtime") {
      action.payload.isOvertime = true;
    } else if (type === "shift_bonus") {
      action.payload.isShiftBonus = true;
    } else if (type === "rttr") {
      action.payload.isRTTR = true;
    } else if (type === "other") {
      action.payload.isOther = true;
    }
    const urlSegment = "api/admin/getShiftBillingInfo";
    // @ts-expect-error TS7057
    const response = yield call(rooFetch, `${window.RooConfig.API_URL}${urlSegment}`, {
      method: "POST",
      body: JSON.stringify(action.payload),
    });

    // @ts-expect-error TS7057
    const responseData = yield response.json();
    yield put({ type: LoaderActions.UNSET_LOADER });
    if (responseData.status_code === 200) {
      const { isOvertime, isShiftBonus, isRTTR, isOther } = action.payload;
      yield put({
        type: adminShiftActionTypes.GET_SHIFT_ADDITIONAL_PAY_INFO_SUCCESS,
        payload: {
          data: responseData.data.data,
          isOvertime,
          isShiftBonus,
          isRTTR,
          isOther,
        },
      });
    } else {
      yield put({
        type: adminShiftActionTypes.GET_SHIFT_ADDITIONAL_PAY_INFO_FAIL,
        payload: {
          status: responseData.status_code,
          message: responseData.message || ErrorMessage.EXCEPTION_MESSAGE,
        },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Admin: Error while getting shift additional pay info" },
    });
    yield put({
      type: adminShiftActionTypes.GET_SHIFT_ADDITIONAL_PAY_INFO_FAIL,
      payload: { status: error.status_code, message: error.message },
    });
  }
}

// @ts-expect-error TS7006
export function* getContractorAdditionalPayInfo(action) {
  try {
    const { type } = action.payload;
    if (type === "overtime") {
      action.payload.isOvertime = true;
    } else if (type === "shift_bonus") {
      action.payload.isShiftBonus = true;
    } else if (type === "rttr") {
      action.payload.isRTTR = true;
    } else if (type === "other") {
      action.payload.isOther = true;
    }
    const urlSegment = "api/admin/getContractorAdditionalPayInfo";
    // @ts-expect-error TS7057
    const response = yield call(rooFetch, `${window.RooConfig.API_URL}${urlSegment}`, {
      method: "POST",
      body: JSON.stringify(action.payload),
    });

    // @ts-expect-error TS7057
    const responseData = yield response.json();

    yield put({ type: LoaderActions.UNSET_LOADER });
    if (responseData.status_code === 200) {
      const { isOvertime, isShiftBonus, isRTTR, isOther } = action.payload;
      yield put({
        type: adminShiftActionTypes.GET_CONTRACTOR_ADDITIONAL_PAY_SUCCESS,
        payload: {
          data: responseData.data.data[0],
          isOvertime,
          isShiftBonus,
          isRTTR,
          isOther,
        },
      });
    } else {
      yield put({
        type: adminShiftActionTypes.GET_CONTRACTOR_ADDITIONAL_PAY_FAIL,
        payload: {
          status: responseData.status_code,
          message: responseData.message || ErrorMessage.EXCEPTION_MESSAGE,
        },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Admin: Error while getting contractor additional pay info" },
    });
    yield put({
      type: adminShiftActionTypes.GET_CONTRACTOR_ADDITIONAL_PAY_FAIL,
      payload: { status: error.status_code, message: error.message },
    });
  }
}

// @ts-expect-error TS7006
export function* getCalloutList(action) {
  try {
    const urlSegment = "api/admin/getCalloutList";
    // @ts-expect-error TS7057
    const response = yield call(rooFetch, `${window.RooConfig.API_URL}${urlSegment}`, {
      method: "POST",
      body: JSON.stringify(action.payload),
    });

    // @ts-expect-error TS7057
    const responseData = yield response.json();

    yield put({ type: LoaderActions.UNSET_LOADER });
    if (responseData.status_code === 200) {
      yield put({
        type: adminShiftActionTypes.GET_CALLOUT_LIST_SUCCESS,
        payload: {
          data: responseData.data.data,
        },
      });
    } else {
      yield put({
        type: adminShiftActionTypes.GET_CALLOUT_LIST_FAIL,
        payload: {
          status: responseData.status_code,
          message: responseData.message || ErrorMessage.EXCEPTION_MESSAGE,
        },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Admin: Error while getting callout list" },
    });
    yield put({
      type: adminShiftActionTypes.GET_CALLOUT_LIST_FAIL,
      payload: { status: error.status_code, message: error.message },
    });
  }
}

// @ts-expect-error TS7006
export function* getRatings(action) {
  try {
    const urlSegment = "api/admin/getRatings";

    // @ts-expect-error TS7057
    const response = yield call(rooFetch, `${window.RooConfig.API_URL}${urlSegment}`, {
      method: "POST",
      body: JSON.stringify(action.payload),
    });

    // @ts-expect-error TS7057
    const responseData = yield response.json();

    yield put({ type: LoaderActions.UNSET_LOADER });
    if (responseData.status_code === 200) {
      yield put({
        type: adminShiftActionTypes.GET_ADMIN_RATINGS_SUCCESS,
        payload: {
          data: responseData.data.data[0],
        },
      });
    } else {
      yield put({
        type: adminShiftActionTypes.GET_ADMIN_RATINGS_FAIL,
        payload: {
          status: responseData.status_code,
          message: responseData.message || ErrorMessage.EXCEPTION_MESSAGE,
        },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Admin: Error while getting ratings: " } });
    yield put({
      type: adminShiftActionTypes.GET_ADMIN_RATINGS_FAIL,
      payload: { status: error.status_code, message: error.message },
    });
  }
}

// @ts-expect-error TS7006
export function* adminUpdateRatings(action) {
  try {
    const oUpdate = {
      ...action.payload,
      adminUserId: localStorage.getItem("userId"),
    };
    const urlSegment = "api/admin/updateRatings";

    // @ts-expect-error TS7057
    const response = yield call(rooFetch, `${window.RooConfig.API_URL}${urlSegment}`, {
      method: "POST",
      body: JSON.stringify(oUpdate),
    });

    // @ts-expect-error TS7057
    const responseData = yield response.json();

    yield put({ type: LoaderActions.UNSET_LOADER });
    if (responseData.status_code === 200) {
      yield put({
        type: adminShiftActionTypes.ADMIN_UPDATE_RATINGS_SUCCESS,
        payload: {
          data: responseData.data.shifts,
        },
      });
    } else {
      yield put({
        type: adminShiftActionTypes.ADMIN_UPDATE_RATINGS_FAIL,
        payload: {
          status: responseData.status_code,
          message: responseData.message || ErrorMessage.EXCEPTION_MESSAGE,
        },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Admin: Error while updating ratings: " } });
    yield put({
      type: adminShiftActionTypes.ADMIN_UPDATE_RATINGS_FAIL,
      payload: { status: error.status_code, message: error.message },
    });
  }
}

// @ts-expect-error TS7006
export function* adminEditShiftDetailsAsync(action) {
  try {
    // @ts-expect-error TS7057
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/hospital/shift/updateShift",
      {
        method: "PUT",
        body: JSON.stringify(action.payload),
      }
    );

    // @ts-expect-error TS7057
    const data = yield response.json();

    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      if (data.message === EDIT_SHIFT_CONFIRMED_MORE_THAN_90_DAYS_AGO) {
        yield put({
          type: adminShiftActionTypes.ADMIN_EDIT_SHIFT_SUCCESS,
          payload: { status: data.status_code, isConfirmedMoreThan90DaysAgo: true },
        });
      } else {
        yield put({
          type: adminShiftActionTypes.ADMIN_EDIT_SHIFT_SUCCESS,
          payload: { status: data.status_code, isVetBid: action.payload.isVetBid },
        });
      }
    } else {
      yield put({
        type: adminShiftActionTypes.ADMIN_EDIT_SHIFT_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Admin: Error while editing shift: " } });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: adminShiftActionTypes.ADMIN_EDIT_SHIFT_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// @ts-expect-error TS7006
export function* adminEditShiftPriceAsync(action) {
  let apiURL = "api/admin/shifts/";
  const oSave = action.payload;
  oSave.adminUserId = localStorage.getItem("userId");
  if (!action.payload.isVetShift) {
    apiURL = "api/admin/techShifts/";
  }
  try {
    // @ts-expect-error TS7057
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + apiURL + action.payload.shiftId + "/pricing",
      {
        method: "PUT",
        body: JSON.stringify(oSave),
      }
    );

    // @ts-expect-error TS7057
    const data = yield response.json();
    yield put({ type: LoaderActions.UNSET_LOADER });
    if (data.status_code === 200) {
      if (data.message === EDIT_SHIFT_CONFIRMED_MORE_THAN_90_DAYS_AGO) {
        yield put({
          type: adminShiftActionTypes.ADMIN_EDIT_SHIFT_PRICE_SUCCESS,
          payload: { status: data.status_code, isConfirmedMoreThan90DaysAgo: true },
        });
      } else {
        yield put({
          type: adminShiftActionTypes.ADMIN_EDIT_SHIFT_PRICE_SUCCESS,
          payload: { status: data.status_code },
        });
      }
    } else {
      yield put({
        type: adminShiftActionTypes.ADMIN_EDIT_SHIFT_PRICE_FAIL,
        payload: { status: data.status_code },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Admin: Error while editing shift price" },
    });
    yield put({ type: LoaderActions.UNSET_LOADER });
    yield put({
      type: adminShiftActionTypes.ADMIN_EDIT_SHIFT_PRICE_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// @ts-expect-error TS7006
function* adminPayToContractorAsync(action) {
  let apiURL;
  const isVetShift = !!action.payload.vetId;

  if (isVetShift) {
    apiURL = "api/admin/transfer";
  } else {
    apiURL = "api/admin/transfer/tech";
  }

  try {
    // @ts-expect-error TS7057
    const response = yield call(rooFetch, window.RooConfig.API_URL + apiURL, {
      method: "POST",
      body: JSON.stringify(action.payload),
    });

    // @ts-expect-error TS7057
    const data = yield response.json();

    yield put({ type: LoaderActions.UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: adminShiftActionTypes.PAY_TO_VET_SUCCESS,
        payload: { status: data.status_code },
      });
    } else {
      yield put({
        type: adminShiftActionTypes.PAY_TO_VET_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Admin: Error while paying to contractor" },
    });
    yield put({ type: LoaderActions.UNSET_LOADER });
    yield put({
      type: adminShiftActionTypes.PAY_TO_VET_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// @ts-expect-error TS7006
export function* adminAddShiftAsync(action) {
  try {
    // @ts-expect-error TS7057
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/hospital/shift/addPastShift",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    // @ts-expect-error TS7057
    const data = yield response.json();

    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      yield put({
        type: adminShiftActionTypes.ADMIN_ADD_SHIFT_SUCCESS,
        payload: { status: data.status_code },
      });
    } else {
      yield put({
        type: adminShiftActionTypes.ADMIN_ADD_SHIFT_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Admin: Error while adding shift: " } });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: adminShiftActionTypes.ADMIN_ADD_SHIFT_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// @ts-expect-error TS7006
export function* adminRemoveShiftRequestAsync(action) {
  let apiURL = "api/admin/shiftRequest/";

  if (action.payload.techs) {
    apiURL = "api/admin/techShift/request";
  }

  delete action.payload.shiftAmount;

  try {
    // @ts-expect-error TS7057
    const response = yield call(rooFetch, window.RooConfig.API_URL + apiURL, {
      method: "DELETE",
      body: JSON.stringify(action.payload),
    });

    // @ts-expect-error TS7057
    const data = yield response.json();

    yield put({ type: LoaderActions.UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({ type: CLOSE_PROFILE_PANEL });
      yield put({
        type: SHOW_TOAST_FOR_PANEL,
        payload: {
          toastType: ToastType.AdminShiftRequestRemoved,
        },
      });
      yield put({
        type: adminShiftActionTypes.ADMIN_REMOVE_SHIFT_REQUEST_SUCCESS,
        payload: { status: data.status_code },
      });
    } else {
      yield put({
        type: adminShiftActionTypes.ADMIN_REMOVE_SHIFT_REQUEST_FAIL,
        payload: { status: data.status_code },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Admin: Error while removing shift request" },
    });
    yield put({
      type: adminShiftActionTypes.ADMIN_REMOVE_SHIFT_REQUEST_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// @ts-expect-error TS7006
function* adminCancelPaymentAsync(action) {
  try {
    // @ts-expect-error TS7057
    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/admin/transfer", {
      method: "DELETE",
      body: JSON.stringify(action.payload),
    });

    // @ts-expect-error TS7057
    const data = yield response.json();
    yield put({ type: LoaderActions.UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: adminShiftActionTypes.CANCEL_PAYMENT_SUCCESS,
        payload: { status: data.status_code },
      });
    } else {
      yield put({
        type: adminShiftActionTypes.CANCEL_PAYMENT_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Admin: Error while cancelling payment" },
    });
    yield put({ type: LoaderActions.UNSET_LOADER });
    yield put({
      type: adminShiftActionTypes.CANCEL_PAYMENT_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// @ts-expect-error TS7006
export function* adminConfirmShiftRequestAsync(action) {
  try {
    if (action.payload.requestedShiftStartTime) {
      action.payload.isDST = checkDST(action.payload.shiftDate);
    }
    const oConfirm = {
      ...action.payload,
      adminUserId: action.payload.adminUserId,
    };
    yield put({ type: LoaderActions.SET_LOADER });
    // @ts-expect-error TS7057
    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/admin/confirmShift", {
      method: "POST",
      body: JSON.stringify(oConfirm),
    });

    // @ts-expect-error TS7057
    const data = yield response.json();
    yield put({ type: LoaderActions.UNSET_LOADER });
    yield put({ type: CLOSE_PROFILE_PANEL });
    if (data.status_code === 200) {
      yield put({
        type: SHOW_TOAST_FOR_PANEL,
        payload: {
          toastType: ToastType.AdminShiftRequestConfirmed,
        },
      });
      yield put({
        type: adminShiftActionTypes.ADMIN_CONFIRM_SHIFT_REQUEST_SUCCESS,
        payload: { status: data.status_code, message: data.message },
      });
    } else {
      yield put({
        type: adminShiftActionTypes.ADMIN_CONFIRM_SHIFT_REQUEST_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Admin: Error while confirming shift request" },
    });
    yield put({
      type: adminShiftActionTypes.ADMIN_CONFIRM_SHIFT_REQUEST_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// @ts-expect-error TS6133, TS7006
function* getAllVetsAsync(action) {
  try {
    // @ts-expect-error TS7057
    const response = yield call(rooFetch, window.RooConfig.API_URL + `api/vet/vet`);
    // @ts-expect-error TS7057
    const data = yield response.json();
    yield put({ type: LoaderActions.UNSET_LOADER });

    if (data.status_code === 200) {
      yield put({
        type: adminShiftActionTypes.ADMIN_GET_ALL_VETS_SUCCESS,
        payload: { status: data.status_code, vetList: data.data.data },
      });
    } else {
      yield put({
        type: adminShiftActionTypes.ADMIN_GET_ALL_VETS_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Admin: Error while getting all vets: " } });
    yield put({ type: LoaderActions.UNSET_LOADER });

    yield put({
      type: adminShiftActionTypes.ADMIN_GET_ALL_VETS_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// @ts-expect-error TS7006
function* requestVetShiftAsync(action) {
  try {
    // @ts-expect-error TS7057
    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/vet/shift/requestShift", {
      method: "POST",
      body: JSON.stringify(action.payload),
    });

    // @ts-expect-error TS7057
    const data = yield response.json();
    yield put({ type: LoaderActions.UNSET_LOADER });

    if (data.status_code === 200) {
      if (data.data.added === 0) {
        yield put({
          type: adminShiftActionTypes.ADMIN_REQUEST_TECH_SHIFT_OVERLAPS,
          payload: { status: data.status_code, message: data.data.message },
        });
      } else {
        yield put({
          type: adminShiftActionTypes.ADMIN_REQUEST_SHIFT_SUCCESS,
          payload: { status: data.status_code, data: data.data.data },
        });
        logBrazeCustomEvent(SHIFT_REQUEST, { shift_id: action.payload.shiftId });
      }
    } else if (data.status_code === 400 || data.status_code === 500) {
      yield put({
        type: adminShiftActionTypes.ADMIN_REQUEST_SHIFT_FAIL,
        payload: { status: data.status_code, message: data.data.data },
      });
    } else if (data.status_code === 412) {
      yield put({
        type: adminShiftActionTypes.ADMIN_REQUEST_TECH_SHIFT_OVERLAPS,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Admin: Error while requesting vet shift" },
    });
    yield put({
      type: adminShiftActionTypes.ADMIN_REQUEST_SHIFT_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// @ts-expect-error TS7006
function* requestAndConfirmShiftAsync(action) {
  try {
    // @ts-expect-error TS7057
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/admin/requestAndConfirmShift",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    // @ts-expect-error TS7057
    const data = yield response.json();
    yield put({ type: LoaderActions.UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: adminShiftActionTypes.ADMIN_REQUEST_AND_CONFIRM_SHIFT_SUCCESS,
        payload: { status: data.status_code, data: data.data.data },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: adminShiftActionTypes.ADMIN_REQUEST_AND_CONFIRM_SHIFT_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Admin: Error while requesting and confirming shift" },
    });
    yield put({ type: LoaderActions.UNSET_LOADER });
    yield put({
      type: adminShiftActionTypes.ADMIN_REQUEST_AND_CONFIRM_SHIFT_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// @ts-expect-error TS6133, TS7006
function* getAlltechsAsync(action) {
  try {
    // @ts-expect-error TS7057
    const response = yield call(rooFetch, window.RooConfig.API_URL + `api/tech/tech`);
    // @ts-expect-error TS7057
    const data = yield response.json();
    yield put({ type: LoaderActions.UNSET_LOADER });

    if (data.status_code === 200) {
      yield put({
        type: adminShiftActionTypes.ADMIN_GET_ALL_TECHS_SUCCESS,
        payload: { status: data.status_code, techList: data.data.data },
      });
    } else {
      yield put({
        type: adminShiftActionTypes.ADMIN_GET_ALL_TECHS_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Admin: Error while getting all techs" },
    });
    yield put({ type: LoaderActions.UNSET_LOADER });

    yield put({
      type: adminShiftActionTypes.ADMIN_GET_ALL_TECHS_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// @ts-expect-error TS7006
function* requestTechShiftAsync(action) {
  try {
    // @ts-expect-error TS7057
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/tech/shift/requestShift",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    // @ts-expect-error TS7057
    const data = yield response.json();
    yield put({ type: LoaderActions.UNSET_LOADER });
    if (data.status_code === 200) {
      if (data.data.added === 0) {
        yield put({
          type: adminShiftActionTypes.ADMIN_REQUEST_TECH_SHIFT_OVERLAPS,
          payload: { status: data.status_code, message: data.data.message },
        });
      } else {
        yield put({
          type: adminShiftActionTypes.ADMIN_REQUEST_TECH_SHIFT_SUCCESS,
          payload: { status: data.status_code, data: data.data.data },
        });
        logBrazeCustomEvent(SHIFT_REQUEST, { shift_id: action.payload.shiftId });
      }
    } else if (data.status_code === 400) {
      yield put({
        type: adminShiftActionTypes.ADMIN_REQUEST_TECH_SHIFT_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    } else if (data.status_code === 412) {
      yield put({
        type: adminShiftActionTypes.ADMIN_REQUEST_TECH_SHIFT_OVERLAPS,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Admin: Error while requesting tech shift" },
    });
    yield put({ type: LoaderActions.UNSET_LOADER });

    yield put({
      type: adminShiftActionTypes.ADMIN_REQUEST_TECH_SHIFT_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// @ts-expect-error TS7006
export function* adminAddVetAdditionalPayoutAsync(action) {
  try {
    // @ts-expect-error TS7057
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/admin/addVetAdditionalPayout",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    // @ts-expect-error TS7057
    const data = yield response.json();
    yield put({ type: LoaderActions.UNSET_LOADER });

    if (data.status_code === 200) {
      yield hospitalSaga.getShiftDetailsAsync(action.payload);
      if (data.message?.overtimeInvoiceExists) {
        yield put({ type: adminShiftActionTypes.ADMIN_OVERTIME_ALREADY_EXISTS });
      } else {
        yield put({
          type: adminShiftActionTypes.ADMIN_VET_ADDITIONAL_PAYOUT_SUCCESS,
          payload: { status: data.status_code, data: data },
        });
      }
    } else {
      yield put({
        type: adminShiftActionTypes.ADMIN_VET_ADDITIONAL_PAYOUT_FAIL,
        payload: { status: data.status_code, message: data.data || ErrorMessage.EXCEPTION_MESSAGE },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Admin: Error while adding vet additional payout" },
    });
    yield put({ type: LoaderActions.UNSET_LOADER });

    yield put({
      type: adminShiftActionTypes.ADMIN_VET_ADDITIONAL_PAYOUT_FAIL,
      payload: { status: error.status_code, message: error.data || ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// @ts-expect-error TS7006
export function* adminAddTechAdditionalPayoutAsync(action) {
  try {
    // @ts-expect-error TS7057
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/admin/addTechAdditionalPayout",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );
    yield put({ type: LoaderActions.UNSET_LOADER });
    // @ts-expect-error TS7057
    const data = yield response.json();

    if (data.status_code === 200) {
      yield hospitalSaga.getTechShiftDetailsAsync(action.payload);
      if (data.message?.overtimeInvoiceExists) {
        yield put({ type: adminShiftActionTypes.ADMIN_OVERTIME_ALREADY_EXISTS });
      } else {
        yield put({
          type: adminShiftActionTypes.ADMIN_TECH_ADDITIONAL_PAYOUT_SUCCESS,
          payload: { status: data.status_code, data: data },
        });
      }
    } else {
      yield put({
        type: adminShiftActionTypes.ADMIN_TECH_ADDITIONAL_PAYOUT_FAIL,
        payload: { status: data.status_code, message: data.data || ErrorMessage.EXCEPTION_MESSAGE },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Admin: Error while adding tech additional payout" },
    });
    yield put({ type: LoaderActions.UNSET_LOADER });
    yield put({
      type: adminShiftActionTypes.ADMIN_TECH_ADDITIONAL_PAYOUT_FAIL,
      payload: { status: error.status_code, message: error.data || ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// @ts-expect-error TS7006
function* adminEditTechShiftAsync(action) {
  try {
    // @ts-expect-error TS7057
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/hospital/tech_shift/updateShift",
      {
        method: "PUT",
        body: JSON.stringify(action.payload),
      }
    );

    // @ts-expect-error TS7057
    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      if (data.message === EDIT_SHIFT_CONFIRMED_MORE_THAN_90_DAYS_AGO) {
        yield put({
          type: adminShiftActionTypes.ADMIN_EDIT_TECH_SHIFT_SUCCESS,
          payload: { status: data.status_code, isConfirmedMoreThan90DaysAgo: true },
        });
      } else {
        yield put({
          type: adminShiftActionTypes.ADMIN_EDIT_TECH_SHIFT_SUCCESS,
          payload: { status: data.status_code },
        });
      }
    } else {
      yield put({
        type: adminShiftActionTypes.ADMIN_EDIT_TECH_SHIFT_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Admin: Error while editing tech shift" },
    });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: adminShiftActionTypes.ADMIN_EDIT_TECH_SHIFT_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// @ts-expect-error TS7006
function* adminAddTechShiftAsync(action) {
  try {
    // @ts-expect-error TS7057
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/hospital/tech_shift/addPastShift",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    // @ts-expect-error TS7057
    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: adminShiftActionTypes.ADMIN_ADD_TECH_SHIFT_SUCCESS,
        payload: { status: data.status_code },
      });
    } else {
      yield put({
        type: adminShiftActionTypes.ADMIN_ADD_TECH_SHIFT_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Admin: Error while adding tech shift" },
    });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: adminShiftActionTypes.ADMIN_ADD_TECH_SHIFT_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// @ts-expect-error TS7006
function* sendNoReplacementFoundEmail(action) {
  try {
    yield put({ type: LoaderActions.SET_LOADER });
    // @ts-expect-error TS7057
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/admin/sendNoReplacementFoundEmail",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    // @ts-expect-error TS7057
    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({
        type: adminShiftActionTypes.GET_SHIFT_LIST,
        // @ts-expect-error TS2345
        payload: JSON.parse(sessionStorage.getItem("searchTechFilter")),
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Admin: Error while sending no replacement found email" },
    });
    yield put({ type: UNSET_LOADER });
  }
}

// @ts-expect-error TS7006
function* bulkDeleteShifts(action) {
  try {
    const { isVetShifts } = action.payload;
    const apiEndpoint = isVetShifts
      ? "api/hospital/shift/bulkDeleteShifts"
      : "api/hospital/tech_shift/bulkDeleteShifts";
    // @ts-expect-error TS2345
    action.payload.userId = parseInt(localStorage.getItem("userId"));

    // @ts-expect-error TS7057
    const response = yield call(rooFetch, window.RooConfig.API_URL + apiEndpoint, {
      method: "PUT",
      body: JSON.stringify(action.payload),
    });

    // @ts-expect-error TS7057
    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({
        type: adminShiftActionTypes.GET_SHIFT_LIST,
        payload: { status: data.status_code },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Admin: Error while bulk deleting shifts" },
    });
    yield put({ type: UNSET_LOADER });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootAdminShiftSaga() {
  yield takeEvery(adminShiftActionTypes.GET_SHIFT_LIST, getShiftList);
  yield takeEvery(adminShiftActionTypes.GET_SHIFT_LOGS, getShiftLogs);
  yield takeEvery(adminShiftActionTypes.GET_CALLOUT_LIST, getCalloutList);
  yield takeEvery(adminShiftActionTypes.GET_SHIFT_BILLING_INFO, getShiftBillingInfo);
  yield takeEvery(adminShiftActionTypes.GET_SHIFT_ADDITIONAL_PAY_INFO, getShiftAdditionalPayInfo);
  yield takeEvery(
    adminShiftActionTypes.GET_CONTRACTOR_ADDITIONAL_PAY,
    getContractorAdditionalPayInfo
  );
  yield takeEvery(adminShiftActionTypes.GET_ADMIN_RATINGS, getRatings);
  yield takeEvery(adminShiftActionTypes.ADMIN_UPDATE_RATINGS, adminUpdateRatings);
  yield takeEvery(adminShiftActionTypes.ADMIN_EDIT_SHIFT, adminEditShiftDetailsAsync);
  yield takeEvery(adminShiftActionTypes.ADMIN_ADD_SHIFT, adminAddShiftAsync);
  yield takeEvery(adminShiftActionTypes.ADMIN_EDIT_SHIFT_PRICE, adminEditShiftPriceAsync);
  yield takeEvery(adminShiftActionTypes.ADMIN_REMOVE_SHIFT_REQUEST, adminRemoveShiftRequestAsync);
  yield takeEvery(adminShiftActionTypes.ADMIN_CONFIRM_SHIFT_REQUEST, adminConfirmShiftRequestAsync);
  yield takeEvery(adminShiftActionTypes.PAY_TO_CONTRACTOR_REQUEST, adminPayToContractorAsync);
  yield takeEvery(adminShiftActionTypes.CANCEL_PAYMENT_REQUEST, adminCancelPaymentAsync);
  yield takeEvery(adminShiftActionTypes.ADMIN_GET_ALL_VETS_REQUEST, getAllVetsAsync);
  yield takeEvery(adminShiftActionTypes.ADMIN_REQUEST_SHIFT_REQUEST, requestVetShiftAsync);
  yield takeEvery(adminShiftActionTypes.ADMIN_GET_ALL_TECHS_REQUEST, getAlltechsAsync);
  yield takeEvery(adminShiftActionTypes.ADMIN_REQUEST_TECH_SHIFT_REQUEST, requestTechShiftAsync);
  yield takeEvery(
    adminShiftActionTypes.ADMIN_VET_ADDITIONAL_PAYOUT_REQUEST,
    adminAddVetAdditionalPayoutAsync
  );
  yield takeEvery(
    adminShiftActionTypes.ADMIN_TECH_ADDITIONAL_PAYOUT_REQUEST,
    adminAddTechAdditionalPayoutAsync
  );
  yield takeEvery(
    adminShiftActionTypes.ADMIN_REQUEST_AND_CONFIRM_SHIFT_REQUEST,
    requestAndConfirmShiftAsync
  );
  yield takeEvery(adminShiftActionTypes.ADMIN_EDIT_TECH_SHIFT, adminEditTechShiftAsync);
  yield takeEvery(adminShiftActionTypes.ADMIN_ADD_TECH_SHIFT, adminAddTechShiftAsync);

  yield takeEvery(adminShiftActionTypes.NO_REPLACEMENT_FOUND_EMAIL, sendNoReplacementFoundEmail);
  yield takeEvery(adminShiftActionTypes.BULK_DELETE_SHIFTS, bulkDeleteShifts);
}
