import { useRooFeatureFlagEnabled } from "@Roo/Common/Wrappers/useRooFeatureFlagEnabled";
import { FEATURE_FLAGS } from "@Roo/constants/postHogFeatureFlags";
import * as Sentry from "@sentry/react";
import React from "react";

import { Navigation } from "@RooBeta/components";
import { useAuthorizedUser, useNewDesignExperiment } from "@RooBeta/hooks";

import OldHeader from "./HeaderLoggedIn";

const ErrorFallback = ({ referEnabled }: { referEnabled: boolean }) => (
  // @ts-expect-error TS2322
  <OldHeader referEnabled={referEnabled} />
);

export const LoadHeader = () => {
  const { isEnabled } = useNewDesignExperiment();
  const { user } = useAuthorizedUser();
  const isHospitalReferralEnabled = useRooFeatureFlagEnabled(
    FEATURE_FLAGS.HOSPITALS_REFER_HOSPITALS
  );

  return isEnabled && (!!user?.vetId || !!user?.techId) ? (
    <Navigation />
  ) : (
    // @ts-expect-error TS2322
    <OldHeader referEnabled={!!isHospitalReferralEnabled} />
  );
};

export const HeaderNavigation = () => {
  const isHospitalReferralEnabled = useRooFeatureFlagEnabled(
    FEATURE_FLAGS.HOSPITALS_REFER_HOSPITALS
  );

  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback referEnabled={!!isHospitalReferralEnabled} />}>
      <LoadHeader />
    </Sentry.ErrorBoundary>
  );
};
