import { useMemo } from "react";

import { useSearchShiftsStore, useShiftDetailsStore } from "@RooBeta/store";
import { Shift, ViewportSize } from "@RooBeta/types";
import { ShiftCardPointOfEntry } from "@Roo/constants/posthogEventContextTypes";

const MAX_LIST_ITEMS = 4;

export type ShiftCardVariant = "list" | "carousel" | "drawer" | "action_list";

export type ShiftCardElements =
  | "date_column"
  | "date"
  | "flexible_time"
  | "hospital_logo"
  | "hospital_name"
  | "favorite"
  | "status"
  | "currently_viewing"
  | "chevron";

type UseShiftMetadata = {
  shift: Shift;
  viewport: ViewportSize;
  variant: ShiftCardVariant;
  pointOfEntry: ShiftCardPointOfEntry;
};

export const useShiftMetadata = ({ shift, viewport, variant, pointOfEntry }: UseShiftMetadata) => {
  const { shiftGroupId: viewingShiftGroupId } = useShiftDetailsStore();
  const { rank } = useSearchShiftsStore();

  /**
   * Special cases:
   *  - Don't show the date and time in the `list` for wide viewports.
   *  - Always show the date and time in the `carousel` (if it's not already present then add it).
   */

  const listItems = useMemo(() => {
    const filtered = shift.metadata
      .filter(({ display }) => display === "list")
      .filter(
        ({ icon }) =>
          viewport < ViewportSize.l ||
          variant !== "list" ||
          (icon !== "calendar_month" && icon !== "time")
      );

    if (variant === "carousel" && !filtered.some(({ icon }) => icon === "calendar_month")) {
      filtered.unshift({
        icon: "calendar_month",
        label: shift.shiftDateFormatted,
        display: "list",
      });
    }

    return variant === "drawer" ? filtered : filtered.slice(0, MAX_LIST_ITEMS);
  }, [shift, viewport, variant]);

  /**
   * Special cases:
   *  - Don't show the "Fear Free" tag in the drawer.
   */

  const tags = useMemo(
    () =>
      listItems.length < MAX_LIST_ITEMS || shift.hospitalName.length < 40
        ? shift.metadata
            .filter(({ display }) => display === "tag")
            .filter(({ icon }) => icon !== "general_practice" || variant !== "drawer")
        : [],
    [shift, listItems.length, variant]
  );

  const elements = useMemo(() => {
    const elementSet: Set<ShiftCardElements> = new Set([]);

    if (viewport >= ViewportSize.l && variant === "list") {
      elementSet.add("date_column");
    }

    if (
      (rank !== "date" && pointOfEntry !== ShiftCardPointOfEntry.MyShiftsPane) ||
      variant === "drawer"
    ) {
      elementSet.add("date");
    }

    if (shift.isFlexibleTime) {
      elementSet.add("flexible_time");
    }

    if (variant !== "drawer") {
      elementSet.add("hospital_logo");
    }

    if (
      !(rank === "distance" && pointOfEntry === ShiftCardPointOfEntry.SearchShiftsPane) &&
      variant !== "drawer"
    ) {
      elementSet.add("hospital_name");
    }

    if (shift.isFavorite) {
      elementSet.add("favorite");
    }

    if (shift.shiftStatus === "CONFIRMED" || shift.shiftStatus === "REQUESTED") {
      elementSet.add("status");
    }

    if (variant === "drawer" && viewingShiftGroupId === shift.shiftGroupId) {
      elementSet.add("currently_viewing");
    }

    if (variant === "list") {
      elementSet.add("chevron");
    }

    return elementSet;
  }, [shift, viewport, variant, rank, viewingShiftGroupId, pointOfEntry]);

  return { listItems, tags, elements };
};
