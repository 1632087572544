import {
  Chip,
  FlexLayout,
  Modal,
  SmallText,
  Text,
  grayLight400,
  purpleBase,
  greenBase,
} from "@RooUI";
import styled from "styled-components";
import React, { useState } from "react";
import { HStack, RooIcon, VStack } from "@RooBeta/components";
import { ShiftDetailsMetadata as Metadata } from "@RooBeta/types";
import { PreviousPrice } from "./styles";

const DATA_TESTID_MAP: Record<string, string> = {
  Date: "shiftDate",
  Time: "shiftTime",
  "Total pay": "totalPay",
  "Shift type": "shiftType",
  "Expected lunch/break time": "expectedLunchBreakTime",
  "Estimated appointment length": "averageAppointmentDuration",
  Surgery: "surgery",
  "Number of appointments": "numberOfAppointments",
  "Additional doctors": "additionalDoctors",
  "Walk-ins": "isWalkIns",
  "Drop-offs": "isDropOffs",
  "Tech tier": "techTier",
};

const EmailLink = styled.a`
  color: ${greenBase};
  text-decoration: none;
`;

const InfoIcon = styled(RooIcon)`
  cursor: pointer;
`;

// Modal content based on infoModalType
const infoModalContent: Record<string, { header: string; body: React.ReactNode }> = {
  appointmentType: {
    header: "Working interview shifts",
    body: (
      <FlexLayout vertical gap="l">
        <Text>
          <Chip text="BETA" borderColor={purpleBase} type="label" /> Introducing — working interview
          shifts!
        </Text>
        <Text>
          This hospital is looking to hire a Vet. Please only request this shift if you would
          seriously consider part-time or full-time work at the hospital. However, by requesting the
          shift you are not making any commitment beyond completing the shift as scheduled. Please
          reach out to <EmailLink href="mailto:placements@roo.vet">placements@roo.vet</EmailLink> if
          you have questions.
        </Text>
      </FlexLayout>
    ),
  },
};

export const ShiftDetailsMetadata = ({ metadata }: { metadata?: Metadata[] }) => {
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [currentInfoModalType, setCurrentInfoModalType] = useState<string | null>(null);

  const handleOpenModal = (infoModalType: string) => {
    setCurrentInfoModalType(infoModalType);
    setShowInfoModal(true);
  };

  const handleCloseModal = () => {
    setShowInfoModal(false);
  };

  return (
    <VStack $gap="m" $padding="l">
      {metadata?.map(({ icon, title, value, updatedValue, infoModalType }) => (
        <HStack $gap="m" key={title}>
          <RooIcon icon={icon} size="l" />
          <VStack>
            <HStack $gap="xs" $alignItems="center">
              {updatedValue ? (
                <>
                  <div>{updatedValue}</div>
                  <PreviousPrice>{value}</PreviousPrice>
                </>
              ) : (
                <div
                  data-testid={
                    DATA_TESTID_MAP[title] || `metadata-${title.toLowerCase().replace(/\s+/g, "-")}`
                  }
                >
                  {value}
                </div>
              )}
              {infoModalType && (
                <InfoIcon
                  aria-label="Information icon"
                  icon="info"
                  size="s"
                  onClick={() => handleOpenModal(infoModalType)}
                  data-testid={`infoIcon${title.replace(/\s+/g, "")}`}
                />
              )}
            </HStack>
            <SmallText color={grayLight400}>{title}</SmallText>
          </VStack>
        </HStack>
      ))}

      {currentInfoModalType && infoModalContent[currentInfoModalType] && (
        <Modal
          showModal={showInfoModal}
          onCloseModal={handleCloseModal}
          header={infoModalContent[currentInfoModalType].header}
          body={infoModalContent[currentInfoModalType].body}
          $primaryButtonProps={{
            title: "Got It",
            onClick: handleCloseModal,
          }}
          $size="m"
          data-testid="infoModal"
        />
      )}
    </VStack>
  );
};
