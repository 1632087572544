import * as Sentry from "@sentry/react";
import { Auth } from "aws-amplify";
import { call, put, takeEvery } from "redux-saga/effects";

import { rooFetch } from "../../../api/common/rooFetch";
import * as LoginAction from "../../../constants/Login";
import { SET_AUTHORISE_USER } from "../../../constants/Login";
import * as userTypes from "../../../constants/UserTypeConstants";
import * as C from "../../../constants/enterpriseConstants";
import * as errorMessage from "../../../constants/errorMessage";

// @ts-expect-error TS7006
function* registerEnterpriseHospitalUserAsync(action) {
  try {
    const { email, password, isFromMobile, userId, enterpriseId, enterpriseName } = action.payload;
    // @ts-expect-error TS7057
    const oSignUp = yield Auth.signUp({
      username: email,
      password: password,
      attributes: {
        "custom:usertype": String(userTypes.HOSPITAL_USER_TYPE),
      },
    });
    const cognitoId = oSignUp.userSub;
    // isFromRegistration = true;

    yield put({ type: SET_AUTHORISE_USER }); //TO set authorize flag once user registered
    yield putRegisterEnterpriseHospitalUserData({
      email,
      password,
      isFromMobile,
      userId,
      enterpriseId,
      enterpriseName,
      cognitoId,
    });
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while registering enterprise hospital user: " },
    });
    yield put({
      type: C.REGISTER_ENTERPRISE_HOSPITAL_USER_FAIL,
      payload: { status: 409, message: error.message },
    });
  }
}

// @ts-expect-error TS7006
function* putRegisterEnterpriseHospitalUserData(userData) {
  try {
    const { email, password, isFromMobile, userId, enterpriseId, enterpriseName, cognitoId } =
      userData;
    // @ts-expect-error TS7057
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/enterprise/registerEnterpriseHospitalUser",
      {
        method: "POST",
        body: JSON.stringify({
          email,
          isFromMobile,
          userId,
          cognitoId,
          enterpriseId,
          enterpriseName,
        }),
      }
    );

    // @ts-expect-error TS7057
    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({ type: LoginAction.LOGIN_REQUEST, payload: { email, password } });
      yield put({
        type: C.REGISTER_ENTERPRISE_HOSPITAL_USER_SUCCESS,
        payload: {
          status: data.status_code,
          userId: userId,
          enterpriseId: enterpriseId,
          enterpriseName: enterpriseName,
          email: email,
        },
      });
      yield put({ type: SET_AUTHORISE_USER }); //TO set authorize flag once user registered
    } else if (data.status_code === 400) {
      yield put({
        type: C.REGISTER_ENTERPRISE_HOSPITAL_USER_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while registering enterprise hospital user: " },
    });
    yield put({
      type: C.REGISTER_ENTERPRISE_HOSPITAL_USER_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// @ts-expect-error TS7006
function* registerEnterpriseHospitalAsync(action) {
  try {
    // @ts-expect-error TS6133
    const { email, password, userId } = action.payload;
    // @ts-expect-error TS7057
    const oSignUp = yield Auth.signUp({
      username: email,
      password: password,
      attributes: {
        "custom:usertype": String(userTypes.HOSPITAL_USER_TYPE),
      },
    });
    const cognitoId = oSignUp.userSub;

    yield put({ type: SET_AUTHORISE_USER }); //TO set authorize flag once user registered
    yield putRegisterEnterpriseHospitalData({
      ...action.payload,
      cognitoId,
    });
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while registering enterprise hospital: " },
    });
    yield put({
      type: C.REGISTER_ENTERPRISE_HOSPITAL_FAIL,
      payload: { status: 409, message: error.message },
    });
  }
}

// @ts-expect-error TS7006
function* putRegisterEnterpriseHospitalData(userData) {
  try {
    const { email, password, userId, hospitalId, cognitoId, enterpriseId } = userData;
    // @ts-expect-error TS7057
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/enterprise/registerEnterpriseHospital",
      {
        method: "POST",
        body: JSON.stringify({
          email,
          userId,
          cognitoId,
          enterpriseId,
        }),
      }
    );

    // @ts-expect-error TS7057
    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({ type: LoginAction.LOGIN_REQUEST, payload: { email, password } });
      yield put({
        type: C.REGISTER_ENTERPRISE_HOSPITAL_SUCCESS,
        payload: {
          status: data.status_code,
          hospitalId,
          userId,
          email,
        },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: C.REGISTER_ENTERPRISE_HOSPITAL_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while registering enterprise hospital: " },
    });
    yield put({
      type: C.REGISTER_ENTERPRISE_HOSPITAL_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootRegisterEnterpriseSaga() {
  yield takeEvery(C.REGISTER_ENTERPRISE_HOSPITAL_USER, registerEnterpriseHospitalUserAsync);
  yield takeEvery(C.REGISTER_ENTERPRISE_HOSPITAL, registerEnterpriseHospitalAsync);
}
